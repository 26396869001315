import { TREATMENT_PRODUCT_STATUS } from '@/modules/dashboard/api/constants';

export default class CompositionProduct {
  constructor({
    name,
    productName,
    descriptions = [],
    price,
    multiplier,
    isSerum,
    status,
    microneedling
  }) {
    this.name = name;
    this.productName = productName;
    this.descriptions = descriptions;
    this.price = price;
    this.multiplier = multiplier;
    this.isSerum = isSerum;
    this.isDeleted = status === TREATMENT_PRODUCT_STATUS.REMOVED_BY_PATIENT;
    this.isUpdated =
      status === TREATMENT_PRODUCT_STATUS.ADDED_BY_PATIENT ||
      status === TREATMENT_PRODUCT_STATUS.MODIFIED_BY_PATIENT;
    this.microneedling = microneedling;
  }
}
