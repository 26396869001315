import { BOOLEAN, GENDER, TREATMENT, AMOUNT } from './constants';

// Generic helpers

export const includes = (array, value) => array && array.includes(value);

export const includesAny = (array, values) =>
  array && values && values.some(value => array.includes(value));

export const equalsAny = (value, array) => array.some(v => value === v);

// Boolean helpers

export const yes = value =>
  value === BOOLEAN.YES || value === AMOUNT.CONSIDERABLY || value === AMOUNT.MODERATELY;

export const no = value => value === BOOLEAN.NO;

export const any = (...values) => values.some(yes);

export const all = (...values) => values.every(yes);

// Gender helpers

export const female = gender => gender === GENDER.FEMALE;
export const male = gender => gender === GENDER.MALE;
export const other = gender => gender === GENDER.OTHER;

// Sought treatment helpers

export const face = soughtTreatments =>
  soughtTreatments && soughtTreatments.includes(TREATMENT.FACE);

export const body = soughtTreatments =>
  soughtTreatments && soughtTreatments.includes(TREATMENT.BODY);

export const eyeContour = soughtTreatments =>
  soughtTreatments && soughtTreatments.includes(TREATMENT.EYE_CONTOUR);

export const onlyFace = soughtTreatments =>
  soughtTreatments && soughtTreatments.length === 1 && soughtTreatments.includes(TREATMENT.FACE);

export const onlyEyeContour = soughtTreatments =>
  soughtTreatments &&
  soughtTreatments.length === 1 &&
  soughtTreatments.includes(TREATMENT.EYE_CONTOUR);

export const onlyBody = soughtTreatments =>
  soughtTreatments && soughtTreatments.length === 1 && soughtTreatments.includes(TREATMENT.BODY);

export const faceOrBody = soughtTreatments =>
  includesAny(soughtTreatments, [TREATMENT.FACE, TREATMENT.BODY]);

// Skin Dysfunction

export const hasSkinDysfunction = dysfunction => dysfunction !== '' && !no(dysfunction);

export const isAnySkinDysfunctions = ({
  thinningSkin,
  saggingSkin,
  saggingSkinLocations,
  dryingSkin,
  dryOpenPores,
  wrinkles,
  darkCircles,
  eyeBags,
  stretchMarks,
  acneScars,
  sensitiveSkin,
  flushes,
  skinRedness,
  prominentBloodVessels,
  skinDryness,
  greasySkin,
  skinRoughness,
  comedones,
  patches,
  proneToAcne,
  hyperpigmentation,
  wishesLightening
}) =>
  hasSkinDysfunction(wrinkles) ||
  hasSkinDysfunction(skinRoughness) ||
  any(
    thinningSkin,
    saggingSkin,
    saggingSkinLocations,
    dryingSkin,
    dryOpenPores,
    darkCircles,
    eyeBags,
    stretchMarks,
    acneScars,
    sensitiveSkin,
    flushes,
    skinRedness,
    prominentBloodVessels,
    skinDryness,
    greasySkin,
    comedones,
    patches,
    proneToAcne,
    hyperpigmentation,
    wishesLightening
  );

// Convert to PascalCase

export const pascalCase = str => {
  if (typeof str !== 'string') {
    throw new TypeError('expected a string.');
  }

  let newString = str.replace(/([A-Z])/g, ' $1');

  if (newString.length === 1) {
    return newString.toUpperCase();
  }

  newString = newString.replace(/^[\W_]+|[\W_]+$/g, '').toLowerCase();
  newString = newString.charAt(0).toUpperCase() + newString.slice(1);

  return newString.replace(/[\W_]+(\w|$)/g, (_, ch) => ch.toUpperCase());
};

export const getLocalizedScarCause = (i18n, cause) => i18n.t(`scarCause.${cause}`);

export const flagAiSelectedOption = (options, aiSelectedValue) =>
  options.map(option => ({ ...option, aiSelected: option.value === aiSelectedValue }));

export const flagAiSelectedArrayOptions = (options, aiSelectedValues) =>
  options.map(option => ({ ...option, aiSelected: aiSelectedValues.includes(option.value) }));
