import { DEFAULT_LOCALE } from '@/constants';
import { types } from '@/store/types';

export const getInitialState = () => ({
  [types.state.STATUS]: {
    loading: false,
    report: {}
  },
  [types.state.USER_NAME]: null,
  [types.state.IS_NEW_DESIGN]: true,
  [types.state.USER]: null,
  [types.state.USER_AUTHENTICATION_DATA]: {
    recoveryCodes: [],
    authenticatationCode: {}
  },
  [types.state.LOCALE]: DEFAULT_LOCALE
});
