import { INGREDIENTS_COLORS } from '@/modules/dashboard/api/scores';
import { DEFAULT_INGREDIENT_NUMBER_OF_CAPSULES } from '@/modules/dashboard/api/constants';

export default class Ingredient {
  constructor(name, nbOfCapsules) {
    this.name = name;
    this.nbOfCapsules = nbOfCapsules || DEFAULT_INGREDIENT_NUMBER_OF_CAPSULES;
    this.color = INGREDIENTS_COLORS[name];
  }
}
