import stripNamespace from '@/store/getNamespaceTypes';

export const types = {
  state: {
    STATUS: 'dashboard/status',
    PATIENTS: 'dashboard/patients',
    TOTAL_PATIENT_COUNT: 'dashboard/totalPatientCount',
    PDF_SUMMARY: 'dashboard/pdfSummary',
    NOTIFICATIONS_COUNTER: 'dashboard/notificationsCounter',
    PATIENT_ORDER: 'dashboard/patientOrder',
    PATIENT_DATE_ORDER: 'dashboard/patientDateOrder',
    OPENED_PATIENT: 'dashboard/openedPatient',
    SORTING_DIRECTIONS: 'dashboard/sortingDirections',
    PARTIAL_NAME: 'dashboard/partialName',
    PATIENT_FILTER: 'dashboard/patientFilter',
    PATIENT_FILTER_COUNT: 'dashboard/patientFilterCount',
    ACTIVE_PATIENT_TAB: 'dashboard/activePatientTab',
    ACTIVE_INVITE_TAB: 'dashboard/activeInviteTab',
    ACTIVE_DIAGNOSTIC_ID: 'dashboard/activeDiagnosticId',
    ACTIVE_TREATMENT_ID: 'dashboard/activeTreatmentId',
    ERROR: 'dashboard/error',
    CURRENT_PATIENT: 'dashboard/currentPatient',
    CREATE_TREATMENT: 'dashboard/createTreatment',
    SETTING: 'dashboard/setting',
    MARKETING_CAMPAIGNS: 'dashboard/marketingCampaigns',
    MESSAGE_TEMPLATE: 'dashboard/messageTemplate',
    PHOTO_ANALYSIS: 'dashboard/photoAnalysis',
    TREATMENT_DIRECTORY: 'dashboard/treatmentDirectory',
    INVITE_PATIENT: 'dashboard/invitePatient',
    NOT_VALIDATED_DIAGNOSTIC_ID: 'dashboard/notValidatedDiagnosticId',
    AVAILABLE_MOISTURIZE_USAGES: 'dashboard/moisturizeUsages'
  },
  getters: {
    // -> bool
    HAS_ERRORS: 'dashboard/hasErrors',
    HAS_PHOTO_VALIDATION_ERRORS: 'dashboard/hasPhotoValidationErrors',

    IS_MICRONEEDLING_AVAILABLE: 'dashboard/isMicroneedlingAvailable',
    IS_MICRONEEDLING_ACTIVE: 'dashboard/isMicroneedling',
    GET_IS_MICRONEEDLING_ACTIVE_FOR_TREATMENT_SECTION:
      'dashboard/getIsMicroneedlingActiveForTreatmentSection',

    PATIENT_INFO_EMAIL_ERROR: 'dashboard/patientInfoEmailError',
    HAS_PATIENT_INFO_EMAIL_ERROR: 'dashboard/hasPatientInfoEmailError',
    HAS_NEW_CAMPAIGN_TITLE_ERROR: 'dashboard/hasNewCampaignTitleError',

    IS_DASHBOARD_LOCKED: 'dashboard/isDashboardLocked',

    NOTIFICATIONS_COUNTER: 'dashboard/notificationCounter',

    PHOTO_ANALYSIS: 'dashboard/photoAnalysis',
    PHOTO_ANALYSIS_RESULT: 'dashboard/photoAnalysisResult',
    PHOTO_ANALYSIS_SPIDER_CHART: 'dashboard/photoAnalysisSpiderChart',
    PHOTO_ANALYSIS_PHOTO_ID: 'dashboard/photoAnalysisPhotoId',
    PHOTO_ANALYSIS_PERMISSION_TO_DELETE: 'dashboard/photoAnalysisPermissionToDelete',
    PHOTO_ANALYSIS_STORE_IMAGE_OPTION: 'dashboard/photoAnalysisStoreImageOption',
    PHOTO_ANALYSIS_UPDATED_RESULTS: 'dashboard/photoAnalysisUpdatedResults',

    TREATMENT_DIRECTORY_QUESTIONS: 'dashboard/treatmentDirectoryQuestions',
    TREATMENT_DIRECTORY_RECOMMENDATIONS: 'dashboard/treatmentDirectoryRecommendations',

    PARTIAL_NAME: 'dashboard/partialName',
    PATIENT_FILTER: 'dashboard/patientFilter',
    PATIENT_FILTER_COUNT: 'dashboard/patientFilterCount',
    CURRENT_PATIENT: 'dashboard/currentPatient',
    CURRENT_PATIENT_ID: 'dashboard/currentPatientId',
    CURRENT_PATIENT_PHOTO_ID: 'dashboard/currentPatientPhotoId',
    IS_CURRENT_PATIENT_HAS_AVATAR: 'dashboard/isCurrentPatientHasAvatar',
    CURRENT_PATIENT_LOCALE: 'dashboard/currentPatientLocale',
    CURRENT_PATIENT_FIRST_NAME: 'dashboard/currentPatientFirstName',
    CURRENT_PATIENT_FULL_NAME: 'dashboard/currentPatientFullName',
    CURRENT_PATIENT_DIAGNOSTICS: 'dashboard/currentPatientDiagnostics',
    GET_CURRENT_PATIENT_DIAGNOSTIC_BY_ID: 'dashboard/getCurrentPatientDiagnosticById',
    CURRENT_PATIENT_TREATMENTS: 'dashboard/currentPatientTreatments',
    CURRENT_PATIENT_COMMENTS: 'dashboard/currentPatientComments',
    SETTING: 'dashboard/setting',
    GET_CURRENCY: 'dashboard/getCurrency',
    PRODUCT_PRICE_BY_REFERENCE: 'dashboard/productPriceByReference',
    PATIENTS_ORDER: 'dashboard/patientsOrder',
    PATIENTS_DATE_ORDER: 'dashboard/patientsDateOrder',
    PATIENTS_SORTING_DIRECTIONS: 'dashboard/patientsSortingDirections',
    ACTIVE_DIAGNOSTIC_ID: 'dashboard/getActiveDiagnosticId',
    SUMMARY_TREATMENT_ID: 'dashboard/getSummaryTreatmentId',
    ACTIVE_TREATMENT_ID: 'dashboard/getActiveTreatmentId',
    ACTIVE_PATIENT_TAB: 'dashboard/getActivePatientTab',
    ACTIVE_INVITE_TAB: 'dashboard/getActiveInviteTab',
    OPENED_PATIENT: 'dashboard/getOpenedPatient',
    PDF_SUMMARY: 'dashboard/getPdfSummary',

    CREATE_TREATMENT_TYPE: 'dashboard/createTreatmentType',
    RECOMMENDATION_DIAGNOSTIC: 'dashboard/recommendationDiagnostic',
    SKIN_CHARACTERISTICS_BY_RECOMMENDATION_DIAGNOSTIC:
      'dashboard/skinCharacteristicsByRecommendationDiagnostic',
    HAS_TREATMENT_RECOMMENDATION: 'dashboard/hasTreatmentRecommendation',
    TREATMENT_RECOMMENDATION: 'dashboard/treatmentRecommendation',
    TREAT_FACE_RECOMMENDATION: 'dashboard/treatFaceRecommendation',
    TREAT_EYES_RECOMMENDATION: 'dashboard/treatEyesRecommendation',
    SUMMARY_TREATMENT: 'dashboard/summaryTreatment',
    IS_SUMMARY_TREATMENT_SELECTED: 'dashboard/isSummaryTreatmentSelected',
    GET_SERUM_SUMMARY_BY_SECTION_NAME: 'dashboard/getSerumSummaryBySectionName',
    TREAT_FACE: 'dashboard/treatFace',
    TREAT_FACE_PRICE: 'dashboard/treatFacePrice',
    TREAT_FACE_PRICE_WITH_PROMO: 'dashboard/treatFacePriceWithPromo',
    TREAT_FACE_DURATION: 'dashboard/treatFaceDuration',
    TREAT_EYES: 'dashboard/treatEyes',
    TREAT_EYES_PRICE: 'dashboard/treatEyesPrice',
    TREAT_EYES_DURATION: 'dashboard/treatEyesDuration',
    TREAT_UNIVERSKIN_H_PRICE: 'dashboard/treatUniverskinHPrice',
    TREAT_SECTION_PRICE: 'dashboard/treatSectionPrice',
    SECTION_PRODUCTS: 'dashboard/sectionProducts',
    COMPLEMENTARY_CARE_SECTION_TOTAL_PRICE: 'dashboard/complementaryCareSectionTotalPrice',
    CLEANSE_PRODUCTS: 'dashboard/cleanseProducts',
    CLEANSE_PRODUCTS_REFERENCES: 'dashboard/cleanseProductsReferences',
    CLEANSE_TREATMENT_PRICE: 'dashboard/cleanseTreatmentPrice',
    CLEANSE_TREATMENT: 'dashboard/cleanseTreatment',
    CLEANSE_TREATMENT_DURATION: 'dashboard/cleanseTreatmentDuration',
    MOISTURIZE_PRODUCTS: 'dashboard/moisturizeProducts',
    MOISTURIZE_PRODUCTS_REFERENCES: 'dashboard/moisturizeProductsReferences',
    MOISTURIZE_TREATMENT_PRICE: 'dashboard/moisturizeTreatmentPrice',
    MOISTURIZE_TREATMENT: 'dashboard/moisturizeTreatment',
    MOISTURIZE_TREATMENT_DURATION: 'dashboard/moisturizeTreatmentDuration',
    PROTECT_PRODUCTS: 'dashboard/protectProducts',
    PROTECT_PRODUCTS_REFERENCES: 'dashboard/protectProductsReferences',
    PROTECT_TREATMENT_PRICE: 'dashboard/protectTreatmentPrice',
    PROTECT_TREATMENT: 'dashboard/protectTreatment',
    PROTECT_TREATMENT_DURATION: 'dashboard/protectTreatmentDuration',
    UNIVERSKIN_H_SECTION_SELECTION: 'dashboard/universkinHSectionSelection',
    SUMMARY_TREATMENT_TOTAL_PRICE: 'dashboard/summaryTreatmentTotalPrice',
    SUMMARY_TREATMENT_PRICE_WITH_PROMO: 'dashboard/summaryTreatmentPriceWithPromo',
    SUMMARY_TREATMENT_TOTAL_DURATION: 'dashboard/summaryTreatmentTotalDuration',
    TREAT_FACE_COMPOSITION: 'dashboard/treatFaceComposition',
    TREAT_EYES_COMPOSITION: 'dashboard/treatEyesComposition',
    SUMMARY_TREATMENT_COMPOSITION: 'dashboard/summaryTreatmentComposition',
    CREATE_TREATMENT_ERRORS: 'dashboard/createTreatmentErrors',
    HAS_CREATE_TREATMENT_ERRORS: 'dashboard/hasCreateTreatmentErrors',

    AVAILABLE_COMPLEMENTARY_CARE: 'dashboard/availableComplementaryCare',
    GET_IS_COMPLEMENTARY_CARE_AVAILABLE: 'dashboard/getIsComplementaryCareProductAvailable',

    MARKETING_CAMPAIGNS: 'dashboard/marketingCampaigns',
    ACTIVE_MARKETING_CAMPAIGN: 'dashboard/activeMarketingCampaign',

    SAVED_MESSAGE_TEMPLATE: 'dashboard/savedMessageTemplate',
    CURRENT_MESSAGE_TEMPLATE: 'dashboard/currentMessageTemplate',
    IS_MESSAGE_TEMPLATE_UPDATED: 'dashboard/isMessageTemplateUpdated',
    IS_CURRENT_MESSAGE_TEMPLATE_TITLE_EMPTY: 'dashboard/isCurrentMessageTemplateTitleEmpty',

    ACTIVE_MESSAGE_TEMPLATE_TAB: 'dashboard/activeMessageTemplateTab'
  },
  mutations: {
    RESET_MODULE: 'dashboard/resetModule',

    // loading:bool
    SET_LOADING: 'dashboard/setLoading',
    // error:str
    ADD_ERROR: 'dashboard/addError',
    CLEAR_ERRORS: 'dashboard/clearErrors',

    SET_IS_DASHBOARD_LOCKED: 'dashboard/setIsDashboardLocked',

    SET_NOTIFICATIONS_COUNTER: 'dashboard/setNotificationCounter',

    SET_PATIENT_ORDER: 'dashboard/setPatientOrder',
    SET_PATIENT_DATE_ORDER: 'dashboard/setPatientDateOrder',
    SET_REGISTRATION_DATE_SORTING_DIRECTION: 'dashboard/setRegistrationDateSortingDirection',
    SET_LAST_ACTIVITY_SORTING_DIRECTION: 'dashboard/setLastActivitySortingDirection',
    SET_PATIENT_NAME_SORTING_DIRECTION: 'dashboard/setPatientNameSortingDirection',
    SET_OPENED_PATIENT: 'dashboard/setOpenedPatient',

    SET_PHOTO_ANALYSIS: 'dashboard/setPhotoAnalysis',
    SET_PHOTO_ANALYSIS_RESULTS: 'dashboard/setPhotoAnalysisResults',
    SET_PHOTO_ANALYSIS_SPIDER_CHART: 'dashboard/setPhotoAnalysisSpiderChart',
    SET_PHOTO_ANALYSIS_UPDATED_RESULTS: 'dashboard/setPhotoAnalysisUpdatedResults',

    SET_TREATMENT_DIRECTORY_QUESTIONS: 'dashboard/setTreatmentDirectoryQuestions',
    SET_TREATMENT_DIRECTORY_RECOMMENDATIONS: 'dashboard/setTreatmentDirectoryRecommendations',
    RESET_TREATMENT_DIRECTORY: 'dashboard/resetTreatmentDirectory',

    SET_ERROR: 'dashboard/error',
    SET_UPDATE_FILTER: 'dashboard/updateFilter',
    SET_PATIENT_FILTER_COUNT: 'dashboard/setPatientFilterCounter',
    SET_CURRENT_PATIENT: 'dashboard/currentPatient',
    RESET_CURRENT_PATIENT: 'dashboard/resetCurrentPatient',
    SET_CURRENT_PATIENT_DIAGNOSTICS: 'dashboard/setCurrentPatientDiagnostics',
    SET_CURRENT_PATIENT_TREATMENTS: 'dashboard/setCurrentPatientTreatments',
    SET_LOAD_SETTING: 'dashboard/loadSetting',
    SET_UPDATE_PATIENT: 'dashboard/updatePatient',
    SET_UPDATE_PRICE: 'dashboard/updatePrice',
    RESET: 'dashboard/reset',
    SET_DIAGNOSTIC_VALIDATED: 'dashboard/setDiagnosticValidated',
    SET_ACTIVE_DIAGNOSTIC_ID: 'dashboard/setActiveDiagnosticId',
    SET_SUMMARY_TREATMENT_ID: 'dashboard/setSummaryTreatmentId',
    SET_ACTIVE_TREATMENT_ID: 'dashboard/setActiveTreatmentId',
    SET_ACTIVE_PATIENT_TAB: 'dashboard/setActivePatientTab',
    SET_ACTIVE_INVITE_TAB: 'dashboard/setActiveInviteTab',
    SET_NOT_VALIDATED_DIAGNOSTIC_ID: 'dashboard/setNotValidatedDiagnosticId',

    SET_PATIENT_FILTER: 'dashboard/setPatientFilter',
    RESET_PATIENT_FILTER: 'dashboard/resetPatientFilter',

    SET_PDF_SUMMARY: 'dashboard/setPdfSummary',
    RESET_PDF_SUMMARY: 'dashboard/resetPdfSummary',
    ADD_PATIENT_COMMENT: 'dashboard/addPatientComment',
    EDIT_PATIENT_COMMENT: 'dashboard/editPatientComment',
    DELETE_PATIENT_COMMENT: 'dashboard/deletePatientComment',

    SET_CREATE_TREATMENT_TYPE: 'dashboard/setCreateTreatmentType',
    SET_RECOMMENDATION_DIAGNOSTIC: 'dashboard/setRecommendationDiagnostic',
    SET_TREATMENT_RECOMMENDATION: 'dashboard/setTreatmentRecommendation',
    SET_SUMMARY_TREATMENT: 'dashboard/setSummaryTreatment',
    SET_TREAT_FACE: 'dashboard/setTreatFace',
    SET_TREAT_EYES: 'dashboard/setTreatEyes',
    SET_CLEANSE_PRODUCTS: 'dashboard/setCleanseProducts',
    SET_CLEANSE_TREATMENT: 'dashboard/setCleanseTreatment',
    SET_MOISTURIZE_PRODUCTS: 'dashboard/setMoisturizeProducts',
    SET_MOISTURIZE_TREATMENT: 'dashboard/setMoisturizeTreatment',
    SET_PROTECT_PRODUCTS: 'dashboard/setProtectProducts',
    SET_PROTECT_TREATMENT: 'dashboard/setProtectTreatment',
    SET_UNIVERSKIN_H_SECTION_SELECTION: 'dashboard/setUniverskinHSectionSelection',
    ADD_CREATE_TREATMENT_ERROR: 'dashboard/addCreateTreatmentError',
    RESET_CREATE_TREATMENT_ERRORS: 'dashboard/resetCreateTreatmentErrors',
    DELETE_CREATE_TREATMENT_ERROR: 'dashboard/deleteCreateTreatmentError',

    SET_MARKETING_CAMPAIGNS: 'dashboard/setMarketingCampaigns',
    SET_ACTIVE_MARKETING_CAMPAIGN: 'dashboard/setActiveMarketingCampaign',

    SET_SAVED_MESSAGE_TEMPLATE: 'dashboard/setSavedMessageTemplate',
    SET_CURRENT_MESSAGE_TEMPLATE: 'dashboard/setCurrentMessageTemplate',

    SET_ACTIVE_MESSAGE_TEMPLATE_TAB: 'dashboard/setActiveMessageTemplateTab',

    UPDATE_MOISTURIZE_USAGES: 'dashboard/updateMoisturizeUsages',
    SET_MOISTURIZE_USAGES: 'dashboard/setMoisturizeUsages',
    RESET_MOISTURIZE_USAGES: 'dashboard/resetMoisturizeUsages'
  },
  actions: {
    UPDATE_NOTIFICATIONS_COUNTER: 'dashboard/updateNotificationCounter',

    SET_PHOTO_ANALYSIS: 'dashboard/setPhotoAnalysis',
    RUN_FACE_DETECTION: 'dashboard/runFaceDetection',
    RUN_PHOTO_ANALYSIS_ALGORITHM: 'dashboard/runPhotoAnalysisAlgorithm',
    SET_PHOTO_ANALYSIS_UPDATED_RESULTS: 'dashboard/setPhotoAnalysisUpdatedResults',
    RESET_PHOTO_ANALYSIS: 'dashboard/resetPhotoAnalysis',

    SET_MICRONEEDLING_ON: 'dashboard/setMicroneedlingOn',
    SET_MICRONEEDLING_OFF: 'dashboard/setMicroneedlingOff',

    LOAD_PATIENT: 'dashboard/loadPatient',
    CREATE_PATIENT: 'dashboard/createPatient',
    UPDATE_FILTER: 'dashboard/updateFilter',
    UPLOAD_CURRENT_PATIENT_PHOTO: 'dashboard/uploadCurrentPatientPhoto',
    SET_CURRENT_PATIENT: 'dashboard/setCurrentPatient',
    LOAD_CURRENT_PATIENT_TREATMENTS: 'dashboard/setCurrentPatientTreatments',
    LOAD_PRICE_SETTING: 'dashboard/loadPriceSetting',
    UPDATE_PATIENT: 'dashboard/updatePatient',
    UPDATE_PRICE: 'dashboard/updatePrice',
    RESET: 'dashboard/reset',
    VALIDATE_DIAGNOSTIC: 'dashboard/validateDiagnostic',
    // { doctorId:str, patientIds:[], patientEmails:[] }
    DELETE_PATIENT: 'dashboard/deletePatient',
    SET_PATIENT_ORDER: 'dashboard/setPatientOrder',
    SET_PATIENT_DATE_ORDER: 'dashboard/setPatientDateOrder',
    SET_REGISTRATION_DATE_SORTING_DIRECTION: 'dashboard/setRegistrationDateSortingDirection',
    SET_LAST_ACTIVITY_SORTING_DIRECTION: 'dashboard/setLastActivitySortingDirection',
    SET_PATIENT_NAME_SORTING_DIRECTION: 'dashboard/setPatientNameSortingDirection',
    SET_ACTIVE_DIAGNOSTIC_ID: 'dashboard/setActiveDiagnosticId',
    SET_ACTIVE_TREATMENT_ID: 'dashboard/setActiveTreatmentId',
    SET_FIRST_DIAGNOSTIC_AS_ACTIVE: 'dashboard/setFirstDiagnosticAsActive',
    RESET_ACTIVE_DIAGNOSTIC_ID: 'dashboard/resetActiveDiagnosticId',
    RESET_SUMMARY_TREATMENT_ID: 'dashboard/resetSummaryTreatmentId',
    SET_FIRST_TREATMENT_AS_ACTIVE: 'dashboard/setFirstTreatmentAsActive',
    RESET_ACTIVE_TREATMENT: 'dashboard/resetActiveTreatment',
    SET_ACTIVE_PATIENT_TAB: 'dashboard/setActivePatientTab',
    SET_ACTIVE_INVITE_TAB: 'dashboard/setActiveInviteTab',
    SET_SUMMARY_TREATMENT_ID: 'dashboard/setSummaryTreatmentId',

    SET_OPENED_PATIENT: 'dashboard/setOpenedPatient',
    RESET_OPENED_PATIENT: 'dashboard/resetOpenedPatient',

    SET_PATIENT_FILTER: 'dashboard/setPatientFilter',
    RESET_PATIENT_FILTER: 'dashboard/resetPatientFilter',
    UPDATE_PATIENT_FILTER_COUNT: 'dashboard/updatePatientFilterCount',
    SET_PDF_SUMMARY: 'dashboard/setPdfSummary',
    RESET_PDF_SUMMARY: 'dashboard/resetPdfSummary',
    UPDATE_PATIENT_INFO: 'dashboard/updatePatientInfo',
    ADD_PATIENT_COMMENT: 'dashboard/addPatientComment',
    EDIT_PATIENT_COMMENT: 'dashboard/editPatientComment',
    DELETE_PATIENT_COMMENT: 'dashboard/deletePatientComment',

    SELECT_CREATE_TREATMENT_TYPE: 'dashboard/selectCreateTreatmentType',
    RESET_CREATE_TREATMENT_TYPE: 'dashboard/resetCreateTreatmentType',
    SELECT_RECOMMENDATION_DIAGNOSTIC: 'dashboard/selectRecommendationDiagnostic',
    RESET_RECOMMENDATION_DIAGNOSTIC: 'dashboard/resetRecommendationDiagnostic',
    LOAD_TREATMENT_RECOMMENDATION: 'dashboard/loadTreatmentRecommendation',
    SET_TREATMENT_RECOMMENDATION: 'dashboard/setTreatmentRecommendation',
    RESET_TREATMENT_RECOMMENDATION: 'dashboard/resetTreatmentRecommendation',
    UPDATE_COMPLEMENTARY_CARE_PRODUCT: 'dashboard/updateComplementaryCareProduct',
    TOGGLE_COMPLEMENTARY_CARE_PRODUCT: 'dashboard/toggleComplementaryCareProduct',
    UPDATE_SUMMARY_TREATMENT: 'dashboard/updateSummaryTreatment',
    SET_SUMMARY_TREATMENT_BASED_ON_CREATED_TREATMENT:
      'dashboard/setSummaryTreatmentBasedOnCreatedTreatment',
    RESET_SUMMARY_TREATMENT: 'dashboard/resetSummaryTreatment',
    SET_COMPLEMENTARY_CARE_SECTIONS_PRODUCTS: 'dashboard/setComplementaryCareSectionsProducts',
    UPDATE_CLEANSE_TREATMENT: 'dashboard/updateCleanseTreatment',
    UPDATE_TREAT_FACE: 'dashboard/updateTreatFace',
    UPDATE_TREAT_EYES: 'dashboard/updateTreatEyes',
    UPDATE_MOISTURIZE_TREATMENT: 'dashboard/updateRebalanceAndMoisturize',
    UPDATE_PROTECT_TREATMENT: 'dashboard/updateProtectTreatment',
    SET_UNIVERSKIN_H_SECTION_SELECTION: 'dashboard/setUniverskinHSectionSelection',
    ADD_CREATE_TREATMENT_ERROR: 'dashboard/addCreateTreatmentError',
    RESET_CREATE_TREATMENT_ERRORS: 'dashboard/resetCreateTreatmentErrors',
    DELETE_CREATE_TREATMENT_ERROR: 'dashboard/deleteCreateTreatmentError',
    VALIDATE_SUMMARY_TREATMENT: 'dashboard/validateSummaryTreatment',
    FINISH_CREATE_TREATMENT_PROCESS: 'dashboard/finishCreateTreatmentProcess',
    RESET_CREATE_TREATMENT_SELECTIONS: 'dashboard/resetCreateTreatmentSelections',

    SELECT_TREATMENT_DIRECTORY_RECOMMENDATIONS: 'dashboard/selectTreatmentDirectoryRecommendations',

    LOAD_CUSTOM_CAMPAIGNS: 'dashboard/loadCustomCampaigns',
    CREATE_CUSTOM_CAMPAIGN: 'dashboard/createCustomCampaign',
    LOAD_SKINXS_CAMPAIGNS: 'dashboard/loadSkinxsCampaigns',
    UPDATE_MARKETING_CAMPAIGN: 'dashboard/updateMarketingCampaign',
    DELETE_MARKETING_CAMPAIGN: 'dashboard/deleteMarketingCampaign',
    SET_ACTIVE_MARKETING_CAMPAIGN: 'dashboard/setActiveMarketingCampaign',

    UPDATE_STORE_MESSAGE_TEMPLATE: 'dashboard/updateStoreMessageTemplate',
    SET_SAVED_MESSAGE_TEMPLATE: 'dashboard/setSavedMessageTemplate',
    SET_CURRENT_MESSAGE_TEMPLATE: 'dashboard/setCurrentMessageTemplate',

    SET_ACTIVE_MESSAGE_TEMPLATE_TAB: 'dashboard/setActiveMessageTemplateTab',

    SET_SERUM_SUMMARY_BY_SECTION_NAME: 'dashboard/setSerumSummaryBySectionName',

    UPDATE_MOISTURIZE_PRODUCTS: 'dashboard/updateMoisturizeProducts',
    REMOVE_FROM_MOISTURIZE_USAGES: 'dashboard/removeFromMoisturizeUsages',
    RESET_MOISTURAZE_USAGES: 'dashboard/resetMoisturizeUsages'
  }
};

export const dashTypes = stripNamespace('dashboard/', types);
