import { countries } from 'countries-list';

import { COUNTRY_ISO } from '@/api/places/COUNTRIES';

export const LOCAL_STORAGE_KEY = 'usk-store';
export const GOOGLE_ANALYTICS_ID = 'UA-142862709-2';

export const NULL_STRING = 'null';

export const BACKEND_VERSION_HEADER = 'x-backend-version';

export const ERROR_MESSAGES = {
  BAD_REQUEST: 'BAD_REQUEST'
};

export const EMAIL_ERRORS = {
  EMAIL_USED_BY_THIS_DOCTOR_PATIENT: 'EMAIL_IS_USED_BY_THIS_DOCTOR_PATIENT',
  EMAIL_USED_BY_ANOTHER_DOCTOR_PATIENT: 'EMAIL_IS_USED_BY_ANOTHER_DOCTOR_PATIENT',
  EMAIL_USED_BY_DOCTOR: 'EMAIL_USED_BY_DOCTOR'
};

export const EMAIL_ERRORS_VALUES = Object.values(EMAIL_ERRORS);

export const VALIDATION_ERRORS = {
  EMAIL_VERIFICATION_REQUIRED: 'EMAIL_VERIFICATION_REQUIRED'
};

export const VALID_EMAIL_REG_EXP = new RegExp(
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@' +
    '((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
);
export const VALID_WEBSITE_URL_REG_EXP = new RegExp(
  '((([A-Za-z]{3,9}:(?:\\/\\/)?)(?:[-;:&=\\+\\$,\\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\\+\\$,\\w]+' +
    '@)[A-Za-z0-9.-]+)((?:\\/[\\+~%\\/.\\w-_]*)?\\??(?:[-\\+=&;%@.\\w_]*)#?(?:[\\w]*))?)|^\\s*$'
);
export const VALID_SLUG_REG_EXP = /^([A-Za-z-])+$/;

export const MOBILE_MEDIA_QUERY = '(max-width: 767px)';

export const INCI_FILE_NAME = 'inci_label.pdf';
export const PDF_SUMMARY_NAME = 'pdf_summary.pdf';

export const PATIENTS_DOWNLOADING_OFFSET = 20;
export const NOTIFICATIONS_DOWNLOADING_SIZE = 10;
export const DAYS_TO_SEEK_NEW_PATIENTS = 30;

export const DEFAULT_DATE_PATTERN = 'DD MMM YYYY';
export const FULL_DATE_PATTERN = 'DD-MMM-YYYY';
export const SMALL_MONTH_NAME_PATTERN = 'MMM';
export const FULL_MONTH_NAME_PATTERN = 'MMMM';
export const FULL_MONTH_NAME_AND_FULL_YEAR_PATTERN = 'MMMM YYYY';
export const HOURS_DATE_PATTERN = 'HH:mm';
export const ACCOUNT_NOTIFICATION_DISPLAYING_DURATION = 3000;

export const CHRONO_TYPES = {
  MINUTES: 'MINUTES',
  HOURS: 'HOURS',
  DAYS: 'DAYS',
  MONTHS: 'MONTHS',
  YEARS: 'YEARS'
};

export const DEFAULT_LOCALE = 'en';

export const NOT_ANSWERED_VALUE_KEY = 'dashboard.placeholder.na';

export const NUMBER_OF_DAYS_IN_MONTH = 30;

export const REQUEST_ERRORS = {
  BAD_REQUEST: 400,
  UNAUTHORIZED_ERROR: 401,
  FORBIDDEN: 403,
  NOT_FOUND_ERROR: 404,
  SERVER_ERROR: 500
};

const AVAILABLE_COUNTRIES = Object.keys(countries).filter(
  code => code !== COUNTRY_ISO.REPUBLIC_OF_KOSOVO
);

export const SORTED_PHONE_CODES_BY_COUNTRY_NAME = (() =>
  AVAILABLE_COUNTRIES.sort((a, b) => {
    const nameA = countries[a].name.toLowerCase();
    const nameB = countries[b].name.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }))();

export const AVAILABLE_DASHBOARD_LANGUAGES = {
  en: 'en',
  fr: 'fr',
  ru: 'ru',
  uk: 'ru',
  es: 'es',
  'es-MX': 'es-MX'
};

export const AVAILABLE_LEGAL_PAGES_LANGUAGE = {
  en: 'en',
  fr: 'fr'
};

export const WEB_CAMERA_DIMENSIONS = {
  WIDTH: 720,
  HEIGHT: 400
};

export const NUMBER_ONLY_REG_EXP = new RegExp('^[0-9]*$');
