export const errorObserver = {
  listeners: [],
  addListener(callback, context = null) {
    this.listeners.push({ callback, context });
  },
  removeListener(callback) {
    this.listeners = this.listeners.filter(listener => listener.callback !== callback);
  },
  dispatchError(error) {
    errorObserver.listeners.forEach(listener => {
      listener.callback.call(listener.context, error);
    });
  }
};
