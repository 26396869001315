export const types = {
  state: {
    STATUS: 'status',
    USER_NAME: 'username',
    IS_NEW_DESIGN: 'isNewDesign',
    USER: 'user',
    USER_AUTHENTICATION_DATA: 'userAuthenticationData',
    LOCALE: 'locale'
  },
  getters: {
    // -> str
    USER_FULL_NAME: 'userFullName',
    DOCTOR_USERNAME: 'doctorUsername',
    GET_USERNAME: 'getUsername',
    GET_LOCALE: 'getLocale',
    GET_USER: 'getUser',
    GET_USER_ID: 'getUserId',
    GET_USER_COUNTRY: 'getUserCountry',
    GET_USER_LANGUAGE: 'getUserLanguage',
    GET_DOCTOR_SLUG: 'getDoctorSlug',
    USER_ISO_CODE: 'getUserIsoCode',
    HAS_WEBSHOP_ACCESS: 'hasWebshopAccess',
    IS_MEDICAL_CONCENTRATION_BLOCKED: 'dashboard/isMedicalConcentrationBlocked',
    DOCTOR_QUESTIONNAIRE_LINK: 'getDoctorQuestionnaireLink',
    GET_USER_CLINICS: 'getUserClinics',
    GET_LOADING: 'getLoading',
    DOCTOR_CONSULTATION_COST: 'doctorConsultationCost',
    GET_USER_RECOVERY_CODES: 'getUserRecoveryCodes',
    GET_USER_AUTHENTICATION_CODE: 'getUserAuthenticationCode'
  },
  mutations: {
    RESET_MODULE: 'resetModule',

    // loading:bool
    SET_LOADING: 'setLoading',

    SET_USERNAME: 'setUsername',
    // user:obj
    SET_USER: 'setUser',
    // locale:str
    SET_USER_PHOTO: 'setUserPhoto',
    SET_LOCALE: 'setLocale',
    SET_USER_RECOVERY_CODES: 'setUserRecoveryCodes',
    SET_USER_AUTHENTICATION_CODE: 'setUserAuthenticationCode'
  },
  actions: {
    LOGIN: 'login',
    LOGOUT: 'logout',
    SEND_PASSWORD_RESET: 'sendPasswordReset',
    UPDATE_USER_PHOTO: 'updateUserPhoto',
    UPDATE_USER: 'updateUser',
    SET_LOCALE: 'setLocale',
    SET_LOADING: 'setLoading',
    GENERATE_USER_RECOVERY_CODES: 'generateUserRecoveryCodes',
    SET_USER_RECOVERY_CODES: 'setUserRecoveryCodes',
    SET_UP_TWO_FACTOR_AUTHENTICATION: 'setUpTwoFactorAuthentication'
  }
};

export default types;
