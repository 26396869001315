import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueI18nDirectives from 'vue-i18n-directives';
import { merge } from 'lodash';

import {
  LOCAL_STORAGE_KEY,
  DEFAULT_LOCALE,
  AVAILABLE_DASHBOARD_LANGUAGES,
  AVAILABLE_LEGAL_PAGES_LANGUAGE
} from '@/constants';

Vue.use(VueI18n);
Vue.use(VueI18nDirectives);

const AVAILABLE_USER_GUIDE_LANGUAGES = {
  fr: 'fr',
  ru: 'ru',
  uk: 'ru'
};

const loadedLanguages = [];

export const i18n = new VueI18n({
  fallbackLocale: DEFAULT_LOCALE,
  silentTranslationWarn: true
});

function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);

  return lang;
}

export function loadLanguage(lang) {
  const isLanguageLoaded = loadedLanguages.includes(lang);

  if (i18n.locale !== lang || !isLanguageLoaded) {
    if (isLanguageLoaded) {
      return Promise.resolve(setI18nLanguage(lang));
    }

    const dashboardLang = AVAILABLE_DASHBOARD_LANGUAGES[lang] || DEFAULT_LOCALE;
    const userGuideLang = AVAILABLE_USER_GUIDE_LANGUAGES[lang] || DEFAULT_LOCALE;
    const legalPagesLang = AVAILABLE_LEGAL_PAGES_LANGUAGE[lang] || DEFAULT_LOCALE;

    return Promise.all([
      import(`@/modules/dashboard/locale/dashboard/${dashboardLang}.json`),
      import(`@/modules/dashboard/locale/diagnostic-details/${dashboardLang}.json`),
      import(`@/modules/dashboard/locale/user-guide/${userGuideLang}.json`),
      import(`@/modules/questionnaire/locale/${lang}.json`),
      import(`@/api/places/towns/${lang}.json`),
      import(`@/locale/legal/${legalPagesLang}.json`),
      import(`universkin-shared/src/commonDomainObjects/translations/${lang}.json`)
    ]).then(([dashboard, diagnosticDetails, userGuide, questionnaire, towns, legal, shared]) => {
      const messages = {};

      merge(messages, dashboard, diagnosticDetails, userGuide, questionnaire, towns, legal, shared);

      i18n.setLocaleMessage(lang, messages);
      loadedLanguages.push(lang);

      return setI18nLanguage(lang);
    });
  }

  return Promise.resolve(lang);
}

export function loadMessageTemplateTranslations(lang) {
  return Promise.all([
    import(`@/modules/dashboard/locale/message-template-translations/${lang}.json`)
  ]).then(([messageTemplateTranslations]) => {
    const messages = i18n.getLocaleMessage(lang) || {};

    merge(messages, messageTemplateTranslations);
    i18n.setLocaleMessage(lang, messages);
  });
}

const localeStorageStore = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

loadLanguage(localeStorageStore ? localeStorageStore.locale : DEFAULT_LOCALE);
