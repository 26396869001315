<template>
  <warning-modal
    class="diagnostic-results-warning-modal"
    name="diagnostic-results-warning-modal"
    title="dashboard.microneedling.warning.notice"
    @before-open="onBeforeOpen"
  >
    <template #main-content>
      {{ $t('dashboard.microneedling.warning.results') }}
      <ul class="diagnostic-results-list">
        <li
          v-for="diagnosticResult of diagnosticResults"
          :key="diagnosticResult"
          class="diagnostic-results-list__list-item"
        >
          <b>{{ $t(diagnosticResult) }}</b>
        </li>
      </ul>
    </template>
    <template #secondary-content>
      {{ $t('dashboard.microneedling.warning.conditions.recommendation') }}
    </template>

    <template #actions>
      <confirmation-modal-actions @cancel="handleClose(false)" @confirm="handleClose(true)" />
    </template>
  </warning-modal>
</template>

<script>
import WarningModal from '@/modules/dashboard/components/common/warning-modal/WarningModal.vue';
import ConfirmationModalActions from '@/modules/dashboard/components/common/warning-modal/ConfirmationModalActions.vue';

export default {
  name: 'DiagnosticResultsWarningModal',
  components: { WarningModal, ConfirmationModalActions },
  data() {
    return {
      diagnosticResults: [],
      onClose: null
    };
  },
  methods: {
    handleClose(confirm) {
      this.onClose({ confirm });
    },
    onBeforeOpen({ params: { diagnosticResults, onClose } }) {
      this.diagnosticResults = diagnosticResults;
      this.onClose = onClose;
    }
  }
};
</script>

<style lang="scss" scoped>
.diagnostic-results-list {
  padding: 10px 0 0 0;
  list-style: none;

  &__list-item {
    margin-bottom: 4px;

    &::before {
      content: '—';
      display: inline-block;
      padding-right: 5px;
    }
  }
}
</style>
