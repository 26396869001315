import axios from '@/api/axiosInstance';

import { LocalStorageService } from '@/services/LocalStorageService.js';

import { BackEndError, NullUrlError, AuthorizationError } from '@/errors';
import { errorObserver } from '@/services/errorObserver';

import { NULL_STRING } from '@/constants';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const verbs = ['get', 'head', 'post', 'patch', 'delete'];

export const getClient = module => {
  const client = {
    auth: false,
    withRedirectOnUnauthorized: true,
    withAuth() {
      client.auth = true;

      return client;
    },
    withoutAuth() {
      client.auth = false;

      return client;
    },
    withoutRedirect() {
      client.withRedirectOnUnauthorized = false;

      return client;
    }
  };

  verbs.forEach(verb => {
    client[verb] = async (url, data = {}, headers = {}) => {
      const hasNullInUrl = url.split('/').includes(NULL_STRING);

      if (hasNullInUrl) {
        errorObserver.dispatchError(new NullUrlError());

        return;
      }

      const token =
        module === 'questionnaire'
          ? LocalStorageService.getQuestionnaireToken()
          : LocalStorageService.getDashboardToken();
      let headersWithAuth;

      if (client.auth && token) {
        headersWithAuth = { Authorization: `Bearer ${token}`, ...headers };
      }

      const response = await axios({
        method: verb,
        url,
        data,
        headers: headersWithAuth || headers
      });

      if (client.auth && (response.status === 401 || response.status === 403)) {
        errorObserver.dispatchError(new AuthorizationError(response.data));

        return;
      }

      if (response.status >= 500) {
        errorObserver.dispatchError(
          new BackEndError(response.data, {
            response,
            request: { method: verb, url, payload: data }
          })
        );

        return;
      }

      return response;
    };
  });

  return client;
};
