import axios from '@/api/axiosInstance';
import { getClient } from '@/api/client';

import UUIDService from '@/modules/dashboard/services/uuidService';
import { LocalStorageService } from '@/services/LocalStorageService';

import { DAYS_TO_SEEK_NEW_PATIENTS } from '@/constants';
import { DOCTOR_PHOTO_FILE_NAME, PDF_APPLICATION_TYPE } from '@/modules/dashboard/api/constants';

const client = getClient('dashboard');

export const sendQuestionnaire = data =>
  client.withAuth().post('/api/patients/send-questionnaires', data);

export const fetchPatient = ({ patientId }) => client.withAuth().get(`/api/patients/${patientId}`);

export const fetchPatientPhotos = ({ patientId }) =>
  client.withAuth().get(`/api/patients/${patientId}/photos`);

export const fetchPatientsThumbnails = patients => {
  const patientsPhotoIds = patients.reduce((accumulator, { photoId }) => {
    if (photoId) {
      accumulator.push(photoId);
    }

    return accumulator;
  }, []);

  return client.withAuth().post('/api/patients/photoThumbnails', patientsPhotoIds);
};

export const fetchPatientPhotoByPhotoId = (patientId, photoId) =>
  client.withAuth().get(`/api/patients/${patientId}/photos/${photoId}`);

export const fetchPatients = filterData =>
  client.withAuth().post('/api/patients/findPaged', filterData);

export const fetchAllPatients = ({ doctorId }) =>
  client.withAuth().get(`/api/patients/all?doctorId=${doctorId}`);

export const countPatients = data => client.withAuth().post('/api/patients/count', data);

export const markPatientAsInactive = ({ id }) =>
  client.withAuth().patch(`/api/patients/${id}/setInactive`);

export const updatePatient = (patientId, patientData) =>
  client.withAuth().patch(`/api/patients/${patientId}`, patientData);

export const deletePatient = patientId => client.withAuth().delete(`/api/patients/${patientId}`);

export const fetchNotifications = ({ doctorId, page, size }) =>
  client.withAuth().get(`/api/notifications?doctorId=${doctorId}&page=${page}&size=${size}`);

export const markNotification = ({ id }, { isRead, doctorId }) =>
  client.withAuth().patch(`/api/notifications/setRead/${id}?doctorId=${doctorId}&isRead=${isRead}`);

export const markAllNotificationAsRead = ({ doctorId }) =>
  client.withAuth().patch(`/api/notifications/setReadAll?doctorId=${doctorId}`);

export const countNotifications = ({ doctorId }) =>
  client.withAuth().get(`/api/notifications/${doctorId}/count`);

export const getInciPdfLabelAsBlob = async ({ patientId, treatmentId }) => {
  const token = LocalStorageService.getDashboardToken();

  const response = await axios({
    url: `/api/patients/${patientId}/treatments/${treatmentId}/label`,
    method: 'GET',
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return new Blob([response.data], {
    type: PDF_APPLICATION_TYPE
  });
};

export const getPdfSummaryAsBlob = async ({ patientId, questionnaireId, diagnosticId }) => {
  const token = LocalStorageService.getDashboardToken();

  const { data } = await axios({
    url: `/api/patients/${patientId}/questionnaires/${questionnaireId}/diagnosis/${diagnosticId}`,
    method: 'GET',
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return new Blob([data], {
    type: PDF_APPLICATION_TYPE
  });
};

export const getSpiderChartImage = async (diagnosticScores, locale) =>
  client.withAuth().post(`/api/patients/${locale}/spiderchart`, diagnosticScores);

export const addPatientPhoto = ({
  patientId,
  photo,
  storePhoto = false,
  allowResearch = false
}) => {
  const formData = new FormData();
  const url = `/api/patients/${patientId}/${storePhoto}/${allowResearch}/saveCommonPhotos`;
  const randomFileName = String(UUIDService.v4());

  formData.append('photos', photo, randomFileName);

  return client.withAuth().post(url, formData, { 'content-Type': 'multipart/form-data' });
};

export const deletePatientPhoto = (patientId, photoId) =>
  client.withAuth().delete(`/api/patients/${patientId}/${photoId}/deletePhoto`);

export const setPatientPhotoAsAvatar = (patientId, photoId) =>
  client.withAuth().patch(`/api/patients/${patientId}/${photoId}/setAsAvatar`);

export const getPatientMedicalData = patientId =>
  client.withAuth().get(`/api/patients/${patientId}/medical-data`);

export const updatePatientMedicalData = (patientId, medicalDataId, medicalData) =>
  client.withAuth().patch(`/api/patients/${patientId}/medical-data/${medicalDataId}`, medicalData);

export const getSettings = doctorId => client.withAuth().get(`/api/settings?doctorId=${doctorId}`);

export const getDoctorStatistics = ({ doctorId }) =>
  client
    .withAuth()
    .get(`/api/users/${doctorId}/statistic?daysToSeekNewPatients=${DAYS_TO_SEEK_NEW_PATIENTS}`);

export const updatePrice = (doctorId, productPrices) =>
  client.withAuth().patch(`/api/settings/${doctorId}/product-prices`, productPrices);

export const createTreatment = (patientId, formulation) =>
  client.withAuth().post(`/api/patients/${patientId}/treatments`, formulation);

export const updateTreatment = ({ patientId, treatmentId, updatedTreatment }) =>
  client.withAuth().patch(`api/patients/${patientId}/treatments/${treatmentId}`, updatedTreatment);

export const getTreatmentDaysBeforeStart = (patientId, treatmentId) =>
  client.withAuth().get(`/api/patients/${patientId}/treatments/${treatmentId}/daysBeforeStart`);

export const deleteTreatment = (patientId, treatmentId) =>
  client.withAuth().delete(`/api/patients/${patientId}/treatments/${treatmentId}`);

export const updateDiagnostic = (patientId, questionnaireId, diagnostic) =>
  client
    .withAuth()
    .patch(`/api/patients/${patientId}/questionnaires/${questionnaireId}/diagnosis`, diagnostic);

export const getPatientDiagnostics = patientId =>
  client.withAuth().get(`/api/patients/${patientId}/diagnosis/all`);

export const getPatientTreatments = patientId =>
  client.withAuth().get(`/api/patients/${patientId}/treatments/all`);

export const updateDoctorPhoto = ({ userId, photo }) => {
  const formData = new FormData();

  formData.append('photo', photo, DOCTOR_PHOTO_FILE_NAME);

  return client
    .withAuth()
    .post(`/api/users/${userId}/savePhoto`, formData, { 'content-Type': 'multipart/form-data' });
};

export const updateDoctorSignature = ({ doctorId, doctorName }) => {
  const params = new URLSearchParams({ doctorName });

  return client.withAuth().post(`/api/users/${doctorId}/saveSignature?${params}`);
};

export const updatePatientInfo = (patientId, data) =>
  client.withAuth().patch(`/api/patients/${patientId}/edit`, data);

export const getSidebarCategories = ({
  doctorId,
  clinicIds = [],
  searchQuery = '',
  sortName = null,
  sortDirection = null,
  statusNames = []
}) =>
  client.withAuth().post('/api/patients/sidebars', {
    doctorId,
    clinicIds,
    firstChar: 0,
    period: null,
    patientId: null,
    search: searchQuery,
    sortName,
    sortDirection,
    statusNames,
    page: 0,
    size: 0
  });

export const deleteDiagnostic = (patientId, diagnosticId) =>
  client.withAuth().delete(`/api/patients/${patientId}/diagnosis/${diagnosticId}`);

export const addPatientComment = commentData =>
  client.withAuth().post('/api/comments', commentData);

export const editPatientComment = (commentId, commentData) =>
  client.withAuth().patch(`/api/comments/${commentId}`, commentData);

export const deletePatientComment = commentId =>
  client.withAuth().delete(`/api/comments/${commentId}`);

export const getTemplateGroupsByTypes = ({ userId, types }) =>
  client.withAuth().post(`/api/users/${userId}/template-groups`, types);

export const updateTemplateGroup = ({ userId, templateGroup }) =>
  client.withAuth().post(`/api/users/${userId}/template-groups/update`, templateGroup);

export const createTemplateGroup = ({ userId, templateGroupData }) =>
  client.withAuth().post(`/api/users/${userId}/template-groups/create`, templateGroupData);

export const deleteMarketingCampaign = ({ userId, groupId }) =>
  client.withAuth().delete(`/api/users/${userId}/template-groups/${groupId}`);

export const getMarketingCampaignTemplate = ({ userId, groupId, wayOfSend, language }) =>
  client.withAuth().get(`/api/users/${userId}/templates/group/${groupId}/${wayOfSend}/${language}`);

export const updateMarketingCampaignTemplate = ({ userId, templateData }) =>
  client.withAuth().post(`/api/users/${userId}/templates/update`, templateData);

export const deleteMessageTemplate = ({ userId, wayOfSend, templateId }) =>
  client.withAuth().delete(`/api/users/${userId}/templates/${wayOfSend}/${templateId}`);

export const getNotificationTemplate = ({ userId, groupType, wayOfSend, language }) =>
  client
    .withAuth()
    .get(`/api/users/${userId}/templates/groupType/${groupType}/${wayOfSend}/${language}`);

export const updateNotificationTemplate = (userId, templateData) =>
  client.withAuth().post(`/api/users/${userId}/templates/update`, templateData);

export const getPersonalizePackagingTreatments = ({ doctorId, page, size, partName }) =>
  client
    .withAuth()
    .get(`/api/patients/${doctorId}/treatments?page=${page}&size=${size}&partName=${partName}`);

export const sendMarketingTemplateInvites = (userId, sendTemplateData) =>
  client.withAuth().post(`/api/users/${userId}/templates/send-invitations`, sendTemplateData);

export const getTreatmentRecommendationsByDiagnostic = (patientId, diagnosticId) =>
  client
    .withAuth()
    .get(`/api/patients/${patientId}/diagnostic/${diagnosticId}/treatment/recommendations`);

export const detectFace = (patientId, photoId, permissionToDelete = false) =>
  client.withAuth().post(`/api/patients/${patientId}/photo/${photoId}/detectFace`, {
    noFace: false,
    severalFaces: false,
    permissionToDelete
  });

export const getPhotoAnalysisRecommendation = (patientId, photoId) =>
  client
    .withAuth()
    .get(`/api/patients/${patientId}/treatments/photo/${photoId}/earlyRecommendations`);

export const getClarificationQuestions = (patientId, data) =>
  client
    .withAuth()
    .post(`/api/patients/${patientId}/treatments/photoDiagnostic/recommendedQuestions`, data);

export const sendFinalTreatmentResults = (patientId, photoId, treatmentResults) =>
  client
    .withAuth()
    .post(
      `/api/patients/${patientId}/treatments/photo/${photoId}/finalRecommendations`,
      treatmentResults
    );

export const getTreatmentDirectionsOfUse = ({ patientId, treatmentId, language }) =>
  client
    .withAuth()
    .get(`/api/patients/${patientId}/treatment/${treatmentId}/${language}/DirectionsTranslation`);

export const createTreatmentPreview = (patientId, treatment) =>
  client.withAuth().post(`/api/patients/${patientId}/treatments/treatmentPreview`, treatment);

export const finishCreatingTreatmentProcess = ({
  patientId,
  languageTag,
  treatmentId,
  messageTemplates,
  cancelNotifications
}) =>
  client
    .withAuth()
    .post(
      `/api/patients/${patientId}/treatments/${treatmentId}/languages/${languageTag}/finishTreatmentCreation/${cancelNotifications}`,
      messageTemplates
    );

export const resendTreatmentOffer = ({ patientId, treatmentId, languageTag, messageTemplates }) =>
  client
    .withAuth()
    .post(
      `/api/patients/${patientId}/treatments/${treatmentId}/languages/${languageTag}/resendTreatmentOffer`,
      messageTemplates
    );

export const getNewTreatmentNotificationTranslation = ({
  patientId,
  languageTag,
  treatmentId,
  messageType,
  wayOfSend
}) =>
  client
    .withAuth()
    .get(
      `/api/patients/${patientId}/treatments/${treatmentId}/languages/${languageTag}/${messageType}/${wayOfSend}/mailPreView`
    );

export const getLoyaltyProgramParticipants = userId =>
  client.withAuth().get(`/api/users/loyalty/${userId}/participants`);

export const addLoyaltyProgramParticipant = (doctorId, participant) =>
  client.withAuth().post(`/api/users/loyalty/${doctorId}/registerParticipant`, participant);

export const getLoyaltyProgramParticipant = (userId, participantId) =>
  client.withAuth().get(`/api/users/loyalty/${userId}/participants/${participantId}`);

export const getParticipantStatistics = (userId, participantId) =>
  client.withAuth().get(`/api/users/loyalty/${userId}/participants/${participantId}/statistic`);

export const deleteLoyaltyProgramParticipant = (userId, deleteData) =>
  client.withAuth().post(`/api/users/loyalty/${userId}/deleteParticipant`, deleteData);

export const sendRedeemRequest = (userId, redeemData) =>
  client.withAuth().post(`/api/users/loyalty/${userId}/redemptionRequest`, redeemData);

export const addEmailToBlacklist = (hashedDoctorId, hashedEmail) =>
  client.withAuth().post(`/blacklist/add/user/${hashedDoctorId}/email/${hashedEmail}`);

export const getConsultationFeeStatistics = (userId, yearToCalculateFee) =>
  client
    .withAuth()
    .get(`/api/users/${userId}/feeStatistic?yearToCalculateFee=${yearToCalculateFee}`);

export const getInfluencerStatistic = ({ userId, year, month }) =>
  client.withAuth().post(`/api/influencer/statistic/${userId}/${year}/${month}`);

export const getTreatmentDirectoryPreview = (locale, country, questionsInfo) =>
  client
    .withAuth()
    .post(
      `/api/treatmentDirectory/findTreatmentRecommendationsPreview/${locale}/${country}`,
      questionsInfo
    );

export const getTreatmentDirectoryRecommendationById = treatmentRecommendationId =>
  client
    .withAuth()
    .get(`/api/treatmentDirectory/treatmentDirectoryRecommendation/${treatmentRecommendationId}`);

export const uploadConsultationFeeInvoice = options => {
  const { doctorId, month, year, file } = options;
  const formData = new FormData();
  const url = `/api/fee/invoice/${doctorId}/${month}/${year}/upload`;
  const randomFileName = String(UUIDService.v4());

  formData.append('file', file, randomFileName);

  return client.withAuth().post(url, formData, { 'content-Type': 'multipart/form-data' });
};

export const downloadConsultationFeeInvoice = options => {
  const { doctorId, month, year } = options;
  const token = LocalStorageService.getDashboardToken();

  return axios({
    url: `/api/fee/invoice/${doctorId}/${month}/${year}/download`,
    method: 'POST',
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getSkinDiagnosticValidation = patientId =>
  client.withAuth().get(`/api/microneedlingValidation/${patientId}/restrictions`);

export const getLastTreatmentValidation = patientId =>
  client.withAuth().get(`/api/microneedlingValidation/${patientId}/timeRestriction`);

export const getSkinThicknessValidation = patientId =>
  client.withAuth().get(`/api/microneedlingValidation/${patientId}/skinThickness`);

export const createPatient = patient => client.withAuth().post('/api/patients', patient);
