<template>
  <warning-modal
    class="forbidden-ingredient-warning-modal"
    name="forbidden-ingredient-warning-modal"
    @confirm="onConfirm"
    @before-open="onBeforeOpen"
  >
    <template #main-content>
      {{ $t('dashboard.microneedling.warning.glycolic') }}
    </template>
  </warning-modal>
</template>

<script>
import WarningModal from '@/modules/dashboard/components/common/warning-modal/WarningModal.vue';

export default {
  name: 'ForbiddenIngredientWarningModal',
  components: { WarningModal },
  data() {
    return { onClose: null };
  },
  methods: {
    onBeforeOpen({ params: { onClose } }) {
      this.onClose = onClose;
    },
    onConfirm() {
      this.onClose({ confirm: true });
    }
  }
};
</script>
