export class BackEndError extends Error {
  constructor(message, { request, response }) {
    super(message);

    this.request = request;
    this.response = response;
  }
}

export class NetworkError extends Error {}

export class NullUrlError extends Error {}

export class AuthorizationError extends Error {}
