import { any, hasSkinDysfunction, yes } from '@/modules/questionnaire/api/helpers';

import { getDoctorFullName } from '@/modules/dashboard/utils';
import { getField } from 'vuex-map-fields';
import { questTypes } from '@/modules/questionnaire/store/types';
import { SKIPPED_SECTIONS } from '@/modules/questionnaire/constants/sections';

export default {
  [questTypes.getters.GET_QUESTIONNAIRE_CREATION_DATE](state) {
    return state.questionnaire.creationDate;
  },

  [questTypes.getters.GET_FIELD](state) {
    return getField(state.questionnaire.fields);
  },

  [questTypes.getters.GET_IS_PHOTO_CONSENTS_ACCEPTED](state) {
    return state.questionnaire.isPhotoConsentsAccepted;
  },

  [questTypes.getters.GET_IS_CONSENTS_ACCEPTED](state) {
    return state.questionnaire.isConsentsAccepted;
  },

  [questTypes.getters.GET_DOCTOR](state) {
    return state.doctor;
  },

  [questTypes.getters.GET_CONTEXT](state) {
    return state.questionnaire.context;
  },

  [questTypes.getters.GET_QUESTIONNAIRE_FIELDS](state) {
    return state.questionnaire.fields;
  },

  [questTypes.getters.GET_DOCTOR_FULL_NAME](state) {
    return getDoctorFullName(state.doctor);
  },

  [questTypes.getters.GET_DOCTOR_CLINIC_LOCATIONS](state) {
    return state.doctor.clinicDtos;
  },

  [questTypes.getters.GET_REQUESTED_DOCTOR](state) {
    return state.requestedDoctor;
  },

  [questTypes.getters.GET_PHOTOS](state) {
    return state.questionnaire.photos;
  },

  [questTypes.getters.GET_PATIENT](state) {
    return state.patient;
  },

  [questTypes.getters.GET_QUESTIONNAIRE](state) {
    return state.questionnaire;
  },

  [questTypes.getters.GET_SCORES](state) {
    return state.scores;
  },

  [questTypes.getters.GET_LOADING](state) {
    return state.status.loading;
  },

  [questTypes.getters.GET_IS_SKIN_APPEARANCE_DISCOMFORT](state) {
    const {
      thinningSkin,
      saggingSkin,
      saggingSkinLocations,
      dryingSkin,
      dryOpenPores,
      wrinkles,
      darkCircles,
      eyeBags,
      stretchMarks,
      acneScars
    } = state.questionnaire.fields;

    return (
      any(
        thinningSkin,
        saggingSkin,
        saggingSkinLocations,
        dryingSkin,
        dryOpenPores,
        darkCircles,
        eyeBags,
        stretchMarks,
        acneScars
      ) || hasSkinDysfunction(wrinkles)
    );
  },

  [questTypes.getters.GET_IS_SKIN_REDNESS_DISCOMFORT](state) {
    const {
      sensitiveSkin,
      flushes,
      skinRedness,
      prominentBloodVessels
    } = state.questionnaire.fields;

    return any(sensitiveSkin, flushes, skinRedness, prominentBloodVessels);
  },

  [questTypes.getters.GET_IS_SKIN_DRYNESS_DISCOMFORT](state) {
    const { skinDryness } = state.questionnaire.fields;

    return yes(skinDryness);
  },

  [questTypes.getters.GET_IS_SKIN_OILINESS_DISCOMFORT](state) {
    const { greasySkin } = state.questionnaire.fields;

    return yes(greasySkin);
  },

  [questTypes.getters.GET_IS_SKIN_TEXTURE_DISCOMFORT](state) {
    const { skinRoughness, comedones, patches } = state.questionnaire.fields;

    return hasSkinDysfunction(skinRoughness) || any(comedones, patches);
  },

  [questTypes.getters.GET_IS_PIMPLES_DISCOMFORT](state) {
    const { proneToAcne } = state.questionnaire.fields;

    return yes(proneToAcne);
  },

  [questTypes.getters.GET_IS_SKIN_PIGMENTATION_DISCOMFORT](state) {
    const { hyperpigmentation, wishesLightening } = state.questionnaire.fields;

    return any(hyperpigmentation, wishesLightening);
  },

  [questTypes.getters.QUESTIONNAIRE_ID](state, storeGetters) {
    const {
      [questTypes.getters.GET_QUESTIONNAIRE]: { id }
    } = storeGetters;

    return id;
  },

  [questTypes.getters.INFLUENCER](state) {
    return state.influencer;
  },

  [questTypes.getters.GET_ARE_CLARIFICATION_QUESTIONS_SKIPPED](state) {
    return state.questionnaire.skippedSections.includes(SKIPPED_SECTIONS.DYSFUNCTIONS_SECTIONS);
  }
};
