<template>
  <warning-modal
    class="thin-skin-warning-modal"
    name="thin-skin-warning-modal"
    title="dashboard.microneedling.warning.notice"
    @before-open="onBeforeOpen"
  >
    <template #main-content>
      {{ $t('dashboard.microneedling.warning.results') }}
      <ul class="thin-skin-warning-list">
        <li class="thin-skin-warning-list__list-item">
          {{ $t($options.restrictionText) }}
        </li>
      </ul>
    </template>
    <template #secondary-content>
      <i18n tag="span" path="dashboard.microneedling.warning.conditions.needleRecommendation.body">
        <b>
          {{ $t('dashboard.microneedling.warning.conditions.needleRecommendation.needleSize') }}
        </b>
      </i18n>
    </template>

    <template #actions>
      <confirmation-modal-actions @cancel="handleClose(false)" @confirm="handleClose(true)" />
    </template>
  </warning-modal>
</template>

<script>
import WarningModal from '@/modules/dashboard/components/common/warning-modal/WarningModal.vue';
import ConfirmationModalActions from '@/modules/dashboard/components/common/warning-modal/ConfirmationModalActions.vue';

import {
  MICRONEEDLING_RESTRICTION_DISPLAY_VALUE,
  MICRONEEDLING_RESTRICTION
} from '@/modules/dashboard/constants/microneedling';

export default {
  name: 'ThinSkinWarningModal',
  components: { WarningModal, ConfirmationModalActions },
  restrictionText: MICRONEEDLING_RESTRICTION_DISPLAY_VALUE[MICRONEEDLING_RESTRICTION.THIN_SKIN],
  data() {
    return {
      diagnosticResults: [],
      onClose: null
    };
  },
  methods: {
    handleClose(confirm) {
      this.onClose({ confirm });
    },
    onBeforeOpen({ params: { diagnosticResults, onClose } }) {
      this.diagnosticResults = diagnosticResults;
      this.onClose = onClose;
    }
  }
};
</script>

<style lang="scss" scoped>
.thin-skin-warning-list {
  padding: 10px 0 0 0;
  list-style: none;

  &__list-item {
    margin-bottom: 4px;
    font-weight: 600;

    &::before {
      content: '—';
      display: inline-block;
      padding-right: 5px;
    }
  }
}
</style>
