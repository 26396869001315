/* eslint no-console: 0 */

const { NODE_ENV } = process.env;

const DEV = NODE_ENV !== 'production';

const noop = () => {};

const info = DEV ? console.log : noop;

const warning = DEV ? console.warn : noop;

const error = DEV ? console.error : noop;

export const logger = {
  info,
  warning,
  error
};
