import { TREATMENT_SECTION_NAME } from '@/modules/dashboard/api/constants';

export const MICRONEEDLING_NEEDLE_SIZE = {
  SMALL: 0.5,
  MEDIUM: 1
};

export const MICRONEEDLING_NEEDLE_SIZES = [
  MICRONEEDLING_NEEDLE_SIZE.SMALL,
  MICRONEEDLING_NEEDLE_SIZE.MEDIUM
];

export const [DEFAULT_NEEDLE_SIZE] = MICRONEEDLING_NEEDLE_SIZES;

export const MICRONEEDLING_VOLUME = {
  [TREATMENT_SECTION_NAME.TREAT_FACE]: 5,
  [TREATMENT_SECTION_NAME.TREAT_EYES]: 2.5
};

export const MICRONEEDLING_RESTRICTION = {
  ACNE_ROSACEA: 'ACNE_ROSACEA',
  ACTIVE_ACNE_VULGARIS: 'ACTIVE_ACNE_VULGARIS',
  ALLERGIC_TO_ASPIRIN: 'ALLERGIC_TO_ASPIRIN',
  COLD_SCORES: 'COLD_SCORES',
  ORAL_RETINOID: 'ORAL_RETINOID',
  POST_INFLAMMATORY_HYPERPIGMENTATION: 'POST_INFLAMMATORY_HYPERPIGMENTATION',
  PREGNANCY: 'PREGNANCY',
  SENSITIVE_SKIN: 'SENSITIVE_SKIN',
  SKIN_CANCER: 'SKIN_CANCER',
  SKIN_REDNESS: 'SKIN_REDNESS',
  THIN_SKIN: 'THIN_SKIN'
};

export const MICRONEEDLING_RESTRICTION_DISPLAY_VALUE = {
  [MICRONEEDLING_RESTRICTION.ACNE_ROSACEA]:
    'dashboard.microneedling.warning.conditions.acnerosacea',
  [MICRONEEDLING_RESTRICTION.ACTIVE_ACNE_VULGARIS]:
    'dashboard.microneedling.warning.conditions.acnevulgaris',
  [MICRONEEDLING_RESTRICTION.ALLERGIC_TO_ASPIRIN]:
    'dashboard.microneedling.warning.conditions.aspirin',
  [MICRONEEDLING_RESTRICTION.COLD_SCORES]: 'dashboard.microneedling.warning.conditions.coldsores',
  [MICRONEEDLING_RESTRICTION.ORAL_RETINOID]:
    'dashboard.microneedling.warning.conditions.oralretinoid',
  [MICRONEEDLING_RESTRICTION.POST_INFLAMMATORY_HYPERPIGMENTATION]:
    'dashboard.microneedling.warning.conditions.hyperpigmentation',
  [MICRONEEDLING_RESTRICTION.PREGNANCY]: 'dashboard.microneedling.warning.conditions.pregnant',
  [MICRONEEDLING_RESTRICTION.SENSITIVE_SKIN]:
    'dashboard.microneedling.warning.conditions.sensitiveskin',
  [MICRONEEDLING_RESTRICTION.SKIN_CANCER]: 'dashboard.microneedling.warning.conditions.cancer',
  [MICRONEEDLING_RESTRICTION.SKIN_REDNESS]: 'dashboard.microneedling.warning.conditions.rendess',
  [MICRONEEDLING_RESTRICTION.THIN_SKIN]: 'dashboard.microneedling.warning.conditions.sensitiveskin'
};
