import { formatDate } from '@/modules/dashboard/utils/date-utils';
import {
  convertToUpperCase,
  currencyFormat,
  formatIngredientConcentration,
  formatNumberWithOneDecimalPart,
  formatNumberWithTwoDecimalPart,
  transformPhone
} from '@/modules/dashboard/utils';

export default {
  install(Vue) {
    Vue.filter('formatDate', formatDate);
    Vue.filter('upperCase', convertToUpperCase);
    Vue.filter('phoneNumber', transformPhone);
    Vue.filter('currencyFormat', currencyFormat);
    Vue.filter('formatNumberOneDecimalPart', formatNumberWithOneDecimalPart);
    Vue.filter('formatNumberTwoDecimalPart', formatNumberWithTwoDecimalPart);
    Vue.filter('ingredientConcentration', formatIngredientConcentration);
  }
};
