export const screenHeightMixin = {
  mounted() {
    this.screenHeightMixinUpdateVHUnitSize();

    window.addEventListener('resize', this.screenHeightMixinUpdateVHUnitSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.screenHeightMixinUpdateVHUnitSize);
  },
  methods: {
    screenHeightMixinUpdateVHUnitSize() {
      const windowInnerHeight = window.innerHeight;

      document.body.style.setProperty('--screen-height', `${windowInnerHeight}px`);
    }
  }
};
