import { SERUM_USAGE_KEYS } from '@/modules/dashboard/api/constants';

export const checkMoisturizeSelection = ({ name, usage }, moisutrizeUsages, modal) =>
  new Promise(resolve => {
    const isUsageAlreadySelected = moisutrizeUsages[usage];
    let isMorningAndEveningSelected;

    switch (usage) {
      case SERUM_USAGE_KEYS.MORNING_ONLY:
      case SERUM_USAGE_KEYS.EVENING_ONLY: {
        // Checks if Morning and Evening are selected separately.
        const isSeparatedAmAndPmSelected =
          moisutrizeUsages[SERUM_USAGE_KEYS.MORNING_ONLY] &&
          moisutrizeUsages[SERUM_USAGE_KEYS.EVENING_ONLY];

        // Checks if 'Morning and Evening' is selected and we are changing usage on another product.
        const isAmAndPmSelected =
          moisutrizeUsages[SERUM_USAGE_KEYS.MORNING_AND_EVENING] &&
          moisutrizeUsages[SERUM_USAGE_KEYS.MORNING_AND_EVENING] !== name;

        const isMorningAndEveningSelectedWithAmOrPm =
          isAmAndPmSelected &&
          (moisutrizeUsages[SERUM_USAGE_KEYS.MORNING_ONLY] ||
            moisutrizeUsages[SERUM_USAGE_KEYS.EVENING_ONLY]);

        isMorningAndEveningSelected =
          isSeparatedAmAndPmSelected || isAmAndPmSelected || isMorningAndEveningSelectedWithAmOrPm;

        break;
      }

      default: {
        // Checks if we have any other products selected with another name.
        isMorningAndEveningSelected = Object.values(moisutrizeUsages).some(
          usageProduct => usageProduct && usageProduct !== name
        );
      }
    }

    if (isUsageAlreadySelected || isMorningAndEveningSelected) {
      modal.show('moisturize-usage-selection-modal', {
        productName: name,
        productTimeUsage: usage,
        resolver: resolve
      });

      return;
    }

    resolve(true);
  });

export const removeFromMoisturizeUsages = (productName, moisutrizeUsages) =>
  Object.entries(moisutrizeUsages).reduce(
    (usages, [usageTime, usageProduct]) => {
      if (usageProduct && usageProduct === productName) {
        return { ...usages, [usageTime]: null };
      }

      return usages;
    },
    { ...moisutrizeUsages }
  );

export const handleMorningOrEveningUsageSelection = ({ usage }, moisutrizeUsages) => {
  const selectedUsageKeysToCheck = [usage, SERUM_USAGE_KEYS.MORNING_AND_EVENING];

  // Checks if we have needed usage selected or 'Morning and Evening'.
  return selectedUsageKeysToCheck.reduce((usageObject, key) => {
    const usageProduct = moisutrizeUsages[key];

    if (!usageProduct) {
      return usageObject;
    }

    return { key, productName: usageProduct };
  }, {});
};
