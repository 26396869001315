import { omit, without } from 'lodash';
import { dashTypes } from '@/modules/dashboard/store/types';

import { getInitialState } from '@/modules/dashboard/store/state';

import {
  DEFAULT_PATIENT_FILTER,
  TREATMENT_DIRECTORY_INITIAL_STATE,
  INITIAL_MOISTURIZE_USAGES
} from '@/modules/dashboard/api/constants';

export default {
  [dashTypes.mutations.RESET_MODULE](state) {
    const resetState = omit(getInitialState(), [
      'patientOrder',
      'patientDateOrder',
      'sortingDirections'
    ]);

    Object.assign(state, resetState);
  },

  // STATUS

  [dashTypes.mutations.SET_LOADING](state, loading) {
    const { [dashTypes.state.STATUS]: status } = state;

    status.loading = loading;
  },

  [dashTypes.mutations.ADD_ERROR](state, error) {
    const { [dashTypes.state.STATUS]: status } = state;

    status.errors.push(error);
  },

  [dashTypes.mutations.CLEAR_ERRORS](state) {
    const { [dashTypes.state.STATUS]: status } = state;

    status.errors = [];
  },

  [dashTypes.mutations.SET_IS_DASHBOARD_LOCKED](state, lockStatus) {
    const { [dashTypes.state.STATUS]: status } = state;

    status.isDashboardLocked = lockStatus;
  },

  [dashTypes.mutations.SET_NOTIFICATIONS_COUNTER](state, notificationsCounter) {
    state[dashTypes.state.NOTIFICATIONS_COUNTER] = notificationsCounter;
  },

  // PATIENTS

  [dashTypes.mutations.SET_PATIENT_ORDER](state, patientOrder) {
    state[dashTypes.state.PATIENT_ORDER] = patientOrder;
  },

  [dashTypes.mutations.SET_PATIENT_DATE_ORDER](state, patientDateOrder) {
    state[dashTypes.state.PATIENT_DATE_ORDER] = patientDateOrder;
  },

  [dashTypes.mutations.SET_OPENED_PATIENT](state, openedPatient) {
    state[dashTypes.state.OPENED_PATIENT] = openedPatient;
  },

  [dashTypes.mutations.SET_REGISTRATION_DATE_SORTING_DIRECTION](state, direction) {
    const { [dashTypes.state.SORTING_DIRECTIONS]: sortingDirections } = state;

    sortingDirections.registrationDecentOrder = direction;
  },

  [dashTypes.mutations.SET_LAST_ACTIVITY_SORTING_DIRECTION](state, direction) {
    const { [dashTypes.state.SORTING_DIRECTIONS]: sortingDirections } = state;

    sortingDirections.lastActivityDecentOrder = direction;
  },

  [dashTypes.mutations.SET_PATIENT_NAME_SORTING_DIRECTION](state, direction) {
    const { [dashTypes.state.SORTING_DIRECTIONS]: sortingDirections } = state;

    sortingDirections.patientNameDecentOrder = direction;
  },

  [dashTypes.mutations.SET_ERROR](state, payload) {
    state[dashTypes.state.ERROR] = payload;
  },

  [dashTypes.mutations.SET_UPDATE_FILTER](state, payload) {
    state[dashTypes.state.PARTIAL_NAME] = payload;
  },

  [dashTypes.mutations.SET_PATIENT_FILTER_COUNT](state, patientFilterCount) {
    state[dashTypes.state.PATIENT_FILTER_COUNT] = patientFilterCount;
  },

  [dashTypes.mutations.SET_CURRENT_PATIENT](state, payload) {
    state[dashTypes.state.CURRENT_PATIENT] = {
      ...state[dashTypes.state.CURRENT_PATIENT],
      ...payload
    };
  },

  [dashTypes.mutations.RESET_CURRENT_PATIENT](state) {
    state[dashTypes.state.CURRENT_PATIENT] = null;
  },

  [dashTypes.mutations.SET_CURRENT_PATIENT_DIAGNOSTICS](state, diagnostics) {
    state[dashTypes.state.CURRENT_PATIENT] = {
      ...state[dashTypes.state.CURRENT_PATIENT],
      diagnostics
    };
  },

  [dashTypes.mutations.SET_CURRENT_PATIENT_TREATMENTS](state, treatments) {
    state[dashTypes.state.CURRENT_PATIENT] = {
      ...state[dashTypes.state.CURRENT_PATIENT],
      treatments
    };
  },

  [dashTypes.mutations.SET_PHOTO_ANALYSIS](state, photoAnalysis) {
    state[dashTypes.state.PHOTO_ANALYSIS] = photoAnalysis;
  },

  [dashTypes.mutations.SET_PHOTO_ANALYSIS_RESULTS](state, result) {
    state[dashTypes.state.PHOTO_ANALYSIS] = { ...state[dashTypes.state.PHOTO_ANALYSIS], result };
  },

  [dashTypes.mutations.SET_PHOTO_ANALYSIS_SPIDER_CHART](state, spiderChart) {
    state[dashTypes.state.PHOTO_ANALYSIS] = {
      ...state[dashTypes.state.PHOTO_ANALYSIS],
      spiderChart
    };
  },

  [dashTypes.mutations.SET_PHOTO_ANALYSIS_UPDATED_RESULTS](state, updatedResults) {
    state[dashTypes.state.PHOTO_ANALYSIS] = {
      ...state[dashTypes.state.PHOTO_ANALYSIS],
      updatedResults
    };
  },

  [dashTypes.mutations.SET_TREATMENT_DIRECTORY_QUESTIONS](state, questions) {
    const { [dashTypes.state.TREATMENT_DIRECTORY]: treatmentDirectory } = state;

    treatmentDirectory.questions = questions;
  },

  [dashTypes.mutations.SET_TREATMENT_DIRECTORY_RECOMMENDATIONS](state, recommendations) {
    const { [dashTypes.state.TREATMENT_DIRECTORY]: treatmentDirectory } = state;

    treatmentDirectory.recommendations = recommendations;
  },

  [dashTypes.mutations.RESET_TREATMENT_DIRECTORY](state) {
    state[dashTypes.state.TREATMENT_DIRECTORY] = {
      questions: { ...TREATMENT_DIRECTORY_INITIAL_STATE.QUESTIONS },
      recommendations: TREATMENT_DIRECTORY_INITIAL_STATE.RECOMMENDATIONS
    };
  },

  [dashTypes.mutations.SET_LOAD_SETTING](state, payload) {
    state[dashTypes.state.SETTING] = payload;
  },

  [dashTypes.mutations.SET_UPDATE_PATIENT](state, { field, fieldValue }) {
    Object.assign(state[dashTypes.state.CURRENT_PATIENT], {
      [field]: fieldValue
    });
  },

  [dashTypes.mutations.SET_UPDATE_PRICE](state, productPrices) {
    Object.assign(state[dashTypes.state.SETTING], { productPrices });
  },

  [dashTypes.mutations.SET_DIAGNOSTIC_VALIDATED](state, { diagnostic, validated }) {
    diagnostic.validated = validated;
  },

  [dashTypes.mutations.SET_ACTIVE_DIAGNOSTIC_ID](state, diagnosticId) {
    state[dashTypes.state.ACTIVE_DIAGNOSTIC_ID] = diagnosticId;
  },

  [dashTypes.mutations.SET_NOT_VALIDATED_DIAGNOSTIC_ID](state, diagnosticId) {
    state[dashTypes.state.NOT_VALIDATED_DIAGNOSTIC_ID] = diagnosticId;
  },

  [dashTypes.mutations.SET_SUMMARY_TREATMENT_ID](state, treatmentId) {
    state[dashTypes.state.CREATE_TREATMENT].summary.summaryTreatmentId = treatmentId;
  },

  [dashTypes.mutations.SET_ACTIVE_TREATMENT_ID](state, treatmentId) {
    state[dashTypes.state.ACTIVE_TREATMENT_ID] = treatmentId;
  },

  [dashTypes.mutations.SET_ACTIVE_PATIENT_TAB](state, activePatientTab) {
    state[dashTypes.state.ACTIVE_PATIENT_TAB] = activePatientTab;
  },

  [dashTypes.mutations.SET_ACTIVE_INVITE_TAB](state, activeInviteTab) {
    state[dashTypes.state.ACTIVE_INVITE_TAB] = activeInviteTab;
  },

  [dashTypes.mutations.SET_PATIENT_FILTER](state, patientFilter) {
    state[dashTypes.state.PATIENT_FILTER] = patientFilter;
  },

  [dashTypes.mutations.RESET_PATIENT_FILTER](state) {
    state[dashTypes.state.PATIENT_FILTER] = DEFAULT_PATIENT_FILTER;
  },

  [dashTypes.mutations.SET_PDF_SUMMARY](state, pdfSummary) {
    state[dashTypes.state.PDF_SUMMARY] = pdfSummary;
  },

  [dashTypes.mutations.RESET_PDF_SUMMARY](state) {
    state[dashTypes.state.PDF_SUMMARY] = null;
  },

  [dashTypes.mutations.SET_CREATE_TREATMENT_TYPE](state, createTreatmentType) {
    state[dashTypes.state.CREATE_TREATMENT].type = createTreatmentType;
  },

  [dashTypes.mutations.SET_RECOMMENDATION_DIAGNOSTIC](state, recommendationDiagnostic) {
    state[dashTypes.state.CREATE_TREATMENT].recommendationDiagnostic = recommendationDiagnostic;
  },

  [dashTypes.mutations.SET_TREATMENT_RECOMMENDATION](state, treatmentRecommendation) {
    state[dashTypes.state.CREATE_TREATMENT].treatmentRecommendation = treatmentRecommendation;
  },

  [dashTypes.mutations.SET_SUMMARY_TREATMENT](state, summaryTreatment) {
    state[dashTypes.state.CREATE_TREATMENT].summary = summaryTreatment;
  },

  [dashTypes.mutations.SET_TREAT_FACE](state, treatFace) {
    state[dashTypes.state.CREATE_TREATMENT].summary.treatFace = treatFace;
  },

  [dashTypes.mutations.SET_TREAT_EYES](state, treatEyes) {
    state[dashTypes.state.CREATE_TREATMENT].summary.treatEyes = treatEyes;
  },

  [dashTypes.mutations.SET_CLEANSE_PRODUCTS](state, cleanseProducts) {
    state[dashTypes.state.CREATE_TREATMENT].sectionProducts.cleanse = cleanseProducts;
  },

  [dashTypes.mutations.SET_CLEANSE_TREATMENT](state, cleanse) {
    state[dashTypes.state.CREATE_TREATMENT].summary.cleanse = cleanse;
  },

  [dashTypes.mutations.SET_MOISTURIZE_PRODUCTS](state, moisturizeProducts) {
    state[dashTypes.state.CREATE_TREATMENT].sectionProducts.moisturize = moisturizeProducts;
  },

  [dashTypes.mutations.SET_MOISTURIZE_TREATMENT](state, moisturize) {
    state[dashTypes.state.CREATE_TREATMENT].summary.moisturize = moisturize;
  },

  [dashTypes.mutations.SET_PROTECT_PRODUCTS](state, protectProducts) {
    state[dashTypes.state.CREATE_TREATMENT].sectionProducts.protect = protectProducts;
  },

  [dashTypes.mutations.SET_PROTECT_TREATMENT](state, protect) {
    state[dashTypes.state.CREATE_TREATMENT].summary.protect = protect;
  },

  [dashTypes.mutations.ADD_CREATE_TREATMENT_ERROR](state, createTreatmentError) {
    state[dashTypes.state.CREATE_TREATMENT].errors.push(createTreatmentError);
  },

  [dashTypes.mutations.RESET_CREATE_TREATMENT_ERRORS](state) {
    state[dashTypes.state.CREATE_TREATMENT].errors = [];
  },

  [dashTypes.mutations.DELETE_CREATE_TREATMENT_ERROR](state, createTreatmentError) {
    state[dashTypes.state.CREATE_TREATMENT].errors = without(
      state[dashTypes.state.CREATE_TREATMENT].errors,
      createTreatmentError
    );
  },

  [dashTypes.mutations.ADD_PATIENT_COMMENT](state, comment) {
    state[dashTypes.state.CURRENT_PATIENT].comments.unshift(comment);
  },

  [dashTypes.mutations.EDIT_PATIENT_COMMENT](state, editedComment) {
    state[dashTypes.state.CURRENT_PATIENT].comments = state[
      dashTypes.state.CURRENT_PATIENT
    ].comments.map(comment => {
      if (comment.id === editedComment.id) {
        return editedComment;
      }

      return comment;
    });
  },

  [dashTypes.mutations.DELETE_PATIENT_COMMENT](state, commentId) {
    state[dashTypes.state.CURRENT_PATIENT].comments = state[
      dashTypes.state.CURRENT_PATIENT
    ].comments.filter(({ id }) => id !== commentId);
  },

  [dashTypes.mutations.SET_MARKETING_CAMPAIGNS](state, marketingCampaigns) {
    state[dashTypes.state.MARKETING_CAMPAIGNS].campaigns = marketingCampaigns;
  },

  [dashTypes.mutations.SET_ACTIVE_MARKETING_CAMPAIGN](state, campaign) {
    state[dashTypes.state.MARKETING_CAMPAIGNS].activeCampaign = campaign;
  },

  [dashTypes.mutations.SET_SAVED_MESSAGE_TEMPLATE](state, savedTemplate) {
    state[dashTypes.state.MESSAGE_TEMPLATE].savedTemplate = savedTemplate;
  },

  [dashTypes.mutations.SET_CURRENT_MESSAGE_TEMPLATE](state, currentTemplate) {
    state[dashTypes.state.MESSAGE_TEMPLATE].currentTemplate = currentTemplate;
  },

  [dashTypes.mutations.SET_ACTIVE_MESSAGE_TEMPLATE_TAB](state, activeTab) {
    state[dashTypes.state.MESSAGE_TEMPLATE].activeTab = activeTab;
  },

  [dashTypes.mutations.SET_UNIVERSKIN_H_SECTION_SELECTION](state, section) {
    state[dashTypes.state.CREATE_TREATMENT].universkinHSelectionSection = section;
  },

  [dashTypes.mutations.RESET_MOISTURIZE_USAGES](state) {
    state[dashTypes.state.AVAILABLE_MOISTURIZE_USAGES] = { ...INITIAL_MOISTURIZE_USAGES };
  },

  [dashTypes.mutations.UPDATE_MOISTURIZE_USAGES](state, usages) {
    state[dashTypes.state.AVAILABLE_MOISTURIZE_USAGES] = { ...usages };
  },

  [dashTypes.mutations.SET_MOISTURIZE_USAGES](state, { name, usage }) {
    state[dashTypes.state.AVAILABLE_MOISTURIZE_USAGES][usage] = name;
  }
};
