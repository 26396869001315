export const PATIENT_IDENTITY_FIELDS = [
  'firstName',
  'lastName',
  'phone',
  'phoneIsoCode',
  'dateOfBirth',
  'age',
  'gender',
  'genotype'
];

export const PATIENT_INFORMATION_FIELDS = [
  ...PATIENT_IDENTITY_FIELDS,
  'eyeColor',
  'naturalHairColor',
  'skinColor',
  'skinType',
  'skinTypeNorDryOilyComb',
  'phototype',
  'ethnicity',
  'otherEthnicity',
  'skinThickness'
];

export const PATIENT_MEDICAL_BACKGROUND_FIELDS = [
  'pregnant',
  'menopausalState',
  'takingHRT',
  'takingBirthControlPill',
  'birthControlPillName',
  'usesIUD',
  'takingHormoneSupplements',
  'treatedForSkinCancer',
  'glandularCancer',
  'breastCancer',
  'hasHerpes',
  'sufferedFromAcneRosacea',
  'treatedForAcne',
  'acneTreatments',
  'usingBenzoylPeroxide',
  'benzoylPeroxideUseDuration',
  'benzoylPeroxideUseHelped',
  'usingSalicylicAcid',
  'salicylicAcidUseDuration',
  'salicylicAcidUseHelped',
  'usingSulfur',
  'sulfurUseDuration',
  'sulfurUseHelped',
  'usingTopicalRetinol',
  'topicalRetinolUseDuration',
  'topicalRetinolUseHelped',
  'counterProductName',
  'usingCounterProduct',
  'counterProductUseDuration',
  'counterProductUseHelped',
  'usingClindamycin',
  'clindamycinUseDuration',
  'clindamycinUseHelped',
  'usingErythromycin',
  'erythromycinUseDuration',
  'erythromycinUseHelped',
  'topicalAntibioticName',
  'usingTopicalAntibiotic',
  'topicalAntibioticUseDuration',
  'topicalAntibioticUseHelped',
  'usingDoxicycline',
  'doxicyclineUseDuration',
  'doxicyclineUseHelped',
  'usingMinocycline',
  'minocyclineUseDuration',
  'minocyclineUseHelped',
  'usingTetracycline',
  'tetracyclineUseDuration',
  'tetracyclineUseHelped',
  'oralAntibioticsName',
  'usingOralAntibiotics',
  'oralAntibioticUseDuration',
  'oralAntibioticUseHelped',
  'usingTopicalRetinoid',
  'topicalRetinoidUseDuration',
  'topicalRetinoidUseHelped',
  'usingOralRetinoid',
  'oralRetinoidUseDuration',
  'oralRetinoidUseHelped',
  'usingOralContraceptives',
  'oralContraceptiveUseDuration',
  'oralContraceptiveUseHelped',
  'allergyTypes',
  'foodAllergies',
  'otherFoodAllergies',
  'medicationAllergies',
  'otherMedicationAllergies',
  'environmentalAllergies',
  'otherEnvironmentalAllergies',
  'substanceAllergies',
  'otherSubstanceAllergies',
  'cosmeticAllergies',
  'toleratesExfoliantActives',
  'useOtherExfoliants',
  'prolongedHealingTime',
  'medicalConditions',
  'medicationsUsed',
  'skinReactsToProducts',
  'safeCleansers'
];

export const AI_SELECTION_FIELDS = [
  'ageGroup',
  'gender',
  'phototype',
  'skinTypeNorDryOilyComb',
  'skinThickness',
  'skinReactsToProducts',
  'wrinkles',
  'acneScars',
  'darkCircles',
  'darkCircleColors',
  'eyeBags',
  'prominentBloodVessels',
  'skinDryness',
  'drynessOccurrence',
  'drynessCausedByClimate',
  'facialDrynessLocations',
  'greasySkin',
  'openFacialPores',
  'greasySkinLocations',
  'skinRoughness',
  'proneToAcne',
  'facialAcneCoverage',
  'hyperpigmentation',
  'spotShapes'
];
