export const QUESTIONNAIRE_AVAILABLE_LOCALES = [
  'en',
  'fr',
  'da',
  'de',
  'es',
  'es-mx',
  'ar',
  'el',
  'nl',
  'pl',
  'ro',
  'ru',
  'uk',
  'it',
  'zh-cn',
  'zh',
  'vi',
  'sv'
];

// ---- CONFIG ---------------------------------------------------------------------------------------------------------

export const SOURCE = {
  INTERNAL: 'internal',
  EXTERNAL: 'external'
};

export const QUESTIONNAIRE_STATUS = {
  ONGOING: 'ONGOING',
  COMPLETED: 'COMPLETED'
};

export const USAGE = {
  NEW_PATIENT: 'new_patient',
  FIRST_QUESTIONNAIRE: 'first_questionnaire',
  NEW_QUESTIONNAIRE: 'new_questionnaire',
  CONTINUE_QUESTIONNAIRE: 'continue_questionnaire'
};

export const QUESTIONNAIRE_TYPES = {
  STANDARD: 'STANDARD',
  PHOTO_ANALYSIS: 'PHOTO_ANALYSIS'
};

// ---- COMMON ---------------------------------------------------------------------------------------------------------

export const BOOLEAN = {
  YES: 'yes',
  NO: 'no'
};

export const NONE = 'none';

export const DISCOMFORT = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  VERY_HIGH: 'very_high'
};

export const DURATION = {
  WEEKS: 'weeks',
  MONTHS: 'months',
  YEARS: 'years'
};

export const AMOUNT = {
  CONSIDERABLY: 'considerably',
  MODERATELY: 'moderately',
  NO: 'no'
};

export const BODY_ZONE = {
  SCALP: 'scalp',
  EARS: 'ears',
  FACE: 'face',
  FACE_AND_NECK: 'faceAndNeck',
  NECK: 'neck',
  NAPE_OF_NECK: 'nape_of_neck',
  UPPER_BODY: 'upper_body',
  SHOULDERS: 'shoulders',
  UPPER_BACK: 'upper_back',
  BACK: 'back',
  ARMPITS: 'armpits',
  UPPER_ARMS: 'upper_arms',
  ARMS: 'arms',
  CHEST: 'chest',
  INSIDE_OF_ELBOWS: 'inside_of_elbows',
  OUTSIDE_OF_ELBOWS: 'outside_of_elbows',
  ABDOMEN: 'abdomen',
  BELLY: 'belly',
  LOWER_BACK: 'lower_back',
  HIPS: 'hips',
  WRISTS: 'wrists',
  HANDS: 'hands',
  CROTCH: 'crotch',
  BUTTOCKS: 'buttocks',
  THIGHS: 'thighs',
  LEGS: 'legs',
  INSIDE_OF_KNEES: 'inside_of_knees',
  OUTSIDE_OF_KNEES: 'outside_of_knees',
  ANKLES: 'ankles',
  FEET: 'feet'
};

export const FACE_ZONE = {
  FOREHEAD: 'forehead',
  EYELIDS: 'eyelids',
  EYE_CONTOUR: 'eye_contour',
  NOSE: 'nose',
  CHEEKS: 'cheeks',
  CHEEKS_AND_CHEEKBONES: 'cheeks_and_cheekbones',
  UPPER_CHEEKS: 'upper_cheeks',
  LOWER_CHEEKS: 'lower_cheeks',
  MOUTH: 'mouth',
  SIDE_OF_MOUTH: 'side_of_mouth',
  CHIN: 'chin',
  NECK: 'neck'
};

export const OTHER_VALUE_BINDINGS = {
  value: 'other',
  text: 'label.other',
  overridesAll: true,
  askForOtherInput: true
};

export const OTHER_VALUE_INCLUSIVE_BINDINGS = {
  ...OTHER_VALUE_BINDINGS,
  overridesAll: false
};

// ---- IDENTITY -------------------------------------------------------------------------------------------------------

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other'
};

export const GENDER_VALUES = Object.values(GENDER);

export const GENOTYPE = {
  FEMALE: 'female',
  MALE: 'male'
};

export const EYE_COLOR = {
  BLUE: 'blue',
  GREY: 'grey',
  GREEN: 'green',
  HAZEL: 'hazel',
  BROWN: 'brown',
  BLACK: 'black'
};

export const HAIR_COLOR = {
  RED: 'red',
  LIGHT_BLONDE: 'light_blonde',
  MEDIUM_BLONDE: 'medium_blonde',
  DARK_BLONDE: 'dark_blonde',
  LIGHT_BROWN: 'light_brown',
  MEDIUM_BROWN: 'medium_brown',
  DARK_BROWN: 'dark_brown',
  BLACK: 'black'
};

export const SKIN_COLOR = {
  VERY_PALE_WHITE: 'very_pale_white',
  PALE_WHITE: 'pale_white',
  WHITE: 'white',
  LIGHT_BROWN: 'light_brown',
  MEDIUM_BROWN: 'medium_brown',
  DARK_BROWN: 'dark_brown',
  BLACK: 'black'
};

export const SKIN_TYPE = {
  ALWAYS_BURN: 'always_burn',
  BURN_EASILY: 'burn_easily',
  BURN_MODERATELY: 'burn_moderately',
  BURN_MINIMALLY: 'burn_minimally',
  NEVER_BURN: 'never_burn',
  NO_CHANGE: 'no_change'
};

export const SKIN_TYPES_TO_LIGHTEN = [
  SKIN_TYPE.NO_CHANGE,
  SKIN_TYPE.NEVER_BURN,
  SKIN_TYPE.BURN_MINIMALLY
];

export const PHOTOTYPE = {
  PHOTOTYPE1: 'phototype1',
  PHOTOTYPE2: 'phototype2',
  PHOTOTYPE3: 'phototype3',
  PHOTOTYPE4: 'phototype4',
  PHOTOTYPE5: 'phototype5',
  PHOTOTYPE6: 'phototype6'
};

export const PHOTOTYPES_TO_LIGHTEN = [
  PHOTOTYPE.PHOTOTYPE3,
  PHOTOTYPE.PHOTOTYPE4,
  PHOTOTYPE.PHOTOTYPE5,
  PHOTOTYPE.PHOTOTYPE6
];

export const PHOTOTYPES_TO_WOUND_HEALING = [
  PHOTOTYPE.PHOTOTYPE4,
  PHOTOTYPE.PHOTOTYPE5,
  PHOTOTYPE.PHOTOTYPE6
];

export const ETHNICITY = {
  CAUSCASIAN: 'caucasian',
  AFRICAN: 'african',
  MIDDLE_EATERN: 'middle_eastern',
  ASIAN: 'asian',
  INDIAN: 'indian',
  LATIN_AMERICAN: 'latin_american'
};

export const SKIN_THICKNESS = {
  THIN: 'thin',
  NORMAL: 'normal',
  THICK: 'thick'
};

// ---- SKINCARE GOALS -------------------------------------------------------------------------------------------------

export const TREATMENT = {
  FACE: 'face',
  EYE_CONTOUR: 'eye_contour',
  BODY: 'body'
};

// ---- MEDICAL BACKGROUND ---------------------------------------------------------------------------------------------

export const MENOPAUSAL = {
  PERIMENOPAUSAL: 'perimenopausal',
  MENOPAUSAL: 'menopausal',
  POSTMENOPAUSAL: 'postmenopausal',
  NO: 'no'
};

export const ACNE_TREATMENT = {
  BENZOYL_PEROXIDE: 'benzoyl_peroxide',
  SALICYCLIC_ACID: 'salicylic_acid',
  SULFUR: 'sulfur',
  TOPICAL_RETINOL_GEL: 'topical_retinol_gel',
  CLINDAMYCIN: 'ta_clindamycin',
  TOPICAL_ERYTHROMYCIN: 'ta_erythromycin',
  DOXYCYCLINE: 'oa_doxyccycline',
  ORAL_ERYTHROMYCIN: 'oa_erythromycin',
  MINOCYCLINE: 'oa_minocycline',
  TETRACYCLINE: 'oa_tetracycline',
  TOPICAL_RETINOID: 'topical_retinoid',
  ORAL_RETINOID: 'oral_retinoid',
  ORAL_CONTRACEPTIVES: 'oral_contraceptives'
};

export const ACNE_TREATMENT_OVER_THE_COUNTER_MEDICINE = {
  BENZOYL_PEROXIDE: 'benzoyl_peroxide',
  SALICYCLIC_ACID: 'salicylic_acid',
  SULFUR: 'sulfur',
  TOPICAL_RETINOL_GEL: 'topical_retinol_gel'
};

export const ACNE_TREATMENT_TOPICAL_ANTIBIOTICS = {
  CLINDAMYCIN: 'ta_clindamycin',
  TOPICAL_ERYTHROMYCIN: 'ta_erythromycin'
};

export const ACNE_TREATMENT_ORAL_ANTIBIOTICS = {
  DOXYCYCLINE: 'oa_doxyccycline',
  ORAL_ERYTHROMYCIN: 'oa_erythromycin',
  MINOCYCLINE: 'oa_minocycline',
  TETRACYCLINE: 'oa_tetracycline'
};

export const ACNE_TREATMENT_OTHER_MEDICINE = {
  TOPICAL_RETINOID: 'topical_retinoid',
  ORAL_RETINOID: 'oral_retinoid',
  ORAL_CONTRACEPTIVES: 'oral_contraceptives'
};

export const ALLERGY = {
  FOOD: 'food',
  MEDICATION: 'medication',
  ENVIRONMENTAL: 'environmental',
  SUBSTANCE: 'substance'
};

export const FOOD_ALLERGY = {
  FRUIT: 'fruit',
  RICE: 'rice',
  GARLIC: 'garlic',
  OATS: 'oats',
  MEAT: 'meat',
  MILK: 'milk',
  PEANUTS: 'peanuts',
  FISH_OR_SHELLFISH: 'fish_or_shellfish',
  SOY: 'soy',
  TREE_NUTS: 'tree_nuts',
  WHEAT: 'wheat',
  HOT_PEPPERS: 'hot_peppers',
  GLUTEN: 'gluten',
  EGGS: 'eggs',
  TARTRAZINE: 'tartrazine',
  SULFITES: 'sulfites'
};

export const MEDICAL_ALLERGY = {
  BALSAM_OF_PERU: 'balsam_of_peru',
  TETRACYCLINE: 'tetracycline',
  DILANTIN: 'dilantin',
  TEGRETOL: 'tegretol',
  PENICILLIN: 'penicillin',
  CEPHALOSPORINS: 'cephalosporins',
  SULFONAMIDES: 'sulfonamides',
  ASPRIN: 'asprin',
  LOCAL_ANAESTHETICS: 'local_anaesthetics'
};

export const ENVIRONMENTAL_ALLERGY = {
  POLLEN: 'pollen',
  CATS_OR_DOGS: 'cats_or_dogs',
  SUN: 'sun',
  INSECT_STINGS: 'insect_stings',
  MOLD: 'mold',
  DUST_MITES: 'dust_mites'
};

export const SUBSTANCE_ALLERGY = {
  PERFUME: 'perfume',
  COSMETICS: 'cosmetics',
  NICKEL: 'nickel',
  GOLD: 'gold',
  CHROMIUM: 'chromium',
  COBALT: 'cobalt',
  FORMALDEHYDE: 'formaldehyde',
  FUNGICIDE: 'fungicide',
  LATEX: 'latex',
  DIMETHYLAMINOPROPYLAMINE: 'dimethylaminopropylamine',
  PARAPHENYLENEDIAMINE: 'paraphenylenediamine'
};

export const TOLERATES_EXFOLIANT = {
  YES: 'yes',
  NO: 'no',
  UNSURE: 'unsure'
};

export const SAFE_CLEANSER = {
  WIPES: 'wipes',
  CLEANSING_OIL: 'cleansing_oil',
  SOAP: 'soap',
  CLEANSING_MILK: 'cleansing_milk',
  MICELLAR_WATER: 'micellar_water',
  CREAM_CLEANSER: 'cream_cleanser'
};

// ---- OXIDATIVE STRESS -----------------------------------------------------------------------------------------------

export const STRESS_LEVEL = {
  RELAXED: 'relaxed',
  TIRED: 'tired',
  STRESSED: 'stressed',
  OVERLY_STRESSED: 'overly_stressed'
};

export const AIR_QUALITY = {
  CLEAN: 'clean',
  SLIGHTLY_POLLUTED: 'slightly_polluted',
  POLLUTED: 'polluted',
  VERY_POLLUTED: 'very_polluted'
};

export const USUAL_ENVIRONMENT = {
  OUTSIDE: 'outside',
  IN_THE_SUN: 'in_the_sun',
  INSIDE: 'inside',
  AIR_CONDITIONED: 'air_conditioned'
};

export const TEMPERATURE = {
  FREEZING: 'freezing',
  VERY_COLD: 'very_cold',
  COLD: 'cold',
  COOL: 'cool',
  MILD: 'mild'
};

export const FREQUENCY = {
  NEVER: 'never',
  RARELY: 'rarely',
  SOMETIMES: 'sometimes',
  VERY_OFTEN: 'very_often',
  ALWAYS: 'always'
};

// ---- SKIN APPEARANCE ------------------------------------------------------------------------------------------------

export const SAGGING_SKIN_LOCATION = {
  FACE_AND_NECK: BODY_ZONE.FACE_AND_NECK,
  ARMS: BODY_ZONE.ARMS,
  LEGS: BODY_ZONE.LEGS,
  BELLY: BODY_ZONE.BELLY
};

export const FACE_SAGGING_LOCATION = {
  EYELIDS: FACE_ZONE.EYELIDS,
  EYE_CONTOUR: FACE_ZONE.EYE_CONTOUR,
  CHEEKS: FACE_ZONE.CHEEKS,
  SIDE_OF_MOUTH: FACE_ZONE.SIDE_OF_MOUTH,
  NECK: FACE_ZONE.NECK
};

export const FACE_WRINKLES = {
  FEW_FACE_LINES: 'few_face_lines',
  FACE_EXPRESSION_LINES: 'face_expression_lines',
  FACE_DEEP_LINES: 'face_deep_lines',
  NO: 'no'
};

export const EYE_WRINKLES = {
  FEW_EYE_LINES: 'few_eye_lines',
  EYE_EXPRESSION_LINES: 'eye_expression_lines',
  EYE_DEEP_LINES: 'eye_deep_lines',
  NO: 'no'
};

export const DARK_CIRCLE_COLOR = {
  BLUISH: 'bluish',
  PIGMENTED: 'pigmented',
  HOLLOWED: 'hollowed'
};

export const EYE_BAGS_TYPES = {
  FLUID: 'fluid',
  FAT: 'fat',
  I_AM_NOT_SURE: "I'm not sure"
};

export const ACNE_SCAR_CAUSE = {
  ACNE: 'acne',
  ACCIDENT: 'accident',
  SURGERY: 'surgery',
  DISEASE: 'disease',
  CHICKENPOX: 'chickenpox',
  BURNS_AND_SCALDS: 'burns_and_scalds'
};

export const ACNE_SCAR_APPEARANCE = {
  DEPRESSED: 'depressed',
  RAISED: 'raised'
};

export const MISHAP_SCAR_SIZE = {
  UNDER_10_CM: 'under_10_cm',
  LARGE_COVERAGE: 'large_coverage'
};

export const WRINKLES_GROUP = {
  FORHEAD: 'foreheadLines',
  FROWN: 'frownLines',
  CROWS_FEET: 'crowsFeet',
  BUNNY: 'bunnyLines',
  TEAR_TROUGHS: 'tearTroughs',
  NASOLABIAL_FOLDS: 'nasolabialFolds',
  VERTICAL_LIP: 'verticalLipLines',
  MOUTH_FROWN: 'mouthFrown',
  MARIONETTE: 'marionetteLines',
  CHIN_CREASE: 'chinCrease',
  NECK: 'neck'
};

// ---- SKIN REDNESS ---------------------------------------------------------------------------------------------------

export const FLUSH_LOCATION = {
  FACE: BODY_ZONE.FACE,
  NECK: BODY_ZONE.NECK,
  UPPER_BODY: BODY_ZONE.UPPER_BODY,
  ABDOMEN: BODY_ZONE.ABDOMEN
};

export const FLUSH_DURATION = {
  MANY_YEARS: 'many_years',
  FEW_YEARS: 'few_years',
  FEW_MONTHS: 'few_months'
};

export const REDNESS_FACE_LOCATIONS = {
  FOREHEAD: FACE_ZONE.FOREHEAD,
  NOSE: FACE_ZONE.NOSE,
  CHEEKS: FACE_ZONE.CHEEKS,
  CHIN: FACE_ZONE.CHIN,
  NECK: FACE_ZONE.NECK
};

export const REDNESS_DISCOMFORT_FREQUENCY = {
  UNDER_A_WEEK: 'under_a_week',
  TWO_TO_THREE_WEEKS: 'two_to_three_weeks',
  ALL_THE_TIME: 'all_the_time'
};

export const REDNESS_DURATION = {
  UNDER_A_YEAR: 'under_a_year',
  ONE_TO_THREE_YEARS: 'one_to_three_years',
  OVER_THREE_YEARS: 'over_three_years'
};

export const CAPILLARY_FACE_LOCATION = {
  NOSE: FACE_ZONE.NOSE,
  CHEEKS: FACE_ZONE.CHEEKS,
  CHIN: FACE_ZONE.CHIN
};

// ---- SKIN DRYNESS ---------------------------------------------------------------------------------------------------

export const FACIAL_DRYNESS = {
  CHEEKS: 'cheeks',
  CHEEKS_AND_TZONE: 'cheeks_and_tzone'
};

export const DRYNESS_OCCURRENCE = {
  AFTER_CLEANSING: 'after_cleansing',
  ALL_DAY: 'all_day'
};

// ---- SKIN OILINESS --------------------------------------------------------------------------------------------------

export const GREASY_SKIN_LOCATION = {
  TZONE_ONLY: 'tzone_only',
  CHEEKS_AND_TZONE: 'cheeks_and_tzone'
};

// ---- SKIN TEXTURE ---------------------------------------------------------------------------------------------------

export const BUMPS_FACE_LOCATIONS = {
  NOSE: FACE_ZONE.NOSE,
  CHEEKS: FACE_ZONE.CHEEKS,
  FOREHEAD: FACE_ZONE.FOREHEAD,
  CHIN: FACE_ZONE.CHIN
};

export const PATCH_TYPE = {
  MOSTLY_DRY: 'mostly_dry',
  VERY_DRY: 'very_dry',
  SWOLLEN_AND_GREASY: 'swollen_and_greasy',
  UNSURE: 'unsure'
};

export const PATCH_LOCATIONS = {
  SCALP: BODY_ZONE.SCALP,
  FACE: BODY_ZONE.FACE,
  EARS: BODY_ZONE.EARS,
  NAPE_OF_NECK: BODY_ZONE.NAPE_OF_NECK,
  UPPER_BACK: BODY_ZONE.UPPER_BACK,
  LOWER_BACK: BODY_ZONE.LOWER_BACK,
  CHEST: BODY_ZONE.CHEST,
  ARMPITS: BODY_ZONE.ARMPITS,
  INSIDE_OF_ELBOWS: BODY_ZONE.INSIDE_OF_ELBOWS,
  OUTSIDE_OF_ELBOWS: BODY_ZONE.OUTSIDE_OF_ELBOWS,
  WRISTS: BODY_ZONE.WRISTS,
  HANDS: BODY_ZONE.HANDS,
  CROTCH: BODY_ZONE.CROTCH,
  INSIDE_OF_KNEES: BODY_ZONE.INSIDE_OF_KNEES,
  OUTSIDE_OF_KNEES: BODY_ZONE.OUTSIDE_OF_KNEES,
  ANKLES: BODY_ZONE.ANKLES,
  FEET: BODY_ZONE.FEET
};

export const PATCH_CAUSE = {
  DETERGENTS: 'detergents',
  FOODS: 'foods',
  DUST_OR_PETS: 'dust_or_pets',
  VIRUS: 'virus',
  ITCHY_MATERIALS: 'itchy_materials',
  STRESS: 'stress',
  DRUGS: 'drugs',
  INJURY: 'injury'
};

export const FIRST_PATCH_OCCURRENCE = {
  INFANT: 'infant',
  TEENAGER: 'teenager',
  YOUNG_ADULT: 'young_adult',
  MATURE: 'mature'
};

// ---- PIMPLES --------------------------------------------------------------------------------------------------------

export const COVERAGE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

export const ACNE_TYPE = {
  PAPULES: 'papules',
  PUSTULES: 'pustules',
  NODULES: 'nodules'
};

export const NODULE_COUNT = {
  ONE: 'one',
  TWO: 'two',
  THREE_OR_MORE: 'three_or_more'
};

export const ACNE_FREQUENCY = {
  UNDER_A_WEEK: 'under_a_week',
  TWO_TO_THREE_WEEKS: 'two_to_three_weeks',
  ALL_THE_TIME: 'all_the_time'
};

export const ACNE_DURATION = {
  UNDER_A_YEAR: 'under_a_year',
  ONE_TO_THREE_YEARS: 'one_to_three_years',
  OVER_THREE_YEARS: 'over_three_years'
};

// ---- SKIN PIGMENTATION ----------------------------------------------------------------------------------------------

export const FACIAL_SPOT_LOCATIONS = {
  UPPER_CHEEKS: FACE_ZONE.UPPER_CHEEKS,
  LOWER_CHEEKS: FACE_ZONE.LOWER_CHEEKS,
  FOREHEAD: FACE_ZONE.FOREHEAD,
  NOSE: FACE_ZONE.NOSE,
  CHIN: FACE_ZONE.CHIN,
  MOUTH: FACE_ZONE.MOUTH
};

export const SPOT_SHAPE = {
  ROUND_AND_SMALL: 'round_and_small',
  IRREGULAR_AND_LARGE: 'irregular_and_large'
};

export const SPOT_OCCURRENCE = {
  WHEN_A_CHILD: 'when_a_child',
  AT_MIDDLE_AGE: 'at_middle_age',
  DURING_PREGNANCY: 'during_pregnancy',
  AFTER_STARTING_HRT: 'after_starting_hrt',
  AFTER_STARTING_BIRTH_CONTROL: 'after_starting_birth_control',
  AFTER_AN_INJURY: 'after_an_injury',
  AFTER_PROLONGED_SUN_EXPOSURE: 'after_prolonged_sun_exposure'
};

export const SPOT_OCCURRENCE_VALUES_COMMON = [
  SPOT_OCCURRENCE.WHEN_A_CHILD,
  SPOT_OCCURRENCE.AFTER_AN_INJURY,
  SPOT_OCCURRENCE.AFTER_PROLONGED_SUN_EXPOSURE,
  'unsure'
];

export const SKIN_DYSFUNCTIONS = {
  SKIN_APPEARANCE: 'skinAppearance',
  SKIN_REDNESS: 'skinRedness',
  SKIN_DRYNESS: 'skinDryness',
  SKIN_OILINESS: 'skinOiliness',
  SKIN_TEXTURE: 'skinTexture',
  PIMPLES: 'pimples',
  SKIN_PIGMENTATION: 'skinPigmentation'
};

// ---------------------------------------------------------------------------------------------------------------------

export const MAX_PHOTO_SIZE_IN_BYTES = 15728640;
export const MAX_PHOTO_SIZE_IN_BYTES_DOCTOR = 5242880;
export const MAX_PHOTO_SIZE_IN_MB = 15;
export const MAX_PHOTO_SIZE_IN_MB_DOCTOR = 5;
export const ALLOWED_FILE_TYPE = 'image';
