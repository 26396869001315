<template>
  <popup-notification-template :messages="messages" @send-report="onSendReport" />
</template>

<script>
import PopupNotificationTemplate from '@/components/popup-notification/PopupNotificationTemplate';

const NOTIFICATIONS_DISPLAY_DURATION = 6000;

export default {
  name: 'PopupNotification',
  components: {
    PopupNotificationTemplate
  },
  data() {
    return {
      messages: []
    };
  },
  computed: {
    hasMessages() {
      return this.messages.length > 0;
    }
  },
  methods: {
    push(message) {
      this.messages.push(message);

      setTimeout(() => {
        this.pop(message.id);
      }, NOTIFICATIONS_DISPLAY_DURATION);
    },
    pop(messageId) {
      this.messages = this.messages.filter(({ id }) => messageId !== id);
    },
    onSendReport(messageId) {
      this.$emit('send-report', messageId);
      this.pop(messageId);
    }
  }
};
</script>
