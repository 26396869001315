import Hashids from 'hashids';
import get from 'lodash/get';

import { countries } from 'countries-list';

import { transformPhone } from '@/modules/dashboard/utils';

import { LOCAL_STORAGE_KEY, AVAILABLE_DASHBOARD_LANGUAGES, DEFAULT_LOCALE } from '@/constants';
import { QUESTIONNAIRE_STATUS, QUESTIONNAIRE_TYPES } from '@/modules/questionnaire/api/constants';

const hashids = new Hashids('usk', 8);

export const getHashedId = id => hashids.encode(id);

export const getDecodedId = hashedId => hashids.decode(hashedId);

export const canContinueQuestionnaire = () => {
  const localStorageData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  const questionnaireModule = get(localStorageData, 'questionnaire', {});

  const { questionnaire: lastQuestionnaire, photoAnalysisDiagnostic } = questionnaireModule;
  const { type, status } = lastQuestionnaire || {};

  const isOngoingQuestionnaire = status === QUESTIONNAIRE_STATUS.ONGOING;

  return type === QUESTIONNAIRE_TYPES.PHOTO_ANALYSIS
    ? isOngoingQuestionnaire && photoAnalysisDiagnostic
    : isOngoingQuestionnaire;
};

export const isSystemKeyCode = ({ keyCode }) => {
  const isLeftOrRightArrowKeys = keyCode === 37 || keyCode === 39;
  const isBackspaceOrDeleteOrEnterKey = keyCode === 8 || keyCode === 46 || keyCode === 13;

  return isLeftOrRightArrowKeys || isBackspaceOrDeleteOrEnterKey;
};

export const isCopyingKeys = ({ event: { ctrlKey, metaKey }, keyCode }) => {
  // Ctrl + A, Ctrl + C, Ctrl + V
  const isCopyPasteKeyCodes = keyCode === 86 || keyCode === 67 || keyCode === 65;

  return (ctrlKey || metaKey) && isCopyPasteKeyCodes;
};

export const getPhoneCodeFromISO = phoneIsoCode => countries[phoneIsoCode.toUpperCase()].phone;

export const getPatientPhone = ({ phoneIsoCode, phone }) => {
  const isPhoneIsoCodeExist = phone && phoneIsoCode;

  return isPhoneIsoCodeExist ? `+${getPhoneCodeFromISO(phoneIsoCode)}${phone}` : null;
};

export const getPatientTransformedPhone = ({ phoneIsoCode, phone }) => {
  const isPhoneExists = phone && phoneIsoCode;

  return isPhoneExists ? `+${getPhoneCodeFromISO(phoneIsoCode)} ${transformPhone(phone)}` : null;
};

export const getPhoneNumber = (phoneIsoCode, phoneNumber) =>
  `+${getPhoneCodeFromISO(phoneIsoCode)}${phoneNumber}`;

export const getDashboardLocale = locale => AVAILABLE_DASHBOARD_LANGUAGES[locale] || DEFAULT_LOCALE;

export const nextRepaint = () =>
  new Promise(resolve => {
    requestAnimationFrame(() => {
      requestAnimationFrame(resolve);
    });
  });

export const isIE = () =>
  navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;

export const createDateOfBirthFromAge = age => {
  const currentYear = new Date().getFullYear();
  const yearOfBirth = currentYear - age;

  return new Date(yearOfBirth, 0, 1);
};

export const getCountryDisplayName = (countryCode, localeWithRegion) => {
  const regionNamesInEnglish = new Intl.DisplayNames([localeWithRegion], {
    type: 'region'
  });

  return regionNamesInEnglish.of(countryCode);
};
