export const CURRENCY_SYMBOLS = {
  EUR: '€',
  AUD: 'A$',
  AZN: '₼',
  BGN: 'Лв.',
  CAD: 'C$',
  DKK: 'Kr.',
  INR: '₹',
  CHF: 'CHf',
  MXN: 'Mex$',
  PLN: 'zł',
  RON: 'lei',
  RUB: '₽',
  SEK: 'kr',
  TRY: '₺',
  UAH: '₴',
  GBP: '£',
  USD: '$',
  SGD: '$',
  HKD: '$'
};
