import Vue from 'vue';
import Router from 'vue-router';

import { LocalStorageService } from '@/services/LocalStorageService';

import { routes as dashboardRoutes } from '@/modules/dashboard/routes';
import { routes as questionnaireRoutes } from '@/modules/questionnaire/routes';

Vue.use(Router);

const linkWithBegginingHashPattern = new RegExp('^/#/*');
const linkForV2CanaryServerPattern = new RegExp('^/v2/*');

const hasToken = (to, from, next) => {
  const token = LocalStorageService.getDashboardToken();

  if (token) {
    next({
      path: '/dashboard',
      replace: true
    });
  } else {
    next();
  }
};

const handleHomeRoute = (to, from, next) => {
  const { fullPath } = to;

  if (fullPath.match(linkWithBegginingHashPattern)) {
    // Handles old links with '/#/...' in beggining.
    const newUrl = fullPath.slice(2);

    next({
      path: newUrl,
      replace: true
    });

    return;
  }

  hasToken(to, from, next);
};

const handleV2Route = (to, from, next) => {
  const { fullPath } = to;

  if (fullPath.match(linkForV2CanaryServerPattern)) {
    // Handles canary server links with '/v2/..' in beginning.
    const newUrl = fullPath.slice(3);

    next({
      path: newUrl,
      replace: true
    });

    return;
  }

  next();
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/SignInPage'),
    beforeEnter: handleHomeRoute
  },
  {
    path: '/reload',
    name: 'Reload',
    component: () => import('@/views/Reload.vue')
  },
  {
    path: '/spider-generator',
    name: 'SpiderChartGenerator',
    component: () => import('@/views/PageSpiderChartGenerator.vue'),
    props: route => ({
      language: route.query.language,
      scores: {
        oxidativeStress: +route.query.oxidativeStress || 0,
        skinAppearance: +route.query.skinAppearance || 0,
        skinRedness: +route.query.skinRedness || 0,
        skinDryness: +route.query.skinDryness || 0,
        skinOiliness: +route.query.skinOiliness || 0,
        skinTexture: +route.query.skinTexture || 0,
        pimples: +route.query.pimples || 0,
        skinPigmentation: +route.query.skinPigmentation || 0
      }
    })
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPasswordPage'),
    props: true
  },
  {
    path: '/login',
    redirect: '/'
  },
  {
    path: '/reset-password/:hashedUsername/:hashedInstant',
    name: 'ResetPassword',
    component: () => import('@/views/PageResetPassword'),
    props: true
  },
  {
    path: '/two-factor-authentication',
    name: 'TwoFactorAuthentication',
    component: () => import('@/views/TwoFactorAuthenticationPage'),
    props: true
  },
  {
    path: '/recovery-code',
    name: 'RecoveryCode',
    component: () => import('@/views/RecoveryCodePage'),
    props: true
  },
  {
    path: '/legal/:locale?',
    name: 'Legal',
    component: () => import('@/views/PageLegal'),
    props: true
  },
  {
    path: '/terms/:locale?',
    name: 'Terms',
    component: () => import('@/views/PageTerms'),
    props: true
  },
  {
    path: '/patient-notice/:locale?',
    name: 'PatientNotice',
    component: () => import('@/views/PagePatientNotice'),
    props: true
  },
  {
    path: '/doctor-notice/:locale?',
    name: 'DoctorNotice',
    component: () => import('@/views/PageDoctorNotice'),
    props: true
  },
  {
    path: '/patient-consent/:locale?',
    name: 'PatientConsent',
    component: () => import('@/views/PagePatientConsent'),
    props: true
  },
  {
    path: '/terms-of-sales/:locale?/:scrollToId?',
    name: 'TermsOfSales',
    component: () => import('@/views/TermsOfSalesPage'),
    props: true
  },
  {
    path: '/loyaltyProgram/:hashedOperationId/:action/:locale',
    component: () => import('@/views/LoyaltyProgramRedeemAction'),
    props: true
  },
  {
    path: '/blacklist/add/:hashedDoctorId/:hashedPatientEmail',
    component: () => import('@/views/UnsubscribeEmailPage'),
    props: true
  },
  {
    path: '/q',
    component: () => import('@/modules/questionnaire/AppQuestionnaire.vue'),
    children: questionnaireRoutes
  },
  {
    path: '/dashboard',
    component: () => import('@/modules/dashboard/AppDashboard.vue'),
    children: dashboardRoutes
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('@/views/PageNotFound'),
    beforeEnter: handleV2Route
  }
];

export const router = new Router({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const token =
    LocalStorageService.getDashboardToken() || LocalStorageService.getQuestionnaireToken();

  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({
      path: '/',
      query: {
        redirect: to.fullPath
      }
    });
  } else {
    next();
  }
});

export default router;
