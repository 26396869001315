import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

const { VUE_APP_SENTRY_SERVICE_URL, NODE_ENV } = process.env;
const USE_REMOTE_LOGGING = NODE_ENV === 'production' && VUE_APP_SENTRY_SERVICE_URL;

export default {
  install(Vue) {
    if (!USE_REMOTE_LOGGING) {
      return;
    }

    Sentry.init({
      dsn: VUE_APP_SENTRY_SERVICE_URL,
      integrations: [
        new Integrations.Vue({
          Vue,
          attachProps: true,
          logErrors: true
        })
      ]
    });
  }
};
