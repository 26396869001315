<template>
  <warning-modal
    class="recent-treatment-warning-modal"
    name="recent-treatment-warning-modal"
    title="dashboard.microneedling.warning.notice"
    @before-open="onBeforeOpen"
  >
    <template #main-content>
      <i18n tag="span" path="dashboard.microneedling.warning.daysago">
        <b>{{ lastProcedureDaysAgo }}</b>
      </i18n>
    </template>

    <template #secondary-content>
      {{ $t(warningReason) }}
    </template>

    <template #actions>
      <confirmation-modal-actions @cancel="handleClose(false)" @confirm="handleClose(true)" />
    </template>
  </warning-modal>
</template>

<script>
import WarningModal from '@/modules/dashboard/components/common/warning-modal/WarningModal.vue';
import ConfirmationModalActions from '@/modules/dashboard/components/common/warning-modal/ConfirmationModalActions';

const REASON = {
  DEFAULT: 'dashboard.microneedling.warning.interval',
  REDNESS: 'dashboard.microneedling.warning.redness'
};

export default {
  name: 'RecentTreatmentWarningModal',
  components: { WarningModal, ConfirmationModalActions },
  data() {
    return {
      lastProcedureDaysAgo: null,
      isSkinRednessNotification: false
    };
  },
  computed: {
    warningReason() {
      return this.isSkinRednessNotification ? REASON.REDNESS : REASON.DEFAULT;
    }
  },
  methods: {
    onBeforeOpen({ params }) {
      const { lastProcedureDaysAgo, isSkinRednessNotification, onClose } = params;

      this.lastProcedureDaysAgo = lastProcedureDaysAgo;
      this.isSkinRednessNotification = isSkinRednessNotification;
      this.onClose = onClose;
    },
    handleClose(confirm) {
      this.onClose({ confirm });
    }
  }
};
</script>
