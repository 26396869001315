import { i18n } from '@/i18n';
import { get, isEmpty, merge, omit, pick } from 'lodash';

import * as api from '@/modules/questionnaire/api';
import { getDecodedId } from '@/utils';
import { formatDate } from '@/modules/dashboard/utils/date-utils';
import getInitialQuestionnaireFields from '@/modules/questionnaire/store/getInitialQuestionnaireFields';

import {
  addFieldToArray,
  readAsDataURL,
  getHasMedicalData,
  compressImageFile
} from '@/modules/questionnaire/utils';

import { LocalStorageService } from '@/services/LocalStorageService';

import store from '@/store';
import rootTypes from '@/store/types';
import { questTypes } from '@/modules/questionnaire/store/types';
import { DEFAULT_LOCALE, ERROR_MESSAGES } from '@/constants';
import { SKIPPED_SECTIONS } from '@/modules/questionnaire/constants/sections';
import { PATIENT_PHOTO_VALIDATION_ERROR } from '@/modules/questionnaire/constants/photoAnalysis';

import {
  QUESTIONNAIRE_AVAILABLE_LOCALES,
  MAX_PHOTO_SIZE_IN_BYTES,
  QUESTIONNAIRE_STATUS,
  QUESTIONNAIRE_TYPES,
  SOURCE,
  USAGE
} from '@/modules/questionnaire/api/constants';

import {
  AI_SELECTION_FIELDS,
  PATIENT_IDENTITY_FIELDS,
  PATIENT_INFORMATION_FIELDS,
  PATIENT_MEDICAL_BACKGROUND_FIELDS
} from '@/modules/questionnaire/constants/fields';

// ---------------------------------------------------------------------------------------------------------------------

const QUESTIONNAIRE_CREATION_DATE_PATTERN = 'DD.MM.YYYY';

const questionnaireFlagFields = ['isConsentsAccepted', 'isPhotoConsentsAccepted'];

const START_PAGES = {
  DEMOGRAPHICS: 'identity/demographics',
  FACIAL_FEATURES: 'identity/facialFeatures',
  LIFESTYLE: 'oxidativeStress/lifestyle1',
  PRELIMINARY_RESULTS: 'photoAnalysisResults/preliminaryResults'
};

const COMPOUND_LOCALES = {
  'es-mx': 'es-MX',
  'zh-cn': 'zh-CN'
};

const extractInitQuestionnaireParams = params => {
  const extracted = {};
  const paramsLanguage = params.lang.toLowerCase();

  const locale = QUESTIONNAIRE_AVAILABLE_LOCALES.includes(paramsLanguage)
    ? paramsLanguage
    : DEFAULT_LOCALE;

  extracted.locale = COMPOUND_LOCALES[locale] || locale;

  const [id] = getDecodedId(params.hash || '');

  if (!id) {
    return null;
  }

  extracted.id = id;

  return extracted;
};

const makeContext = (source, usage, totalSteps, first) => ({
  source,
  usage,
  firstStep: first,
  currentStep: first,
  furthestStep: first,
  totalSteps,
  answeredSteps: 0
});

const getDiagnosticScoresFunction = diagnostic => section => diagnostic[section].dysfunction;

const getFinalScores = getSectionScore => ({
  oxidativeStress: getSectionScore('yellowDiagnostic'),
  skinAppearance: getSectionScore('pinkDiagnostic'),
  skinRedness: getSectionScore('redDiagnostic'),
  skinDryness: getSectionScore('blueDiagnostic'),
  skinOiliness: getSectionScore('orangeDiagnostic'),
  skinTexture: getSectionScore('greyDiagnostic'),
  pimples: getSectionScore('greenDiagnostic'),
  skinPigmentation: getSectionScore('brownDiagnostic')
});

export default {
  // -------------------------------------------------------------------------------------------------------------------

  async [questTypes.actions.SETUP_QUESTIONNAIRE](
    { state, commit, dispatch },
    { to, from, isDoctorsQuestionnaire }
  ) {
    const { params, query } = to;
    const { name: fromName } = from;

    const questionnaireParams = extractInitQuestionnaireParams(params);

    if (!questionnaireParams) {
      return { errors: 'No questionnaire params' };
    }

    commit(questTypes.mutations.RESET_MODULE);

    await store.dispatch(rootTypes.actions.SET_LOCALE, questionnaireParams.locale);

    const context = {};

    if (isDoctorsQuestionnaire) {
      commit(questTypes.mutations.SET_DOCTOR, {
        id: questionnaireParams.id
      });

      if (fromName === 'Dashboard') {
        context.source = SOURCE.INTERNAL;
      } else {
        context.source = SOURCE.EXTERNAL;

        // TODO: this seems like a dead branch
        // check if we need to remove token here

        const { influencer } = query;

        if (influencer) {
          context.influencer = influencer;
        }
      }
    } else {
      const { errors } = await dispatch(questTypes.actions.FETCH_PATIENT, {
        id: questionnaireParams.id
      });

      if (errors && errors.length > 0) {
        return { errors };
      }

      const { patient } = state;

      commit(questTypes.mutations.SET_DOCTOR, {
        id: patient.doctorId
      });

      context.source = SOURCE.INTERNAL;
    }

    commit(questTypes.mutations.SET_CONTEXT, context);

    return {};
  },

  async [questTypes.actions.REQUEST_QUESTIONNAIRE_ACCESS](
    { state, commit },
    {
      email,
      firstName,
      currentDoctorId,
      verificationCode,
      locale: localeParam,
      checkIfPatientExists = false,
      needPatientToken = true
    }
  ) {
    const doctorId = currentDoctorId || get(state, 'requestedDoctor.id', state.doctor.id);
    const locale = localeParam || i18n.locale;
    const { influencer: influencerSlug } = state;

    commit(questTypes.mutations.SET_LOADING, true);

    const { data: response } = await api.requestQuestionnaireAccess({
      email,
      locale,
      doctorId,
      firstName,
      influencerSlug,
      needPatientToken,
      checkIfPatientExists,
      verificationCode
    });

    commit(questTypes.mutations.SET_LOADING, false);

    if (!response) {
      return { errors: ['No response in request patient access code request!'] };
    }

    const requestError = response.error || response.description;

    if (requestError) {
      return { errors: [requestError] };
    }

    const { patient, isNewPatient, token, actualDoctor, requestedDoctor } = response;

    if (needPatientToken) {
      LocalStorageService.setQuestionnaireToken(token);
    }

    commit(questTypes.mutations.SET_DOCTOR, actualDoctor);
    commit(questTypes.mutations.SET_REQUESTED_DOCTOR, requestedDoctor);
    commit(questTypes.mutations.SET_PATIENT, { ...patient, isNewPatient });

    return {};
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [questTypes.actions.RESTORE_QUESTIONNAIRE_ACCESS](
    { commit },
    { email, doctorId, timestamp, questionnaireId }
  ) {
    commit(questTypes.mutations.SET_LOADING, true);

    const { data } = await api.restoreQuestionnaireAccess({
      email,
      doctorId,
      timestamp,
      questionnaireId
    });

    commit(questTypes.mutations.SET_LOADING, false);

    const requestError = data.error || data.description;

    if (requestError) {
      return { errors: [requestError] };
    }

    const { patient, isNewPatient, token, actualDoctor, requestedDoctor } = data;

    LocalStorageService.setQuestionnaireToken(token);
    commit(questTypes.mutations.SET_DOCTOR, actualDoctor);
    commit(questTypes.mutations.SET_REQUESTED_DOCTOR, requestedDoctor);
    commit(questTypes.mutations.SET_PATIENT, { ...patient, isNewPatient });

    return {};
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [questTypes.actions.INIT_QUESTIONNAIRE]({ state, rootState, dispatch, commit }) {
    commit(questTypes.mutations.SET_LOADING, true);

    const {
      patient,
      consent,
      doctor: { id: doctorId },
      questionnaire: { type, context: questionnaireContext }
    } = state;

    const { source, influencer } = questionnaireContext;
    const { firstName, gender, dateOfBirth } = patient;
    const existingPatient = !!firstName;

    const { status, data: responseData } = await api.getDoctor(doctorId);

    if (status >= 400) {
      return { errors: [responseData] };
    }

    commit(questTypes.mutations.SET_DOCTOR, responseData);

    let lastCompletedQuestionnaire;
    let latestQuestionnaire;

    if (existingPatient) {
      const {
        status: questionnaireResponseStatus,
        data: questionnaireResponseData
      } = await api.getPatientQuestionnaires(patient.id);

      if (questionnaireResponseStatus >= 400) {
        return { errors: [questionnaireResponseData] };
      }

      lastCompletedQuestionnaire = questionnaireResponseData.find(
        ({ status: questionnaireStatus }) => questionnaireStatus === QUESTIONNAIRE_STATUS.COMPLETED
      );

      // Index equals 0 because from the backend array comes sorted by ByLastModifiedDateDesc.
      latestQuestionnaire =
        lastCompletedQuestionnaire ||
        (!isEmpty(questionnaireResponseData) && questionnaireResponseData[0]);
    }

    const patientWithUpdatedLocale = { ...patient, locale: rootState.locale };

    commit(questTypes.mutations.SET_PATIENT, patientWithUpdatedLocale);

    let context;

    if (!existingPatient) {
      context = makeContext(source, USAGE.NEW_PATIENT, 110, START_PAGES.DEMOGRAPHICS);
    } else {
      const firstQuestionnaireStartPage =
        !gender || !dateOfBirth ? START_PAGES.DEMOGRAPHICS : START_PAGES.FACIAL_FEATURES;

      context = latestQuestionnaire
        ? makeContext(source, USAGE.NEW_QUESTIONNAIRE, 72, START_PAGES.LIFESTYLE)
        : makeContext(source, USAGE.FIRST_QUESTIONNAIRE, 108, firstQuestionnaireStartPage);
    }

    const fields = getInitialQuestionnaireFields();
    const { email, smsAccepted, marketingSmsOffersAccepted } = patient;

    fields.email = email;
    fields.smsAccepted = smsAccepted;
    fields.marketingSmsOffersAccepted = marketingSmsOffersAccepted;
    fields.consent = consent;

    if (lastCompletedQuestionnaire) {
      merge(
        fields,
        pick(lastCompletedQuestionnaire.json, [
          ...PATIENT_INFORMATION_FIELDS,
          ...PATIENT_MEDICAL_BACKGROUND_FIELDS
        ])
      );
    } else if (latestQuestionnaire) {
      merge(fields, pick(latestQuestionnaire.json, PATIENT_INFORMATION_FIELDS));

      const { age, gender: patientGender, eyeColor, naturalHairColor, skinColor } = fields;

      const hasDemographicsData = age && patientGender;
      const hasFacialFeaturesData = eyeColor && naturalHairColor && skinColor;

      if (!hasDemographicsData || !hasFacialFeaturesData) {
        const firstPage = !hasDemographicsData
          ? START_PAGES.DEMOGRAPHICS
          : START_PAGES.FACIAL_FEATURES;

        context.firstStep = firstPage;
        context.currentStep = firstPage;
        context.furthestStep = firstPage;
        context.totalSteps = 108;
      }
    } else if (context.usage === USAGE.FIRST_QUESTIONNAIRE) {
      merge(fields, pick(patient, PATIENT_IDENTITY_FIELDS));
    }

    if (influencer) {
      fields.influencer = influencer;
    }

    if (type === QUESTIONNAIRE_TYPES.PHOTO_ANALYSIS) {
      context.firstStep = START_PAGES.PRELIMINARY_RESULTS;
      context.currentStep = START_PAGES.PRELIMINARY_RESULTS;
      context.furthestStep = START_PAGES.PRELIMINARY_RESULTS;
      context.totalSteps = 36;
    }

    commit(questTypes.mutations.SET_CONTEXT, context);
    commit(questTypes.mutations.SET_FIELDS, fields);

    const creationDate = formatDate(new Date(), QUESTIONNAIRE_CREATION_DATE_PATTERN);
    commit(questTypes.mutations.SET_QUESTIONNAIRE_CREATION_DATE, creationDate);

    const hasMedicalData = getHasMedicalData(state.questionnaire);

    if (hasMedicalData) {
      await dispatch(
        questTypes.actions.ADD_SKIPPED_SECTION,
        SKIPPED_SECTIONS.MEDICAL_BACKGROUND_SECTION
      );
    }

    const { errors } = await dispatch(questTypes.actions.UPDATE_QUESTIONNAIRE, { complete: false });

    commit(questTypes.mutations.SET_LOADING, false);

    return { errors };
  },

  async [questTypes.actions.FETCH_PATIENT]({ commit }, { id, email }) {
    if (!id && !email) {
      return { errors: 'id or email required' };
    }

    commit(questTypes.mutations.SET_LOADING, true);

    const { data: patient } = id
      ? await api.getPatientWithId(id)
      : await api.getPatientWithEmail(email);

    commit(questTypes.mutations.SET_LOADING, false);

    if (!patient.id) {
      return { errors: ['No patient found'] };
    }

    commit(questTypes.mutations.SET_PATIENT, patient);

    return {};
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [questTypes.actions.FETCH_QUESTIONNAIRE](
    { commit, state },
    { patientId, questionnaireId }
  ) {
    const { status, data: responseData } = await api.getQuestionnaireById(
      patientId,
      questionnaireId
    );

    if (status >= 400) {
      return { errors: ['Error in fetching questionnaire.'] };
    }

    if (responseData) {
      const questionnaireData = pick(responseData, ['id', 'status', 'type', 'skippedSections']);
      const questionnaireAnswers = responseData.json;
      const questionnaireFlags = pick(responseData.context, questionnaireFlagFields);
      const contextData = omit(responseData.context, questionnaireFlagFields);

      const questionnaire = {
        ...state.questionnaire,
        ...questionnaireData,
        ...questionnaireFlags,
        fields: { ...questionnaireAnswers },
        context: { ...contextData }
      };

      commit(questTypes.mutations.SET_QUESTIONNAIRE, questionnaire);
    }

    return {};
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [questTypes.actions.FETCH_TEMPERATURE]({ commit }, { country, town }) {
    let temperature = 'mild';

    commit(questTypes.mutations.SET_LOADING, true);

    const { status, data: responseData } = await api.getTemperature(country, town);

    if (status >= 400) {
      return { errors: [responseData] };
    }

    const { temperature: currentTemperature } = responseData;

    temperature = currentTemperature;

    commit(questTypes.mutations.SET_FIELD, {
      path: 'temperature',
      value: temperature
    });
    commit(questTypes.mutations.SET_LOADING, false);

    return {};
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [questTypes.actions.FETCH_SCORES]({ state, commit }) {
    const patientId = state.patient.id;
    const questionnaireId = state.questionnaire.id;

    commit(questTypes.mutations.SET_LOADING, true);

    const { status, data } = await api.getScores(patientId, questionnaireId);

    commit(questTypes.mutations.SET_LOADING, false);

    if (status >= 400) {
      return { errors: [data] };
    }

    const diagnostic = get(data, 'json.diagnostic');
    const scores = diagnostic ? getFinalScores(getDiagnosticScoresFunction(diagnostic)) : {};

    commit(questTypes.mutations.SET_SCORES, scores);

    return {};
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [questTypes.actions.UPDATE_PATIENT]({ state, commit }, additionalData = {}) {
    const { fields } = state.questionnaire;
    const savedPatient = state.patient;

    const PHONE_FIELDS = ['phone', 'phoneIsoCode'];
    const isPhoneExist = get(fields, 'phone');

    const phoneFields = isPhoneExist ? PHONE_FIELDS : ['phoneIsoCode'];
    const PATIENT_FIELDS = ['id', 'locale', 'email', 'clinicDto', 'firstName'];
    const FIELDS = [
      'gender',
      'dateOfBirth',
      'smsAccepted',
      'marketingSmsOffersAccepted',
      ...phoneFields
    ];

    addFieldToArray({ PATIENT_FIELDS, FIELDS, patient: savedPatient, property: 'lastName' });

    const data = pick(fields, FIELDS);
    const updatedDataFields = Object.entries(data).reduce(
      (updatedData, [field, value]) => ({ ...updatedData, [field]: value === '' ? null : value }),
      {}
    );

    const patientData = pick(state.patient, PATIENT_FIELDS);

    const patient = {
      ...updatedDataFields,
      ...patientData,
      ...additionalData
    };

    const alreadyPersisted = !!patient.id;

    commit(questTypes.mutations.SET_LOADING, true);

    const createOrUpdatePatient = !alreadyPersisted ? api.createPatient : api.updatePatient;

    if (!alreadyPersisted) {
      patient.doctorId = state.doctor.id;
    }

    const { status, data: responseData } = await createOrUpdatePatient(patient);

    commit(questTypes.mutations.SET_LOADING, false);

    if (status >= 400) {
      return { errors: [responseData] };
    }

    commit(questTypes.mutations.SET_PATIENT, responseData);

    return {};
  },

  [questTypes.actions.UPDATE_PATIENT_FIELDS]({ state, commit }) {
    const { fields } = state.questionnaire;

    const PHONE_FIELDS = ['phone', 'phoneIsoCode'];
    const isPhoneExist = get(fields, 'phone');

    const phoneFields = isPhoneExist ? PHONE_FIELDS : ['phoneIsoCode'];

    const FIELDS = [
      'firstName',
      'lastName',
      'gender',
      'dateOfBirth',
      'smsAccepted',
      'marketingSmsOffersAccepted',
      ...phoneFields
    ];
    const data = pick(fields, FIELDS);
    const patientData = pick(state.patient, ['id', 'locale', 'email']);

    const patient = {
      ...data,
      ...patientData
    };

    commit(questTypes.mutations.SET_PATIENT, patient);
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [questTypes.actions.UPDATE_PHOTO]({ state, commit }, { photoType, photo, allowResearch }) {
    const patientId = state.patient.id;
    const { questionnaire } = state;

    commit(questTypes.mutations.SET_LOADING, true);

    const { status, data: responseData } = await api.addPhotos(patientId, questionnaire.id, {
      photoType,
      photo,
      allowResearch
    });

    commit(questTypes.mutations.SET_LOADING, false);

    if (status >= 400) {
      return { errors: [responseData] };
    }

    if (!questionnaire.photos.includes(photoType)) {
      commit(questTypes.mutations.ADD_PHOTO_TYPE, photoType);
    }

    return { data: responseData };
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [questTypes.actions.UPDATE_QUESTIONNAIRE]({ commit, state, getters }, { complete }) {
    commit(questTypes.mutations.SET_LOADING, true);

    const { id: patientId } = state.patient;
    const { id, fields, type, skippedSections } = state.questionnaire;
    const { [questTypes.getters.INFLUENCER]: influencerSlug } = getters;

    const questionnaire = {
      type,
      skippedSections,
      status: complete ? QUESTIONNAIRE_STATUS.COMPLETED : QUESTIONNAIRE_STATUS.ONGOING,
      json: fields,
      influencerSlug
    };

    const alreadyPersisted = !!id;

    if (!alreadyPersisted) {
      const { status, data: responseData } = await api.createQuestionnaire(
        patientId,
        questionnaire
      );

      if (status >= 400) {
        return { errors: [responseData] };
      }

      commit(questTypes.mutations.SET_QUESTIONNAIRE, {
        ...state.questionnaire,
        status: questionnaire.status,
        id: responseData.id
      });
    } else {
      questionnaire.id = id;

      if (questionnaire.status === QUESTIONNAIRE_STATUS.ONGOING) {
        const { context, isConsentsAccepted, isPhotoConsentsAccepted } = state.questionnaire;

        questionnaire.context = {
          ...context,
          isConsentsAccepted,
          isPhotoConsentsAccepted
        };
      }

      const { data: responseData } = await api.updateQuestionnaire(patientId, questionnaire);
      const { status: questionnaireStatus, message, description } = responseData;

      commit(questTypes.mutations.SET_LOADING, false);

      if (message === ERROR_MESSAGES.BAD_REQUEST) {
        return { errors: [description] };
      }

      commit(questTypes.mutations.SET_QUESTIONNAIRE, {
        ...state.questionnaire,
        status: questionnaireStatus
      });
    }

    return {};
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [questTypes.actions.SEND_CONGRATULATIONS]({ state }) {
    const patientId = state.patient.id;
    const questionnaireId = state.questionnaire.id;

    const { status, data } = await api.sendCongratulations(patientId, questionnaireId);

    if (status >= 400) {
      return { errors: data };
    }

    return {};
  },

  // -------------------------------------------------------------------------------------------------------------------

  [questTypes.actions.SET_IS_PHOTO_CONSENTS_ACCEPTED]({ commit }, photoConsents) {
    commit(questTypes.mutations.SET_IS_PHOTO_CONSENTS_ACCEPTED, photoConsents);
  },

  [questTypes.actions.SET_IS_CONSENTS_ACCEPTED]({ commit }, consents) {
    commit(questTypes.mutations.SET_IS_CONSENTS_ACCEPTED, consents);
  },

  // -------------------------------------------------------------------------------------------------------------------

  [questTypes.actions.SET_CURRENT_STEP]({ commit }, step) {
    commit(questTypes.mutations.SET_CURRENT_STEP, step);
  },

  [questTypes.actions.SET_ANSWERED_STEPS]({ commit }, step) {
    commit(questTypes.mutations.SET_ANSWERED_STEPS, step);
  },

  [questTypes.actions.ADD_PHOTO_TYPE]({ commit }, type) {
    commit(questTypes.mutations.ADD_PHOTO_TYPE, type);
  },

  [questTypes.actions.REMOVE_PHOTO_TYPE]({ commit }, type) {
    commit(questTypes.mutations.REMOVE_PHOTO_TYPE, type);
  },

  [questTypes.actions.RESET_MODULE]({ commit }) {
    commit(questTypes.mutations.RESET_MODULE);
  },

  [questTypes.actions.RESET_REQUESTED_DOCTOR]({ commit }) {
    commit(questTypes.mutations.RESET_REQUESTED_DOCTOR);
  },

  [questTypes.actions.SET_DOCTOR]({ commit }, doctor) {
    commit(questTypes.mutations.SET_DOCTOR, doctor);
  },

  [questTypes.actions.SET_FIELDS]({ commit }, fields) {
    commit(questTypes.mutations.SET_FIELDS, fields);
  },

  [questTypes.actions.SET_CONTEXT_SOURCE]({ commit }, source) {
    commit(questTypes.mutations.SET_CONTEXT_SOURCE, source);
  },

  [questTypes.actions.SET_CONTEXT_USAGE]({ commit }, usage) {
    commit(questTypes.mutations.SET_CONTEXT_USAGE, usage);
  },

  [questTypes.actions.ADD_SKIPPED_SECTION]({ state, commit }, skippedSection) {
    const {
      questionnaire: { skippedSections }
    } = state;

    if (skippedSections.includes(skippedSection)) {
      return;
    }

    commit(questTypes.mutations.SET_SKIPPED_SECTIONS, [...skippedSections, skippedSection]);
  },

  [questTypes.actions.REMOVE_SKIPPED_SECTION]({ state, commit }, skippedSection) {
    const {
      questionnaire: { skippedSections }
    } = state;

    if (!skippedSections.includes(skippedSection)) {
      return;
    }

    const filteredSkippedSections = skippedSections.filter(section => section !== skippedSection);
    commit(questTypes.mutations.SET_SKIPPED_SECTIONS, filteredSkippedSections);
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [questTypes.actions.SEND_INCOMPLETE_QUESTIONNAIRE]({ state }) {
    const { id: patientId } = state.patient;
    const { id: questionnaireId } = state.questionnaire;

    await api.sendIncompleteQuestionnaire(patientId, questionnaireId);
  },

  async [questTypes.actions.LOAD_PHOTO_ANALYSIS_RESULTS]({ state, commit }) {
    const {
      patient: { id: patientId },
      questionnaire: { fields, id: questionnaireId }
    } = state;

    const {
      data: { diagnostic, introTexts }
    } = await api.getPhotoAnalysisResults({
      patientId,
      questionnaireId
    });

    const aiSelectedFields = pick(diagnostic, AI_SELECTION_FIELDS);
    // Age is nullified there in order to remove pre-filling of field age on the Preliminary page
    // as it always should be set by a patient as per requirements
    const fieldsWithPhotoAnalysisResults = { ...fields, ...aiSelectedFields, age: null };

    commit(questTypes.mutations.SET_PHOTO_ANALYSIS_DIAGNOSTIC, diagnostic);
    commit(questTypes.mutations.SET_INTRO_TEXTS, introTexts);
    commit(questTypes.mutations.SET_FIELDS, fieldsWithPhotoAnalysisResults);
  },

  /** Return { error: String } in case of validation error. */
  async [questTypes.actions.SAVE_PATIENT_PHOTO]({ commit }, photo) {
    const isImage = photo.type.startsWith('image/');

    if (!isImage) {
      return { error: PATIENT_PHOTO_VALIDATION_ERROR.UNSUPPORTED_FORMAT };
    }

    const fileSize = photo.size;

    if (fileSize > MAX_PHOTO_SIZE_IN_BYTES) {
      return { error: PATIENT_PHOTO_VALIDATION_ERROR.TOO_LARGE };
    }

    const compressedPhoto = await compressImageFile(photo);
    const photoUrl = await readAsDataURL(compressedPhoto);

    commit(questTypes.mutations.SAVE_PATIENT_PHOTO, photoUrl);

    return {};
  },

  async [questTypes.actions.FETCH_PATIENT_QUESTIONNAIRE_PHOTO]({ state, commit }) {
    const { id: patientId } = state.patient;
    const { id: questionnaireId } = state.questionnaire;

    const {
      data: { convertedToBase64Photo }
    } = await api.fetchUserPhotoByQuestionnaireId({
      patientId,
      questionnaireId
    });

    const photoUrl = `data:image/jpeg;base64,${convertedToBase64Photo}`;

    commit(questTypes.mutations.SAVE_PATIENT_PHOTO, photoUrl);
  }
};
