<template>
  <div>
    <slot></slot>

    <popup-notification ref="notification" @send-report="sendReports" />
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import { mapMutations } from 'vuex';

import { router } from '@/router';

import PopupNotification from '@/components/popup-notification/PopupNotification';
import { NetworkError, BackEndError, NullUrlError, AuthorizationError } from '@/errors';

import { errorObserver } from '@/services/errorObserver';

import { logoutMixin } from '@/modules/dashboard/mixins/logoutMixin';

import { sendReport } from '@/api';
import { logger } from '@/utils/logger';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import { types as questTypes } from '@/modules/questionnaire/store/types';
import { types as rootTypes } from '@/store/types';

const createBackEndErrorReport = backEndError => {
  const { request, response } = backEndError;

  const { method, url } = request;
  const {
    status,
    request: { responseText }
  } = response;

  let { payload } = request;

  if (payload.entries) {
    payload = Array.from(payload.entries());
  }

  const details = {
    request: `${method} ${url}`,
    payload: JSON.stringify(payload),
    errorCode: status,
    errorMessage: responseText
  };

  return {
    browser: navigator.userAgent,
    date: new Date().toString(),
    details: JSON.stringify(details)
  };
};

const isReportEqual = (reportA, reportB) => reportA.details === reportB.details;

export default {
  name: 'ErrorBoundary',
  components: { PopupNotification },
  mixins: [logoutMixin],
  data() {
    return {
      reports: []
    };
  },
  created() {
    // errorCaptured hook can't catch all possible error events
    // so we need to listen global error events on window object
    // for the events which was not handled by errorCaptured hook
    window.addEventListener('unhandledrejection', this.handleError);
    window.addEventListener('error', this.handleError);
    errorObserver.addListener(this.handleError, this);
  },
  beforeDestroy() {
    window.removeEventListener('unhandledrejection', this.handleError);
    window.removeEventListener('error', this.handleError);
  },
  async errorCaptured(err) {
    return this.handleError(err);
  },
  methods: {
    ...mapMutations({
      setLoadingRoot: rootTypes.mutations.SET_LOADING,
      setLoadingDashboard: dashTypes.mutations.SET_LOADING,
      setLoadingQuestionnaire: questTypes.mutations.SET_LOADING
    }),
    async handleError(err) {
      const actualError = (err && err.reason) || err;

      try {
        this.hideLoaders();

        if (actualError instanceof NetworkError) {
          this.showNetworkErrorNotification();

          return false;
        }

        if (actualError instanceof AuthorizationError) {
          await this.logoutAndClearModule();

          return false;
        }

        if (actualError instanceof BackEndError) {
          this.handleBackEndError(actualError);

          return false;
        }

        if (actualError instanceof NullUrlError) {
          await router.push({ name: 'Dashboard' });

          return false;
        }

        // do not stop error propagation for all other cases
        return true;
      } catch (error) {
        // prevent infinite loop of error handler invocations
        logger.error(error);
        return true;
      }
    },
    async sendReports() {
      const firstThreeReports = this.reports.slice(0, 3);

      await Promise.all(firstThreeReports.map(sendReport));

      this.clearReports();
    },
    clearReports() {
      this.reports = [];
    },
    handleBackEndError(err) {
      const report = createBackEndErrorReport(err);
      const isNotificationShownAlready = this.reports.find(rep => isReportEqual(rep, report));

      this.reports.push(report);

      if (!isNotificationShownAlready) {
        this.showSomethingWrongNotification();
      }

      Sentry.captureException(err);
    },
    showNetworkErrorNotification() {
      const text = this.$t('info.warning.checkInternetConnection');

      this.$refs.notification.push({ text, id: text });
    },
    showSomethingWrongNotification() {
      const text = this.$t('info.warning.somethingWrong');

      this.$refs.notification.push({ text, id: text, isReportable: true });
    },
    hideLoaders() {
      this.setLoadingRoot(false);
      this.setLoadingDashboard(false);
      this.setLoadingQuestionnaire(false);
    }
  }
};
</script>
