import { transformPhone } from '@/modules/dashboard/utils';

const phoneNumberDirective = {
  bind: element => {
    const transformPhoneNumber = () => {
      element.value = transformPhone(element.value);
    };

    element.addEventListener('input', transformPhoneNumber);
    element.$destroy = () => {
      element.removeEventListener('input', transformPhoneNumber);
    };
  },
  unbind: element => {
    element.$destroy();
  }
};

export default phoneNumberDirective;
