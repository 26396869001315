<template>
  <modal
    :width="modalWidth"
    :height="modalHeight"
    :classes="['modal-window']"
    adaptive
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="warning modal-container flex-column flex--center--center" :class="warningClasses">
      <div
        v-if="shouldDisplayHeader"
        class="warning__header warning-header flex--space-between--center"
        :class="headerClasses"
      >
        <span v-if="title">{{ $t(title) }}</span>
        <span
          v-if="hasCloseButton"
          class="icon-close warning-header__close-button"
          @click="onClose"
        ></span>
      </div>

      <div class="content warning__content">
        <div v-if="hasPrependContent" class="content__prepend-content">
          <slot name="prepend-content"></slot>
        </div>

        <div class="content__main-content">
          <slot name="main-content"></slot>
        </div>

        <div v-if="hasSecondaryContent" class="content__secondary-content">
          <slot name="secondary-content"></slot>
        </div>
      </div>

      <div class="warning__actions actions flex--center--center" :class="actionsClasses">
        <slot name="actions">
          <button class="rounded-button-white" @click="onConfirm">Ok</button>
        </slot>
      </div>
    </div>
  </modal>
</template>

<script>
import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'WarningModal',
  props: {
    largeScreenWidth: {
      type: String,
      default: '500px'
    },
    title: {
      type: String,
      default: ''
    },
    hasCloseButton: {
      type: Boolean,
      default: false
    },
    hasBorderBeforeActions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalWidth: this.largeScreenWidth,
      modalHeight: 'auto',
      maxModalWidth: Infinity
    };
  },
  computed: {
    shouldDisplayHeader() {
      return this.hasCloseButton || this.title;
    },
    hasPrependContent() {
      return this.$slots['prepend-content'];
    },
    actionsClasses() {
      return { 'warning__actions--bordered': this.hasBorderBeforeActions };
    },
    hasSecondaryContent() {
      return this.$slots['secondary-content'];
    },
    headerClasses() {
      return { 'warning__header--with-close-button': this.hasCloseButton };
    },
    warningClasses() {
      return { 'warning--wide': parseInt(this.largeScreenWidth, 10) > 500 };
    }
  },
  created() {
    const smallScreen = isMobileDevice();

    if (smallScreen) {
      this.modalWidth = '94%';
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onConfirm() {
      this.$emit('confirm');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/icons.css';
@import '../../../../../assets/scss/common/buttons';

.warning-header {
  &__close-button {
    display: inline-block;
    margin-left: auto;
    padding: 6px;
    cursor: pointer;
  }
}

.warning {
  width: 100%;
  height: 100%;
  color: $text-color;

  &__header {
    padding: 20px 20px 0 20px;
    text-align: center;
    font-size: 15px;

    &--with-close-button {
      padding-bottom: 20px;
      border-bottom: 1px solid $dim-white;
      width: 100%;
    }
  }

  &__actions {
    height: 84px;
    width: 100%;

    &--bordered {
      height: 70px;
      border-top: 1px solid $dim-white;
    }
  }

  &__content {
    margin: 0 24px;
    max-width: 350px;
  }
}

.warning--wide {
  .warning__content,
  .warning__footer {
    max-width: 100%;
  }
}

.content {
  font-size: 13px;

  &__prepend-content {
    margin-top: 24px;
  }

  &__main-content {
    margin-top: 24px;
    padding: 25px;
    background-color: $beige;
    border-radius: 8px;
    font-size: 13px;
  }

  &__secondary-content {
    margin-top: 24px;
  }
}

.skin-sensitive-warning-modal {
  .warning {
    &__header {
      padding: 30px 20px 0 20px;
    }

    &__actions {
      height: 96px;
      width: 100%;
    }
  }

  .rounded-button-white {
    width: 120px;
    height: 36px;
  }
}

.nexultra-o-recommandation-modal,
.inform-modal {
  .warning {
    font-weight: 300;

    &__header {
      padding: 30px 20px 0 20px;
    }

    &__content {
      max-width: 420px;
    }

    &__actions {
      height: 90px;
    }
  }

  .content {
    &__secondary-content {
      text-align: center;
    }
  }
}

.inform-modal {
  .content__main-content {
    text-align: center;
  }
}
</style>
