import mutations from '@/modules/dashboard/store/mutations';
import actions from '@/modules/dashboard/store/actions';
import getters from '@/modules/dashboard/store/getters';

import { getInitialState } from '@/modules/dashboard/store/state';

const state = getInitialState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
