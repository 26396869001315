import { BACKEND_VERSION_HEADER } from '@/constants';

const COOKIES_STORAGE_KEY = 'skinXsAcceptCookies';
const DASHBOARD_TOKEN_KEY = 'dashboard-token';
const QUESTIONNAIRE_TOKEN_KEY = 'questionnaire-token';

export const LocalStorageService = {
  getAcceptCookiesConsent() {
    return JSON.parse(localStorage.getItem(COOKIES_STORAGE_KEY)) || false;
  },
  setAcceptCookiesConsent(acceptCookies) {
    return localStorage.setItem(COOKIES_STORAGE_KEY, acceptCookies);
  },
  setBackendVersion(version) {
    localStorage.setItem(BACKEND_VERSION_HEADER, version);
  },
  getBackendVersion() {
    const backendVersion = localStorage.getItem(BACKEND_VERSION_HEADER);

    try {
      return JSON.parse(backendVersion) ? backendVersion : null;
    } catch (error) {
      return null;
    }
  },
  getDashboardToken() {
    return localStorage.getItem(DASHBOARD_TOKEN_KEY);
  },
  getQuestionnaireToken() {
    return localStorage.getItem(QUESTIONNAIRE_TOKEN_KEY);
  },
  setDashboardToken(token) {
    localStorage.setItem(DASHBOARD_TOKEN_KEY, token);
  },
  setQuestionnaireToken(token) {
    localStorage.setItem(QUESTIONNAIRE_TOKEN_KEY, token);
  },
  clearDashboardToken() {
    localStorage.removeItem(DASHBOARD_TOKEN_KEY);
  }
};
