export const getInitialState = () => ({
  status: {
    loading: false,
    errors: []
  },

  accessDetails: {},
  doctor: {},
  requestedDoctor: {},
  patient: {},
  influencer: null,

  consent: {
    implied: false,
    medicalUse: true,
    marketingUse: false
  },

  questionnaire: {
    isPhotoConsentsAccepted: false,
    isConsentsAccepted: false,
    type: null,
    skippedSections: [],
    context: {},
    status: '',
    fields: {},
    photos: []
  },

  scores: {},

  photoAnalysisDiagnostic: null,
  introTexts: null,

  patientPhoto: null,
  allowPhotoResearch: false
});
