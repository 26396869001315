<template>
  <div class="popup-notifications-container">
    <div
      v-for="{ text, isReportable, id } of messages"
      :key="id"
      class="popup-notifications-container__notification notification"
    >
      <!-- We need to inline the icon if the notification is shown
           on the event of loosing internet connection. -->
      <svg
        class="notification__icon"
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.7974 13.7504C18.3742 14.7502 17.6502 16 16.4981 16H1.5017C0.347415 16 -0.373272 14.7483 0.202415 13.7504L7.70073 0.749531C8.27783 -0.25075 9.72323 -0.248937 10.2993 0.749531L17.7974 13.7504ZM9.00001 11.0625C8.20611 11.0625 7.56251 11.7061 7.56251 12.5C7.56251 13.2939 8.20611 13.9375 9.00001 13.9375C9.79392 13.9375 10.4375 13.2939 10.4375 12.5C10.4375 11.7061 9.79392 11.0625 9.00001 11.0625ZM7.63523 5.89544L7.86704 10.1454C7.87789 10.3443 8.04233 10.5 8.24148 10.5H9.75854C9.9577 10.5 10.1221 10.3443 10.133 10.1454L10.3648 5.89544C10.3765 5.68063 10.2055 5.5 9.99036 5.5H8.00964C7.79451 5.5 7.62351 5.68063 7.63523 5.89544Z"
          fill="#AF231C"
        />
      </svg>

      {{ $t(text) }}
      <button v-if="isReportable" class="notification__button" @click="$emit('send-report', id)">
        {{ $t('info.action.sendReport') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupNotificationTemplate',
  props: {
    messages: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-notifications-container {
  position: fixed;
  top: 16px;
  right: 16px;
  width: 421px;
  max-width: calc(100% - 32px);
  --u-icon-color: var(--u-color-red-300);
  --u-icon-size: 16px;
  color: var(--u-color-grey-900);
  z-index: 2147483647;

  &__notification {
    margin-bottom: 8px;
  }
}

.notification {
  min-height: 56px;
  background-color: var(--u-color-red-100);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 8px 8px 16px;
  border-radius: 5px;
  font-family: var(--u-font-family-Gilroy);
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.4px;

  &__icon {
    margin-right: 8px;
  }

  &__button {
    margin-left: auto;
    background-color: var(--u-color-white);
    min-width: 140px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.75px;
    border: none;
    outline: none;
    border-radius: 40px;
    cursor: pointer;

    &:active {
      border: 1px solid var(--u-color-grey-200);
    }
  }
}
</style>
