import stripNamespace from '@/store/getNamespaceTypes';

export const types = {
  getters: {
    // path -> * (vuex-map-fields)
    GET_FIELD: 'questionnaire/getField',
    // -> obj
    GET_PATIENT: 'questionnaire/getPatient',
    // -> obj
    GET_DOCTOR: 'questionnaire/getDoctor',
    GET_QUESTIONNAIRE_CREATION_DATE: 'questionnaire/getQuestionnaireCreationDate',
    GET_CONTEXT: 'questionnaire/getContext',
    GET_QUESTIONNAIRE_FIELDS: 'questionnaire/getQuestionnaireFields',
    // -> obj
    GET_REQUESTED_DOCTOR: 'questionnaire/getRequestedDoctor',
    GET_PHOTOS: 'questionnaire/getPhotos',
    // boolean
    GET_IS_PHOTO_CONSENTS_ACCEPTED: 'questionnaire/isPhotoConsentsAccepted',
    // boolean
    GET_IS_CONSENTS_ACCEPTED: 'questionnaire/isConsentsAccepted',

    GET_DOCTOR_FULL_NAME: 'questionnaire/getDoctorFullName',
    GET_DOCTOR_CLINIC_LOCATIONS: 'questionnaire/getDoctorClinicLocations',
    GET_QUESTIONNAIRE: 'questionnaire/getQuestionnaire',
    QUESTIONNAIRE_ID: 'questionnaire/getQuestionnaireId',

    GET_SCORES: 'questionnaire/getScores',
    GET_LOADING: 'questionnaire/getLoading',

    GET_IS_SKIN_APPEARANCE_DISCOMFORT: 'questionnaire/getIsSkinAppearanceDiscomfort',
    GET_IS_SKIN_REDNESS_DISCOMFORT: 'questionnaire/getIsSkinRednessDiscomfort',
    GET_IS_SKIN_DRYNESS_DISCOMFORT: 'questionnaire/getIsSkinDrynessDiscomfort',
    GET_IS_SKIN_OILINESS_DISCOMFORT: 'questionnaire/getIsSkinOilinessDiscomfort',
    GET_IS_SKIN_TEXTURE_DISCOMFORT: 'questionnaire/getIsSkinTextureDiscomfort',
    GET_IS_PIMPLES_DISCOMFORT: 'questionnaire/getIsPimplesDiscomfort',
    GET_IS_SKIN_PIGMENTATION_DISCOMFORT: 'questionnaire/getIsSkinPigmentationDiscomfort',
    INFLUENCER: 'questionnaire/influencer',

    GET_ARE_CLARIFICATION_QUESTIONS_SKIPPED: 'questionnaire/getAreClarificationQuestionsSkipped'
  },
  mutations: {
    RESET_MODULE: 'questionnaire/resetModule',
    RESET_REQUESTED_DOCTOR: 'questionnaire/resetRequestedDoctor',

    // loading:bool
    SET_LOADING: 'questionnaire/setLoading',
    SET_ACCESS_DETAILS: 'questionnaire/setAccessDetails',
    // doctor:obj
    SET_DOCTOR: 'questionnaire/setDoctor',
    // requestedDoctor:obj
    SET_REQUESTED_DOCTOR: 'questionnaire/setRequestedDoctor',
    // patient:obj
    SET_PATIENT: 'questionnaire/setPatient',
    SET_CONSENT: 'questionnaire/setConsent',

    SET_QUESTIONNAIRE: 'questionnaire/setQuestionnaire',
    SET_QUESTIONNAIRE_CREATION_DATE: 'questionnaire/setQuestionnaireCreationDate',
    SET_HAS_QUESTIONNAIRE_MEDICAL_DATA: 'questionnaire/setHasQuestionnaireMedicalData',
    SET_QUESTIONNAIRE_TYPE: 'questionnaire/setQuestionnaireType',
    SET_SKIPPED_SECTIONS: 'questionnaire/setSkippedSections',

    // context:obj
    SET_CONTEXT: 'questionnaire/setContext',
    // step:str
    SET_CURRENT_STEP: 'questionnaire/setCurrentStep',
    // step:str
    SET_FURTHEST_STEP: 'questionnaire/setFurthestStep',
    // step:int
    SET_ANSWERED_STEPS: 'questionnaire/setAnsweredSteps',

    // fields:obj
    SET_FIELDS: 'questionnaire/setFields',
    // { path:str, value:* } (vuex-map-fields)
    SET_FIELD: 'questionnaire/setField',
    // photoConsents:boolean
    SET_IS_PHOTO_CONSENTS_ACCEPTED: 'questionnaire/isPhotoConsentsAccepted',
    // consents:boolean
    SET_IS_CONSENTS_ACCEPTED: 'questionnaire/isConsentsAccepted',
    // photoType:str
    ADD_PHOTO_TYPE: 'questionnaire/addPhotoType',
    // photoType:str
    REMOVE_PHOTO_TYPE: 'questionnaire/removePhotoType',
    // scores:obj
    SET_SCORES: 'questionnaire/setScores',
    SET_CONTEXT_USAGE: 'questionnaire/setContextUsage',
    SET_CONTEXT_SOURCE: 'questionnaire/setContextSource',

    SET_INFLUENCER: 'questionnaire/setInfluencer',

    SET_PHOTO_ANALYSIS_DIAGNOSTIC: 'questionnaire/setPhotoAnalysisDiagnostic',
    SET_INTRO_TEXTS: 'questionnaire/setIntroTexts',

    SAVE_PATIENT_PHOTO: 'questionnaire/savePatientPhoto',
    SET_ALLOW_PHOTO_RESEARCH: 'questionnaire/setAllowPhotoResearch'
  },
  actions: {
    RESET_MODULE: 'questionnaire/resetModule',
    RESET_REQUESTED_DOCTOR: 'questionnaire/resetRequestedDoctor',

    SETUP_QUESTIONNAIRE: 'questionnaire/setupQuestionnaire',
    // email:str
    REQUEST_QUESTIONNAIRE_ACCESS: 'questionnaire/requestPatientAccess',
    RESTORE_QUESTIONNAIRE_ACCESS: 'questionnaire/restorePatientAccess',
    // { email:str, doctorId:str, accessCode:str }
    AUTHENTICATE_PATIENT: 'questionnaire/authenticatePatient',

    INIT_QUESTIONNAIRE: 'questionnaire/initQuestionnaire',

    // { id:str, email:str }
    FETCH_PATIENT: 'questionnaire/fetchPatient',
    // id:str
    FETCH_QUESTIONNAIRE: 'questionnaire/fetchQuestionnaire',
    FETCH_PATIENT_QUESTIONNAIRE_PHOTO: 'questionnaire/fetchPatientQuestionnairePhoto',
    // { country:str, town:str }
    FETCH_TEMPERATURE: 'questionnaire/fetchTemperature',
    // obj
    FETCH_SCORES: 'questionnaire/fetchScores',

    UPDATE_PATIENT: 'questionnaire/updatePatient',
    // { photoType:str, photo:b64 }
    UPDATE_PHOTO: 'questionnaire/updatePhoto',
    SAVE_PATIENT_PHOTO: 'questionnaire/savePatientPhoto',
    // { complete? }
    UPDATE_QUESTIONNAIRE: 'questionnaire/updateQuestionnaire',
    UPDATE_PATIENT_FIELDS: 'questionnaire/updatePatientFields',

    SEND_CONGRATULATIONS: 'questionnaire/sendCongratulations',

    // email:str
    CHECK_EMAIL_REGISTERED: 'questionnaire/checkEmailRegistered',

    SET_IS_PHOTO_CONSENTS_ACCEPTED: 'questionnaire/isPhotoConsentsAccepted',
    // photoConsents:boolean
    SET_IS_CONSENTS_ACCEPTED: 'questionnaire/isConsentsAccepted',
    SET_DOCTOR: 'questionnaire/setDoctor',
    SET_FIELDS: 'questionnaire/setFields',
    SET_CONTEXT_USAGE: 'questionnaire/setContextUsage',
    SET_CONTEXT_SOURCE: 'questionnaire/setContextSource',
    // photoType:str
    ADD_PHOTO_TYPE: 'questionnaire/addPhotoType',
    // photoType:str
    REMOVE_PHOTO_TYPE: 'questionnaire/removePhotoType',

    // step:str
    SET_CURRENT_STEP: 'questionnaire/setCurrentStep',
    // step:int
    SET_ANSWERED_STEPS: 'questionnaire/setAnsweredSteps',

    ADD_SKIPPED_SECTION: 'questionnaire/addSkippedSection',
    REMOVE_SKIPPED_SECTION: 'questionnaire/removeSkippedSection',

    SEND_INCOMPLETE_QUESTIONNAIRE: 'questionnaire/sendIncompleteQuestionnaire',

    LOAD_PHOTO_ANALYSIS_RESULTS: 'questionnaire/loadPhotoAnalysisResults'
  }
};

export const questTypes = stripNamespace('questionnaire/', types);

export default types;
