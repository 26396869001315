import { get } from 'lodash';

import { types } from '@/store/types';

import { getHashedId } from '@/utils';
import { getDoctorFullName } from '@/modules/dashboard/utils';

const DOCTOR_WEBSHOP_ACCESS_LEVELS = [2, 3];

export default {
  [types.getters.USER_FULL_NAME](state) {
    return getDoctorFullName(state[types.state.USER]);
  },

  [types.getters.GET_USERNAME](state) {
    return state[types.state.USER_NAME];
  },

  [types.getters.GET_LOCALE](state) {
    return state[types.state.LOCALE];
  },

  [types.getters.GET_USER](state) {
    return state[types.state.USER];
  },

  [types.getters.GET_USER_ID](state) {
    const user = state[types.state.USER];

    return user && user.id;
  },

  [types.getters.GET_USER_COUNTRY](state) {
    const user = state[types.state.USER];

    return user && user.country;
  },

  [types.getters.GET_USER_LANGUAGE](state) {
    const user = state[types.state.USER];

    return user && user.locale;
  },

  [types.getters.GET_DOCTOR_SLUG](state) {
    const user = state[types.state.USER];

    return user && user.doctorSlug;
  },

  [types.getters.USER_ISO_CODE](state) {
    const user = state[types.state.USER];

    return user && user.officePhoneIso;
  },

  [types.getters.DOCTOR_USERNAME](state) {
    const { username, email } = state[types.state.USER];

    return username || email;
  },

  [types.getters.HAS_WEBSHOP_ACCESS](state) {
    const doctorLevel = get(state, [types.state.USER, 'doctorLevel'], 0);

    return DOCTOR_WEBSHOP_ACCESS_LEVELS.includes(doctorLevel);
  },

  [types.getters.IS_MEDICAL_CONCENTRATION_BLOCKED](state) {
    return get(state, [types.state.USER, 'medicalConcentrationBlocked'], false);
  },

  [types.getters.DOCTOR_QUESTIONNAIRE_LINK](state) {
    const { id, doctorSlug, locale } = state[types.state.USER];
    let linkPostfix;

    if (doctorSlug) {
      linkPostfix = `diag/${doctorSlug}`;
    } else {
      const doctorIdHash = getHashedId(id);

      linkPostfix = `q/d/${locale}/${doctorIdHash}`;
    }

    return `${process.env.VUE_APP_BASE_URL}${linkPostfix}`;
  },

  [types.getters.GET_USER_CLINICS](state) {
    return get(state, [types.state.USER, 'clinicDtos'], []);
  },

  [types.getters.GET_USER_INFLUENCERS](state) {
    return get(state, [types.state.USER, 'influencerDtos'], []);
  },

  [types.getters.GET_LOADING](state) {
    return state.status.loading;
  },

  [types.getters.DOCTOR_CONSULTATION_COST](state) {
    return state[types.state.USER].consultationCost;
  },

  [types.getters.GET_USER_AUTHENTICATION_CODE](state) {
    return state[types.state.USER_AUTHENTICATION_DATA].authenticatationCode;
  },

  [types.getters.GET_USER_RECOVERY_CODES](state) {
    return state[types.state.USER_AUTHENTICATION_DATA].recoveryCodes;
  }
};
