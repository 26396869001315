export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$windowWidth = Vue.observable({ value: window.innerWidth });

    window.addEventListener('resize', () => {
      // eslint-disable-next-line no-param-reassign
      Vue.prototype.$windowWidth.value = window.innerWidth;
    });
  }
};
