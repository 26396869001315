import { isCopyingKeys, isSystemKeyCode } from '@/utils';

const allowOnlyRegExpDirective = {
  bind: (element, regExpPattern) => {
    const checkRegExpForKeyDownEvent = event => {
      const { keyCode } = event;

      if (
        !(
          new RegExp(regExpPattern.value).test(event.key) ||
          isSystemKeyCode({ keyCode }) ||
          isCopyingKeys({ event, keyCode })
        )
      ) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    element.addEventListener('keydown', checkRegExpForKeyDownEvent);
    element.$destroy = () => {
      element.removeEventListener('keydown', checkRegExpForKeyDownEvent);
    };
  },
  unbind: element => {
    element.$destroy();
  }
};

export default allowOnlyRegExpDirective;
