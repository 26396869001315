import { mapActions, mapMutations } from 'vuex';

import { types as rootTypes } from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

export const logoutMixin = {
  methods: {
    ...mapActions({
      logoutMixin__logout: rootTypes.actions.LOGOUT
    }),
    ...mapMutations({
      logoutMixin__resetRootModule: rootTypes.mutations.RESET_MODULE,
      logoutMixin__resetDashboardModule: dashTypes.mutations.RESET_MODULE
    }),
    async logoutAndClearModule() {
      this.logoutMixin__logout();

      document.body.removeAttribute('id');
      document.body.classList.remove('v-unlocked-modal-block-blur');

      await this.$router.push({
        name: 'Home'
      });

      await this.logoutMixin__resetRootModule();
      await this.logoutMixin__resetDashboardModule();
    }
  }
};
