<template>
  <warning-modal
    class="online-offer-forbidden-warning-modal"
    name="online-offer-forbidden-warning-modal"
    @confirm="onConfirm"
    @before-open="onBeforeOpen"
  >
    <template #main-content>
      {{ $t('dashboard.microneedling.warning.onlineOffer') }}
    </template>
    <template #secondary-content>
      {{ $t('dashboard.microneedling.warning.removeStep') }}
    </template>
  </warning-modal>
</template>

<script>
import WarningModal from '@/modules/dashboard/components/common/warning-modal/WarningModal.vue';

export default {
  name: 'OnlineOfferForbiddenWarningModal',
  components: { WarningModal },
  data() {
    return { onClose: null };
  },
  methods: {
    onBeforeOpen({ params: { onClose } }) {
      this.onClose = onClose;
    },
    onConfirm() {
      this.onClose({ confirm: true });
    }
  }
};
</script>
