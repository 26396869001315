import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { LOCAL_STORAGE_KEY } from '@/constants';
import { PATHS_TO_PERSIST } from '@/plugins/storage';

import mutations from '@/store/mutations';
import actions from '@/store/actions';
import getters from '@/store/getters';
import { getInitialState } from '@/store/state';

import { store } from '@/modules';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const state = { ...getInitialState() };

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: LOCAL_STORAGE_KEY,
      paths: PATHS_TO_PERSIST
    })
  ],
  strict: debug,
  state,
  mutations,
  actions,
  getters,
  modules: store
});
