import uuidv1 from 'uuid/v1';
import uuidv3 from 'uuid/v3';
import uuidv4 from 'uuid/v4';
import uuidv5 from 'uuid/v5';

export const uuidService = {
  v1() {
    return uuidv1();
  },

  v3() {
    return uuidv3();
  },

  v4() {
    return uuidv4();
  },

  v5() {
    return uuidv5();
  }
};

export default uuidService;
