import Compressor from 'compressorjs';
import { pick, isEmpty, camelCase } from 'lodash';

import InputObject from '@/components/common/SelectOptionObject';

import { LOCAL_STORAGE_KEY } from '@/constants';
import { ACNE_SCAR_CAUSE } from '@/modules/questionnaire/api/constants';
import { PATIENT_MEDICAL_BACKGROUND_FIELDS } from '@/modules/questionnaire/constants/fields';

import { getLocalizedScarCause } from '@/modules/questionnaire/api/helpers';

const possibleScarTypes = [
  ACNE_SCAR_CAUSE.ACCIDENT,
  ACNE_SCAR_CAUSE.SURGERY,
  ACNE_SCAR_CAUSE.DISEASE,
  ACNE_SCAR_CAUSE.BURNS_AND_SCALDS
];

const CHROME_CHECK_REG_EXP = /Chrome/;
const GOOGLE_INC_REG_EXP = /Google Inc/;
const ANDROID_CHECK_REG_EXP = /Android/i;

export const getMappedArray = (arraySize, mapFn) => Array.from(Array(arraySize)).map(mapFn);

export const getLocalizedScarTypes = (i18n, acneScarCauses) => {
  const scarTypes = acneScarCauses
    .reduce((scars, type) => {
      if (!possibleScarTypes.includes(type)) {
        return scars;
      }

      if (type === ACNE_SCAR_CAUSE.BURNS_AND_SCALDS) {
        return [...scars, getLocalizedScarCause(i18n, camelCase(type))];
      }

      return [...scars, getLocalizedScarCause(i18n, type)];
    }, [])
    .join(' / ');

  return i18n.t('heading.myScars', [scarTypes]);
};

export const redirectPatientAfterQuestionnaire = (i18n, { personalPage }) => {
  const defaultUrl = i18n.t('url.doctor');

  location.replace(personalPage || defaultUrl);
};

export const clearLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};

export const getScarCauses = acneScarCauses => {
  const isAcne = acneScarCauses.includes(ACNE_SCAR_CAUSE.ACNE);

  switch (true) {
    case isAcne && acneScarCauses.includes(ACNE_SCAR_CAUSE.CHICKENPOX): {
      return 'acneAndChickenpox';
    }
    case isAcne: {
      return 'acne';
    }
    default: {
      return 'chickenpox';
    }
  }
};

export const addFieldToArray = ({ PATIENT_FIELDS, FIELDS, patient, property }) => {
  const array = patient[property] ? PATIENT_FIELDS : FIELDS;

  array.push(property);
};

const preventScrollOnDevice = event => {
  event.preventDefault();
};

export const disableScroll = () => {
  document.body.addEventListener('touchmove', preventScrollOnDevice, { passive: false });
  document.body.classList.add('fixed');
};

export const enableScroll = () => {
  document.body.removeEventListener('touchmove', preventScrollOnDevice, { passive: false });
  document.body.classList.remove('fixed');
};

export const isChromeBrowser = () =>
  CHROME_CHECK_REG_EXP.test(navigator.userAgent) && GOOGLE_INC_REG_EXP.test(navigator.vendor);

export const isAndroidDevice = () => ANDROID_CHECK_REG_EXP.test(navigator.userAgent);

export const compressImageFile = imageFile =>
  new Promise(
    (resolve, reject) =>
      new Compressor(imageFile, {
        quality: 0.7,
        maxWidth: 4096,
        maxHeight: 4096,
        success(file) {
          resolve(file);
        },
        error(error) {
          reject(error);
        }
      })
  );

export const readAsDataURL = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = ({ target: { result } }) => resolve(result);
    reader.onerror = err => reject(err);

    reader.readAsDataURL(blob);
  });

export const isMacOS = () => navigator.userAgent.includes('Mac OS');

export const addPixelsPostfix = number => `${number}px`;

export const getHasMedicalData = ({ fields, context: { currentStep } }) => {
  const medicalDataFields = pick(fields, PATIENT_MEDICAL_BACKGROUND_FIELDS);
  const hasFilledMedicalDataInfo = Object.values(medicalDataFields).some(value => !isEmpty(value));

  return hasFilledMedicalDataInfo && !currentStep.includes('medicalBackground');
};

export const getSelectOptionsWithLabel = ({ options = [], keyPrefix }) =>
  options.map(option => {
    const label = `${keyPrefix}${option}`;

    return new InputObject(label, option);
  });
