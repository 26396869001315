import { getClient } from '@/api/client';
import { dataURItoBlob } from '@/api/helpers';

const client = getClient('questionnaire');

export const requestQuestionnaireAccess = async requestData =>
  client
    .withoutAuth()
    .withoutRedirect()
    .post('/auth/request-questionnaire-access', requestData);

export const restoreQuestionnaireAccess = async requestData =>
  client
    .withoutAuth()
    .withoutRedirect()
    .post('/auth/restore-questionnaire', requestData);

export const getQuestionnaireById = (patientId, questionnaireId) =>
  client.withAuth().get(`/api/patients/${patientId}/questionnaires/${questionnaireId}`);

export const getPatientQuestionnaires = patientId =>
  client.withAuth().get(`/api/patients/${patientId}/questionnaires/all`);

export const getPatientWithId = id => client.withAuth().get(`/api/patients/${id}`);

export const getPatientWithEmail = email => client.withAuth().get(`/api/patients?email=${email}`);

export const getDoctor = id => client.withAuth().get(`/api/users/${id}/questionnaireDetails`);

export const getDoctorFullName = id => client.withoutAuth().get(`/api/users/${id}/doctorName`);

export const getTemperature = (country, town) =>
  client.withAuth().get(`/api/temperature?country=${country}&town=${town}`);

export const getScores = (patientId, questionnaireId) =>
  client.withAuth().get(`/api/patients/${patientId}/questionnaires/${questionnaireId}/diagnosis`);

export const updatePatient = patient =>
  client.withAuth().patch(`/api/patients/${patient.id}`, patient);

export const addPhotos = (
  patientId,
  questionnaireId,
  { photoType, photo, allowResearch = false }
) => {
  const formData = new FormData();

  formData.append('photos', dataURItoBlob(photo), photoType);

  const url = `/api/patients/${patientId}/questionnaires/${questionnaireId}/${allowResearch}/photos`;

  return client.withAuth().post(url, formData, { 'content-Type': 'multipart/form-data' });
};

export const createQuestionnaire = (patientId, questionnaire) =>
  client.withAuth().post(`/api/patients/${patientId}/questionnaires`, questionnaire);

export const deletePhoto = (patientId, questionnaireId) =>
  client.withAuth().delete(`/api/patients/${patientId}/questionnaires/${questionnaireId}/photos`);

export const updateQuestionnaire = (patientId, questionnaire) =>
  client
    .withAuth()
    .patch(`/api/patients/${patientId}/questionnaires/${questionnaire.id}`, questionnaire);

export const sendCongratulations = (patientId, questionnaireId) => {
  const url = `/api/patients/${patientId}/questionnaires/${questionnaireId}/congrats`;

  return client.withAuth().post(url);
};

export const sendIncompleteQuestionnaire = (patientId, questionnaireId) =>
  client
    .withAuth()
    .post(
      `/api/patients/${patientId}/questionnaires/${questionnaireId}/incompleteQuestionnaireInvitation`
    );

export const transferPatient = ({
  doctorId,
  patientId,
  influencerSlug,
  medicalDataTransferSource
}) =>
  client.withAuth().post(`/api/patients/${patientId}/medical-data/transfer`, {
    doctorId,
    patientId,
    influencerSlug,
    medicalDataTransferSource
  });

export const getPhotoAnalysisResults = ({ patientId, questionnaireId }) =>
  client
    .withAuth()
    .get(`/api/patients/${patientId}/questionnaires/${questionnaireId}/photoDiagnostic`);

export const fetchUserPhotoByQuestionnaireId = ({ patientId, questionnaireId }) =>
  client.withAuth().get(`/api/patients/${patientId}/questionnaires/${questionnaireId}/photo`);
