import { getClient } from '@/api/client';

const client = getClient();

export const login = authData =>
  client
    .withoutAuth()
    .withoutRedirect()
    .post('/auth/authenticate', authData);

export const getUserWithUsername = username =>
  client.withAuth().get(`/api/users?username=${username}`);

export const getUserWithId = userId => client.withAuth().get(`/api/users/${userId}`);

export const updateUser = user => client.withAuth().patch(`/api/users/${user.id}`, user);

export const unlockDoctorsDashboard = (userId, userData) =>
  client.withAuth().post(`api/users/${userId}/unlockDashboard`, userData);

export const updateUserClinics = clinics =>
  client.withAuth().patch('/api/clinics/batchUpdate', clinics);

export const sendPasswordReset = email => client.withoutAuth().post('/resetPassword/send', email);

export const resetPassword = params => client.withoutAuth().post('/resetPassword/set', params);

export const getDoctorBySlug = doctorSlug => client.withoutAuth().get(`/slugs/${doctorSlug}`);

export const getDoctorBySlugAndInfluencer = (doctorSlug, influencer) =>
  client.withoutAuth().get(`/slugs/${doctorSlug}/${influencer}`);

export const sendReport = report => {
  client
    .withAuth()
    .post('/server-error', JSON.stringify(report), { 'Content-Type': 'application/json' });
};

export const confirmRedeem = operationId =>
  client.withoutAuth().post(`/api/users/loyalty/operation/${operationId}/redeemPoints`);

export const rejectRedeem = operationId =>
  client.withoutAuth().post(`/api/users/loyalty/operation/${operationId}/rejectRedemption`);

export const sendPatientTransferVerificationCode = ({ doctorId, patientId }) =>
  client
    .withAuth()
    .post(
      `/auth/sendMedicalDataTransferVerificationCode/doctors/${doctorId}/patients/${patientId}`
    );

export const verifyPatientTransfer = ({
  doctorId,
  patientId,
  verificationCode,
  influencerSlug,
  medicalDataTransferSource
}) =>
  client
    .withAuth()
    .post(
      `/auth/verifyMedicalDataTransfer/doctors/${doctorId}/patients/${patientId}/${verificationCode}`,
      { doctorId, patientId, verificationCode, influencerSlug, medicalDataTransferSource }
    );

export const generateRecoveryCodes = doctorId =>
  client.withAuth().post(`/api/users/${doctorId}/generateRecoveryCodes`);

export const getRecoveryCodes = doctorId =>
  client.withAuth().get(`/api/users/${doctorId}/recoveryCodes`);

export const setUpTwoFactorAuthentication = doctorId =>
  client.withAuth().post(`/api/users/${doctorId}/setUpTwoFactorAuthentication`);

export const confirmTwoFactorAuthenticationSetup = (doctorId, verificationCode) =>
  client
    .withAuth()
    .post(`/api/users/${doctorId}/confirmTwoFactorAuthenticationSetup`, verificationCode);
