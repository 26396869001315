<template>
  <error-boundary>
    <router-view />
    <q-loader :is-loading="isLoading" />
    <offline @detected-condition="handleConnectivityChange" />
    <not-supported-browser-modal />
    <microneedling-validation-modals />
    <v-modal-target name="root-modal" />
  </error-boundary>
</template>

<script>
import '@/assets/scss/styles.scss';

import { mapGetters } from 'vuex';
import offline from 'v-offline';

import { VModalTarget } from '@arfedulov/vue2-modal';
import QLoader from '@/modules/questionnaire/components/common/Loader';
import NotSupportedBrowserModal from '@/components/NotSupportedBrowserModal';
import MicroneedlingValidationModals from '@/modules/dashboard/components/microneedling/MicroneedlingValidationModals';
import ErrorBoundary from '@/components/ErrorBoundary';

import { screenHeightMixin } from '@/modules/questionnaire/mixins/screenHeightMixin';

import { isIE } from '@/utils';
import { errorObserver } from '@/services/errorObserver';

import { types } from '@/store/types';

import { NetworkError } from '@/errors';

export default {
  name: 'App',
  components: {
    NotSupportedBrowserModal,
    QLoader,
    offline,
    MicroneedlingValidationModals,
    ErrorBoundary,
    VModalTarget
  },
  mixins: [screenHeightMixin],
  computed: {
    ...mapGetters({
      isLoading: types.getters.GET_LOADING
    })
  },
  mounted() {
    if (isIE()) {
      this.$modal.show('not-supported-browser-modal');
    }
  },
  methods: {
    handleConnectivityChange(isOnline) {
      if (!isOnline) {
        errorObserver.dispatchError(new NetworkError());
      }
    }
  }
};
</script>
