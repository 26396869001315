import { types as dashTypes } from '@/modules/dashboard/store/types';

const DASHBOARD_PATHS = Object.values(dashTypes.state)
  .filter(path => path !== dashTypes.state.AVAILABLE_MOISTURIZE_USAGES)
  .map(path => path.replace('/', '.'));

const QIESTIONNAIRE_PATHS = [
  'questionnaire.status',
  'questionnaire.accessDetails',
  'questionnaire.doctor',
  'questionnaire.requestedDoctor',
  'questionnaire.patient',
  'questionnaire.influencer',
  'questionnaire.consent',
  'questionnaire.questionnaire',
  'questionnaire.scores',
  'questionnaire.photoAnalysisDiagnostic',
  'questionnaire.introTexts',
  'questionnaire.allowPhotoResearch'
];

export const PATHS_TO_PERSIST = [
  'status.errors',
  'user',
  'locale',
  'username',
  'isNewDesign',
  'userAuthenticationData',
  ...DASHBOARD_PATHS,
  ...QIESTIONNAIRE_PATHS
];
