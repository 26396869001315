import { get, isEqual, isEmpty, min, some } from 'lodash';

import { dashTypes } from '@/modules/dashboard/store/types';

import CompositionProduct from '@/modules/dashboard/components/common/CompositionProduct';

import {
  convertMonthsToDays,
  getFullName,
  formatNumberWithTwoDecimalPart
} from '@/modules/dashboard/utils';

import {
  calculateTreatEyeContourSerumDuration,
  calculateTreatEyesSectionPrice,
  calculateTreatFaceSectionPrice,
  calculateTreatFaceSectionSerumDuration,
  isBottleDuplicated,
  getBottleDescription,
  getComplementaryCareComposition,
  getMicroneedlingConfigFromBottles,
  doesFormulaContainMicroneedling,
  getSectionTreatmentDurations
} from '@/modules/dashboard/utils/create-treatment-utils';

import { DEFAULT_LOCALE, EMAIL_ERRORS_VALUES } from '@/constants';

import {
  CUSTOM_CAMPAIGN_TITLE_ERROR,
  CREATE_TREATMENT_SECTION_NAMES,
  CHANGABLE_SIZE_PRODUCTS,
  CHANGABLE_SIZE_PRODUCTS_BINDINGS,
  CREATE_TREATMENT_TYPES,
  PRODUCT_REFERENCES,
  SERUM_USAGE_KEYS,
  CLEANSE_TREATMENT_DURATION,
  MOISTURIZE_TREATMENT_MORNING_AND_EVENING_ONLY_DURATION,
  MOISTURIZE_TREATMENT_DEFAULT_DURATION,
  NEXULTRA_L_DURATION,
  NEXULTRA_B_DURATION,
  NEXULTRA_P_DURATION,
  PROTECT_TREATMENT_DURATION,
  DEFAULT_CREATE_TREATMENT_SUMMARY,
  TREATMENT_COMPOSITION_SECTION_KEY,
  PRODUCT_NAMES,
  TREATMENT_SECTION_NAME
} from '@/modules/dashboard/api/constants';

const COMPLEMENTARY_CARE_REGEXP = /^(nexUltra|universkin)/;

const DEFAULT_COMPLEMENTARY_CARES = [
  'nexUltraPLight',
  'nexUltraPRich',
  'nexUltra1',
  'nexUltra2',
  'nexUltra3',
  'nexUltraB1',
  'nexUltraB2',
  'nexUltraUV',
  'nexultraLBrush'
];

const getHasEmailErrorsByType = ({ type }) => EMAIL_ERRORS_VALUES.includes(type);

const getCurrentPatientDiagnostics = state =>
  get(state, [dashTypes.state.CURRENT_PATIENT, 'diagnostics'], []);

const TREATMENT_SECTION_NAME_TO_GETTER_TYPE_MAP = {
  [TREATMENT_SECTION_NAME.TREAT_FACE]: dashTypes.getters.TREAT_FACE,
  [TREATMENT_SECTION_NAME.TREAT_EYES]: dashTypes.getters.TREAT_EYES
};

export default {
  [dashTypes.getters.HAS_ERRORS](state) {
    const { [dashTypes.state.STATUS]: status } = state;

    return status.errors.length > 0;
  },

  [dashTypes.getters.PATIENT_INFO_EMAIL_ERROR](state) {
    const { [dashTypes.state.STATUS]: status } = state;

    return status.errors.find(getHasEmailErrorsByType);
  },

  [dashTypes.getters.HAS_PATIENT_INFO_EMAIL_ERROR](state) {
    const { [dashTypes.state.STATUS]: status } = state;

    return status.errors.some(getHasEmailErrorsByType);
  },

  [dashTypes.getters.HAS_NEW_CAMPAIGN_TITLE_ERROR](state) {
    const { [dashTypes.state.STATUS]: status } = state;

    return status.errors.some(error => error === CUSTOM_CAMPAIGN_TITLE_ERROR);
  },

  [dashTypes.getters.HAS_PHOTO_VALIDATION_ERRORS](state) {
    const { [dashTypes.state.STATUS]: status } = state;

    return !isEmpty(status.photoValidationErrors);
  },

  [dashTypes.getters.IS_DASHBOARD_LOCKED](state) {
    const { [dashTypes.state.STATUS]: status } = state;
    const { isDashboardLocked } = status;

    return isDashboardLocked;
  },

  [dashTypes.getters.NOTIFICATIONS_COUNTER](state) {
    return state[dashTypes.state.NOTIFICATIONS_COUNTER];
  },

  [dashTypes.getters.PARTIAL_NAME](state) {
    return state[dashTypes.state.PARTIAL_NAME];
  },

  [dashTypes.getters.PHOTO_ANALYSIS](state) {
    return state[dashTypes.state.PHOTO_ANALYSIS];
  },

  [dashTypes.getters.PHOTO_ANALYSIS_RESULT](state) {
    return state[dashTypes.state.PHOTO_ANALYSIS].result;
  },

  [dashTypes.getters.PHOTO_ANALYSIS_SPIDER_CHART](state) {
    return state[dashTypes.state.PHOTO_ANALYSIS].spiderChart;
  },

  [dashTypes.getters.PHOTO_ANALYSIS_PHOTO_ID](state) {
    return state[dashTypes.state.PHOTO_ANALYSIS].photoId;
  },

  [dashTypes.getters.PHOTO_ANALYSIS_PERMISSION_TO_DELETE](state) {
    return state[dashTypes.state.PHOTO_ANALYSIS].hasPermissionToDelete;
  },

  [dashTypes.getters.PHOTO_ANALYSIS_STORE_IMAGE_OPTION](state) {
    return state[dashTypes.state.PHOTO_ANALYSIS].storeImageOption;
  },

  [dashTypes.getters.PHOTO_ANALYSIS_UPDATED_RESULTS](state) {
    return state[dashTypes.state.PHOTO_ANALYSIS].updatedResults;
  },

  [dashTypes.getters.TREATMENT_DIRECTORY_QUESTIONS](state) {
    return get(state[dashTypes.state.TREATMENT_DIRECTORY], 'questions');
  },

  [dashTypes.getters.TREATMENT_DIRECTORY_RECOMMENDATIONS](state) {
    return get(state[dashTypes.state.TREATMENT_DIRECTORY], 'recommendations');
  },

  [dashTypes.getters.PATIENT_FILTER](state) {
    return state[dashTypes.state.PATIENT_FILTER];
  },

  [dashTypes.getters.PATIENT_FILTER_COUNT](state) {
    return state[dashTypes.state.PATIENT_FILTER_COUNT];
  },

  [dashTypes.getters.CURRENT_PATIENT](state) {
    return state[dashTypes.state.CURRENT_PATIENT];
  },

  [dashTypes.getters.CURRENT_PATIENT_ID](state) {
    return get(state, [dashTypes.state.CURRENT_PATIENT, 'id'], null);
  },

  [dashTypes.getters.CURRENT_PATIENT_PHOTO_ID](state) {
    return get(state, [dashTypes.state.CURRENT_PATIENT, 'photoId'], null);
  },

  [dashTypes.getters.IS_CURRENT_PATIENT_HAS_AVATAR](state, { currentPatientPhotoId }) {
    return !!currentPatientPhotoId;
  },

  [dashTypes.getters.CURRENT_PATIENT_LOCALE](state) {
    return get(state, [dashTypes.state.CURRENT_PATIENT, 'locale'], DEFAULT_LOCALE);
  },

  [dashTypes.getters.CURRENT_PATIENT_FIRST_NAME](state) {
    const { [dashTypes.state.CURRENT_PATIENT]: currentPatient } = state;

    return get(currentPatient, 'firstName', '');
  },

  [dashTypes.getters.CURRENT_PATIENT_FULL_NAME](state) {
    return state[dashTypes.state.CURRENT_PATIENT]
      ? getFullName(state[dashTypes.state.CURRENT_PATIENT])
      : '';
  },

  [dashTypes.getters.SETTING](state) {
    return state[dashTypes.state.SETTING];
  },

  [dashTypes.getters.GET_CURRENCY](state) {
    return get(state[dashTypes.state.SETTING], 'currencyCode', '');
  },

  [dashTypes.getters.PRODUCT_PRICE_BY_REFERENCE]: state => productReference => {
    const product = state[dashTypes.state.SETTING].productPrices.find(
      ({ reference }) => reference === productReference
    );

    return get(product, 'chosenPrice', 0);
  },

  [dashTypes.getters.PATIENTS_ORDER](state) {
    return state[dashTypes.state.PATIENT_ORDER];
  },

  [dashTypes.getters.PATIENTS_DATE_ORDER](state) {
    return state[dashTypes.state.PATIENT_DATE_ORDER];
  },

  [dashTypes.getters.PATIENTS_SORTING_DIRECTIONS](state) {
    return state[dashTypes.state.SORTING_DIRECTIONS];
  },

  [dashTypes.getters.OPENED_PATIENT](state) {
    return state[dashTypes.state.OPENED_PATIENT];
  },

  [dashTypes.getters.CURRENT_PATIENT_DIAGNOSTICS](state) {
    return getCurrentPatientDiagnostics(state);
  },

  [dashTypes.getters.GET_CURRENT_PATIENT_DIAGNOSTIC_BY_ID](state) {
    return diagnosticId =>
      getCurrentPatientDiagnostics(state).find(({ id }) => id === diagnosticId) || null;
  },

  [dashTypes.getters.CURRENT_PATIENT_TREATMENTS](state) {
    return get(state[dashTypes.state.CURRENT_PATIENT], 'treatments', []);
  },

  [dashTypes.getters.CURRENT_PATIENT_COMMENTS](state) {
    return get(state[dashTypes.state.CURRENT_PATIENT], 'comments', []);
  },

  [dashTypes.getters.ACTIVE_DIAGNOSTIC_ID](state) {
    return state[dashTypes.state.ACTIVE_DIAGNOSTIC_ID];
  },

  [dashTypes.getters.ACTIVE_TREATMENT_ID](state) {
    return state[dashTypes.state.ACTIVE_TREATMENT_ID];
  },

  [dashTypes.getters.SUMMARY_TREATMENT_ID](state) {
    return state.createTreatment.summary.summaryTreatmentId;
  },

  [dashTypes.getters.SUMMARY_TREATMENT_ID](state) {
    return state[dashTypes.state.CREATE_TREATMENT].summary.summaryTreatmentId;
  },

  [dashTypes.getters.ACTIVE_PATIENT_TAB](state) {
    return state[dashTypes.state.ACTIVE_PATIENT_TAB];
  },

  [dashTypes.getters.ACTIVE_INVITE_TAB](state) {
    return state[dashTypes.state.ACTIVE_INVITE_TAB];
  },

  /* eslint-disable indent */
  [dashTypes.getters.AVAILABLE_COMPLEMENTARY_CARE](state) {
    const availableProducts = state[dashTypes.state.SETTING]
      ? state[dashTypes.state.SETTING].productPrices
          .filter(({ reference }) => COMPLEMENTARY_CARE_REGEXP.test(reference))
          .map(({ reference }) => reference)
      : DEFAULT_COMPLEMENTARY_CARES;

    const isNexUltraBAvailable =
      availableProducts.includes(PRODUCT_REFERENCES.NEXULTRA_B1) ||
      availableProducts.includes(PRODUCT_REFERENCES.NEXULTRA_B2);

    if (isNexUltraBAvailable) {
      availableProducts.push(PRODUCT_REFERENCES.NEXULTRA_B);
    }

    const isNexUltraLAvailable =
      availableProducts.includes(PRODUCT_REFERENCES.NEXULTRA_L1) ||
      availableProducts.includes(PRODUCT_REFERENCES.NEXULTRA_L2);

    if (isNexUltraLAvailable) {
      availableProducts.push(PRODUCT_REFERENCES.NEXULTRA_L);
    }

    const isNexUltraOAvailable =
      availableProducts.includes(PRODUCT_REFERENCES.NEXULTRA_O1) ||
      availableProducts.includes(PRODUCT_REFERENCES.NEXULTRA_O2);

    if (isNexUltraOAvailable) {
      availableProducts.push(PRODUCT_REFERENCES.NEXULTRA_O);
    }

    return availableProducts;
  },

  [dashTypes.getters.GET_IS_COMPLEMENTARY_CARE_AVAILABLE](state, { availableComplementaryCare }) {
    return (productName, size) =>
      availableComplementaryCare.includes(CHANGABLE_SIZE_PRODUCTS_BINDINGS[productName][size]);
  },

  [dashTypes.getters.PDF_SUMMARY](state) {
    return state[dashTypes.state.PDF_SUMMARY];
  },

  [dashTypes.getters.CREATE_TREATMENT_TYPE](state) {
    return state[dashTypes.state.CREATE_TREATMENT].type;
  },

  [dashTypes.getters.RECOMMENDATION_DIAGNOSTIC](state) {
    return state[dashTypes.state.CREATE_TREATMENT].recommendationDiagnostic;
  },

  [dashTypes.getters.SKIN_CHARACTERISTICS_BY_RECOMMENDATION_DIAGNOSTIC](
    state,
    { recommendationDiagnostic }
  ) {
    const { questionnaireSkinCharacteristicsDto, photoSkinCharacteristicsDto } =
      recommendationDiagnostic || {};

    return questionnaireSkinCharacteristicsDto || photoSkinCharacteristicsDto || {};
  },

  [dashTypes.getters.HAS_TREATMENT_RECOMMENDATION](state, { createTreatmentType }) {
    return createTreatmentType !== CREATE_TREATMENT_TYPES.TREATMENT_FROM_SCRATCH;
  },

  [dashTypes.getters.SUMMARY_TREATMENT](state) {
    return state[dashTypes.state.CREATE_TREATMENT].summary;
  },

  [dashTypes.getters.IS_SUMMARY_TREATMENT_SELECTED](state) {
    const { [dashTypes.state.CREATE_TREATMENT]: createTreatment } = state;
    const { summary } = createTreatment;

    return !isEqual(summary, DEFAULT_CREATE_TREATMENT_SUMMARY);
  },

  [dashTypes.getters.TREATMENT_RECOMMENDATION](state) {
    return state[dashTypes.state.CREATE_TREATMENT].treatmentRecommendation;
  },

  [dashTypes.getters.TREAT_FACE_RECOMMENDATION](state, { treatmentRecommendation }) {
    return get(treatmentRecommendation, 'treatFace');
  },

  [dashTypes.getters.TREAT_EYES_RECOMMENDATION](state, { treatmentRecommendation }) {
    return get(treatmentRecommendation, 'treatEyes');
  },

  [dashTypes.getters.TREAT_FACE](state) {
    return get(state[dashTypes.state.CREATE_TREATMENT], 'summary.treatFace');
  },

  [dashTypes.getters.TREAT_FACE_PRICE](state, { setting, treatFace, treatEyes }) {
    return calculateTreatFaceSectionPrice({
      setting,
      firstBottle: treatFace.compositionBottle1,
      secondBottle: treatFace.compositionBottle2,
      treatEyesBottle: treatEyes.compositionBottle1
    });
  },

  [dashTypes.getters.TREAT_FACE_PRICE_WITH_PROMO](state, { setting, treatFace, treatEyes }) {
    return calculateTreatFaceSectionPrice({
      setting,
      firstBottle: treatFace.compositionBottle1,
      secondBottle: treatFace.compositionBottle2,
      treatEyesBottle: treatEyes.compositionBottle1,
      usePromoActivesPrice: true
    });
  },

  [dashTypes.getters.TREAT_FACE_DURATION](state, { treatFace }) {
    return calculateTreatFaceSectionSerumDuration({
      firstBottle: treatFace.compositionBottle1,
      secondBottle: treatFace.compositionBottle2
    });
  },

  [dashTypes.getters.TREAT_EYES](state) {
    return get(state[dashTypes.state.CREATE_TREATMENT], 'summary.treatEyes');
  },

  [dashTypes.getters.TREAT_EYES_PRICE](state, { setting, treatFace, treatEyes }) {
    return calculateTreatEyesSectionPrice({
      setting,
      firstBottle: treatFace.compositionBottle1,
      secondBottle: treatFace.compositionBottle2,
      treatEyesBottle: treatEyes.compositionBottle1
    });
  },

  [dashTypes.getters.TREAT_EYES_DURATION](state, { treatEyes }) {
    return calculateTreatEyeContourSerumDuration(treatEyes.compositionBottle1);
  },

  [dashTypes.getters.TREAT_UNIVERSKIN_H_PRICE](
    state,
    { moisturizeTreatment, universkinHSectionSelection, productPriceByReference }
  ) {
    const isUniverskinHSelected = moisturizeTreatment.includes(PRODUCT_REFERENCES.UNIVERSKIN_H);
    const isUniverskinHInTreatSection =
      isUniverskinHSelected && universkinHSectionSelection === CREATE_TREATMENT_SECTION_NAMES.TREAT;

    return isUniverskinHInTreatSection
      ? productPriceByReference(PRODUCT_REFERENCES.UNIVERSKIN_H)
      : 0;
  },

  [dashTypes.getters.TREAT_SECTION_PRICE](
    state,
    { treatFacePrice, treatEyesPrice, treatUniverskinHPrice }
  ) {
    return treatFacePrice + treatEyesPrice + treatUniverskinHPrice;
  },

  [dashTypes.getters.SECTION_PRODUCTS](state) {
    return state[dashTypes.state.CREATE_TREATMENT].sectionProducts;
  },

  [dashTypes.getters.COMPLEMENTARY_CARE_SECTION_TOTAL_PRICE]: (store, getters) => (
    section,
    sectionTreatment
  ) =>
    sectionTreatment.reduce((price, productReference) => {
      if (
        productReference === PRODUCT_REFERENCES.UNIVERSKIN_H &&
        getters.universkinHSectionSelection !== section
      ) {
        return price;
      }

      let reference = productReference;
      const isChangableSizeProduct = CHANGABLE_SIZE_PRODUCTS.includes(productReference);

      if (isChangableSizeProduct) {
        const sectionProducts = getters.sectionProducts[section];
        const product = sectionProducts.find(({ name }) => name === productReference);

        if (!product) {
          return price;
        }

        reference = CHANGABLE_SIZE_PRODUCTS_BINDINGS[productReference][product.size];
      }

      return price + getters.productPriceByReference(reference);
    }, 0),

  [dashTypes.getters.CLEANSE_PRODUCTS](state) {
    return get(state[dashTypes.state.CREATE_TREATMENT], 'sectionProducts.cleanse');
  },

  [dashTypes.getters.CLEANSE_PRODUCTS_REFERENCES](store, { cleanseProducts }) {
    return cleanseProducts.map(({ name }) => name);
  },

  [dashTypes.getters.CLEANSE_TREATMENT_PRICE](
    store,
    { complementaryCareSectionTotalPrice, cleanseTreatment }
  ) {
    return complementaryCareSectionTotalPrice(
      CREATE_TREATMENT_SECTION_NAMES.CLEANSE,
      cleanseTreatment
    );
  },

  [dashTypes.getters.CLEANSE_TREATMENT](state) {
    return get(state[dashTypes.state.CREATE_TREATMENT], 'summary.cleanse');
  },

  [dashTypes.getters.CLEANSE_TREATMENT_DURATION](state, { cleanseTreatment }) {
    if (!isEmpty(cleanseTreatment)) {
      return CLEANSE_TREATMENT_DURATION;
    }

    return 0;
  },

  [dashTypes.getters.MOISTURIZE_PRODUCTS](state) {
    return get(state[dashTypes.state.CREATE_TREATMENT], 'sectionProducts.moisturize');
  },

  [dashTypes.getters.MOISTURIZE_PRODUCTS_REFERENCES](store, { moisturizeProducts }) {
    return moisturizeProducts.map(({ name }) => name);
  },

  [dashTypes.getters.MOISTURIZE_TREATMENT_PRICE](
    store,
    { complementaryCareSectionTotalPrice, moisturizeTreatment }
  ) {
    return complementaryCareSectionTotalPrice(
      CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE,
      moisturizeTreatment
    );
  },

  [dashTypes.getters.MOISTURIZE_TREATMENT](state) {
    return get(state[dashTypes.state.CREATE_TREATMENT], 'summary.moisturize');
  },

  [dashTypes.getters.MOISTURIZE_TREATMENT_DURATION](
    state,
    { moisturizeTreatment, moisturizeProducts }
  ) {
    if (isEmpty(moisturizeTreatment)) {
      return 0;
    }

    const selectedProducts = moisturizeProducts.filter(({ name }) =>
      moisturizeTreatment.includes(name)
    );

    const productsDurations = selectedProducts.reduce((durations, { name, size, usage }) => {
      switch (true) {
        case name === PRODUCT_REFERENCES.NEXULTRA_B: {
          return [...durations, NEXULTRA_B_DURATION[size]];
        }
        case name === PRODUCT_REFERENCES.NEXULTRA_L: {
          return [...durations, NEXULTRA_L_DURATION[size]];
        }
        case name === PRODUCT_REFERENCES.NEXULTRA_P_LIGHT ||
          name === PRODUCT_REFERENCES.NEXULTRA_P_RICH: {
          return [...durations, NEXULTRA_P_DURATION[usage]];
        }
        case usage && usage === SERUM_USAGE_KEYS.MORNING_AND_EVENING: {
          return [...durations, MOISTURIZE_TREATMENT_MORNING_AND_EVENING_ONLY_DURATION];
        }
        default: {
          return [...durations, MOISTURIZE_TREATMENT_DEFAULT_DURATION];
        }
      }
    }, []);

    return min(productsDurations);
  },

  [dashTypes.getters.PROTECT_PRODUCTS](state) {
    return get(state[dashTypes.state.CREATE_TREATMENT], 'sectionProducts.protect');
  },

  [dashTypes.getters.PROTECT_PRODUCTS_REFERENCES](store, { protectProducts }) {
    return protectProducts.map(({ name }) => name);
  },

  [dashTypes.getters.PROTECT_TREATMENT_PRICE](
    store,
    { complementaryCareSectionTotalPrice, protectTreatment }
  ) {
    return complementaryCareSectionTotalPrice(
      CREATE_TREATMENT_SECTION_NAMES.PROTECT,
      protectTreatment
    );
  },

  [dashTypes.getters.PROTECT_TREATMENT](state) {
    return get(state[dashTypes.state.CREATE_TREATMENT], 'summary.protect');
  },

  [dashTypes.getters.PROTECT_TREATMENT_DURATION](state, { protectTreatment }) {
    if (!isEmpty(protectTreatment)) {
      return PROTECT_TREATMENT_DURATION;
    }

    return 0;
  },

  [dashTypes.getters.SUMMARY_TREATMENT_TOTAL_PRICE](
    state,
    { treatSectionPrice, cleanseTreatmentPrice, moisturizeTreatmentPrice, protectTreatmentPrice }
  ) {
    return (
      treatSectionPrice + cleanseTreatmentPrice + moisturizeTreatmentPrice + protectTreatmentPrice
    );
  },

  [dashTypes.getters.SUMMARY_TREATMENT_PRICE_WITH_PROMO](
    state,
    { summaryTreatmentTotalPrice, treatFacePrice, treatFacePriceWithPromo }
  ) {
    return summaryTreatmentTotalPrice - treatFacePrice + treatFacePriceWithPromo;
  },

  [dashTypes.getters.SUMMARY_TREATMENT_TOTAL_DURATION](state, getters) {
    const {
      treatFaceDuration,
      treatEyesDuration,
      cleanseTreatmentDuration,
      moisturizeTreatmentDuration,
      protectTreatmentDuration,
      protectTreatment
    } = getters;

    const complementaryCareDurations = [
      cleanseTreatmentDuration,
      moisturizeTreatmentDuration,
      min(getSectionTreatmentDurations(protectTreatment, protectTreatmentDuration))
    ];

    const complementaryCareSectionsDurations = complementaryCareDurations.filter(Boolean);
    const complementaryCareSectionTotalDuration = min(complementaryCareSectionsDurations);

    const treatmentDuration =
      treatFaceDuration || treatEyesDuration || complementaryCareSectionTotalDuration;

    return convertMonthsToDays(treatmentDuration);
  },

  [dashTypes.getters.TREAT_FACE_COMPOSITION]: (
    state,
    { treatFace, treatFacePrice, treatFacePriceWithPromo }
  ) => ({ useActivePromoPrice = false }) => {
    const { compositionBottle1, compositionBottle2 } = treatFace;

    if (!compositionBottle1 && !compositionBottle2) {
      return [];
    }

    const microneedling = getMicroneedlingConfigFromBottles({
      compositionBottle1,
      compositionBottle2
    });

    const isFirstBottleDuplicated = isBottleDuplicated(compositionBottle1);
    const isSecondBottleDuplicated = isBottleDuplicated(compositionBottle2);
    const firstBottleDescription = getBottleDescription(compositionBottle1);
    const secondBottleDescription = getBottleDescription(compositionBottle2);

    const firstDescription = isSecondBottleDuplicated
      ? secondBottleDescription
      : firstBottleDescription;

    const secondDescription = isFirstBottleDuplicated
      ? firstBottleDescription
      : secondBottleDescription;

    const price = useActivePromoPrice ? treatFacePriceWithPromo : treatFacePrice;
    const descriptions = [firstDescription, secondDescription].filter(description => !!description);

    return [
      new CompositionProduct({
        name: TREATMENT_COMPOSITION_SECTION_KEY.TREAT_FACE,
        productName: PRODUCT_NAMES.TREAT_FACE,
        descriptions,
        price: formatNumberWithTwoDecimalPart(price),
        isSerum: true,
        microneedling
      })
    ];
  },

  [dashTypes.getters.TREAT_EYES_COMPOSITION]: (state, { treatEyes, treatEyesPrice }) => () => {
    const { compositionBottle1 } = treatEyes;

    if (!compositionBottle1) {
      return [];
    }

    const description = getBottleDescription(compositionBottle1);

    return [
      new CompositionProduct({
        name: TREATMENT_COMPOSITION_SECTION_KEY.TREAT_EYES,
        productName: PRODUCT_NAMES.TREAT_EYES,
        descriptions: [description],
        price: formatNumberWithTwoDecimalPart(treatEyesPrice),
        isSerum: true,
        microneedling: compositionBottle1.microneedling
      })
    ];
  },

  [dashTypes.getters.SUMMARY_TREATMENT_COMPOSITION]: (state, getters) => ({
    useActivePromoPrice = false
  }) => {
    const {
      treatFaceComposition,
      treatEyesComposition,
      cleanseTreatment,
      cleanseProducts,
      moisturizeTreatment,
      moisturizeProducts,
      protectTreatment,
      protectProducts,
      productPriceByReference
    } = getters;

    return [
      ...treatFaceComposition({ useActivePromoPrice }),
      ...treatEyesComposition(),
      ...getComplementaryCareComposition({
        sectionName: CREATE_TREATMENT_SECTION_NAMES.CLEANSE,
        treatment: cleanseTreatment,
        sectionProducts: cleanseProducts,
        getProductPrice: productPriceByReference
      }),
      ...getComplementaryCareComposition({
        sectionName: CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE,
        treatment: moisturizeTreatment,
        sectionProducts: moisturizeProducts,
        getProductPrice: productPriceByReference
      }),
      ...getComplementaryCareComposition({
        sectionName: CREATE_TREATMENT_SECTION_NAMES.PROTECT,
        treatment: protectTreatment,
        sectionProducts: protectProducts,
        getProductPrice: productPriceByReference
      })
    ];
  },

  [dashTypes.getters.CREATE_TREATMENT_ERRORS](state) {
    const { [dashTypes.state.CREATE_TREATMENT]: createTreatment } = state;
    const { errors } = createTreatment;

    return errors;
  },

  [dashTypes.getters.HAS_CREATE_TREATMENT_ERRORS](state) {
    const { [dashTypes.state.CREATE_TREATMENT]: createTreatment } = state;
    const { errors } = createTreatment;

    return !isEmpty(errors);
  },

  [dashTypes.getters.MARKETING_CAMPAIGNS](state) {
    return state[dashTypes.state.MARKETING_CAMPAIGNS].campaigns || [];
  },

  [dashTypes.getters.ACTIVE_MARKETING_CAMPAIGN](state) {
    return state[dashTypes.state.MARKETING_CAMPAIGNS].activeCampaign || {};
  },

  [dashTypes.getters.SAVED_MESSAGE_TEMPLATE](state) {
    return state[dashTypes.state.MESSAGE_TEMPLATE].savedTemplate;
  },

  [dashTypes.getters.CURRENT_MESSAGE_TEMPLATE](state) {
    return state[dashTypes.state.MESSAGE_TEMPLATE].currentTemplate;
  },

  [dashTypes.getters.IS_MESSAGE_TEMPLATE_UPDATED](state) {
    const { savedTemplate, currentTemplate } = state[dashTypes.state.MESSAGE_TEMPLATE];

    return isEqual(savedTemplate, currentTemplate);
  },

  [dashTypes.getters.IS_CURRENT_MESSAGE_TEMPLATE_TITLE_EMPTY](state) {
    const { currentTemplate } = state[dashTypes.state.MESSAGE_TEMPLATE];

    return currentTemplate.title === '';
  },

  [dashTypes.getters.ACTIVE_MESSAGE_TEMPLATE_TAB](state) {
    return state[dashTypes.state.MESSAGE_TEMPLATE].activeTab;
  },

  [dashTypes.getters.UNIVERSKIN_H_SECTION_SELECTION](state) {
    return state[dashTypes.state.CREATE_TREATMENT].universkinHSelectionSection;
  },

  [dashTypes.getters.IS_MICRONEEDLING_AVAILABLE](state, getters, { user }) {
    return user.microneedlingEnabled;
  },

  [dashTypes.getters.GET_SERUM_SUMMARY_BY_SECTION_NAME]: (state, getters) => sectionName =>
    getters[TREATMENT_SECTION_NAME_TO_GETTER_TYPE_MAP[sectionName]],

  [dashTypes.getters.IS_MICRONEEDLING_ACTIVE](state, getters) {
    const summarySectionNames = Object.values(TREATMENT_SECTION_NAME_TO_GETTER_TYPE_MAP);
    const summarySectionsContainMicroneedling = summarySectionNames.map(sectionName => {
      const section = getters[sectionName];

      return doesFormulaContainMicroneedling(section);
    });

    return some(summarySectionsContainMicroneedling);
  },

  [dashTypes.getters.GET_IS_MICRONEEDLING_ACTIVE_FOR_TREATMENT_SECTION]: (
    state,
    getters
  ) => treatmentSectionName => {
    const section = getters[TREATMENT_SECTION_NAME_TO_GETTER_TYPE_MAP[treatmentSectionName]];

    return doesFormulaContainMicroneedling(section);
  }
};
