<template>
  <warning-modal
    name="contraindications-warning-modal"
    class="pin-to-top-mobile-modal"
    has-close-button
    has-border-before-actions
    large-screen-width="800px"
    title="dashboard.microneedling.warning.contraindications.title"
    @close="onCancel"
    @before-open="onBeforeOpen"
  >
    <template #prepend-content>
      <div class="contraindications-warning-modal__first-paragraph">
        {{ $t('dashboard.microneedling.warning.contraindications.aboutToCreate') }}
      </div>
    </template>
    <template #main-content>
      {{ $t('dashboard.microneedling.warning.contraindications.consult') }}
    </template>
    <template #secondary-content>
      <div class="contradictions">
        <b class="contradictions__title">
          {{ $t('dashboard.microneedling.warning.contraindications.subtitle') }}:
        </b>
        <ul class="contradictions-list contradictions__list">
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseIfOr"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.pregnant') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.breathfeeding') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseOn"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.cancer') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseIfHaveOr"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.bleeding') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.antiCoagulating') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseIfAre"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.scarring') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseIfTwelveMonth"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.retinoids') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseIfTaking"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.photosensitivity') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseOnOr"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.raisedMoles') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.warts') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseOnFiveParams"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.wound') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.brokenSkin') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.scabs') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.bleedingSkin') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.blisters') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseOn"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.coldSores') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.proneToColdSores') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseOn"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.proneToAcne') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseOnSixParams"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.rashez') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.eczema') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.psoriasis') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.rosacea') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.skinInfections') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.fungalInfections') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseSevenParams"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.microdermabrasion') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.botulinumToxin') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.dermalFillers') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.ipl') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.chemicalPeels') }}</b>
            <b>{{ $t('dashboard.microneedling.warning.contraindications.cosmetic') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotPerformOn"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.sunBurnedSkin') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseWhile"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.selfTanning') }}</b>
          </i18n>
          <i18n
            tag="li"
            path="dashboard.microneedling.warning.contraindications.doNotUseInCases"
            class="contradictions-list__item"
          >
            <b>{{ $t('dashboard.microneedling.warning.contraindications.metalAllergy') }}</b>
          </i18n>
        </ul>
      </div>
    </template>
    <template #actions>
      <confirmation-modal-actions @confirm="onConfirm" @cancel="onCancel" />
    </template>
  </warning-modal>
</template>

<script>
import WarningModal from '@/modules/dashboard/components/common/warning-modal/WarningModal.vue';
import ConfirmationModalActions from '@/modules/dashboard/components/common/warning-modal/ConfirmationModalActions.vue';

export default {
  name: 'ContraindicationsWarningModal',
  components: { WarningModal, ConfirmationModalActions },
  data() {
    return { onClose: null };
  },
  methods: {
    onBeforeOpen({ params: { onClose } }) {
      this.onClose = onClose;
    },
    onConfirm() {
      this.onClose({ confirm: true });
    },
    onCancel() {
      this.onClose({ confirm: false });
    }
  }
};
</script>

<style lang="scss" scoped>
.contradictions {
  &__title {
    display: inline-block;
    margin-bottom: 8px;
  }

  &__list {
    padding: 0;
    list-style: inside;
  }
}

.contradictions-list {
  &__item {
    padding: 2px 0;
  }
}

.contraindications-warning-modal {
  &__first-paragraph {
    text-align: center;
    font-size: 15px;
    max-width: 350px;
    margin: 0 auto;
  }
}
</style>
