import { get, isEmpty, omit, compact, some } from 'lodash';

import Ingredient from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/Ingredient';
import ProductConfig from '@/modules/dashboard/components/create-treatment-tab/treatments/ProductConfig';
import CompositionProduct from '@/modules/dashboard/components/common/CompositionProduct';
import CompositionProductDescription from '@/modules/dashboard/components/common/CompositionProductDescription';

import {
  DEFAULT_NEEDLE_SIZE,
  MICRONEEDLING_VOLUME
} from '@/modules/dashboard/constants/microneedling';
import {
  ACTIVE_INGREDIENTS_KEYS,
  CHANGABLE_SIZE_PRODUCTS,
  CHANGABLE_SIZE_PRODUCTS_BINDINGS,
  DEFAULT_ACTIVE_INGREDIENT,
  DEFAULT_COMPOSITION_BOTTLE_VALUES,
  DEFAULT_TREAT_EYES_TREATMENT_DURATION,
  DEFAULT_TREAT_SECTION_TREATMENT,
  DEFAULT_TREATMENT_DURATION,
  EMPTY_COMPLEMENTARY_CARE_SECTION,
  EMPTY_TREAT_SECTION_BOTTLES,
  HOW_TO_APPLY_SPLITTING_SYMBOLS,
  INGREDIENT_MEDICAL_CONCENTRATIONS,
  INGREDIENT_SEND_IGNORE_FIELDS,
  PRODUCT_REFERENCES,
  SERUM_USAGE_KEYS,
  TREATMENT_BOTTLE_ICONS_CONFIG,
  TREATMENT_PRODUCT_HINTS_CONFIG,
  TREATMENT_PRODUCT_SIZES_CONFIG,
  TREATMENT_TIME_USAGE_CONFIG,
  TREATMENT_USAGE_HEADER_LABELS_KEYS,
  SERUM_USAGE_ABBREVIATION,
  TREATMENT_COMPOSITION_PRODUCT_TYPE,
  TREATMENT_TYPES,
  ACTIVES_PROMO_NUMBER,
  SIZE_MEASUREMENT_UNITS,
  INGREDIENT_CONCENTRATIONS,
  DEFAULT_PRODUCT_ONE_BOTTLE_SIZES,
  PRODUCT_NAMES,
  COMPLEMENTARY_PRODUCT_SIZE_UNITS,
  PRODUCT_REFERENCE_TO_DISPLAY_TITLE_MAP
} from '@/modules/dashboard/api/constants';

export const getProductByReference = (setting, productReference) => {
  const productPrices = get(setting, 'productPrices');

  if (!productPrices) {
    return null;
  }

  const product = productPrices.find(({ reference }) => reference === productReference);

  return product || null;
};

export const productPriceByReference = (setting, reference) =>
  get(getProductByReference(setting, reference), 'chosenPrice', 0);

export const getBottleActivesNumber = bottle => {
  const firstIngredientCapsules = get(bottle, 'actif1.nbOfCapsules', 0);
  const secondIngredientCapsules = get(bottle, 'actif2.nbOfCapsules', 0);
  const thirdIngredientCapsules = get(bottle, 'actif3.nbOfCapsules', 0);

  const isDuplicated = get(bottle, 'isDuplicated', false);

  const oneSerumAmountOfCapsules =
    firstIngredientCapsules + secondIngredientCapsules + thirdIngredientCapsules;

  return isDuplicated ? oneSerumAmountOfCapsules * 2 : oneSerumAmountOfCapsules;
};

export const calculateBottleActivesPrice = (setting, bottle) => {
  const capsulePrice = productPriceByReference(setting, PRODUCT_REFERENCES.ACTIVE_UNIT);

  return getBottleActivesNumber(bottle) * capsulePrice;
};

export const calculateAllActivesPriceWithPromo = (setting, firstBottle, secondBottles) => {
  const capsulePrice = productPriceByReference(setting, PRODUCT_REFERENCES.ACTIVE_UNIT);
  const capsulePricePromo = productPriceByReference(setting, PRODUCT_REFERENCES.ACTIVE_UNIT_PROMO);
  const totalActivesNumber =
    getBottleActivesNumber(firstBottle) + getBottleActivesNumber(secondBottles);

  if (totalActivesNumber <= ACTIVES_PROMO_NUMBER) {
    return totalActivesNumber * capsulePrice;
  }

  const promoActivesNumber = totalActivesNumber - ACTIVES_PROMO_NUMBER;

  return ACTIVES_PROMO_NUMBER * capsulePrice + promoActivesNumber * capsulePricePromo;
};

export const isOneOfBottlesDuplicated = (firstBottle, secondBottle) =>
  get(firstBottle, 'isDuplicated', false) || get(secondBottle, 'isDuplicated', false);

export const getTreatSerumPriceWithDiscount = ({
  setting,
  firstBottle,
  secondBottle,
  treatEyesBottle,
  isTreatEyesPriceCalculation
}) => {
  const isNoEyesSerumBottleSelected = !treatEyesBottle && isTreatEyesPriceCalculation;
  const isNoFaceSerumBottlesSelection =
    !firstBottle && !secondBottle && !isTreatEyesPriceCalculation;

  if (isNoEyesSerumBottleSelected || isNoFaceSerumBottlesSelection) {
    return 0;
  }

  if ((firstBottle && secondBottle) || isOneOfBottlesDuplicated(firstBottle, secondBottle)) {
    return isTreatEyesPriceCalculation
      ? productPriceByReference(setting, PRODUCT_REFERENCES.SERUM_UNIT)
      : productPriceByReference(setting, PRODUCT_REFERENCES.SERUM_X2_UNIT);
  }

  if ((firstBottle || secondBottle) && treatEyesBottle) {
    return productPriceByReference(setting, PRODUCT_REFERENCES.SERUM_X2_UNIT) / 2;
  }

  return productPriceByReference(setting, PRODUCT_REFERENCES.SERUM_UNIT);
};

const getActivePrice = ({ setting, firstBottle, secondBottle, usePromoActivesPrice }) => {
  if (usePromoActivesPrice) {
    return calculateAllActivesPriceWithPromo(setting, firstBottle, secondBottle);
  }

  return (
    calculateBottleActivesPrice(setting, firstBottle) +
    calculateBottleActivesPrice(setting, secondBottle)
  );
};

export const calculateTreatFaceSectionPrice = ({
  setting,
  firstBottle,
  secondBottle,
  treatEyesBottle,
  usePromoActivesPrice = false
}) => {
  if (!firstBottle && !secondBottle) {
    return 0;
  }

  const activePrice = getActivePrice({ setting, firstBottle, secondBottle, usePromoActivesPrice });
  const treatFaceSerumPrice = getTreatSerumPriceWithDiscount({
    setting,
    firstBottle,
    secondBottle,
    treatEyesBottle
  });

  return treatFaceSerumPrice + activePrice;
};

export const calculateTreatEyesSectionPrice = ({
  setting,
  firstBottle,
  secondBottle,
  treatEyesBottle
}) => {
  if (!treatEyesBottle) {
    return 0;
  }

  const treatEyesSerumPrice = getTreatSerumPriceWithDiscount({
    setting,
    firstBottle,
    secondBottle,
    treatEyesBottle,
    isTreatEyesPriceCalculation: true
  });

  return treatEyesSerumPrice + calculateBottleActivesPrice(setting, treatEyesBottle);
};

const calculateOneTreatmentBottleDuration = bottle => {
  if (!bottle) {
    return 0;
  }

  const { usage, isDuplicated } = bottle;

  if (usage === SERUM_USAGE_KEYS.MORNING_AND_EVENING) {
    return isDuplicated ? DEFAULT_TREATMENT_DURATION : 1;
  }

  if (usage === SERUM_USAGE_KEYS.MORNING_ONLY || usage === SERUM_USAGE_KEYS.EVENING_ONLY) {
    return isDuplicated ? 4 : DEFAULT_TREATMENT_DURATION;
  }

  return 0;
};

const TREATMENT_WITH_MICRONEEDLING_DURATION_TABLE = {
  ONE_FORMULA: {
    SINGLE_BOTTLE: {
      [SERUM_USAGE_KEYS.MORNING_AND_EVENING]: 0.5,
      [SERUM_USAGE_KEYS.MORNING_ONLY]: 1,
      [SERUM_USAGE_KEYS.EVENING_ONLY]: 1
    },
    DUPLICATED_BOTTLE: {
      [SERUM_USAGE_KEYS.MORNING_AND_EVENING]: 1.5,
      [SERUM_USAGE_KEYS.MORNING_ONLY]: 3,
      [SERUM_USAGE_KEYS.EVENING_ONLY]: 3
    }
  },
  TWO_FORMULAS: {
    [SERUM_USAGE_KEYS.MORNING_ONLY]: 1.5,
    [SERUM_USAGE_KEYS.EVENING_ONLY]: 1.5
  }
};

const calculateTreatFaceSectionDurationWithMicroneedling = (bottle, isOneFormula) => {
  const { isDuplicated } = bottle;

  let durationTable = TREATMENT_WITH_MICRONEEDLING_DURATION_TABLE.TWO_FORMULAS;

  if (isOneFormula) {
    durationTable = isDuplicated
      ? TREATMENT_WITH_MICRONEEDLING_DURATION_TABLE.ONE_FORMULA.DUPLICATED_BOTTLE
      : TREATMENT_WITH_MICRONEEDLING_DURATION_TABLE.ONE_FORMULA.SINGLE_BOTTLE;
  }

  const { usage } = bottle;

  return durationTable[usage];
};

const getIsOneFormula = ({ firstBottle, secondBottle }) => {
  if (firstBottle && secondBottle) {
    return firstBottle.usage === secondBottle.usage;
  }

  return !!(firstBottle || secondBottle);
};

export const calculateTreatFaceSectionSerumDuration = ({ firstBottle, secondBottle }) => {
  if (!firstBottle && !secondBottle) {
    return 0;
  }

  const bottleWithMicroneedling = compact([firstBottle, secondBottle]).find(
    ({ microneedling }) => microneedling
  );

  if (bottleWithMicroneedling) {
    const isOneFormula = getIsOneFormula({ firstBottle, secondBottle });

    return calculateTreatFaceSectionDurationWithMicroneedling(
      bottleWithMicroneedling,
      isOneFormula
    );
  }

  return (
    calculateOneTreatmentBottleDuration(firstBottle) ||
    calculateOneTreatmentBottleDuration(secondBottle)
  );
};

export const calculateTreatEyeContourSerumDuration = bottle => {
  if (!bottle) {
    return 0;
  }

  const { usage: bottleUsage } = bottle;

  if (bottleUsage === SERUM_USAGE_KEYS.MORNING_AND_EVENING) {
    return DEFAULT_TREATMENT_DURATION;
  }

  return DEFAULT_TREAT_EYES_TREATMENT_DURATION;
};

export const isBottleDuplicated = bottle => bottle && bottle.isDuplicated;

export const isBottleEmpty = bottle => {
  if (!bottle) {
    return true;
  }

  return !ACTIVE_INGREDIENTS_KEYS.some(ingredientKey => {
    const ingredient = bottle[ingredientKey];

    return ingredient && ingredient.name;
  });
};

export const getTreatProducts = ({ compositionBottle1, compositionBottle2, usage }) => {
  const treatProducts = [];

  if (compositionBottle1) {
    treatProducts.push({ ...compositionBottle1, usage: usage || compositionBottle1.usage });
  }

  if (compositionBottle2) {
    treatProducts.push({ ...compositionBottle2, usage: usage || compositionBottle2.usage });
  }

  return treatProducts;
};

export const getTreatProductsWithDuplication = ({
  compositionBottle1,
  compositionBottle2,
  usage
}) => {
  if (compositionBottle1 && compositionBottle1.isDuplicated) {
    const firstBottle = { ...compositionBottle1, usage: usage || compositionBottle1.usage };

    return [firstBottle, { ...firstBottle }];
  }

  if (compositionBottle2 && compositionBottle2.isDuplicated) {
    const secondBottle = { ...compositionBottle2, usage: usage || compositionBottle2.usage };

    return [secondBottle, { ...secondBottle }];
  }

  return getTreatProducts({ compositionBottle1, compositionBottle2, usage });
};

export const getParsedHowToApply = ({ howToApply }) => {
  if (!howToApply || !(howToApply instanceof Array)) {
    return [];
  }

  return howToApply.map((howTo = '') => {
    const [name, description] = howTo.split(HOW_TO_APPLY_SPLITTING_SYMBOLS);

    if (!description) {
      return { description: name };
    }

    return {
      name: name.trim(),
      description
    };
  });
};

export const getIngredientFromRecommendationActif = ({ name, nbOfCapsules }) => {
  if (!name && !nbOfCapsules) {
    return null;
  }

  return new Ingredient(name, nbOfCapsules);
};

export const getBottleCompositionForRecommendation = (
  bottle,
  usage = SERUM_USAGE_KEYS.MORNING_ONLY
) => {
  if (!bottle || !usage) {
    return null;
  }

  return ACTIVE_INGREDIENTS_KEYS.reduce(
    (bottleComposition, ingredient) => ({
      ...bottleComposition,
      [ingredient]: getIngredientFromRecommendationActif(bottle[ingredient])
    }),
    {
      ...DEFAULT_COMPOSITION_BOTTLE_VALUES,
      usage,
      microneedling: get(bottle, 'microneedling', null)
    }
  );
};

export const getTwoFormulaBottles = ({ compositionBottle1, compositionBottle2 }) => ({
  compositionBottle1: getBottleCompositionForRecommendation(compositionBottle1),
  compositionBottle2: getBottleCompositionForRecommendation(
    compositionBottle2,
    SERUM_USAGE_KEYS.EVENING_ONLY
  )
});

export const getOneFormulaBottles = ({ compositionBottle1, compositionBottle2, usage }) => ({
  compositionBottle1: {
    ...getBottleCompositionForRecommendation(compositionBottle1, usage),
    isDuplicated: !!compositionBottle2
  },
  compositionBottle2: null
});

export const getTreatSectionBottles = treatSection => {
  if (!treatSection) {
    return { ...EMPTY_TREAT_SECTION_BOTTLES };
  }

  const { isRecommended, formulasOptions } = treatSection;

  if (!isRecommended || !formulasOptions) {
    return { ...EMPTY_TREAT_SECTION_BOTTLES };
  }

  const { oneFormula, twoFormulas } = formulasOptions;

  if (twoFormulas && twoFormulas.isFound) {
    return getTwoFormulaBottles(twoFormulas);
  }

  if (oneFormula && oneFormula.isFound) {
    return getOneFormulaBottles(oneFormula);
  }

  return { ...EMPTY_TREAT_SECTION_BOTTLES };
};

export const getComplementaryCareTreatment = ({ products = [] }, availableProducts = []) => {
  if (!products || !availableProducts) {
    return [];
  }

  return products.map(({ name }) => name).filter(name => availableProducts.includes(name));
};

const getBottleWithIngredients = bottle => {
  const actives = ACTIVE_INGREDIENTS_KEYS.reduce(
    (bottleWithIngredients, active) => ({
      ...bottleWithIngredients,
      [active]: get(bottle, active)
        ? omit(bottle[active], INGREDIENT_SEND_IGNORE_FIELDS)
        : { ...DEFAULT_ACTIVE_INGREDIENT }
    }),
    {}
  );
  const { microneedling } = bottle;

  return { ...actives, microneedling };
};

const getDuplicatedCompositionBottle = bottle => {
  const bottleWithIngredients = getBottleWithIngredients(bottle);

  return {
    isFound: true,
    compositionBottle1: bottleWithIngredients,
    compositionBottle2: { ...bottleWithIngredients, microneedling: null },
    usage: bottle.usage
  };
};

const getOneBottleCompositionBottle = bottle => ({
  isFound: true,
  compositionBottle1: getBottleWithIngredients(bottle),
  compositionBottle2: null,
  usage: bottle.usage
});

const getOneFormulaOptions = (firstBottle, secondBottle) => {
  if (firstBottle && firstBottle.isDuplicated) {
    return getDuplicatedCompositionBottle(firstBottle);
  }

  if (secondBottle && secondBottle.isDuplicated) {
    return getDuplicatedCompositionBottle(secondBottle);
  }

  if (firstBottle) {
    return getOneBottleCompositionBottle(firstBottle);
  }

  return getOneBottleCompositionBottle(secondBottle);
};

const getTreatFaceTreatment = ({ compositionBottle1, compositionBottle2 }, actionScores) => {
  if (!compositionBottle1 && !compositionBottle2) {
    return { ...DEFAULT_TREAT_SECTION_TREATMENT };
  }

  if (compositionBottle1 && compositionBottle2) {
    return {
      actionScores: actionScores || null,
      isRecommended: true,
      formulasOptions: {
        twoFormulas: {
          isFound: true,
          compositionBottle1: getBottleWithIngredients(compositionBottle1),
          compositionBottle2: getBottleWithIngredients(compositionBottle2),
          usage: SERUM_USAGE_KEYS.ONE_MORNING_ONE_EVENING
        },
        oneFormula: { isFound: false, usage: '' }
      }
    };
  }

  return {
    actionScores: actionScores || null,
    isRecommended: true,
    formulasOptions: {
      twoFormulas: { isFound: false, usage: SERUM_USAGE_KEYS.ONE_MORNING_ONE_EVENING },
      oneFormula: getOneFormulaOptions(compositionBottle1, compositionBottle2)
    }
  };
};

const getTreatEyesTreatment = ({ compositionBottle1 }) => {
  if (!compositionBottle1) {
    return { ...DEFAULT_TREAT_SECTION_TREATMENT };
  }

  return {
    isRecommended: true,
    formulasOptions: {
      oneFormula: {
        isFound: true,
        compositionBottle1: getBottleWithIngredients(compositionBottle1),
        usage: compositionBottle1.usage
      },
      twoFormulas: {
        isFound: false,
        usage: SERUM_USAGE_KEYS.ONE_MORNING_ONE_EVENING
      }
    }
  };
};

const getComplementaryCareProducts = (products, getProductPriceByReference) => ({
  products: products.map(product => {
    if (!product) {
      return {
        isRecommended: false
      };
    }

    const { name: productName, size: productSize } = product;
    const productProperties = { isRecommended: true, multiplier: 1 };

    if (CHANGABLE_SIZE_PRODUCTS.includes(productName)) {
      const changeableSizeProductName = CHANGABLE_SIZE_PRODUCTS_BINDINGS[productName][productSize];
      const changeableProductPrice = getProductPriceByReference(changeableSizeProductName);

      return {
        ...product,
        ...productProperties,
        name: changeableSizeProductName,
        price: changeableProductPrice
      };
    }

    const productPrice = getProductPriceByReference(productName);

    return {
      ...product,
      ...productProperties,
      price: productPrice
    };
  })
});

const getComplementaryCareSectionSelectedProducts = (
  sectionTreatment = [],
  sectionProducts = []
) => {
  if (!sectionTreatment || !sectionProducts) {
    return [];
  }

  return sectionProducts.filter(({ name }) => sectionTreatment.includes(name));
};

const checkComplementaryCareProducts = getProductPriceByReference => (
  sectionTreatment = [],
  sectionProducts = []
) => {
  const selectedProducts = getComplementaryCareSectionSelectedProducts(
    sectionTreatment,
    sectionProducts
  );

  return !isEmpty(selectedProducts)
    ? getComplementaryCareProducts(selectedProducts, getProductPriceByReference)
    : { ...EMPTY_COMPLEMENTARY_CARE_SECTION };
};

export const getTreatmentFromSummaryTreatment = (
  { cleanse, moisturize, protect, treatFace, treatEyes },
  { cleanseProducts, moisturizeProducts, protectProducts, actionScores },
  getProductPriceByReference
) => {
  const getSectionComplementaryCareProducts = checkComplementaryCareProducts(
    getProductPriceByReference
  );

  return {
    treatmentDetails: {
      actionScores: actionScores || null,
      cleanse: getSectionComplementaryCareProducts(cleanse, cleanseProducts),
      moisturize: getSectionComplementaryCareProducts(moisturize, moisturizeProducts),
      protect: getSectionComplementaryCareProducts(protect, protectProducts),
      treatFace: getTreatFaceTreatment(treatFace || {}, actionScores),
      treatEyes: getTreatEyesTreatment(treatEyes || {})
    }
  };
};

export const getBottleActiveTime = ({ bottle, isDuplicated, $t }) => {
  const bottleUsage = get(bottle, 'usage');
  const isMicroneedling = get(bottle, 'microneedling');
  const step = $t('dashboard.microneedling.step');

  const usage = $t(TREATMENT_USAGE_HEADER_LABELS_KEYS[bottleUsage]);
  const microneedlingLabel = isMicroneedling ? ` + ${step}` : '';
  const duplicatedLabel = isDuplicated ? ' x2' : '';

  return bottleUsage ? `${usage}${duplicatedLabel}${microneedlingLabel}` : '';
};

export const getHasMedicalConcentration = (ingredient, numberOfCapsules) => {
  if (!ingredient || !numberOfCapsules) {
    return false;
  }

  return numberOfCapsules >= INGREDIENT_MEDICAL_CONCENTRATIONS[ingredient];
};

export const getIngredientsWithSelection = (ingredients, selections) => {
  if (!ingredients || !ingredients.map || !selections) {
    return [];
  }

  return ingredients.map(ingredient => ({
    ...ingredient,
    selection: get(selections, `${ingredient.name}.selection`, 2)
  }));
};

export const getIngredientFromArrayByName = (ingredientArray, ingredientName) =>
  ingredientArray.find(({ name = '' }) => name.toLowerCase() === ingredientName.toLowerCase());

export const findOptionNameSuggestion = (array = [], searchString = '') => {
  const emptyResult = {
    name: '',
    translation: ''
  };

  if (!array) {
    return emptyResult;
  }

  const searchStringLower = searchString.toLowerCase();
  const isLowerCase = searchString === searchStringLower;

  const suggestedElement = array.find(
    ({ translation = '' }) =>
      searchStringLower && translation.toLowerCase().startsWith(searchStringLower)
  );

  if (suggestedElement) {
    const { name = '', translation = '' } = suggestedElement;

    return {
      name,
      translation: isLowerCase ? translation.toLowerCase() : translation
    };
  }

  return emptyResult;
};

export const getTreatmentProductConfig = reference =>
  new ProductConfig({
    nameKey: PRODUCT_REFERENCE_TO_DISPLAY_TITLE_MAP[reference],
    hintText: TREATMENT_PRODUCT_HINTS_CONFIG[reference],
    usageOptions: TREATMENT_TIME_USAGE_CONFIG[reference] || [],
    sizeOptions: TREATMENT_PRODUCT_SIZES_CONFIG[reference] || [],
    bottleIcon: TREATMENT_BOTTLE_ICONS_CONFIG[reference]
  });

export const getMicroneedlingConfigFromBottles = ({ compositionBottle1, compositionBottle2 }) => {
  const bottleWithMicroneedling = compact([compositionBottle1, compositionBottle2]).find(
    ({ microneedling }) => microneedling
  );

  return get(bottleWithMicroneedling, ['microneedling'], null);
};

export const getBottleDescription = bottle => {
  if (!bottle) {
    return null;
  }

  const { actif1, actif2, actif3, usage } = bottle;
  const activesArray = [actif1, actif2, actif3];

  const activesDescriptions = activesArray.reduce((actives, currentActive) => {
    if (!currentActive || currentActive.name === '') {
      return actives;
    }

    return [...actives, currentActive];
  }, []);

  const additionalData = !isEmpty(activesDescriptions) ? activesDescriptions : null;

  return new CompositionProductDescription({
    productType: TREATMENT_COMPOSITION_PRODUCT_TYPE.SERUM,
    usage: SERUM_USAGE_ABBREVIATION[usage],
    additionalData,
    size: DEFAULT_PRODUCT_ONE_BOTTLE_SIZES[PRODUCT_NAMES.SERUM_UNIT],
    sizeMeasurement: SIZE_MEASUREMENT_UNITS.ML
  });
};

export const getComplementaryCareDescription = (section, product) => {
  if (!product) {
    return null;
  }

  const { name, size, usage } = product;
  const descriptionItems = [section];
  const sizeMeasurement = COMPLEMENTARY_PRODUCT_SIZE_UNITS[name];

  return new CompositionProductDescription({
    productType: TREATMENT_COMPOSITION_PRODUCT_TYPE.COMPLEMENTARY_CARE,
    usage: SERUM_USAGE_ABBREVIATION[usage],
    additionalData: descriptionItems,
    size,
    sizeMeasurement
  });
};

export const getProductReference = ({ name, size }) => {
  const productReference = CHANGABLE_SIZE_PRODUCTS.includes(name)
    ? CHANGABLE_SIZE_PRODUCTS_BINDINGS[name][size]
    : name;

  return productReference;
};

export const getComplementaryCareComposition = ({
  treatment,
  sectionName,
  sectionProducts,
  getProductPrice
}) => {
  if (treatment.length === 0) {
    return [];
  }

  return treatment.map(productName => {
    const product = sectionProducts.find(sectionProduct => productName === sectionProduct.name);
    const productDescription = getComplementaryCareDescription(sectionName, product);
    const productPriceReference = getProductReference(product);

    return new CompositionProduct({
      name: PRODUCT_REFERENCE_TO_DISPLAY_TITLE_MAP[productName],
      productName,
      descriptions: [productDescription],
      price: getProductPrice(productPriceReference)
    });
  });
};

export const getTreatmentType = ({ isTreatmentDirectory, diagnosticId }) => {
  if (isTreatmentDirectory) {
    return TREATMENT_TYPES.TREATMENT_DIRECTORY;
  }

  return diagnosticId ? TREATMENT_TYPES.DIAGNOSTIC : TREATMENT_TYPES.FROM_SCRATCH;
};

export const getIngredientsComposition = ({ ingredients, $t, locale, translationPrefix }) =>
  ingredients.reduce((currentDescription, { name, nbOfCapsules }) => {
    const ingredientTranslatedName = $t(`${translationPrefix}.ingredients.${name}`, locale);
    const defaultConcentration = INGREDIENT_CONCENTRATIONS[name];
    const capsules = nbOfCapsules !== 1 ? `×${nbOfCapsules}` : '';
    const ingredientsDescription = `${ingredientTranslatedName} ${defaultConcentration}%${capsules}`;

    return [...currentDescription, ingredientsDescription];
  }, []);

export const getIngredientsDescription = ({ ingredients, $t, locale, translationPrefix }) => {
  if (ingredients) {
    return getIngredientsComposition({ ingredients, $t, locale, translationPrefix }).join(', ');
  }

  return $t(`${translationPrefix}.label.noActiveIngredients`, locale).toLowerCase();
};

export const getMicroneedlingConfig = ({ sectionName, usage, price, needleSize }) => ({
  needleSize: needleSize || DEFAULT_NEEDLE_SIZE,
  volume: MICRONEEDLING_VOLUME[sectionName],
  price,
  usage
});

export const doesFormulaContainMicroneedling = formula => {
  if (!formula) {
    return false;
  }

  const { compositionBottle1, compositionBottle2 } = formula;
  const bottles = compact([compositionBottle1, compositionBottle2]);

  return some(bottles, bottle => bottle.microneedling);
};

export const getTreatmentSectionFormulaFromTreatment = (treatment, sectionName) => {
  const pathToFormulasOptions = ['treatmentDetails', sectionName, 'formulasOptions'];
  const oneFormula = get(treatment, [...pathToFormulasOptions, 'oneFormula']);
  const twoFormulas = get(treatment, [...pathToFormulasOptions, 'twoFormulas']);

  return compact([oneFormula, twoFormulas]).find(({ isFound }) => isFound);
};

export const getSectionTreatmentDurations = (treatmentSectionProducts, treatmentSectionDurations) =>
  treatmentSectionProducts.map(product => treatmentSectionDurations[product]);
