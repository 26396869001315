import { convertToUpperCase } from '@/modules/dashboard/utils';

export const routes = [
  {
    path: '',
    name: 'Dashboard',
    component: () => import('../views/Dashboard'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'patient/:id',
    name: 'PatientCard',
    component: () => import('../views/PatientCard.vue'),
    meta: {
      requiresAuth: true
    },
    props(route) {
      const props = { ...route.params };

      props.id = parseInt(props.id, 10);

      return props;
    }
  },
  {
    path: 'patient/:id/edit',
    name: 'EditPatientInfo',
    component: () => import('../components/patient-card/EditPatientInfo'),
    meta: {
      requiresAuth: true,
      mobile: true
    }
  },
  {
    path: 'profile',
    name: 'UserProfile',
    component: () => import('../views/Profile'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'profile/two-factor-authentication-set-up',
    name: 'TwoFactorAuthenticationSetUp',
    component: () => import('../components/two-factor-authentication/TwoFactorAuthenticationSetUp'),
    meta: {
      requiresAuth: true
    },
    props: route => {
      const { isHeaderWithBackButton = false } = route.params;

      return {
        isHeaderWithBackButton
      };
    }
  },
  {
    path: 'profile/two-factor-authentication-recovery-codes',
    name: 'TwoFactorAuthenticationRecoveryCodes',
    component: () =>
      import('../components/two-factor-authentication/TwoFactorAuthenticationRecoveryCodes'),
    meta: {
      requiresAuth: true
    },
    props: route => {
      const { isHeaderWithBackButton, hasAuthenticationEnabled } = route.params;

      return {
        isHeaderWithBackButton,
        hasAuthenticationEnabled
      };
    }
  },
  {
    path: 'profile/two-factor-authentication-scan-code',
    name: 'TwoFactorAuthenticationScanCode',
    component: () =>
      import('../components/two-factor-authentication/TwoFactorAuthenticationScanCode'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'profile/two-factor-authentication-manual-flow',
    name: 'TwoFactorAuthenticationManualFlow',
    component: () =>
      import('../components/two-factor-authentication/TwoFactorAuthenticationManualFlow'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'profile/edit',
    name: 'EditUserInfo',
    component: () => import('../components/profile/EditDoctorInfo'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'online-consultation-fee',
    name: 'OnlineConsultationFee',
    component: () => import('../views/OnlineConsultationFee'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'templates',
    name: 'MessageTemplates',
    component: () => import('../views/MessageTemplates'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'templates/marketing-campaign',
    name: 'MessageCampaign',
    component: () => import('../components/message-templates/MarketingCampaignTemplate'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'templates/notification/:notificationType',
    name: 'Notification',
    component: () => import('../components/message-templates/NotificationTemplateTabs'),
    meta: {
      requiresAuth: true
    },
    props: route => {
      const { notificationType } = route.params;

      return {
        notificationType: convertToUpperCase(notificationType)
      };
    }
  },
  {
    path: 'patient/:id/diagnostics',
    name: 'Diagnostics',
    component: () => import('../components/diagnostic-tab/DiagnosticTab'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'patient/:id/treatment-history',
    name: 'TreatmentHistory',
    component: () => import('../components/treatment-history-tab/TreatmentHistoryTab'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'patient/:id/create-treatment',
    name: 'CreateTreatment',
    component: () => import('../components/create-treatment-tab/CreateTreatmentTab'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'patient/:id/summary-treatment-composition',
    name: 'SummaryTreatmentComposition',
    component: () => import('../components/create-treatment-tab/SummaryTreatmentComposition'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'patient/:id/summary-treatment-details',
    name: 'SummaryTreatmentDetails',
    component: () => import('../components/create-treatment-tab/SummaryTreatmentDetails'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'patient/:id/summary-treatment-review/:templateType',
    name: 'ReviewPatientInviteTabs',
    component: () => import('../components/patient-card/invite/ReviewPatientInviteTabs'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'patient/:id/send-offer',
    name: 'SendOffer',
    component: () => import('../components/create-treatment-tab/send-offer/SendOffer'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'patient/:id/offer-composition',
    name: 'OfferComposition',
    component: () => import('../components/create-treatment-tab/send-offer/OfferComposition'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'patient/:id/medicalData',
    name: 'MedicalData',
    component: () => import('../components/patient-card/MedicalDataTab'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'patient/:id/photos',
    name: 'PhotosTab',
    component: () => import('../components/photo-tab/PhotoTab'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'patient/:id/send-questionnaire',
    name: 'SendPatientInviteWithEmail',
    component: () => import('../components/patient-card/invite/SendPatientInviteWithEmail'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'patient/:id/send-invite',
    name: 'SendPatientInviteTabs',
    component: () => import('../components/patient-card/invite/SendPatientInviteTabs'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'patient/:id/diagnostics/:diagnosticId',
    name: 'DiagnosticDetails',
    component: () => import('../components/diagnostic-tab/DiagnosticDetails'),
    meta: {
      requiresAuth: true
    },
    props(route) {
      const props = { ...route.params };

      props.diagnosticId = parseInt(props.diagnosticId, 10);

      return props;
    }
  },
  {
    path: 'patient/:id/treatment-history/:treatmentId',
    name: 'TreatmentDetails',
    component: () => import('../components/treatment-history-tab/TreatmentDetails'),
    meta: {
      requiresAuth: true
    },
    props(route) {
      const props = { ...route.params };

      props.treatmentId = parseInt(props.treatmentId, 10);

      return props;
    }
  },
  {
    path: 'patient/:id/comments',
    name: 'Comments',
    component: () => import('../components/patient-card/comments/CommentsTab'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'invite-patient',
    name: 'InvitePatient',
    component: () => import('../components/dashboard/invite-patient/InvitePatient'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'invite-patient/facebook',
    name: 'ShareWithFacebook',
    component: () => import('../components/dashboard/invite-patient/facebook/ShareWithFacebook'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'invite-patient/email',
    name: 'SendWithEmail',
    component: () => import('../components/dashboard/invite-patient/email/SendWithEmail'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'invite-patient/sms',
    name: 'SendWithTextMessage',
    component: () => import('../components/dashboard/invite-patient/sms/SendWithTextMessage'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'new-treatment',
    name: 'NewTreatment',
    component: () => import('../components/dashboard/create-treatment/new-treatment/NewTreatment'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'new-treatment/patient-details/:newTreatmentFlowType',
    name: 'NewTreatmentPatientDetails',
    component: () =>
      import('../components/dashboard/create-treatment/quick-treatment/NewTreatmentPatientDetails'),
    props(route) {
      return { ...route.params };
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'new-treatment/add-photo',
    name: 'PhotoAnalysisAddPhoto',
    component: () =>
      import('../components/dashboard/create-treatment/photo-analysis/PhotoAnalysisAddPhoto'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'new-treatment/add-photo-with-previous',
    name: 'PhotoAnalysisAddPhotoWithPrevious',
    component: () =>
      import('../components/dashboard/create-treatment/photo-analysis/PhotoAnalysisAddPhoto'),
    meta: {
      requiresAuth: true
    },
    props() {
      return { isHeaderWithBackButton: true };
    }
  },
  {
    path: 'new-treatment/clarification',
    name: 'PhotoAnalysisClarification',
    component: () =>
      import('../components/dashboard/create-treatment/photo-analysis/Clarification'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'new-treatment/analyzing-photo/:canUploadAnother',
    name: 'AnalyzingPhoto',
    component: () =>
      import('../components/dashboard/create-treatment/photo-analysis/AnalyzingPhoto'),
    props(route) {
      return { hasUploadAnotherOption: JSON.parse(route.params.canUploadAnother) };
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'new-treatment/analysis-results-overview',
    name: 'AnalysisResultsOverview',
    component: () =>
      import('../components/dashboard/create-treatment/photo-analysis/AnalysisResultsOverview'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'new-treatment/photo-analysis-result',
    name: 'PhotoAnalysisResultUpdate',
    component: () =>
      import('../components/dashboard/create-treatment/photo-analysis/PhotoAnalysisResultUpdate'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'new-treatment/treatment-selection',
    name: 'TreatmentSelection',
    component: () =>
      import('../components/dashboard/create-treatment/treatment-directory/TreatmentSelection'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'new-treatment/treatment-directory',
    name: 'TreatmentDirectory',
    component: () =>
      import('../components/dashboard/create-treatment/treatment-directory/TreatmentDirectory'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'personalize-packaging',
    name: 'PersonalizePackaging',
    component: () => import('../components/dashboard/personalize-packaging/PersonalizePackaging'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'loyalty-program',
    name: 'LoyaltyProgram',
    component: () => import('../views/LoyaltyProgram'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'loyalty-program/redeem/:participantId',
    name: 'LoyaltyProgramRedeem',
    component: () => import('../components/loyalty-program/Redeem'),
    props(route) {
      const props = { ...route.params };

      props.participantId = parseInt(props.participantId, 10);

      return props;
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'loyalty-program/redeem/:participantId/:redeemType/contact-information',
    name: 'RedeemContactInformation',
    component: () => import('../components/loyalty-program/ContactInformation'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'tutorial/:tutorialScript',
    name: 'Tutorial',
    component: () => import('../components/tutorial/Tutorial'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'user-guide',
    component: () => import('../views/UserGuide'),
    children: [
      {
        path: '',
        name: 'UserGuideMainPage',
        component: () => import('../components/user-guide/ArticlesSelectionSection')
      },
      {
        path: ':articleName',
        name: 'ArticleSection',
        component: () => import('../components/user-guide/ArticleSection'),
        props: true
      },
      {
        path: 'search/:searchQuery',
        name: 'UserGuideSearch',
        component: () => import('../components/user-guide/search/UserGuideSearch'),
        props: true
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: ':doctorId/patient/:patientId/diagnostic/:diagnosticId',
    redirect: ({ params }) => {
      const { doctorId, patientId, diagnosticId } = params;

      return {
        name: 'EmailPdfSummaryHandler',
        params: { doctorId, patientId, diagnosticId }
      };
    }
  },
  {
    path: ':doctorId/patient/:patientId/diagnostic/:diagnosticId/download',
    redirect: ({ params }) => {
      const { doctorId, patientId, diagnosticId } = params;

      return {
        name: 'EmailPdfSummaryHandler',
        params: { doctorId, patientId, diagnosticId, isDirect: 'yes' }
      };
    }
  },
  {
    path: 'email-pdf-summary/:doctorId/:patientId/:diagnosticId/:isDirect?',
    name: 'EmailPdfSummaryHandler',
    component: () => import('@/modules/dashboard/views/EmailPdfSummaryHandler.vue'),
    props: ({ params }) => ({ ...params, isDirect: params.isDirect === 'yes' })
  }
];
