export const PATIENT_PHOTO_VALIDATION_ERROR = {
  NO_FACE: 'noFace',
  MULTIPLE_FACES: 'multipleFaces',
  UNSUPPORTED_FORMAT: 'unsupportedFormat',
  TOO_LARGE: 'tooLarge'
};

export const PHOTO_VALIDATION_ERROR_MODALS = {
  [PATIENT_PHOTO_VALIDATION_ERROR.NO_FACE]: 'photo-face-low-quality-modal',
  [PATIENT_PHOTO_VALIDATION_ERROR.MULTIPLE_FACES]: 'photo-multiple-faces-modal',
  [PATIENT_PHOTO_VALIDATION_ERROR.TOO_LARGE]: 'photo-too-large-file-size-modal',
  [PATIENT_PHOTO_VALIDATION_ERROR.UNSUPPORTED_FORMAT]: 'photo-unsupported-format-modal'
};

export default { PATIENT_PHOTO_VALIDATION_ERROR, PHOTO_VALIDATION_ERROR_MODALS };
