import { getDoctorBySlug, getDoctorBySlugAndInfluencer } from '@/api';
import { getDecodedId, getHashedId } from '@/utils';

import { LocalStorageService } from '@/services/LocalStorageService';

const beforeEnterQuestionnaire = (to, from, next) => {
  const token = LocalStorageService.getQuestionnaireToken();

  if (token) {
    next();
  }
};

const getHashedQuestionnaireUrl = (locale, doctorIdHash) => `/q/d/${locale}/${doctorIdHash}`;

const getRedirectRouteByDoctorSlug = async (doctorSlug, influencer) => {
  const { data: response } = influencer
    ? await getDoctorBySlugAndInfluencer(doctorSlug, influencer)
    : await getDoctorBySlug(doctorSlug);

  if (response && response.id) {
    const { id, locale } = response;
    const doctorIdHash = getHashedId(id);

    return { path: getHashedQuestionnaireUrl(locale, doctorIdHash) };
  }

  return { name: 'PageNotFound' };
};

const handleDoctorSlug = async ({ params }, from, next) => {
  const { doctorSlug, influencer } = params;

  const url = await getRedirectRouteByDoctorSlug(doctorSlug, influencer);

  next({ ...url, query: { influencer } });
};

const getIsContinueQuestionnaireTimestampValid = timestamp => {
  if (!timestamp) {
    return false;
  }

  const decodedTimestamp = getDecodedId(timestamp)[0];

  const oneWeekAgo = new Date();
  const tenMinutesAgo = new Date();

  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  tenMinutesAgo.setMinutes(tenMinutesAgo.getMinutes() - 10);

  const expiredTimestamp = process.env.NODE_ENV === 'production' ? oneWeekAgo : tenMinutesAgo;
  const isLinkExpired = expiredTimestamp > decodedTimestamp;

  return !isLinkExpired;
};

const handleContinueQuestionnaire = ({ params }, from, next, { withSlug }) => {
  const { timestamp, doctorLang, doctorIdHash } = params;

  const isTimestampValid = getIsContinueQuestionnaireTimestampValid(timestamp);

  if (isTimestampValid) {
    next();

    return;
  }

  if (withSlug) {
    handleDoctorSlug({ params }, from, next);

    return;
  }

  const url = getHashedQuestionnaireUrl(doctorLang, doctorIdHash);

  next(url);
};

const continueQuestionnairePropsFunc = (route, withSlug) => {
  const props = { ...route.params };

  props.withSlug = withSlug;

  return props;
};

export const routes = [
  /* Root level urls */
  {
    path: '/diag/:doctorSlug/:influencer?',
    beforeEnter: handleDoctorSlug
  },
  {
    path: '/diag/:doctorSlug/:lang/:hash/:timestamp',
    component: () => import('@/views/PageRestoreQuestionnaire'),
    beforeEnter: (...params) => handleContinueQuestionnaire(...params, { withSlug: true }),
    props: route => continueQuestionnairePropsFunc(route, true)
  },
  {
    path: '/q4d/:lang/:doctorHash',
    redirect: ({ params: { lang, doctorHash } }) => ({
      name: 'QuestionnaireForDoctor',
      params: { lang, hash: doctorHash }
    })
  },
  {
    path: '/questionnaire/doctor/:doctorHash',
    redirect: ({ params: { doctorHash } }) => ({
      name: 'QuestionnaireForDoctor',
      params: { lang: 'en', hash: doctorHash }
    })
  },

  /* Module-prefixed urls */
  {
    path: '',
    redirect: { name: 'PageNotFound' }
  },
  {
    path: 'd/:doctorLang/:doctorIdHash/:lang/:hash/:timestamp',
    component: () => import('@/views/PageRestoreQuestionnaire'),
    beforeEnter: (...params) => handleContinueQuestionnaire(...params, { withSlug: false }),
    props: route => continueQuestionnairePropsFunc(route, false)
  },
  {
    path: 'd/:lang/:hash',
    name: 'QuestionnaireForDoctor',
    component: () => import('@/modules/questionnaire/pages/PageIntro.vue')
  },
  {
    path: 'p/:lang/:hash',
    name: 'QuestionnaireForPatient',
    component: () => import('@/modules/questionnaire/pages/PageIntro.vue')
  },
  {
    path: 'get-acquainted/:doctorHash',
    name: 'GetAcquainted',
    props: true,
    component: () => import('@/modules/questionnaire/pages/PageLetsGetAcquainted.vue')
  },
  {
    path: 'lets-make-a-selfie',
    name: 'LetsMakeASelfie',
    props: true,
    component: () => import('@/modules/questionnaire/pages/PageLetsMakeASelfie.vue')
  },
  {
    path: 'take-photo',
    name: 'TakeAPhoto',
    props: true,
    component() {
      return import('@/modules/questionnaire/pages/PageTakeAPhoto.vue');
    }
  },
  {
    path: 'email-verification',
    name: 'QuestionnaireEmailVerification',
    component: () => import('@/modules/questionnaire/pages/PageEmailVerification.vue')
  },
  {
    path: 'doctor-connection',
    name: 'ConnectWithOtherDoctor',
    component: () => import('@/modules/questionnaire/pages/PageConnectWithOtherDoctor.vue')
  },
  {
    path: 'data-safety',
    name: 'DataIsSafeWithUs',
    component: () => import('@/modules/questionnaire/pages/PageYourDataIsSafe.vue'),
    beforeEnter: beforeEnterQuestionnaire
  },
  {
    path: 'select-your-diagnostic',
    name: 'SelectYourDiagnostic',
    beforeEnter: beforeEnterQuestionnaire,
    component: () =>
      import('@/modules/questionnaire/pages/select-your-diagnostic/PageSelectYourDiagnostic.vue')
  },
  {
    path: 'upload-photo',
    name: 'PageLetsMakeASelfie',
    beforeEnter: beforeEnterQuestionnaire,
    component: () => import('@/modules/questionnaire/pages/PageLetsMakeASelfie')
  },
  {
    path: 'your-photo',
    name: 'WeHaveGotYourPhoto',
    beforeEnter: beforeEnterQuestionnaire,
    component: () => import('@/modules/questionnaire/pages/PageWeHaveGotYourPhoto')
  },
  {
    path: 'face-scanner',
    name: 'FaceScanner',
    beforeEnter: beforeEnterQuestionnaire,
    component: () => import('@/modules/questionnaire/pages/PageFaceScanner')
  },
  {
    path: 'questions',
    name: 'Questions',
    component: () => import('@/modules/questionnaire/pages/PageQuestionnaire.vue'),
    beforeEnter: beforeEnterQuestionnaire
  },
  {
    path: 'congratulations',
    name: 'Congratulations',
    component: () => import('@/modules/questionnaire/pages/PageCongratulations.vue'),
    beforeEnter: beforeEnterQuestionnaire
  }
];
