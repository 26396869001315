import { updateField } from 'vuex-map-fields';

import { getInitialState } from '@/modules/questionnaire/store/state';

import { questTypes } from '@/modules/questionnaire/store/types';

export default {
  [questTypes.mutations.RESET_MODULE](state) {
    Object.assign(state, getInitialState());
  },

  [questTypes.mutations.RESET_REQUESTED_DOCTOR](state) {
    state.requestedDoctor = {};
  },

  // STATUS

  [questTypes.mutations.SET_LOADING](state, loading) {
    state.status.loading = loading;
  },

  // QUESTIONNAIRE START
  [questTypes.mutations.SET_ACCESS_DETAILS](state, details) {
    state.accessDetails = details;
  },

  // DOCTOR

  [questTypes.mutations.SET_DOCTOR](state, doctor) {
    state.doctor = doctor;
  },

  [questTypes.mutations.SET_REQUESTED_DOCTOR](state, requestedDoctor) {
    state.requestedDoctor = requestedDoctor;
  },

  // PATIENT

  [questTypes.mutations.SET_PATIENT](state, patient) {
    state.patient = patient;
  },

  // CONSENT

  [questTypes.mutations.SET_CONSENT](state, consent) {
    state.consent = consent;
  },

  // QUESTIONNAIRE

  [questTypes.mutations.SET_QUESTIONNAIRE](state, questionnaire) {
    state.questionnaire = questionnaire;
  },

  [questTypes.mutations.SET_QUESTIONNAIRE_CREATION_DATE](state, creationDate) {
    state.questionnaire.creationDate = creationDate;
  },

  [questTypes.mutations.SET_QUESTIONNAIRE_TYPE](state, type) {
    state.questionnaire.type = type;
  },

  [questTypes.mutations.SET_SKIPPED_SECTIONS](state, skippedSections) {
    state.questionnaire.skippedSections = skippedSections;
  },

  // QUESTIONNAIRE - CONTEXT

  [questTypes.mutations.SET_CONTEXT](state, context) {
    state.questionnaire.context = context;
  },

  [questTypes.mutations.SET_CURRENT_STEP](state, step) {
    state.questionnaire.context.currentStep = step;
  },

  [questTypes.mutations.SET_FURTHEST_STEP](state, step) {
    state.questionnaire.context.furthestStep = step;
  },

  [questTypes.mutations.SET_ANSWERED_STEPS](state, steps) {
    state.questionnaire.context.answeredSteps = steps;
  },

  // QUESTIONNAIRE - PROPERTIES

  [questTypes.mutations.SET_FIELDS](state, fields) {
    state.questionnaire.fields = fields;
  },

  [questTypes.mutations.SET_FIELD](state, field) {
    updateField(state.questionnaire.fields, field);
  },

  [questTypes.mutations.ADD_PHOTO_TYPE](state, phototype) {
    state.questionnaire.photos.push(phototype);
  },

  [questTypes.mutations.REMOVE_PHOTO_TYPE](state, phototype) {
    const { photos } = state.questionnaire;
    const index = photos.indexOf(phototype);

    if (index > -1) {
      state.questionnaire.photos.splice(index, 1);
    }
  },

  [questTypes.mutations.SET_SCORES](state, scores) {
    state.scores = scores;
  },

  [questTypes.mutations.SET_CONTEXT_SOURCE](state, source) {
    state.questionnaire.context.source = source;
  },

  [questTypes.mutations.SET_CONTEXT_USAGE](state, usage) {
    state.questionnaire.context.usage = usage;
  },

  [questTypes.mutations.SET_IS_PHOTO_CONSENTS_ACCEPTED](state, answer) {
    state.questionnaire.isPhotoConsentsAccepted = answer;
  },

  [questTypes.mutations.SET_IS_CONSENTS_ACCEPTED](state, answer) {
    state.questionnaire.isConsentsAccepted = answer;
  },

  [questTypes.mutations.SET_INFLUENCER](state, influencer) {
    state.influencer = influencer;
  },

  [questTypes.mutations.SET_PHOTO_ANALYSIS_DIAGNOSTIC](state, photoAnalysisDiagnostic) {
    state.photoAnalysisDiagnostic = photoAnalysisDiagnostic;
  },

  [questTypes.mutations.SET_INTRO_TEXTS](state, introTexts) {
    state.introTexts = introTexts;
  },

  [questTypes.mutations.SAVE_PATIENT_PHOTO](state, photo) {
    state.patientPhoto = photo;
  },

  [questTypes.mutations.SET_ALLOW_PHOTO_RESEARCH](state, allowPhotoResearch) {
    state.allowPhotoResearch = allowPhotoResearch;
  }
};
