const identityFields = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  phoneIsoCode: '',
  smsAccepted: false,
  marketingSmsOffersAccepted: false,

  consent: {
    implied: false,

    medicalUse: true,
    marketingUse: false
  },

  dateOfBirth: '',
  age: '',
  gender: '',
  genotype: '',
  eyeColor: '',
  naturalHairColor: '',
  skinColor: '',
  skinType: '',
  skinTypeNorDryOilyComb: '',
  phototype: '',
  ethnicity: [],
  otherEthnicity: '',
  skinThickness: ''
};

const medicalBackgroundFields = {
  pregnant: '',
  menopausalState: '',
  takingHRT: '',
  takingBirthControlPill: '',
  birthControlPillName: '',
  usesIUD: '',
  takingHormoneSupplements: '',
  treatedForSkinCancer: '',
  glandularCancer: '',
  breastCancer: '',
  hasHerpes: '',
  sufferedFromAcneRosacea: '',
  treatedForAcne: '',
  acneTreatments: [],
  usingBenzoylPeroxide: '',
  benzoylPeroxideUseDuration: '',
  benzoylPeroxideUseHelped: '',
  usingSalicylicAcid: '',
  salicylicAcidUseDuration: '',
  salicylicAcidUseHelped: '',
  usingSulfur: '',
  sulfurUseDuration: '',
  sulfurUseHelped: '',
  usingTopicalRetinol: '',
  topicalRetinolUseDuration: '',
  topicalRetinolUseHelped: '',
  counterProductName: '',
  usingCounterProduct: '',
  counterProductUseDuration: '',
  counterProductUseHelped: '',
  usingClindamycin: '',
  clindamycinUseDuration: '',
  clindamycinUseHelped: '',
  usingErythromycin: '',
  erythromycinUseDuration: '',
  erythromycinUseHelped: '',
  topicalAntibioticName: '',
  usingTopicalAntibiotic: '',
  topicalAntibioticUseDuration: '',
  topicalAntibioticUseHelped: '',
  usingDoxicycline: '',
  doxicyclineUseDuration: '',
  doxicyclineUseHelped: '',
  usingMinocycline: '',
  minocyclineUseDuration: '',
  minocyclineUseHelped: '',
  usingTetracycline: '',
  tetracyclineUseDuration: '',
  tetracyclineUseHelped: '',
  oralAntibioticsName: '',
  usingOralAntibiotics: '',
  oralAntibioticUseDuration: '',
  oralAntibioticUseHelped: '',
  usingTopicalRetinoid: '',
  topicalRetinoidUseDuration: '',
  topicalRetinoidUseHelped: '',
  usingOralRetinoid: '',
  oralRetinoidUseDuration: '',
  oralRetinoidUseHelped: '',
  usingOralContraceptives: '',
  oralContraceptiveUseDuration: '',
  oralContraceptiveUseHelped: '',
  allergyTypes: [],
  foodAllergies: [],
  otherFoodAllergies: '',
  medicationAllergies: [],
  otherMedicationAllergies: '',
  environmentalAllergies: [],
  otherEnvironmentalAllergies: '',
  substanceAllergies: [],
  otherSubstanceAllergies: '',
  cosmeticAllergies: '',
  toleratesExfoliantActives: '',
  useOtherExfoliants: '',
  prolongedHealingTime: '',
  otherMedicalConditions: '',
  medicalConditions: '',
  underMedications: '',
  medicationsUsed: '',
  skinReactsToProducts: '',
  safeCleansers: []
};

const oxidativeStressFields = {
  cigaretteIntake: '',
  alcoholIntake: '',
  regularExercise: '',
  currentStressLevel: '',
  sleepDeprived: '',
  localAirQuality: '',
  usualEnvironment: [],
  countryOfResidence: '',
  cityOfResidence: '',
  temperature: '',
  plannedHighUVExposure: '',
  alwaysUsesSunscreen: '',
  currentlyTanned: '',
  processedFoodIntake: '',
  antiOxidantIntake: ''
};

const myDoctorFields = {
  alreadyPatient: '',
  isAssociatedWithClinic: '',
  wantToConnectWithClinic: ''
};

const skinAppearanceFields = {
  thinningSkin: '',
  thinningSkinLocations: [],
  saggingSkin: '',
  saggingSkinLocations: [],
  flushesDueToMenopause: '',
  faceSaggingLocations: [],
  dryingSkin: '',
  dryOpenPores: '',
  wrinkles: '',
  foreheadLinesDepth: '0',
  frownLinesDepth: '0',
  crowsFeetDepth: '0',
  bunnyLinesDepth: '0',
  tearThroughDepth: '0',
  nasolabialFoldsDepth: '0',
  verticalLipLinesDepth: '0',
  mouthFrownDepth: '0',
  marionetteLinesDepth: '0',
  chinCreaseDepth: '0',
  neckWrinkleDepth: '0',
  darkCircles: '',
  darkCircleColors: [],
  darkCirclesChildhood: '',
  eyeBags: '',
  eyeBagsType: '',
  cellulite: '',
  celluliteLocations: [],
  otherCelluliteLocations: '',
  stretchMarks: '',
  stretchMarkLocations: [],
  otherStretchMarkLocations: '',
  acneScars: '',
  acneScarCauses: [],
  acneScarLocations: [],
  otherAcneScarLocations: '',
  faceScarsAppearance: '',
  backScarsAppearance: '',
  chestScarsAppearance: '',
  mishapScarsSize: '',
  mishapScarLocations: [],
  otherMishapScarLocations: '',
  skinAppearanceDiscomfort: ''
};

const skinRednessFields = {
  sensitiveSkin: '',
  flushes: '',
  hotFlushes: '',
  flushLocations: [],
  otherFlushLocations: '',
  flushesDuration: '',
  skinRedness: '',
  rednessLocations: [],
  otherRednessLocations: '',
  rednessLimitedToPimples: '',
  rednessDiscomfortFrequency: '',
  rednessDuration: '',
  prominentBloodVessels: '',
  bloodVesselLocations: [],
  otherBloodVesselLocations: '',
  firstBloodVesselOccurrance: '',
  facialSwelling: '',
  inflammationDiscomfort: ''
};

const skinDrynessFields = {
  skinDryness: '',
  flakingSkin: '',
  drynessLocations: '',
  facialDrynessLocations: '',
  drynessCausedByClimate: '',
  drynessOccurrence: [],
  drynessDiscomfort: ''
};

const skinOilinessFields = {
  greasySkin: '',
  openFacialPores: '',
  excessShine: '',
  greasySkinLocations: '',
  onlyGreasyInSummer: '',
  greasySkinDiscomfort: ''
};

const skinTextureFields = {
  comedones: '',
  comedoLocations: [],
  otherComedoLocations: '',
  skinRoughness: '',
  exfoliateSkin: '',
  chemicalPeeling: '',
  tinyBumps: '',
  tinyBumpLocations: [],
  otherTinyBumpLocations: '',
  patches: '',
  patchesType: '',
  patchCauses: [],
  firstPatchOccurrences: '',
  patchLocations: [],
  otherPatchLocations: '',
  textureDiscomfort: ''
};

const pimplesFields = {
  proneToAcne: '',
  facialAcneCoverage: '',
  acneLocations: [],
  otherAcneLocations: '',
  acneTypes: [],
  concurrentNodules: '',
  acneDiscomfortFrequency: '',
  firstAcneOccurrence: '',
  acneDiscomfort: ''
};

const skinPigmentationFields = {
  hyperpigmentation: '',
  spotShapes: '',
  firstSpotsOccurrence: [],
  spotLocations: [],
  otherSpotLocations: '',
  facialSpotLocations: [],
  wishesLightening: '',
  hyperpigmentationDiscomfort: ''
};

const getInitialQuestionnaireFields = () => ({
  ...identityFields,
  soughtTreatments: ['face'],
  ...medicalBackgroundFields,
  ...oxidativeStressFields,
  ...skinAppearanceFields,
  ...skinRednessFields,
  ...skinDrynessFields,
  ...skinOilinessFields,
  ...skinTextureFields,
  ...pimplesFields,
  ...skinPigmentationFields,
  ...myDoctorFields
});

export default getInitialQuestionnaireFields;
