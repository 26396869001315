const uploadDisplayNamesPolyfillLocale = locales => {
  const script = document.createElement('script');
  const langs = locales.map(locale => locale.split('-')[0]);
  const langsUniq = Array.from(new Set(langs));
  const features = langsUniq.map(lang => `Intl.DisplayNames.~locale.${lang}`).join(',');
  script.setAttribute(
    'src',
    `https://polyfill.io/v3/polyfill.min.js?features=Intl.DisplayNames,${features}`
  );
  script.setAttribute('crossorigin', 'anonymous');
  document.body.append(script);
};

uploadDisplayNamesPolyfillLocale(navigator.languages);
