import format from 'date-fns/format';
import differenceInDays from 'date-fns/difference_in_days';

import enLocale from 'date-fns/locale/en';
import frLocale from 'date-fns/locale/fr';
import ruLocale from 'date-fns/locale/ru';
import esLocale from 'date-fns/locale/es';

import { i18n } from '@/i18n';

import { CHRONO_TYPES, DEFAULT_DATE_PATTERN } from '@/constants';

const dateLocales = {
  en: enLocale,
  fr: frLocale,
  ru: ruLocale,
  es: esLocale,
  'es-MX': esLocale
};

export const formatDate = (date, pattern) => {
  const formatPattern = pattern || DEFAULT_DATE_PATTERN;

  return date ? format(date, formatPattern, { locale: dateLocales[i18n.locale] }) : '';
};

const getChronoTypesHours = value => {
  if (value === 1) {
    return 'hour';
  }
  if (value === 21) {
    return 'twentyOneHours';
  }
  if (value >= 5 && value <= 20) {
    return 'severalHours';
  }
  if ((value >= 2 && value <= 4) || (value >= 22 && value <= 24)) {
    return 'fewHours';
  }

  return '';
};

const getChronoTypesDays = value => {
  if (value === 1) {
    return 'day';
  }
  if (value === 21 || value === 31) {
    return 'twentyOneDays';
  }
  if ((value >= 5 && value <= 20) || (value >= 24 && value <= 30)) {
    return 'severalDays';
  }
  if ((value >= 2 && value <= 4) || (value >= 22 && value <= 24)) {
    return 'fewDays';
  }

  return '';
};

const getChronoTypesMonths = value => {
  if (value === 1) {
    return 'month';
  }
  if (value > 1) {
    return 'severalMonths';
  }

  return '';
};

export const formatInPastDate = ({ value, chronoType }, date) => {
  const translationPrefix = 'dashboard.activity.';
  let translationKey;

  switch (chronoType) {
    case CHRONO_TYPES.MINUTES: {
      translationKey = 'min';
      break;
    }
    case CHRONO_TYPES.HOURS: {
      translationKey = getChronoTypesHours(value);
      break;
    }
    case CHRONO_TYPES.DAYS: {
      translationKey = getChronoTypesDays(value);
      break;
    }
    case CHRONO_TYPES.MONTHS: {
      translationKey = getChronoTypesMonths(value);
      break;
    }
    case CHRONO_TYPES.YEARS: {
      translationKey = 'year';
      break;
    }
    default: {
      return formatDate(date);
    }
  }

  if (translationKey === '') {
    return formatDate(date);
  }

  translationKey = `${translationPrefix}${translationKey}`;

  return `${value} ${i18n.t(translationKey)}`;
};

const getDaysTranslationKey = days => {
  const translationPrefix = 'dashboard.counter.';

  const units = days % 10;
  const dozens = days % 100;
  const isDecades = dozens >= 10 && dozens < 20;

  if (!isDecades && units === 1) {
    return `${translationPrefix}startsInFewOneDays`;
  }

  const isFromTwoToFour = units >= 2 && units <= 4;

  if (!isDecades && isFromTwoToFour) {
    return `${translationPrefix}startsInSeveralDays`;
  }

  return `${translationPrefix}days`;
};

export const getDaysTranslationWithNumber = ($t, numberOfDays = 0) => {
  const daysTranslationKey = getDaysTranslationKey(numberOfDays);

  return `${numberOfDays} ${$t(daysTranslationKey)}`;
};

export const getDifferenceInDays = (firstDate, secondDate) =>
  differenceInDays(firstDate, secondDate);
