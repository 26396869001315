import axios from 'axios';
import { get } from 'lodash';

import { LocalStorageService } from '@/services/LocalStorageService';

import { BACKEND_VERSION_HEADER } from '@/constants';

const checkBackendVersionInterceptor = response => {
  const storageVersion = LocalStorageService.getBackendVersion();

  const responseVersion = get(response, ['headers', BACKEND_VERSION_HEADER], null);

  if (!storageVersion) {
    LocalStorageService.setBackendVersion(responseVersion);
  }

  if (storageVersion && responseVersion && storageVersion !== responseVersion) {
    localStorage.clear();
    LocalStorageService.setBackendVersion(responseVersion);

    window.location.reload();
  }

  return Promise.resolve(response);
};

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    common: {
      'Content-Type': 'application/json'
    }
  },
  validateStatus() {
    // by default it trows an error for any status >= 400
    // but we handle all status codes in our application
    return true;
  }
});

axiosInstance.interceptors.response.use(checkBackendVersionInterceptor);

export default axiosInstance;
