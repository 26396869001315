<template>
  <div v-if="isLoading" class="rootContainer">
    <infinite-loading class="loader" spinner="waveDots" />
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'Loader',
  components: {
    InfiniteLoading
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.rootContainer {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;

  background-color: rgb(48, 48, 48);
  opacity: 0.5;

  z-index: 999;
}

.loader {
  width: 100px;
}
</style>
