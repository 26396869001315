import InputObject from '@/components/common/SelectOptionObject';

const COUNTRIES = {
  ad: {
    name: 'Andorra',
    numericCode: '020',
    alpha2Code: 'ad',
    alpha3Code: 'and',
    independent: true,
    cities: ['andorra_la_vella']
  },
  ae: {
    name: 'United Arab Emirates',
    numericCode: '784',
    alpha2Code: 'ae',
    alpha3Code: 'are',
    independent: true,
    cities: ['abu_dhabi', 'dubai', 'sharjah']
  },
  af: {
    name: 'Afghanistan',
    numericCode: '004',
    alpha2Code: 'af',
    alpha3Code: 'afg',
    independent: true,
    cities: [
      'farah',
      'ghazni',
      'herat',
      'kabul',
      'kandahar',
      'kunduz',
      'lashkar_gah',
      'mazari_sharif'
    ],
    capital: 'kabul',
    currency: {
      code: 'AFN',
      name: 'afghani',
      symbol: '\u060B'
    },
    languages: ['pashto', 'dari'],
    callingCode: '93'
  },
  ag: {
    name: 'Antigua and Barbuda',
    numericCode: '028',
    alpha2Code: 'ag',
    alpha3Code: 'atg',
    independent: true,
    cities: ['liberta']
  },
  al: {
    name: 'Albania',
    numericCode: '008',
    alpha2Code: 'al',
    alpha3Code: 'ala',
    independent: true,
    cities: ['berat', 'korce', 'pogradec', 'sarande', 'shkoder', 'tirana', 'vlore'],
    capital: 'tirana',
    currency: {
      code: 'ALL',
      name: 'lek',
      sign: 'L',
      symbol: 'LEK'
    },
    languages: ['albanian'],
    callingCode: '355'
  },
  am: {
    name: 'Armenia',
    numericCode: '051',
    alpha2Code: 'am',
    alpha3Code: 'arm',
    independent: true,
    cities: [
      'alaverdi',
      'artik',
      'dilijan',
      'gyumri',
      'kapan',
      'martuni',
      'sevan',
      'sisian',
      'spitak',
      'vagarshapat',
      'vanadzor',
      'vardenis',
      'vedi',
      'yerevan'
    ]
  },
  ao: {
    name: 'Angola',
    numericCode: '024',
    alpha2Code: 'ao',
    alpha3Code: 'ago',
    independent: true,
    cities: ['dundo', 'huambo', 'lobito', 'luanda', 'malanje', 'menongue', 'namacunde', 'namibe']
  },
  ar: {
    name: 'Argentina',
    numericCode: '032',
    alpha2Code: 'ar',
    alpha3Code: 'arg',
    independent: true,
    cities: [
      'azul',
      'bahia_blanca',
      'buenos_aires',
      'comodoro_rivadavia',
      'cordoba',
      'el_calafate',
      'formosa',
      'gobernador_gregores',
      'la_rioja',
      'las_grutas',
      'mar_del_plata',
      'mendoza',
      'neuquen',
      'rio_gallegos',
      'rosario',
      'salta',
      'san_carlos_de_bariloche',
      'san_miguel_de_tucuman',
      'trelew'
    ]
  },
  at: {
    name: 'Austria',
    numericCode: '040',
    alpha2Code: 'at',
    alpha3Code: 'aut',
    independent: true,
    cities: [
      'bregenz',
      'eisenstadt',
      'graz',
      'hallein',
      'innsbruck',
      'klagenfurt',
      'linz',
      'salzburg',
      'st_polten',
      'vienna',
      'villach'
    ]
  },
  au: {
    name: 'Australia',
    numericCode: '036',
    alpha2Code: 'au',
    alpha3Code: 'aus',
    independent: true,
    cities: [
      'adelaide',
      'albany',
      'alice_springs',
      'brisbane',
      'carins',
      'canberra',
      'darwin',
      'exmouth',
      'hobart',
      'melbourne',
      'perth',
      'sydney'
    ]
  },
  az: {
    name: 'Azerbaijan',
    numericCode: '031',
    alpha2Code: 'az',
    alpha3Code: 'aze',
    independent: true,
    cities: [
      'baku',
      'barda',
      'ganga',
      'lankaran',
      'mingecevir',
      'quba',
      'sheki',
      'shirvan',
      'sumgayit'
    ]
  },
  ba: {
    name: 'Bosnia and Herzegovina',
    numericCode: '070',
    alpha2Code: 'ba',
    alpha3Code: 'bih',
    independent: true,
    cities: ['mostar', 'sarajevo', 'tuzla']
  },
  bb: {
    name: 'Barbados',
    numericCode: '052',
    alpha2Code: 'bb',
    alpha3Code: 'brb',
    independent: true,
    cities: ['bridgetown']
  },
  bd: {
    name: 'Bangladesh',
    numericCode: '050',
    alpha2Code: 'bd',
    alpha3Code: 'bgd',
    independent: true,
    cities: ['barisal', 'chittagong', 'dahka', 'jamalpur', 'rajshahi', 'rangpur', 'sylhet']
  },
  be: {
    name: 'Belgium',
    numericCode: '056',
    alpha2Code: 'be',
    alpha3Code: 'bel',
    independent: true,
    cities: [
      'brussels',
      'antwerp',
      'ghent',
      'charleroi',
      'liege',
      'bruges',
      'namur',
      'leuwen',
      'mons',
      'deurne'
    ]
  },
  bf: {
    name: 'Burkina Faso',
    numericCode: '854',
    alpha2Code: 'bf',
    alpha3Code: 'bfa',
    independent: true,
    cities: ['bobo_dioulasso', 'koudougou', 'ouahigouya']
  },
  bg: {
    name: 'Bulgaria',
    numericCode: '100',
    alpha2Code: 'bg',
    alpha3Code: 'bgr',
    independent: true,
    cities: ['pleven', 'plovdiv', 'sofia', 'varna']
  },
  bh: {
    name: 'Bahrain',
    numericCode: '048',
    alpha2Code: 'bh',
    alpha3Code: 'bhr',
    independent: true,
    cities: ['manama', 'riffa']
  },
  bi: {
    name: 'Burundi',
    numericCode: '108',
    alpha2Code: 'bi',
    alpha3Code: 'bdi',
    independent: true,
    cities: ['giteranyi', 'merhe']
  },
  bj: {
    name: 'Benin',
    numericCode: '204',
    alpha2Code: 'bj',
    alpha3Code: 'ben',
    independent: true,
    cities: ['parakou']
  },
  bm: {
    name: 'Bermuda',
    numericCode: '060',
    alpha2Code: 'bm',
    alpha3Code: 'bmu',
    independent: true,
    cities: ['somerset']
  },
  bn: {
    name: 'Brunei Darussalam',
    numericCode: '096',
    alpha2Code: 'bn',
    alpha3Code: 'brn',
    independent: true,
    cities: ['seria', 'tutong']
  },
  bo: {
    name: 'Bolivia',
    numericCode: '068',
    alpha2Code: 'bo',
    alpha3Code: 'bol',
    independent: true,
    cities: ['cochabamba', 'la_paz', 'santa_cruiz_de_la_sierra']
  },
  br: {
    name: 'Brazil',
    numericCode: '076',
    alpha2Code: 'br',
    alpha3Code: 'bra',
    independent: true,
    cities: [
      'bazilia',
      'belem',
      'belo_horizonte',
      'caruaru',
      'curitiba',
      'goiania',
      'manaus',
      'porto_alegre',
      'rio_de_janeiro',
      'salvador',
      'sao_paolo'
    ]
  },
  bs: {
    name: 'Bahamas',
    numericCode: '044',
    alpha2Code: 'bs',
    alpha3Code: 'bhs',
    independent: true,
    cities: ['andros_town']
  },
  bt: {
    name: 'Bhutan',
    numericCode: '064',
    alpha2Code: 'bt',
    alpha3Code: 'btn',
    independent: true,
    cities: ['thimphu']
  },
  bw: {
    name: 'Botswana',
    numericCode: '072',
    alpha2Code: 'bw',
    alpha3Code: 'bwa',
    independent: true,
    cities: ['gaborone']
  },
  by: {
    name: 'Belarus',
    numericCode: '112',
    alpha2Code: 'by',
    alpha3Code: 'blr',
    independent: true,
    cities: ['babruysk', 'brest', 'minsk', 'viciebsk']
  },
  bz: {
    name: 'Belize',
    numericCode: '084',
    alpha2Code: 'bz',
    alpha3Code: 'blz',
    independent: true,
    cities: ['belize_city', 'belmopan']
  },
  ca: {
    name: 'Canada',
    numericCode: '124',
    alpha2Code: 'ca',
    alpha3Code: 'can',
    independent: true,
    cities: [
      'calgary',
      'edmonton',
      'montreal',
      'ottawa',
      'quebec_city',
      'toronto',
      'vancouver',
      'winnipeg'
    ]
  },
  cf: {
    name: 'Central African Republic',
    numericCode: '140',
    alpha2Code: 'cf',
    alpha3Code: 'caf',
    independent: true,
    cities: ['bocaranga', 'samba', 'tissi']
  },
  cg: {
    name: 'Congo',
    numericCode: '178',
    alpha2Code: 'cg',
    alpha3Code: 'cog',
    independent: true,
    cities: ['kindu', 'kinshasa', 'lubummashi']
  },
  ch: {
    name: 'Switzerland',
    numericCode: '756',
    alpha2Code: 'ch',
    alpha3Code: 'che',
    independent: true,
    cities: [
      'basel',
      'bern',
      'geneva',
      'lugano',
      'zurich',
      'lausanne',
      'sion',
      'neuchâtel',
      'fribourg',
      'sierre'
    ]
  },
  ci: {
    name: "Côte d'Ivoire",
    numericCode: '384',
    alpha2Code: 'ci',
    alpha3Code: 'civ',
    independent: true,
    cities: ['abidjan', 'bondoukou', 'korhogo', 'man', 'san_pedro', 'soubre', 'yamoussoukro']
  },
  ck: {
    name: 'Cook Islands',
    numericCode: '184',
    alpha2Code: 'ck',
    alpha3Code: 'cok',
    independent: true,
    cities: ['avarua']
  },
  cl: {
    name: 'Chile',
    numericCode: '152',
    alpha2Code: 'cl',
    alpha3Code: 'chl',
    independent: true,
    cities: ['castro', 'santiago']
  },
  cm: {
    name: 'Cameroon',
    numericCode: '120',
    alpha2Code: 'cm',
    alpha3Code: 'cmr',
    independent: true,
    cities: ['douala', 'yaounde']
  },
  cn: {
    name: 'China',
    numericCode: '156',
    alpha2Code: 'cn',
    alpha3Code: 'chn',
    independent: true,
    cities: [
      'beijing',
      'changchun',
      'chengdu',
      'chongqing',
      'fuzhou',
      'ganzhou',
      'guangzhou',
      'harbin',
      'jilin',
      'jinan',
      'nanchang',
      'shanghai',
      'shijizhuang',
      'tianjin',
      'xi_an',
      'shenzhen',
      'hong_kong',
      'macau'
    ]
  },
  co: {
    name: 'Colombia',
    numericCode: '170',
    alpha2Code: 'co',
    alpha3Code: 'col',
    independent: true,
    cities: ['barranquilla', 'bogota', 'cali', 'cartagena', 'medellin', 'tulua']
  },
  cr: {
    name: 'Costa Rica',
    numericCode: '188',
    alpha2Code: 'cr',
    alpha3Code: 'cri',
    independent: true,
    cities: ['cartago', 'liberia', 'limon', 'san_antonio', 'san_jose']
  },
  cv: {
    name: 'Cabo Verde',
    numericCode: '132',
    alpha2Code: 'cv',
    alpha3Code: 'cpv',
    independent: true,
    cities: ['mindelo']
  },
  cy: {
    name: 'Cyprus',
    numericCode: '196',
    alpha2Code: 'cy',
    alpha3Code: 'cyp',
    independent: true,
    cities: ['limassol', 'nicosia']
  },
  cz: {
    name: 'Czechia',
    numericCode: '203',
    alpha2Code: 'cz',
    alpha3Code: 'cze',
    independent: true,
    cities: ['brno', 'libereco', 'ostrava', 'prague']
  },
  de: {
    name: 'Germany',
    numericCode: '276',
    alpha2Code: 'de',
    alpha3Code: 'deu',
    independent: true,
    cities: [
      'berlin',
      'dresden',
      'dusseldorf',
      'frankfurt',
      'hamburg',
      'hanover',
      'munich',
      'nuremberg',
      'stuttgart'
    ]
  },
  dj: {
    name: 'Djibouti',
    numericCode: '262',
    alpha2Code: 'dj',
    alpha3Code: 'dji',
    independent: true,
    cities: ['diyara', 'djibouti', 'obock', 'randa']
  },
  dk: {
    name: 'Denmark',
    numericCode: '208',
    alpha2Code: 'dk',
    alpha3Code: 'dnk',
    independent: true,
    cities: ['aalborg', 'aarhus', 'copenhagen', 'esbjerg', 'herning', 'odense', 'randers']
  },
  dm: {
    name: 'Dominica',
    numericCode: '212',
    alpha2Code: 'dm',
    alpha3Code: 'dma',
    independent: true,
    cities: ['penville']
  },
  do: {
    name: 'Dominican Republic',
    numericCode: '214',
    alpha2Code: 'do',
    alpha3Code: 'dom',
    independent: true,
    cities: ['santo_domingo']
  },
  dz: {
    name: 'Algeria',
    numericCode: '012',
    alpha2Code: 'dz',
    alpha3Code: 'dza',
    independent: true,
    cities: [
      'adrar',
      'algiers',
      'biskra',
      'bordj_bou_arreridj',
      'constantine',
      'djanet',
      'el_kala',
      'in_ghar',
      'ouargla',
      'reggane',
      'tamanrasset'
    ],
    capital: 'algiers',
    currency: {
      code: 'DZD',
      name: 'dinar'
    },
    officialLanguages: ['arabic', 'berber'],
    callingCode: '213'
  },
  ec: {
    name: 'Ecuador',
    numericCode: '218',
    alpha2Code: 'ec',
    alpha3Code: 'ecu',
    independent: true,
    cities: ['cuenca', 'guayaquil', 'loja', 'quito', 'salinas']
  },
  ee: {
    name: 'Estonia',
    numericCode: '233',
    alpha2Code: 'ee',
    alpha3Code: 'est',
    independent: true,
    cities: ['tallinn', 'tartu', 'valga']
  },
  eg: {
    name: 'Egypt',
    numericCode: '818',
    alpha2Code: 'eg',
    alpha3Code: 'egy',
    independent: true,
    cities: ['alexandria', 'aswan', 'asyut', 'cairo', 'luxor', 'marsa_matruh', 'port_said']
  },
  er: {
    name: 'Eritrea',
    numericCode: '232',
    alpha2Code: 'er',
    alpha3Code: 'eri',
    independent: true,
    cities: ['assab', 'barentu', 'keren']
  },
  es: {
    name: 'Spain',
    numericCode: '724',
    alpha2Code: 'es',
    alpha3Code: 'esp',
    independent: true,
    cities: ['barcelona', 'bilbao', 'madrid', 'malaga', 'murcia', 'palma', 'seville', 'valencia']
  },
  et: {
    name: 'Ethiopia',
    numericCode: '231',
    alpha2Code: 'et',
    alpha3Code: 'eth',
    independent: true,
    cities: ['addis_abeba', 'awassa', 'dessie', 'dire_dawa', 'gondar']
  },
  fi: {
    name: 'Finland',
    numericCode: '246',
    alpha2Code: 'fi',
    alpha3Code: 'fin',
    independent: true,
    cities: [
      'helsinki',
      'kuopio',
      'lahti',
      'lappeenrata',
      'oulu',
      'rovaniemi',
      'tampere',
      'turku',
      'vaasa'
    ]
  },
  fj: {
    name: 'Fiji',
    numericCode: '242',
    alpha2Code: 'fj',
    alpha3Code: 'fji',
    independent: true,
    cities: ['lautoka', 'suva']
  },
  fm: {
    name: 'Micronesia',
    numericCode: '253',
    alpha2Code: 'fm',
    alpha3Code: 'fsm',
    independent: true,
    cities: ['palikir']
  },
  fr: {
    name: 'France',
    numericCode: '250',
    alpha2Code: 'fr',
    alpha3Code: 'fra',
    independent: true,
    cities: [
      'bordeaux',
      'lille',
      'lyon',
      'marseille',
      'nantes',
      'nice',
      'paris',
      'pau',
      'strasbourg',
      'toulouse',
      'guadeloupe',
      'martinique',
      'french_guiana',
      'reunion',
      'mayotte',
      'saint_pierre_and_miquelon',
      'saint_barthelemy',
      'saint_martin',
      'wallis_and_futuna',
      'french_polynesia',
      'new_caledonia'
    ]
  },
  ga: {
    name: 'Gabon',
    numericCode: '266',
    alpha2Code: 'ga',
    alpha3Code: 'gab',
    independent: true,
    cities: ['franceville', 'mayumba', 'mekambo']
  },
  gb: {
    name: 'United Kingdom',
    numericCode: '826',
    alpha2Code: 'gb',
    alpha3Code: 'gbr',
    independent: true,
    cities: ['belfast', 'glasgow', 'inverness', 'liverpool', 'london', 'plymouth']
  },
  gd: {
    name: 'Grenada',
    numericCode: '308',
    alpha2Code: 'gd',
    alpha3Code: 'grd',
    independent: true,
    cities: ['morne_rouge']
  },
  ge: {
    name: 'Georgia',
    numericCode: '268',
    alpha2Code: 'ge',
    alpha3Code: 'geo',
    independent: true,
    cities: ['batumi', 'kutaisi', 'tbilisi', 'telavi']
  },
  gh: {
    name: 'Ghana',
    numericCode: '288',
    alpha2Code: 'gh',
    alpha3Code: 'gha',
    independent: true,
    cities: ['accra', 'kumasi']
  },
  gm: {
    name: 'Gambia',
    numericCode: '270',
    alpha2Code: 'gm',
    alpha3Code: 'gmb',
    independent: true,
    cities: ['basse_santa_su', 'farafenni', 'serrekunda']
  },
  gn: {
    name: 'Guinea',
    numericCode: '324',
    alpha2Code: 'gn',
    alpha3Code: 'gin',
    independent: true,
    cities: ['conakry', 'dinguiraye', 'kankan', 'labe']
  },
  gq: {
    name: 'Equatorial Guinea',
    numericCode: '226',
    alpha2Code: 'gq',
    alpha3Code: 'gnq',
    independent: true,
    cities: ['bata', 'mbini']
  },
  gr: {
    name: 'Greece',
    numericCode: '300',
    alpha2Code: 'gr',
    alpha3Code: 'grc',
    independent: true,
    cities: [
      'alexandroupoli',
      'athens',
      'corfu',
      'florina',
      'heraklion',
      'ioannina',
      'kalamata',
      'karditsa',
      'kavala',
      'kozani',
      'lamia',
      'larissa',
      'lefkas',
      'mitilini',
      'nafplio',
      'patras',
      'thessaloniki',
      'rhodes',
      'volos'
    ]
  },
  gt: {
    name: 'Guatemala',
    numericCode: '320',
    alpha2Code: 'gt',
    alpha3Code: 'gtm',
    independent: true,
    cities: ['guatemala_city']
  },
  gw: {
    name: 'Guinea-Bissau',
    numericCode: '624',
    alpha2Code: 'gw',
    alpha3Code: 'gnb',
    independent: true,
    cities: ['caio']
  },
  gy: {
    name: 'Guyana',
    numericCode: '328',
    alpha2Code: 'gy',
    alpha3Code: 'guy',
    independent: true,
    cities: ['aishalton', 'georgetown', 'linden']
  },
  hn: {
    name: 'Honduras',
    numericCode: '340',
    alpha2Code: 'hn',
    alpha3Code: 'hnd',
    independent: true,
    cities: ['san_pedro_sula']
  },
  hr: {
    name: 'Croatia',
    numericCode: '191',
    alpha2Code: 'hr',
    alpha3Code: 'hrv',
    independent: true,
    cities: ['osijek', 'pula', 'split', 'zadar', 'zagreb']
  },
  ht: {
    name: 'Haiti',
    numericCode: '332',
    alpha2Code: 'ht',
    alpha3Code: 'hti',
    independent: true,
    cities: ['port_au_prince']
  },
  hu: {
    name: 'Hungary',
    numericCode: '348',
    alpha2Code: 'hu',
    alpha3Code: 'hun',
    independent: true,
    cities: ['budapest', 'nyiregyhaza', 'pecs']
  },
  id: {
    name: 'Indonesia',
    numericCode: '360',
    alpha2Code: 'id',
    alpha3Code: 'idn',
    independent: true,
    cities: ['bandung', 'jakarta', 'kendiri', 'palu', 'samarinda']
  },
  ie: {
    name: 'Ireland',
    numericCode: '372',
    alpha2Code: 'ie',
    alpha3Code: 'irl',
    independent: true,
    cities: ['cork', 'dublin', 'galway', 'limerick', 'sligo', 'tralee', 'waterford']
  },
  il: {
    name: 'Israel',
    numericCode: '376',
    alpha2Code: 'il',
    alpha3Code: 'isr',
    independent: true,
    cities: ['beer_sheva', 'eilat', 'haifa', 'jerusalem', 'nazareth', 'tel_aviv_yafo']
  },
  in: {
    name: 'India',
    numericCode: '356',
    alpha2Code: 'in',
    alpha3Code: 'ind',
    independent: true,
    cities: [
      'agra',
      'ahmedabad',
      'bengaluru',
      'chennai',
      'coimbatore',
      'hyderabad',
      'indore',
      'jaipur',
      'kolkata',
      'lucknow',
      'mumbai',
      'new_delhi',
      'pune',
      'varanasi'
    ]
  },
  iq: {
    name: 'Iraq',
    numericCode: '368',
    alpha2Code: 'iq',
    alpha3Code: 'irq',
    independent: true,
    cities: ['baghdad', 'erbil', 'kirkuk', 'mosul', 'nasiriyah', 'suleimaniyah']
  },
  ir: {
    name: 'Iran',
    numericCode: '364',
    alpha2Code: 'ir',
    alpha3Code: 'irn',
    independent: true,
    cities: ['abadan', 'birjand', 'isfahan', 'mashhad', 'shiraz', 'tehran']
  },
  is: {
    name: 'Iceland',
    numericCode: '352',
    alpha2Code: 'is',
    alpha3Code: 'isl',
    independent: true,
    cities: ['grindavik', 'reykjavik', 'stodvarfjordur']
  },
  it: {
    name: 'Italy',
    numericCode: '380',
    alpha2Code: 'it',
    alpha3Code: 'ita',
    independent: true,
    cities: [
      'bari',
      'bologna',
      'brescia',
      'cagliari',
      'catania',
      'ferrara',
      'florence',
      'foggia',
      'genoa',
      'livorno',
      'messina',
      'milan',
      'modena',
      'naples',
      'padua',
      'palermo',
      'parma',
      'perugia',
      'prato',
      'ravenna',
      'reggio_calabria',
      'reggio_emilia',
      'rimini',
      'rome',
      'salerno',
      'taranto',
      'trieste',
      'turin',
      'venice',
      'verona'
    ]
  },
  jm: {
    name: 'Jamaica',
    numericCode: '388',
    alpha2Code: 'jm',
    alpha3Code: 'jam',
    independent: true,
    cities: ['kingston', 'sapporo']
  },
  jo: {
    name: 'Jordan',
    numericCode: '400',
    alpha2Code: 'jo',
    alpha3Code: 'jor',
    independent: true,
    cities: ['amman', 'irbid', 'karak', 'mabada']
  },
  jp: {
    name: 'Japan',
    numericCode: '392',
    alpha2Code: 'jp',
    alpha3Code: 'jpn',
    independent: true,
    cities: [
      'fukuoka',
      'hamamatsu',
      'hiroshima',
      'kitakyushu',
      'kumamoto',
      'kyoto',
      'nagasaki',
      'nagoya',
      'niigata',
      'okayama',
      'osaka',
      'sendai',
      'tokyo',
      'yokohama'
    ]
  },
  ke: {
    name: 'Kenya',
    numericCode: '404',
    alpha2Code: 'ke',
    alpha3Code: 'ken',
    independent: true,
    cities: ['kisiwani', 'malindi', 'mutomo', 'nairobi', 'sereolipi']
  },
  kg: {
    name: 'Kyrgyzstan',
    numericCode: '417',
    alpha2Code: 'kg',
    alpha3Code: 'kgz',
    independent: true,
    cities: ['bishkek', 'ivanovka', 'naryn', 'osh']
  },
  kh: {
    name: 'Cambodia',
    numericCode: '116',
    alpha2Code: 'kh',
    alpha3Code: 'khm',
    independent: true,
    cities: ['phnom_penh']
  },
  ki: {
    name: 'Kiribati',
    numericCode: '296',
    alpha2Code: 'ki',
    alpha3Code: 'kir',
    independent: true,
    cities: ['abatiku', 'tebaronga']
  },
  km: {
    name: 'Comoros',
    numericCode: '174',
    alpha2Code: 'km',
    alpha3Code: 'com',
    independent: true,
    cities: ['foumbouni']
  },
  kn: {
    name: 'Saint Kitts and Nevis',
    numericCode: '659',
    alpha2Code: 'kn',
    alpha3Code: 'kna',
    independent: true,
    cities: ['basseterre']
  },
  kp: {
    name: 'North Korea',
    numericCode: '408',
    alpha2Code: 'kp',
    alpha3Code: 'prk',
    independent: true,
    cities: ['chongjin', 'hamhung', 'hyesan', 'kaesong', 'kanggye', 'kimchaek', 'pyongyang']
  },
  kr: {
    name: 'South Korea',
    numericCode: '410',
    alpha2Code: 'kr',
    alpha3Code: 'kor',
    independent: true,
    cities: ['busan', 'gwangju', 'incheon', 'jeju', 'seoul']
  },
  kw: {
    name: 'Kuwait',
    numericCode: '414',
    alpha2Code: 'kw',
    alpha3Code: 'kwt',
    independent: true,
    cities: ['kuwait_city']
  },
  kz: {
    name: 'Kazakhstan',
    numericCode: '398',
    alpha2Code: 'kz',
    alpha3Code: 'kaz',
    independent: true,
    cities: ['aktobe', 'almaty', 'astana', 'pavlodar', 'shymkent', 'turkestan', 'ust_kamenogorsk']
  },
  la: {
    name: 'Laos',
    numericCode: '418',
    alpha2Code: 'la',
    alpha3Code: 'lao',
    independent: true,
    cities: ['attapeu', 'da_nang', 'dong_ha', 'muang_khong', 'pakse', 'thanh_hoi', 'vientiane']
  },
  lb: {
    name: 'Lebanon',
    numericCode: '422',
    alpha2Code: 'lb',
    alpha3Code: 'lbn',
    independent: true,
    cities: ['beirut', 'tripoli', 'tyre']
  },
  lc: {
    name: 'Saint Lucia',
    numericCode: '662',
    alpha2Code: 'lc',
    alpha3Code: 'lca',
    independent: true,
    cities: ['castries']
  },
  li: {
    name: 'Liechtenstein',
    numericCode: '438',
    alpha2Code: 'li',
    alpha3Code: 'lie',
    independent: true,
    cities: ['vaduz']
  },
  lk: {
    name: 'Sri Lanka',
    numericCode: '144',
    alpha2Code: 'lk',
    alpha3Code: 'lka',
    independent: true,
    cities: ['colombo', 'galle']
  },
  lr: {
    name: 'Liberia',
    numericCode: '430',
    alpha2Code: 'lr',
    alpha3Code: 'lbr',
    independent: true,
    cities: ['monrovia']
  },
  ls: {
    name: 'Lesotho',
    numericCode: '426',
    alpha2Code: 'ls',
    alpha3Code: 'lso',
    independent: true,
    cities: ['makaung']
  },
  lt: {
    name: 'Lithuania',
    numericCode: '440',
    alpha2Code: 'lt',
    alpha3Code: 'ltu',
    independent: true,
    cities: ['kaunas', 'klaipeda', 'vilnius']
  },
  lu: {
    name: 'Luxembourg',
    numericCode: '442',
    alpha2Code: 'lu',
    alpha3Code: 'lux',
    independent: true,
    cities: ['luxembourg']
  },
  lv: {
    name: 'Latvia',
    numericCode: '428',
    alpha2Code: 'lv',
    alpha3Code: 'lva',
    independent: true,
    cities: ['daugavpils', 'liepaja', 'riga']
  },
  ly: {
    name: 'Libya',
    numericCode: '434',
    alpha2Code: 'ly',
    alpha3Code: 'lby',
    independent: true,
    cities: ['benghazi', 'misrata', 'sabha', 'tripoli']
  },
  ma: {
    name: 'Morocco',
    numericCode: '504',
    alpha2Code: 'ma',
    alpha3Code: 'mar',
    independent: true,
    cities: ['agadir', 'casablanca', 'fes', 'guelmim', 'marrakesh', 'oujda', 'rabat']
  },
  mc: {
    name: 'Monaco',
    numericCode: '492',
    alpha2Code: 'mc',
    alpha3Code: 'mco',
    independent: true,
    cities: ['monaco']
  },
  md: {
    name: 'Moldova',
    numericCode: '498',
    alpha2Code: 'md',
    alpha3Code: 'mda',
    independent: true,
    cities: ['chisinau']
  },
  me: {
    name: 'Montenegro',
    numericCode: '499',
    alpha2Code: 'me',
    alpha3Code: 'mne',
    independent: true,
    cities: ['budva', 'kolasin', 'kotor', 'podgorica', 'zabljak']
  },
  mg: {
    name: 'Madagascar',
    numericCode: '450',
    alpha2Code: 'mg',
    alpha3Code: 'mdg',
    independent: true,
    cities: ['antananarivo', 'antsiranana', 'mahajanga', 'toliara']
  },
  mh: {
    name: 'Marshall Islands',
    numericCode: '584',
    alpha2Code: 'mh',
    alpha3Code: 'mhl',
    independent: true,
    cities: ['majuro']
  },
  mk: {
    name: 'Macedonia',
    numericCode: '807',
    alpha2Code: 'mk',
    alpha3Code: 'mkd',
    independent: true,
    cities: ['skopje']
  },
  ml: {
    name: 'Mali',
    numericCode: '466',
    alpha2Code: 'ml',
    alpha3Code: 'mli',
    independent: true,
    cities: ['bamako', 'dire', 'gao', 'kayes', 'mopti']
  },
  mm: {
    name: 'Myanmar',
    numericCode: '104',
    alpha2Code: 'mm',
    alpha3Code: 'mmr',
    independent: true,
    cities: ['mandalay', 'myitkyina', 'pathein', 'sittwe', 'yangon']
  },
  mn: {
    name: 'Mongolia',
    numericCode: '496',
    alpha2Code: 'mn',
    alpha3Code: 'mng',
    independent: true,
    cities: ['baruunturuun', 'ulaanbaatar', 'ulaangom']
  },
  mr: {
    name: 'Mauritania',
    numericCode: '478',
    alpha2Code: 'mr',
    alpha3Code: 'mrt',
    independent: true,
    cities: ['nema', 'nouakchott', 'zouerat']
  },
  mt: {
    name: 'Malta',
    numericCode: '470',
    alpha2Code: 'mt',
    alpha3Code: 'mlt',
    independent: true,
    cities: ['rabat']
  },
  mu: {
    name: 'Mauritius',
    numericCode: '480',
    alpha2Code: 'mu',
    alpha3Code: 'mus',
    independent: true,
    cities: ['port_louis']
  },
  mv: {
    name: 'Maldives',
    numericCode: '462',
    alpha2Code: 'mv',
    alpha3Code: 'mdv',
    independent: true,
    cities: ['male']
  },
  mw: {
    name: 'Malawi',
    numericCode: '454',
    alpha2Code: 'mw',
    alpha3Code: 'mwi',
    independent: true,
    cities: ['lilongwe']
  },
  mx: {
    name: 'Mexico',
    numericCode: '484',
    alpha2Code: 'mx',
    alpha3Code: 'mex',
    independent: true,
    cities: [
      'guadalajara',
      'hermosillo',
      'merida',
      'mexico_city',
      'monterrey',
      'salina_cruz',
      'tijuana',
      'puebla',
      'leon',
      'cancun',
      'ecatepec',
      'chihuahua',
      'acapulco',
      'ciudad_juarez',
      'zapopan',
      'san_cristobal_de_las_casas',
      'torreon',
      'mazatlan',
      'puerto_vallarta',
      'veracruz',
      'poza_rica',
      'san_luis_potosi',
      'colima',
      'los_mochis',
      'paraiso',
      'oxaca_de_juarez',
      'tampico',
      'pachuca',
      'aguascalientes',
      'michoacan',
      'morelos',
      'nayarit',
      'nuevo_leon',
      'queretaro',
      'quintana_roo',
      'sinaloa',
      'sonora',
      'tabasco',
      'tamaulipas',
      'tlaxcala',
      'yucatan',
      'zacatecas',
      'oaxaca',
      'baja_california',
      'baja_california_sur',
      'campeche',
      'coahuila',
      'chiapas',
      'durango',
      'distrito_federal',
      'guanajuato',
      'guerrero',
      'hidalgo',
      'jalisco'
    ]
  },
  my: {
    name: 'Malaysia',
    numericCode: '458',
    alpha2Code: 'my',
    alpha3Code: 'mys',
    independent: true,
    cities: ['kluang', 'kuala_lumpur', 'pekan', 'sungai_petani', 'taiping']
  },
  mz: {
    name: 'Mozambique',
    numericCode: '508',
    alpha2Code: 'mz',
    alpha3Code: 'moz',
    independent: true,
    cities: ['chimoio', 'maputo', 'nampula', 'pemba']
  },
  na: {
    name: 'Namibia',
    numericCode: '516',
    alpha2Code: 'na',
    alpha3Code: 'nam',
    independent: true,
    cities: ['karasburg', 'keetmanshoop', 'luderitz', 'ondangwa', 'swakopmund', 'windhoek']
  },
  ne: {
    name: 'Niger',
    numericCode: '562',
    alpha2Code: 'ne',
    alpha3Code: 'ner',
    independent: true,
    cities: ['agadez', 'arlit', 'niamey', 'tahoua', 'zinder']
  },
  ng: {
    name: 'Nigeria',
    numericCode: '566',
    alpha2Code: 'ng',
    alpha3Code: 'nga',
    independent: true,
    cities: [
      'abuja',
      'bauchi',
      'birnin_kebbi',
      'gombe',
      'jalingo',
      'jos',
      'kaduna',
      'kano',
      'lagos',
      'maiduguri',
      'mokoloki',
      'port_harcourt',
      'potiskum'
    ]
  },
  ni: {
    name: 'Nicaragua',
    numericCode: '558',
    alpha2Code: 'ni',
    alpha3Code: 'nic',
    independent: true,
    cities: ['juigalpa', 'managua']
  },
  nl: {
    name: 'Netherlands',
    numericCode: '528',
    alpha2Code: 'nl',
    alpha3Code: 'nld',
    independent: true,
    cities: [
      'amsterdam',
      'groningen',
      'leeuwarden',
      'assen',
      'lelystad',
      'haarlem',
      'zwolle',
      'den_haag',
      'rotterdam',
      'utrecht',
      'arnhem',
      'eindhoven',
      'den_bosch',
      'maastricht',
      'middelburg'
    ]
  },
  no: {
    name: 'Norway',
    numericCode: '578',
    alpha2Code: 'no',
    alpha3Code: 'nor',
    independent: true,
    cities: [
      'alta',
      'bergen',
      'bodo',
      'kristiansand',
      'oslo',
      'skarsvag',
      'stavanger',
      'tromso',
      'trondheim'
    ]
  },
  np: {
    name: 'Nepal',
    numericCode: '524',
    alpha2Code: 'np',
    alpha3Code: 'npl',
    independent: true,
    cities: ['bhimdatta', 'biratnagar', 'janakpur', 'kathmandu']
  },
  nr: {
    name: 'Nauru',
    numericCode: '520',
    alpha2Code: 'nr',
    alpha3Code: 'nru',
    independent: true,
    cities: ['yaren']
  },
  nu: {
    name: 'Niue',
    numericCode: '570',
    alpha2Code: 'nu',
    alpha3Code: 'niu',
    independent: true,
    cities: ['alofi']
  },
  nz: {
    name: 'New Zealand',
    numericCode: '554',
    alpha2Code: 'nz',
    alpha3Code: 'nzl',
    independent: true,
    cities: ['auckland', 'christchurch', 'dunedin', 'gisborne', 'hamilton', 'timaru', 'wellington']
  },
  om: {
    name: 'Oman',
    numericCode: '512',
    alpha2Code: 'om',
    alpha3Code: 'omn',
    independent: true,
    cities: ['haima', 'muscat', 'nizwa', 'salalah', 'sohar']
  },
  pa: {
    name: 'Panama',
    numericCode: '591',
    alpha2Code: 'pa',
    alpha3Code: 'pan',
    independent: true,
    cities: ['david', 'panama_city', 'penonome', 'yaviza']
  },
  pe: {
    name: 'Peru',
    numericCode: '604',
    alpha2Code: 'pe',
    alpha3Code: 'per',
    independent: true,
    cities: [
      'arequipa',
      'ayacucho',
      'cajamarca',
      'chimbote',
      'cusco',
      'huacho',
      'huancayo',
      'huaraz',
      'iberia',
      'juliaca',
      'lima',
      'pisco',
      'pucallpa',
      'sullana',
      'tacna',
      'tarma',
      'tumbes'
    ]
  },
  pg: {
    name: 'Papua New Guinea',
    numericCode: '598',
    alpha2Code: 'pg',
    alpha3Code: 'png',
    independent: true,
    cities: ['aitape', 'angoram', 'kerema', 'lae', 'madang', 'popondetta', 'port_moresby']
  },
  ph: {
    name: 'Philippines',
    numericCode: '608',
    alpha2Code: 'ph',
    alpha3Code: 'phl',
    independent: true,
    cities: ['cebu_city', 'davao_city', 'luzon', 'manila']
  },
  pk: {
    name: 'Pakistan',
    numericCode: '586',
    alpha2Code: 'pk',
    alpha3Code: 'pak',
    independent: true,
    cities: ['islamabad', 'karachi', 'multan']
  },
  pl: {
    name: 'Poland',
    numericCode: '616',
    alpha2Code: 'pl',
    alpha3Code: 'pol',
    independent: true,
    cities: [
      'bialystok',
      'bydgoszcz',
      'gdynia',
      'krakow',
      'lublin',
      'szczecin',
      'warsaw',
      'wroclaw',
      'czestochowa',
      'gliwice',
      'katowice',
      'kolobrzeg',
      'kielce',
      'lodz',
      'plock',
      'zabrze',
      'torun'
    ]
  },
  ps: {
    name: 'Palestine',
    numericCode: '275',
    alpha2Code: 'ps',
    alpha3Code: 'pse',
    independent: true,
    cities: ['state_of_palestine']
  },
  pt: {
    name: 'Portugal',
    numericCode: '620',
    alpha2Code: 'pt',
    alpha3Code: 'prt',
    independent: true,
    cities: ['coimbra', 'faro', 'guarda', 'lisbon', 'porto']
  },
  pw: {
    name: 'Palau',
    numericCode: '585',
    alpha2Code: 'pw',
    alpha3Code: 'plw',
    independent: true,
    cities: ['koror']
  },
  py: {
    name: 'Paraguay',
    numericCode: '600',
    alpha2Code: 'py',
    alpha3Code: 'pry',
    independent: true,
    cities: ['asuncion', 'filadelfia', 'horqueta', 'san_jorge']
  },
  qa: {
    name: 'Qatar',
    numericCode: '634',
    alpha2Code: 'qa',
    alpha3Code: 'qat',
    independent: true,
    cities: ['al_khor', 'as_salwa', 'doha', 'dukhan', 'mesaieed']
  },
  ro: {
    name: 'Romania',
    numericCode: '642',
    alpha2Code: 'ro',
    alpha3Code: 'rou',
    independent: true,
    cities: [
      'brasov',
      'bucharest',
      'cluj_napoca',
      'iasi',
      'timisoara',
      'oradea',
      'constanta',
      'craiova',
      'ploiesti',
      'galati',
      'braila',
      'arad',
      'pitesti',
      'sibiu',
      'bacau'
    ]
  },
  rs: {
    name: 'Serbia',
    numericCode: '688',
    alpha2Code: 'rs',
    alpha3Code: 'srb',
    independent: true,
    cities: ['belgrade', 'cacak', 'nis', 'novi_sad', 'subotica']
  },
  ru: {
    name: 'Russia',
    numericCode: '643',
    alpha2Code: 'ru',
    alpha3Code: 'rus',
    independent: true,
    cities: [
      'aim',
      'aldan',
      'amga',
      'apatity',
      'arkhangelsk',
      'aykhal',
      'balashikha',
      'barnaul',
      'bilibino',
      'borzya',
      'bratsk',
      'bryansk',
      'chelyabinsk',
      'chersky',
      'chita',
      'chokurdakh',
      'ebyakh',
      'esso',
      'inya',
      'irkutsk',
      'izhevsk',
      'karasuk',
      'kazan',
      'kemerovo',
      'khabarovsk',
      'kirensk',
      'krasnodar',
      'krasnoyarsk',
      'krestyakh',
      'kurgan',
      'lamutskoye',
      'lesosibirsk',
      'lorino',
      'magadan',
      'makhachkala',
      'mayskiy',
      'moscow',
      'murmansk',
      'neverovo',
      'nizhny_novgorod',
      'novokuznetsk',
      'novosibirsk',
      'omolon',
      'omsk',
      'onega',
      'ozyorny',
      'palana',
      'perm',
      'petrozavodsk',
      'pskov',
      'rostov_on_don',
      'saint_petersburg',
      'samara',
      'sangar',
      'saratov',
      'smolensk',
      'sulomay',
      'susuman',
      'talovka',
      'tiksi',
      'tolyatti',
      'tomsk',
      'tyumen',
      'uchami',
      'ufa',
      'ulan_ude',
      'ulyanovsk',
      'vladivostok',
      'volgograd',
      'voronezh',
      'yakutsk',
      'yaroslavl',
      'yekaterinburg',
      'yelizovo',
      'yeniseysk'
    ]
  },
  rw: {
    name: 'Rwanda',
    numericCode: '646',
    alpha2Code: 'rw',
    alpha3Code: 'rwa',
    independent: true,
    cities: ['kigali']
  },
  sa: {
    name: 'Saudi Arabia',
    numericCode: '682',
    alpha2Code: 'sa',
    alpha3Code: 'sau',
    independent: true,
    cities: ['dammam', 'jeddah', 'medina', 'riyadh']
  },
  sb: {
    name: 'Solomon Islands',
    numericCode: '090',
    alpha2Code: 'sb',
    alpha3Code: 'slb',
    independent: true,
    cities: ['honiara']
  },
  sc: {
    name: 'Seychelles',
    numericCode: '690',
    alpha2Code: 'sc',
    alpha3Code: 'syc',
    independent: true,
    cities: ['victoria']
  },
  sd: {
    name: 'Sudan',
    numericCode: '729',
    alpha2Code: 'sd',
    alpha3Code: 'sdn',
    independent: true,
    cities: ['khartoum', 'nyala', 'port_sudan']
  },
  se: {
    name: 'Sweden',
    numericCode: '752',
    alpha2Code: 'se',
    alpha3Code: 'swe',
    independent: true,
    cities: [
      'gallivare',
      'gothenburg',
      'malmo',
      'ostersund',
      'skelleftea',
      'stockholm',
      'uppsala',
      'vasteras',
      'orebro',
      'linkoping',
      'helsingborg',
      'jonkoping',
      'norrkoping',
      'lund',
      'umea',
      'gavle',
      'boras',
      'sodertalje'
    ]
  },
  sg: {
    name: 'Singapore',
    numericCode: '702',
    alpha2Code: 'sg',
    alpha3Code: 'sgp',
    independent: true,
    cities: ['singapore']
  },
  si: {
    name: 'Slovenia',
    numericCode: '705',
    alpha2Code: 'si',
    alpha3Code: 'svn',
    independent: true,
    cities: ['ljubljana', 'maribor']
  },
  sk: {
    name: 'Slovakia',
    numericCode: '703',
    alpha2Code: 'sk',
    alpha3Code: 'svk',
    independent: true,
    cities: ['bratislava', 'kosice']
  },
  sl: {
    name: 'Sierra Leone',
    numericCode: '694',
    alpha2Code: 'sl',
    alpha3Code: 'sle',
    independent: true,
    cities: ['freetown', 'kabala', 'kenema', 'makeni']
  },
  sm: {
    name: 'San Marino',
    numericCode: '674',
    alpha2Code: 'sm',
    alpha3Code: 'smr',
    independent: true,
    cities: ['serravalle']
  },
  sn: {
    name: 'Senegal',
    numericCode: '686',
    alpha2Code: 'sn',
    alpha3Code: 'sen',
    independent: true,
    cities: ['ballou', 'dakar', 'kebemer', 'tambacounda']
  },
  so: {
    name: 'Somalia',
    numericCode: '706',
    alpha2Code: 'so',
    alpha3Code: 'som',
    independent: true,
    cities: ['berbera', 'mogadishu']
  },
  sr: {
    name: 'Suriname',
    numericCode: '740',
    alpha2Code: 'sr',
    alpha3Code: 'sur',
    independent: true,
    cities: ['paramaribo']
  },
  ss: {
    name: 'South Sudan',
    numericCode: '728',
    alpha2Code: 'ss',
    alpha3Code: 'ssd',
    independent: true,
    cities: ['juba', 'malakal', 'wau']
  },
  st: {
    name: 'Sao Tome and Principe',
    numericCode: '678',
    alpha2Code: 'st',
    alpha3Code: 'stp',
    independent: true,
    cities: ['sao_tome']
  },
  sv: {
    name: 'El Salvador',
    numericCode: '222',
    alpha2Code: 'sv',
    alpha3Code: 'slv',
    independent: true,
    cities: ['santo_domingo']
  },
  sy: {
    name: 'Syria',
    numericCode: '760',
    alpha2Code: 'sy',
    alpha3Code: 'syr',
    independent: true,
    cities: ['aleppo', 'damascus', 'hama', 'homs']
  },
  sz: {
    name: 'Swaziland',
    numericCode: '748',
    alpha2Code: 'sz',
    alpha3Code: 'swz',
    independent: true,
    cities: ['mbabane']
  },
  td: {
    name: 'Chad',
    numericCode: '148',
    alpha2Code: 'td',
    alpha3Code: 'tcd',
    independent: true,
    cities: ['n_djamena']
  },
  tg: {
    name: 'Togo',
    numericCode: '768',
    alpha2Code: 'tg',
    alpha3Code: 'tgo',
    independent: true,
    cities: ['lome', 'sokode']
  },
  th: {
    name: 'Thailand',
    numericCode: '764',
    alpha2Code: 'th',
    alpha3Code: 'tha',
    independent: true,
    cities: ['bangkok', 'chiang_mai', 'pattaya']
  },
  tj: {
    name: 'Tajikistan',
    numericCode: '762',
    alpha2Code: 'tj',
    alpha3Code: 'tjk',
    independent: true,
    cities: ['dushanbe', 'khorugh', 'panjakent', 'qurghonteppa']
  },
  tl: {
    name: 'Timor-Leste',
    numericCode: '626',
    alpha2Code: 'tl',
    alpha3Code: 'tls',
    independent: true,
    cities: ['lospalos', 'same', 'suai']
  },
  tm: {
    name: 'Turkmenistan',
    numericCode: '795',
    alpha2Code: 'tm',
    alpha3Code: 'tkm',
    independent: true,
    cities: ['ashgabat', 'turkmenbashi']
  },
  tn: {
    name: 'Tunisia',
    numericCode: '788',
    alpha2Code: 'tn',
    alpha3Code: 'tun',
    independent: true,
    cities: ['sidi_bouzid', 'tataouine', 'tunis']
  },
  to: {
    name: 'Tonga',
    numericCode: '776',
    alpha2Code: 'to',
    alpha3Code: 'ton',
    independent: true,
    cities: ['nuku_alofa']
  },
  tr: {
    name: 'Turkey',
    numericCode: '792',
    alpha2Code: 'tr',
    alpha3Code: 'tur',
    independent: true,
    cities: [
      'ankara',
      'antalya',
      'gaziantep',
      'istanbul',
      'izmir',
      'kars',
      'kayseri',
      'ordu',
      'konya',
      'kütahya',
      'malatya',
      'manisa',
      'mardin',
      'muğla',
      'muş',
      'nevşehir',
      'niğde',
      'rize',
      'sakarya',
      'samsun',
      'siirt',
      'sinop',
      'sivas',
      'tekirdağ',
      'tokat',
      'trabzon',
      'tunceli',
      'şanlıurfa',
      'uşak',
      'yozgat',
      'zonguldak',
      'aksaray',
      'bayburt',
      'karaman',
      'kırıkkale',
      'batman',
      'şırnak',
      'bartın',
      'ardahan',
      'iğdır',
      'yalova',
      'karabük',
      'kilis',
      'osmaniye',
      'düzce',
      'van'
    ]
  },
  tt: {
    name: 'Trinidad and Tobago',
    numericCode: '780',
    alpha2Code: 'tt',
    alpha3Code: 'tto',
    independent: true,
    cities: ['arima']
  },
  tv: {
    name: 'Tuvalu',
    numericCode: '798',
    alpha2Code: 'tv',
    alpha3Code: 'tuv',
    independent: true,
    cities: ['funafuti']
  },
  tw: {
    name: 'Taiwan',
    numericCode: '158',
    alpha2Code: 'tw',
    alpha3Code: 'twn',
    independent: true,
    cities: ['kaohsiung_city', 'taichung_city', 'taipei']
  },
  tz: {
    name: 'Tanzania',
    numericCode: '834',
    alpha2Code: 'tz',
    alpha3Code: 'tza',
    independent: true,
    cities: ['dar_es_salaam', 'tabora']
  },
  ua: {
    name: 'Ukraine',
    numericCode: '804',
    alpha2Code: 'ua',
    alpha3Code: 'ukr',
    independent: true,
    cities: [
      'donetsk',
      'kharkiv',
      'kyiv',
      'luhansk',
      'lviv',
      'mykolaiv',
      'poltava',
      'zaporizhia',
      'odessa',
      'dnipro',
      'vinnytsia',
      'kryvyi_rih',
      'mariupol',
      'makiivka',
      'sevastopol',
      'chernihiv'
    ]
  },
  ug: {
    name: 'Uganda',
    numericCode: '800',
    alpha2Code: 'ug',
    alpha3Code: 'uga',
    independent: true,
    cities: ['arua', 'kampala']
  },
  us: {
    name: 'United States of America',
    numericCode: '840',
    alpha2Code: 'us',
    alpha3Code: 'usa',
    independent: true,
    cities: [
      'albany',
      'atlanta',
      'annapolis',
      'augusta',
      'austin',
      'baton_rouge',
      'bismarck',
      'boise',
      'boston',
      'carson_city',
      'charleston',
      'cheyenne',
      'chicago',
      'columbia',
      'columbus',
      'concord',
      'dallas',
      'denver',
      'des_moines',
      'dover',
      'frankfort',
      'harrisburg',
      'hartford',
      'helena',
      'honolulu',
      'houston',
      'indianapolis',
      'jackson',
      'jefferson_city',
      'juneau',
      'lansing',
      'lincoln',
      'little_rock',
      'los_angeles',
      'madison',
      'montgomery',
      'montpelier',
      'nashville',
      'new_york',
      'oklahoma_city',
      'olympia',
      'philadelphia',
      'phoenix',
      'pierre',
      'providence',
      'raleigh',
      'richmond',
      'sacramento',
      'saint_paul',
      'salem',
      'salt_lake_city',
      'san_antonio',
      'san_diego',
      'san_francisco',
      'san_jose',
      'seattle',
      'santa_fe',
      'springfield',
      'tallahassee',
      'topeka',
      'trenton',
      'washington'
    ]
  },
  uy: {
    name: 'Uruguay',
    numericCode: '858',
    alpha2Code: 'uy',
    alpha3Code: 'ury',
    independent: true,
    cities: ['melo', 'montevideo', 'rivera', 'salto', 'tacuarembo']
  },
  uz: {
    name: 'Uzbekistan',
    numericCode: '860',
    alpha2Code: 'uz',
    alpha3Code: 'uzb',
    independent: true,
    cities: ['bukhara', 'samarkand', 'urgench']
  },
  va: {
    name: 'Vatican',
    numericCode: '336',
    alpha2Code: 'va',
    alpha3Code: 'vat',
    independent: true,
    cities: ['vatican_city']
  },
  vc: {
    name: 'Saint Vincent and the Grenadines',
    numericCode: '670',
    alpha2Code: 'vc',
    alpha3Code: 'vct',
    independent: true,
    cities: ['kingstown']
  },
  ve: {
    name: 'Venezuela',
    numericCode: '862',
    alpha2Code: 've',
    alpha3Code: 'ven',
    independent: true,
    cities: [
      'barinas',
      'barquisimeto',
      'caracas',
      'ciudad_bolivar',
      'maracaibo',
      'maturin',
      'san_cristobal',
      'valencia'
    ]
  },
  vn: {
    name: 'Vietnam',
    numericCode: '704',
    alpha2Code: 'vn',
    alpha3Code: 'vnm',
    independent: true,
    cities: [
      'da_nang',
      'nha_trang',
      'hai_phong',
      'hanoi',
      'ho_chi_minh_city',
      'can_tho',
      'quang_ninh',
      'vung_tau',
      'bac_ninh'
    ]
  },
  vu: {
    name: 'Vanuatu',
    numericCode: '548',
    alpha2Code: 'vu',
    alpha3Code: 'vut',
    independent: true,
    cities: ['port_vila']
  },
  ws: {
    name: 'Samoa',
    numericCode: '882',
    alpha2Code: 'ws',
    alpha3Code: 'wsm',
    independent: true,
    cities: ['apia']
  },
  ye: {
    name: 'Yemen',
    numericCode: '887',
    alpha2Code: 'ye',
    alpha3Code: 'yem',
    independent: true,
    cities: [
      'aden',
      'al_ghaydah',
      'al_hudaydah',
      'balhaf',
      'dhamar',
      'sana_a',
      'shibam',
      'taizz',
      'thamud'
    ]
  },
  za: {
    name: 'South Africa',
    numericCode: '710',
    alpha2Code: 'za',
    alpha3Code: 'zaf',
    independent: true,
    cities: ['bloemfontein', 'cape_town', 'durban', 'port_elizabeth', 'pretoria', 'upington']
  },
  zm: {
    name: 'Zambia',
    numericCode: '894',
    alpha2Code: 'zm',
    alpha3Code: 'zmb',
    independent: true,
    cities: ['kalomo', 'kasama', 'lusaka', 'mansa']
  },
  zw: {
    name: 'Zimbabwe',
    numericCode: '716',
    alpha2Code: 'zw',
    alpha3Code: 'zwe',
    independent: true,
    cities: ['gweru', 'harare']
  }
};

export const COUNTRY_ISO = {
  SWITZERLAND: 'CH',
  CANADA: 'CA',
  MEXICO: 'MX',
  AUSTRALIA: 'AU',
  SINGAPORE: 'SG',
  INDIA: 'IN',
  UNITED_STATES_OF_AMERICA: 'US',
  REPUBLIC_OF_KOSOVO: 'XK',
  HONG_KONG: 'HK',
  SAUDIA_ARABIA: 'SA'
};

export const FRANCE_REGION_COUNTRY_CODES = [
  COUNTRIES.fr.alpha2Code,
  COUNTRIES.mc.alpha2Code,
  COUNTRIES.lu.alpha2Code,
  COUNTRIES.be.alpha2Code
];

const COUNTRY_STATES = {
  [COUNTRY_ISO.CANADA]: [
    new InputObject('Alberta', 'AB'),
    new InputObject('British Columbia', 'BC'),
    new InputObject('Manitoba', 'MB'),
    new InputObject('New Brunswick', 'NB'),
    new InputObject('Newfoundland and Labrador', 'NL'),
    new InputObject('Northwest Territories', 'NT'),
    new InputObject('Nova Scotia', 'NS'),
    new InputObject('Nunavut', 'NU'),
    new InputObject('Ontario', 'ON'),
    new InputObject('Prince Edward Island', 'PE'),
    new InputObject('Quebec', 'QC'),
    new InputObject('Saskatchewan', 'SK'),
    new InputObject('Yukon', 'YT')
  ],
  [COUNTRY_ISO.MEXICO]: [
    new InputObject('Aguascalientes', 'AG'),
    new InputObject('Baja California', 'BC'),
    new InputObject('Baja California Sur', 'BS'),
    new InputObject('Campeche', 'CM'),
    new InputObject('Chiapas', 'CS'),
    new InputObject('Ciudad de México', 'CX'),
    new InputObject('Chihuahua', 'CH'),
    new InputObject('Coahuila de Zaragoza', 'CO'),
    new InputObject('Colima', 'CL'),
    new InputObject('Durango', 'DG'),
    new InputObject('Guanajuato', 'GT'),
    new InputObject('Guerrero', 'GR'),
    new InputObject('Hidalgo', 'HG'),
    new InputObject('Jalisco', 'JC'),
    new InputObject('México', 'EM'),
    new InputObject('Michoacán de Ocampo', 'MI'),
    new InputObject('Morelos', 'MO'),
    new InputObject('Nayarit', 'NA'),
    new InputObject('Nuevo León', 'NL'),
    new InputObject('Oaxaca', 'OA'),
    new InputObject('Puebla', 'PU'),
    new InputObject('Querétaro de Arteaga', 'QT'),
    new InputObject('Quintana Roo', 'QR'),
    new InputObject('San Luis Potosí', 'SL'),
    new InputObject('Sinaloa', 'SI'),
    new InputObject('Sonora', 'SO'),
    new InputObject('Tabasco', 'TB'),
    new InputObject('Tamaulipas', 'TM'),
    new InputObject('Tlaxcala', 'TL'),
    new InputObject('Veracruz de Ignacio de la Llave', 'VE'),
    new InputObject('Yucatán', 'YU'),
    new InputObject('Zacatecas', 'ZA')
  ],
  [COUNTRY_ISO.AUSTRALIA]: [
    new InputObject('New South Wales', 'NSW'),
    new InputObject('Queensland', 'QLD'),
    new InputObject('South Australia', 'SA'),
    new InputObject('Tasmania', 'TAS'),
    new InputObject('Victoria', 'VIC'),
    new InputObject('Western Australia', 'WA'),
    new InputObject('Australian Capital Territory', 'ACT'),
    new InputObject('Northern Territory', 'NT')
  ],
  [COUNTRY_ISO.UNITED_STATES_OF_AMERICA]: [
    new InputObject('Wyoming', 'WY'),
    new InputObject('Wisconsin', 'WI'),
    new InputObject('West Virginia', 'WV'),
    new InputObject('Washington', 'WA'),
    new InputObject('Virginia', 'VA'),
    new InputObject('Vermont', 'VT'),
    new InputObject('Utah', 'UT'),
    new InputObject('Texas', 'TX'),
    new InputObject('Tennessee', 'TN'),
    new InputObject('South Dakota', 'SD'),
    new InputObject('South Carolina', 'SC'),
    new InputObject('Rhode Island', 'RI'),
    new InputObject('Pennsylvania', 'PA'),
    new InputObject('Oregon', 'OR'),
    new InputObject('Oklahoma', 'OK'),
    new InputObject('Ohio', 'OH'),
    new InputObject('North Dakota', 'ND'),
    new InputObject('North Carolina', 'NC'),
    new InputObject('New York', 'NY'),
    new InputObject('New Mexico', 'NM'),
    new InputObject('New Jersey', 'NJ'),
    new InputObject('New Hampshire', 'NH'),
    new InputObject('Nevada', 'NV'),
    new InputObject('Nebraska', 'NE'),
    new InputObject('Montana', 'MT'),
    new InputObject('Missouri', 'MO'),
    new InputObject('Mississippi', 'MS'),
    new InputObject('Minnesota', 'MN'),
    new InputObject('Michigan', 'MI'),
    new InputObject('Massachusetts', 'MA'),
    new InputObject('Maryland', 'MD'),
    new InputObject('Maine', 'ME'),
    new InputObject('Louisiana', 'LA'),
    new InputObject('Kentucky', 'KY'),
    new InputObject('Kansas', 'KS'),
    new InputObject('Iowa', 'IA'),
    new InputObject('Indiana', 'IN'),
    new InputObject('Illinois', 'IL'),
    new InputObject('Idaho', 'ID'),
    new InputObject('Hawaii', 'HI'),
    new InputObject('Georgia', 'GA'),
    new InputObject('Florida', 'FL'),
    new InputObject('Delaware', 'DE'),
    new InputObject('Connecticut', 'CT'),
    new InputObject('Colorado', 'CO'),
    new InputObject('California', 'CA'),
    new InputObject('Arkansas', 'AR'),
    new InputObject('Arizona', 'AZ'),
    new InputObject('Alaska', 'AK'),
    new InputObject('Alabama', 'AL')
  ]
};

const COUNTRY_CODES = Object.keys(COUNTRIES);

export { COUNTRIES, COUNTRY_STATES, COUNTRY_CODES };
