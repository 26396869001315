<template>
  <div class="confirmation-modal-actions">
    <button class="confirmation-modal-actions__cancel cancel-button" @click="onCancel">
      {{ $t(cancelText) }}
    </button>
    <button class="rounded-button-white" @click="onConfirm">
      {{ $t(confirmText) }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationModalActions',
  props: {
    cancelText: {
      type: String,
      default: 'dashboard.action.cancel'
    },
    confirmText: {
      type: String,
      default: 'dashboard.diagnostic.understand'
    }
  },
  methods: {
    onConfirm() {
      this.$emit('confirm');
    },
    onCancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../../../assets/scss/common/buttons';

.confirmation-modal-actions {
  &__cancel {
    display: inline-block;
    padding-right: 30px;
  }
}

.nexultra-o-recommandation-modal {
  .rounded-button-white {
    padding: 9px 40px;
  }
}
</style>
