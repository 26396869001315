import { get } from 'lodash';

import {
  INGREDIENTS,
  ACTIVE_INGREDIENTS_KEYS,
  ACTIVES_TO_COLORS_COMPATIBILITY
} from '@/modules/dashboard/api/constants';

export const sections = [
  'oxidativeStress',
  'skinAppearance',
  'skinRedness',
  'skinDryness',
  'skinOiliness',
  'skinTexture',
  'pimples',
  'skinPigmentation'
];

export const INGREDIENTS_COLORS = {
  [INGREDIENTS.FERULIC]: '#84764e',
  [INGREDIENTS.SOD]: '#84764e',
  [INGREDIENTS.DMAE]: '#9e797c',
  [INGREDIENTS.MADECA]: '#9e797c',
  [INGREDIENTS.ISO]: '#9e797c',
  [INGREDIENTS.RETINOL]: '#9e797c',
  [INGREDIENTS.ASCORBIC]: '#9e797c',
  [INGREDIENTS.AZELAIC]: '#962f4c',
  [INGREDIENTS.RUTIN]: '#962f4c',
  [INGREDIENTS.NIACINAMIDE]: '#962f4c',
  [INGREDIENTS.DPANTHENOL]: '#526d9f',
  [INGREDIENTS.ALOE]: '#526d9f',
  [INGREDIENTS.ZINC]: '#be5f20',
  [INGREDIENTS.GLYCOLIC]: '#8a7d71',
  [INGREDIENTS.PHYTIC]: '#8a7d71',
  [INGREDIENTS.SALICYLIC]: '#8a7d71',
  [INGREDIENTS.LACTO]: '#60885d',
  [INGREDIENTS.KOJIC]: '#4e433c',
  [INGREDIENTS.ARBUTIN]: '#4e433c'
};

const createIngredientMap = () => {
  const map = new Map();

  map.set(INGREDIENTS.SERUM, [2, 2, 2, 1, 1, 0, 0, 1]);
  map.set(INGREDIENTS.FERULIC, [[5, 0, 0, 0, 0, 0, 0, 0], [1, 0, 0, 0, 0, 0, 0, 0]]);
  map.set(INGREDIENTS.DMAE, [[0, 3, 0, 0, 0, 0, 0, 0], [0, 1, 0, 0, 0, 0, 0, 0]]);
  // Vitamine B5
  map.set(INGREDIENTS.DPANTHENOL, [[0, 0, 0, 6, 0, 0, 0, 0], [0, 0, 0, 3, 0, 0, 0, 0]]);
  map.set(INGREDIENTS.GLYCOLIC, [[0, 0, 0, 0, 0, 9, 0, 0], [0, 0, 0, 0, 0, 1, 0, 0]]);
  map.set(INGREDIENTS.ARBUTIN, [[0, 0, 0, 0, 0, 0, 0, 7], [0, 0, 0, 0, 0, 0, 0, 3]]);
  map.set(INGREDIENTS.KOJIC, [[0, 0, 0, 0, 0, 0, 0, 7], [0, 0, 0, 0, 0, 0, 0, 3]]);
  map.set(INGREDIENTS.SOD, [
    [4, 0, 1, 0, 0, 0, 0, 0],
    [2, 0, 1, 0, 0, 0, 0, 0],
    [1, 0, 1, 0, 0, 0, 0, 0]
  ]);
  map.set(INGREDIENTS.MADECA, [
    [0, 5, 2, 0, 0, 0, 0, 0],
    [0, 2, 1, 0, 0, 0, 0, 0],
    [0, 1, 1, 0, 0, 0, 0, 0]
  ]);
  map.set(INGREDIENTS.AZELAIC, [[0, 0, 4, 0, 0, 0, 0, 4], [0, 0, 2, 0, 0, 0, 0, 2]]);
  map.set(INGREDIENTS.RUTIN, [[2, 0, 4, 0, 0, 0, 0, 0], [0, 0, 3, 0, 0, 0, 0, 0]]);
  map.set(INGREDIENTS.SALICYLIC, [[0, 0, 1, 0, 0, 8, 0, 0], [0, 0, 0, 0, 0, 2, 0, 0]]);
  map.set(INGREDIENTS.PHYTIC, [[4, 0, 0, 0, 0, 7, 0, 0], [0, 0, 0, 0, 0, 2, 0, 0]]);
  map.set(INGREDIENTS.ZINC, [[0, 0, 2, 0, 10, 0, 3, 0]]);
  map.set(INGREDIENTS.LACTO, [[0, 0, 0, 0, 3, 0, 10, 0]]);
  // Vitamine B3
  map.set(INGREDIENTS.NIACINAMIDE, [
    [1, 1, 3, 1, 0, 0, 0, 2],
    [1, 1, 2, 1, 0, 0, 0, 1],
    [0, 0, 1, 0, 0, 0, 0, 0]
  ]);
  // Vitamine C
  map.set(INGREDIENTS.ALOE, [[0, 1, 1, 5, 0, 0, 0, 0], [0, 1, 1, 2, 0, 0, 0, 0]]);
  map.set(INGREDIENTS.ISO, [[2, 5, 0, 0, 0, 0, 0, 1], [1, 3, 0, 0, 0, 0, 0, 0]]);
  map.set(INGREDIENTS.RETINOL, [[2, 4, 0, 0, 0, 7, 0, 3], [1, 2, 0, 0, 0, 3, 0, 2]]);
  map.set(INGREDIENTS.ASCORBIC, [
    [3, 4, 1, 1, 0, 2, 0, 1],
    [2, 2, 1, 0, 0, 2, 0, 0],
    [1, 1, 1, 0, 0, 1, 0, 0]
  ]);

  return map;
};

export const getPimplesScoreByIndex = activeIndex => {
  switch (activeIndex) {
    case 0:
      return 6;
    case 1:
      return 2;
    default:
      return 0;
  }
};

export const actionScoresMap = createIngredientMap();

export const getSectionScore = (actives, index, skinCharacteristics) => {
  const initialScore = actionScoresMap.get(INGREDIENTS.SERUM)[index];
  const currentSection = sections[index];

  let dysfunctionLevel = null;

  if (currentSection === 'pimples') {
    dysfunctionLevel =
      get(skinCharacteristics, 'pimples.score') || get(skinCharacteristics, 'green');
  }

  const score = Object.entries(actives).reduce((finalScore, [activeName, activeCapsuls]) => {
    let calculatedScore = finalScore;

    for (let activeIndex = 0; activeIndex < activeCapsuls; activeIndex += 1) {
      let activeScore;

      if (activeName === INGREDIENTS.AZELAIC && dysfunctionLevel && dysfunctionLevel > 2) {
        // If pimples score more that 2 we need to use different values for azelatic.
        activeScore = getPimplesScoreByIndex(activeIndex);
      } else {
        const activeValues = actionScoresMap.get(activeName)[activeIndex];

        activeScore = (activeValues && activeValues[index]) || 0;
      }

      calculatedScore += activeScore;
    }

    return calculatedScore;
  }, initialScore);

  return score > 10 ? 10 : score;
};

export const getTreatmentSectionsScore = (treatment, skinCharacteristics = {}) => {
  const treatmentActives = Object.values(treatment).reduce((actives, compositionBottle) => {
    if (!compositionBottle) {
      return actives;
    }

    const activesObject = { ...actives };

    ACTIVE_INGREDIENTS_KEYS.forEach(ingredientKey => {
      const active = compositionBottle[ingredientKey];

      if (!active) {
        return;
      }

      const { name, nbOfCapsules } = active;
      const currentNumberOfCapsules = activesObject[name] || 0;

      activesObject[name] = currentNumberOfCapsules + nbOfCapsules;
    });

    return activesObject;
  }, {});

  return sections.map((name, index) => ({
    name,
    score: getSectionScore(treatmentActives, index, skinCharacteristics)
  }));
};

export const getTreatmentActionScores = (treatFace, skinCharacteristics = {}) => {
  const selectionScores = getTreatmentSectionsScore(treatFace, skinCharacteristics) || [];

  return selectionScores.reduce(
    (actionScores, { name, score }) => ({
      ...actionScores,
      [ACTIVES_TO_COLORS_COMPATIBILITY[name]]: score
    }),
    {}
  );
};
