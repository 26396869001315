import { types } from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';
import questionnaireTypes from '@/modules/questionnaire/store/types';

import { loadLanguage } from '@/i18n';

import {
  getUserWithUsername,
  getUserWithId,
  login,
  generateRecoveryCodes,
  getRecoveryCodes,
  setUpTwoFactorAuthentication
} from '@/api';
import { getDashboardLocale } from '@/utils';
import { LocalStorageService } from '@/services/LocalStorageService';

export default {
  async [types.actions.LOGIN](
    { commit, dispatch },
    { username, password, twoFactorAuthenticationCode, recoveryCode }
  ) {
    commit(types.mutations.SET_LOADING, true);

    const { status, data: responseData } = await login({
      username,
      password,
      twoFactorAuthenticationCode,
      recoveryCode
    });

    if (status >= 400) {
      commit(types.mutations.SET_LOADING, false);
      return { errors: [responseData] };
    }

    if (!responseData || responseData.error) {
      commit(types.mutations.SET_LOADING, false);

      return { errors: ['Login error!'] };
    }

    const { shouldUseTwoFactorAuthentication } = responseData;

    if (shouldUseTwoFactorAuthentication) {
      return { shouldUseTwoFactorAuthentication };
    }

    dispatch(questionnaireTypes.actions.RESET_MODULE);
    LocalStorageService.setDashboardToken(responseData.token);

    const { status: userResponseStatus, data: userResponseData } = await getUserWithUsername(
      username
    );

    commit(types.mutations.SET_LOADING, false);

    if (userResponseStatus >= 400) {
      return { errors: [userResponseData] };
    }

    commit(types.mutations.SET_USER, userResponseData);

    await dispatch(types.actions.SET_LOCALE, getDashboardLocale(userResponseData.locale));
    await dispatch(dashTypes.actions.LOAD_PRICE_SETTING);

    return {};
  },

  [types.actions.LOGOUT]() {
    LocalStorageService.clearDashboardToken();
  },

  async [types.actions.SET_LOCALE]({ commit }, locale) {
    commit(types.mutations.SET_LOCALE, locale);
    await loadLanguage(locale);
  },

  [types.actions.SET_LOADING]({ commit }, loading) {
    commit(types.mutations.SET_LOADING, loading);
  },

  async [types.actions.UPDATE_USER_PHOTO]({ commit }, userId) {
    const {
      data: { photo }
    } = await getUserWithId(userId);

    commit(types.mutations.SET_USER_PHOTO, photo);
  },

  async [types.actions.UPDATE_USER]({ commit }, userId) {
    const { data: updatedUser } = await getUserWithId(userId);

    commit(types.mutations.SET_USER, updatedUser);
  },

  async [types.actions.GENERATE_USER_RECOVERY_CODES]({ commit }, doctorId) {
    const { data: recoveryCodes } = await generateRecoveryCodes(doctorId);

    commit(types.mutations.SET_USER_RECOVERY_CODES, recoveryCodes);
  },

  async [types.actions.SET_USER_RECOVERY_CODES]({ commit }, doctorId) {
    const { data: recoveryCodes } = await getRecoveryCodes(doctorId);

    commit(types.mutations.SET_USER_RECOVERY_CODES, recoveryCodes);
  },

  async [types.actions.SET_UP_TWO_FACTOR_AUTHENTICATION]({ commit }, doctorId) {
    const { data: code } = await setUpTwoFactorAuthentication(doctorId);

    commit(types.mutations.SET_USER_AUTHENTICATION_CODE, code);
  }
};
