<template>
  <div>
    <contraindications-warning-modal />
    <diagnostic-results-warning-modal />
    <forbidden-ingredient-warning-modal />
    <online-offer-forbidden-warning-modal />
    <recent-treatment-warning-modal />
    <thin-skin-warning-modal />
  </div>
</template>

<script>
import ContraindicationsWarningModal from '@/modules/dashboard/components/microneedling/ContraindicationsWarningModal';
import DiagnosticResultsWarningModal from '@/modules/dashboard/components/microneedling/DiagnosticResultsWarningModal';
import ForbiddenIngredientWarningModal from '@/modules/dashboard/components/microneedling/ForbiddenIngredientWarningModal';
import OnlineOfferForbiddenWarningModal from '@/modules/dashboard/components/microneedling/OnlineOfferForbiddenWarningModal';
import RecentTreatmentWarningModal from '@/modules/dashboard/components/microneedling/RecentTreatmentWarningModal';
import ThinSkinWarningModal from '@/modules/dashboard/components/microneedling/ThinSkinWarningModal';

export default {
  name: 'MicroneedlingValidationModals',
  components: {
    ContraindicationsWarningModal,
    DiagnosticResultsWarningModal,
    ForbiddenIngredientWarningModal,
    OnlineOfferForbiddenWarningModal,
    RecentTreatmentWarningModal,
    ThinSkinWarningModal
  }
};
</script>
