import TutorialSlide from '@/modules/dashboard/components/tutorial/TutorialSlide';
import TutorialTextConfig from '@/modules/dashboard/components/tutorial/TutorialTextConfig';

export const TUTORIAL_CONFIG_TYPE = {
  CREATE_TREATMENT: 'create-treatment'
};

export const TUTORIAL_CUSTOM_ELEMENTS_TYPES = {
  BUTTON: 'BUTTON',
  ICON: 'ICON',
  RECOMMENDED: 'RECOMMENDED',
  GREY_TEXT_BUTTON: 'GREY_TEXT_BUTTON',
  LINK: 'LINK'
};

export const TUTORIAL_VIEW_TYPE = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile'
};

export const DOCTOR_ACCESS_TYPE = {
  ONLINE: 'online',
  OFFLINE: 'offline'
};

export const CREATE_ONLINE_OFFER_CONFIG = [
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.goToDashboard', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.action.createTreatment'
      }
    ]),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.goToDashboard', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.ICON,
        value: 'skinxs-black'
      }
    ])
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.clickQuickTreatment', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.newTreatment.action.treatmentFromPhotoAnalysis'
      }
    ]),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.alternatively')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.enterDetails', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.action.continue'
      }
    ]),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.enterDetails')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.uploadPhoto'),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.uploadPhoto')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.confirm', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.action.continue'
      }
    ]),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.confirm')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.wait'),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.wait')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.checkBasicResults'),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.checkBasicResults')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.adjustResults'),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.adjustResults')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.answerQuestions', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.photoAnalysis.action.seeRecommendedTreatment'
      }
    ]),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.answerQuestions')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.patientCard'),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.patientCard', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.GREY_TEXT_BUTTON,
        value: 'dashboard.action.showDetails'
      }
    ])
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.serumComposition'),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.serumComposition')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.online.addComplementary'),
    secondText: new TutorialTextConfig('dashboard.tutorial.online.afterComposingTreatment', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.action.sendOffer'
      },
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.action.createTreatment'
      }
    ]),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.offline.addComplementary', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.RECOMMENDED
      }
    ]),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.online.aboutOnlineTreatmentOffer', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.LINK,
        value: 'dashboard.tutorial.learnMore',
        link: '/dashboard/user-guide'
      }
    ])
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.review'),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.loyaltyProgram', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.LINK,
        value: 'dashboard.tutorial.learnMore',
        link: '/dashboard/user-guide/loyalty-program'
      }
    ])
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.reviewRecapMessage', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.action.sendAndCreateTreatment'
      }
    ]),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.reviewRecapMessage')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.treatmentRecordAdded'),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.treatmentRecordAdded')
  })
];

export const CREATE_OFFLINE_TREATMENT_CONFIG = [
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.goToDashboard', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.action.createTreatment'
      }
    ]),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.goToDashboard', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.ICON,
        value: 'skinxs-black'
      }
    ])
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.clickQuickTreatment', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.newTreatment.action.treatmentFromPhotoAnalysis'
      }
    ]),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.alternatively')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.enterDetails', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.action.continue'
      }
    ]),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.enterDetails')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.uploadPhoto'),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.uploadPhoto')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.confirm', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.action.continue'
      }
    ]),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.confirm')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.wait'),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.wait')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.checkBasicResults'),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.checkBasicResults')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.adjustResults'),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.adjustResults')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.answerQuestions', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.photoAnalysis.action.seeRecommendedTreatment'
      }
    ]),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.answerQuestions')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.patientCard'),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.patientCard', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.GREY_TEXT_BUTTON,
        value: 'dashboard.action.showDetails'
      }
    ])
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.serumComposition'),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.serumComposition')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.offline.addComplementary'),
    secondText: new TutorialTextConfig('dashboard.tutorial.offline.afterComposingTreatment', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.action.createTreatment'
      }
    ]),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.offline.addComplementary', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.RECOMMENDED
      }
    ])
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.review'),
    tipText: new TutorialTextConfig('dashboard.tutorialTip.loyaltyProgram', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.LINK,
        value: 'dashboard.tutorial.learnMore',
        link: '/dashboard/user-guide/loyalty-program'
      }
    ])
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.reviewRecapMessage', [
      {
        type: TUTORIAL_CUSTOM_ELEMENTS_TYPES.BUTTON,
        value: 'dashboard.action.sendAndCreateTreatment'
      }
    ]),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.reviewRecapMessage')
  }),
  new TutorialSlide({
    firstText: new TutorialTextConfig('dashboard.tutorial.treatmentRecordAdded'),
    noteText: new TutorialTextConfig('dashboard.tutorialNote.treatmentRecordAdded')
  })
];

export const TUTORIAL_CONFIG = {
  [TUTORIAL_CONFIG_TYPE.CREATE_TREATMENT]: {
    [DOCTOR_ACCESS_TYPE.ONLINE]: CREATE_ONLINE_OFFER_CONFIG,
    [DOCTOR_ACCESS_TYPE.OFFLINE]: CREATE_OFFLINE_TREATMENT_CONFIG
  }
};
