import Vue from 'vue';
import Validation from 'vee-validate';
import VueMeta from 'vue-meta';
import VueScrollTo from 'vue-scrollto';
import VueClipboard from 'vue-clipboard2';
import VModal from 'vue-js-modal';
import Notifications from 'vue-notification';
import VTooltip from 'v-tooltip';
import VueTextareaAutosize from 'vue-textarea-autosize';
import SocialSharing from 'vue-social-sharing';
import VueAnalytics from 'vue-analytics';
import { Vue2Modal } from '@arfedulov/vue2-modal';
import windowSize from 'universkin-shared/src/plugins/windowSize';
import 'universkin-shared/src/polyfills/setupDisplayNamesPolyfill';

import 'focus-within-polyfill';

import allowOnlyRegExpDirective from '@/directives/allowOnlyRegExpDirective';
import phoneNumberDirective from '@/directives/phoneNumberDirective';
import { GOOGLE_ANALYTICS_ID } from '@/constants';
import { router } from '@/router';
import store from '@/store';
import Sentry from '@/plugins/sentry';
import PromisifyVueJsModal from '@/plugins/promisifyVueJsModal';
import appFiltersPlugin from '@/plugins/appFiltersPlugin';

import App from './App';

import { i18n } from './i18n';

Vue.use(VueMeta);
Vue.use(Validation);
Vue.use(VueScrollTo);
Vue.use(VueClipboard);
Vue.use(VModal);
Vue.use(PromisifyVueJsModal);
Vue.use(Notifications);
Vue.use(VTooltip);
Vue.use(VueTextareaAutosize);
Vue.use(SocialSharing);
Vue.use(Sentry);
Vue.use(Vue2Modal);
Vue.use(appFiltersPlugin);
Vue.use(windowSize);

Vue.use(VueAnalytics, {
  id: GOOGLE_ANALYTICS_ID,
  router,
  debug: {
    sendHitTask: process.env.VUE_APP_BASE_URL.includes('https://skinxs.com')
  }
});

VTooltip.options.defaultPlacement = 'bottom';
VTooltip.options.defaultTrigger = 'hover click';

Vue.directive('allowOnlyRegExp', allowOnlyRegExpDirective);
Vue.directive('phoneNumber', phoneNumberDirective);

/* eslint-disable no-new */
// eslint-disable-next-line vue/require-name-property
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
