import _ from 'lodash';

const getNamespaceTypes = (namespace, types) => _.reduce(
  types,
  (typeObj, typeValue, typeName) => {
    typeObj[typeName] = _.reduce(
      typeValue,
      (obj, v, k) => {
        obj[k] = v.replace(namespace, '');

        return obj;
      },
      {}
    );

    return typeObj;
  },
  {}
);

export default getNamespaceTypes;
