import {
  CREATE_TREATMENT_TYPES,
  DEFAULT_PATIENT_FILTER,
  PATIENT_TAB_NAMES,
  INVITE_TAB_NAMES,
  DEFAULT_CREATE_TREATMENT_SUMMARY,
  PHOTO_ANALYSIS_INITIAL_STATE,
  TREATMENT_DIRECTORY_INITIAL_STATE,
  INITIAL_MOISTURIZE_USAGES
} from '@/modules/dashboard/api/constants';

import { dashTypes } from '@/modules/dashboard/store/types';

export const getInitialState = () => ({
  [dashTypes.state.STATUS]: {
    loading: false,
    isDashboardLocked: false,
    errors: []
  },

  [dashTypes.state.PATIENTS]: [],
  [dashTypes.state.TOTAL_PATIENT_COUNT]: 0,
  [dashTypes.state.PDF_SUMMARY]: null,

  [dashTypes.state.NOTIFICATIONS_COUNTER]: 0,

  [dashTypes.state.PATIENT_ORDER]: '',
  [dashTypes.state.PATIENT_DATE_ORDER]: '',
  [dashTypes.state.OPENED_PATIENT]: null,
  [dashTypes.state.SORTING_DIRECTIONS]: {
    registrationDecentOrder: true,
    lastActivityDecentOrder: true,
    patientNameDecentOrder: true
  },

  [dashTypes.state.PARTIAL_NAME]: '',
  [dashTypes.state.PATIENT_FILTER]: DEFAULT_PATIENT_FILTER,
  [dashTypes.state.PATIENT_FILTER_COUNT]: null,
  [dashTypes.state.ACTIVE_PATIENT_TAB]: PATIENT_TAB_NAMES.CREATE_TREATMENT,
  [dashTypes.state.ACTIVE_INVITE_TAB]: INVITE_TAB_NAMES.EMAIL_MESSAGE,
  [dashTypes.state.ACTIVE_DIAGNOSTIC_ID]: null,
  [dashTypes.state.ACTIVE_TREATMENT_ID]: null,
  [dashTypes.state.ERROR]: null,
  [dashTypes.state.CURRENT_PATIENT]: null,

  [dashTypes.state.CREATE_TREATMENT]: {
    type: CREATE_TREATMENT_TYPES.DIAGNOSTIC_BASED_TREATMENT,
    recommendationDiagnostic: null,
    sectionProducts: {
      cleanse: [],
      moisturize: [],
      protect: []
    },
    universkinHSelectionSection: null,
    treatmentRecommendation: null,
    summary: DEFAULT_CREATE_TREATMENT_SUMMARY,
    errors: []
  },

  [dashTypes.state.SETTING]: null,

  [dashTypes.state.MARKETING_CAMPAIGNS]: {
    campaigns: [],
    activeCampaign: null
  },

  [dashTypes.state.MESSAGE_TEMPLATE]: {
    activeTab: null,
    savedTemplate: {},
    currentTemplate: {}
  },

  [dashTypes.state.PHOTO_ANALYSIS]: {
    ...PHOTO_ANALYSIS_INITIAL_STATE
  },

  [dashTypes.state.TREATMENT_DIRECTORY]: {
    questions: { ...TREATMENT_DIRECTORY_INITIAL_STATE.QUESTIONS },
    recommendations: TREATMENT_DIRECTORY_INITIAL_STATE.RECOMMENDATIONS
  },

  [dashTypes.state.INVITE_PATIENT]: {
    activeTab: null
  },
  [dashTypes.state.NOT_VALIDATED_DIAGNOSTIC_ID]: null,
  [dashTypes.state.AVAILABLE_MOISTURIZE_USAGES]: INITIAL_MOISTURIZE_USAGES
});
