<template>
  <modal
    name="not-supported-browser-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="300"
    height="auto"
  >
    <div class="not-supported-browser modal-container">
      <h1 class="not-supported-browser__header mb30">Improve your experience</h1>
      <div class="not-supported-browser__experience-text mb20">
        It looks you may be using a web browser we don’t support. Make sure you’re using one of
        these supported browsers to get full SkinXs experience.
      </div>
      <div class="not-supported-browser__browsers-list mb30">
        • Google Chrome
        <br />
        • Safari
        <br />
        • Mozilla Firefox
        <br />
        • Microsoft Edge
      </div>
      <button class="not-supported-browser__close-button rounded-button-gray" @click="closeModal">
        Close
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'NotSupportedBrowserModal',
  methods: {
    closeModal() {
      this.$modal.hide('not-supported-browser-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/helpers/colors';
@import '../assets/scss/common/buttons';

.not-supported-browser {
  padding: 30px;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;

  color: $text-color;

  &__header,
  &__close-button {
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
  }

  &__header,
  &__experience-text,
  &__browsers-list {
    text-align: center;
  }

  &__close-button {
    display: block;
    margin: 0 auto;
  }
}
</style>
