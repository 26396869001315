import { get, lowerCase, memoize, upperFirst } from 'lodash';

import TemplateTextAreaBinding from '@/modules/dashboard/components/message-templates/TemplateTextAreaBinding';
import FilterItem from '@/modules/dashboard/components/patient-filter/FilterItem';
import InputObject from '@/components/common/SelectOptionObject';

import { isAndroidDevice, isChromeBrowser } from '@/modules/questionnaire/utils';
import { isAppleMobileDevice } from '@/utils/featureDetection';

import { CURRENCY_SYMBOLS } from '@/api/places/CURRENCY_SYMBOLS';

import {
  ALL_VALUE,
  DASHBOARD_FUNCTIONAL_BUTTONS,
  DEFAULT_PATIENT_IMAGE,
  EMPTY_PHOTO,
  EYE_CONTOUR_SERUM_ELEMENTS,
  INGREDIENT_CONCENTRATIONS,
  CLARIFICATION_QUESTION_PREFIX,
  PHOTO_VALIDATION_ERRORS,
  PATIENT_PAGE_NAMES,
  SECTION_NAME_TO_PRODUCTS_BINGING,
  CREATE_TREATMENT_DEFAULT_PRODUCTS,
  PDF_APPLICATION_TYPE,
  CHANGABLE_SIZE_PRODUCTS_REFERENCES,
  CHANGABLE_SIZE_PRODUCTS_NAMES,
  TEMPLATE_ERRORS
} from '@/modules/dashboard/api/constants';

import {
  INCI_FILE_NAME,
  MOBILE_MEDIA_QUERY,
  NUMBER_OF_DAYS_IN_MONTH,
  PDF_SUMMARY_NAME,
  VALID_SLUG_REG_EXP,
  VALID_WEBSITE_URL_REG_EXP
} from '@/constants';

import { NOT_APPLICABLE_KEY } from '@/modules/dashboard/api/medicalData';
import { ALLOWED_FILE_TYPE, MAX_PHOTO_SIZE_IN_BYTES } from '@/modules/questionnaire/api/constants';

const URL_PROTOCOL_REG_EXP_ARRAY = [/http:\/\//, /https:\/\//];

export const getPointsCountTranslation = ($t, numberOfPoints, isTotal = false) => {
  const translationPrefix = 'dashboard.label.';
  let translationPostfix = 'points';
  const totalPostfix = isTotal ? 'Total' : '';

  const units = numberOfPoints % 10;
  const dozens = numberOfPoints % 100;
  const isDecades = dozens >= 10 && dozens < 20;

  if (!isDecades && units === 1) {
    translationPostfix = 'onePoints';
  }

  const isFromTwoToFour = units >= 2 && units <= 4;

  if (!isDecades && isFromTwoToFour) {
    translationPostfix = 'twoPoints';
  }

  return $t(`${translationPrefix}${translationPostfix}${totalPostfix}`, [numberOfPoints]);
};

export const arrayNotEmpty = array => array !== undefined && array !== null && array.length > 0;

export const eyeContour = serum => {
  const contains = value => serum.indexOf(value) >= 0;

  if (serum === undefined || serum === null) {
    return null;
  }

  return !EYE_CONTOUR_SERUM_ELEMENTS.some(element => contains(element));
};

export const getDoctorFullName = doctor => get(doctor, 'name', '');

export const getFullName = ({ firstName, lastName }) =>
  `${firstName || ''} ${lastName || ''}`.trim();

export const buildImageUrl = photo => {
  const base64Photo = photo && photo.convertedToBase64Photo;

  return base64Photo ? `data:image/jpeg;base64,${base64Photo}` : DEFAULT_PATIENT_IMAGE;
};

export const buildBackgroundPhotoUrlStyles = photoUrl => ({
  'background-image': `url(${photoUrl})`
});

export const buildPatientPhotoStyles = photo => ({
  'background-image': `url(${buildImageUrl(photo)})`
});

export const buildDoctorImageURL = ({ photo }) => {
  if (photo) {
    return `data:image/jpeg;base64,${photo}`;
  }

  return EMPTY_PHOTO;
};

export const getFirstLetterInUpperCase = string => string && string.charAt(0).toUpperCase();

export const getPatientSortGrouping = patient => {
  const { firstName, lastName } = patient;

  const firstNameLetter = getFirstLetterInUpperCase(firstName);
  const lastNameLetter = getFirstLetterInUpperCase(lastName);

  return lastName ? lastNameLetter : firstNameLetter;
};

export const getPatientFirstLetter = ({ firstName, lastName }) =>
  getFirstLetterInUpperCase(lastName) || getFirstLetterInUpperCase(firstName);

export const getPatientDisplayedName = ({ firstName, lastName }) => {
  const patientFirstName = firstName || '';

  return lastName ? `${lastName}, ${patientFirstName}` : patientFirstName;
};

export const downloadFile = async (blob, options) => {
  const { fileName = INCI_FILE_NAME, shouldOpenFile = true } = options || {};
  const url = window.URL.createObjectURL(blob);

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, INCI_FILE_NAME);

    return;
  }

  if (shouldOpenFile && isAppleMobileDevice()) {
    window.location.href = url;

    return;
  }

  const a = document.createElement('a');

  a.href = url;
  a.download = fileName;

  a.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );

  // Delay is added because iOS doesn't download pdf and opens it as a page.
  // So we have to add some delay to make sure that it has enough time to use url for page opening.
  setTimeout(() => window.URL.revokeObjectURL(url), 50);
};

export const openFileInNewTab = blob => {
  const url = window.URL.createObjectURL(blob);

  if (isChromeBrowser() && !isAndroidDevice()) {
    const pdfWindow = window.open(url, 'PDF_Summary');

    const interval = setInterval(() => {
      if (!pdfWindow) {
        clearInterval(interval);

        return;
      }

      if (pdfWindow.closed) {
        window.URL.revokeObjectURL(url);
        clearInterval(interval);
      }
    }, 1000);

    return;
  }

  downloadFile(blob, { fileName: PDF_SUMMARY_NAME });
};

export const isValuePropertyEqual = property => ({ value }) => property === value;

export const getArrayElementOrNull = (array, property) =>
  array.find(isValuePropertyEqual(property)) || null;

export const convertToUpperCase = (string = '') => string.toUpperCase();

export const convertToLowerCase = (string = '') => string.toLowerCase();

export const getFileType = ({ type }) => type.split('/');

const iosCopyToClipboard = element => {
  const { contentEditable, readOnly, disabled } = element;
  const range = document.createRange();

  element.contentEditable = true;
  element.readOnly = false;
  range.selectNodeContents(element);

  const selection = window.getSelection();

  selection.removeAllRanges();
  selection.addRange(range);
  element.setSelectionRange(0, 999999);
  element.contentEditable = contentEditable;
  element.readOnly = readOnly;
  element.disabled = disabled;

  document.execCommand('copy');
};

export const copyToClipboard = string => {
  const el = document.createElement('textarea');

  el.value = string;
  el.disabled = isAppleMobileDevice();
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');

  if (isAppleMobileDevice()) {
    iosCopyToClipboard(el);
  }

  document.body.removeChild(el);
};

export const deleteSpaces = string => string.replace(/\s/g, '');

const cachedTransformPhone = memoize(phoneNumber => {
  const phoneNumberArray = deleteSpaces(phoneNumber).split('');

  return phoneNumberArray
    .map((digit, index) => (index % 2 ? `${digit} ` : digit))
    .join('')
    .trim();
});

export const transformVerificationCode = verificationCode =>
  deleteSpaces(verificationCode)
    .split('')
    .map((item, index) => (index === 2 ? `${item}  ` : `${item} `))
    .join('')
    .trim();

export const transformPhone = phoneNumber => cachedTransformPhone(phoneNumber);

const cachedCurrencyFormat = memoize(currency => CURRENCY_SYMBOLS[currency] || currency);

export const currencyFormat = currency => cachedCurrencyFormat(currency);

export const validateDoctorSlug = (t, { doctorSlug }) => {
  if (doctorSlug.length < 3 || doctorSlug.length > 25) {
    return t('dashboard.warning.urlCharactersNumber');
  }

  if (!VALID_SLUG_REG_EXP.test(doctorSlug)) {
    return t('dashboard.warning.validSlag');
  }

  return '';
};

export const validateWebsite = (t, { personalPage }) => {
  if (personalPage && !VALID_WEBSITE_URL_REG_EXP.test(personalPage)) {
    return t('dashboard.warning.validAddress');
  }

  return '';
};

export const getUrlWithProtocol = url => {
  if (!url) {
    return url;
  }

  const hasUrlProtocol = URL_PROTOCOL_REG_EXP_ARRAY.some(protocolRegExp =>
    protocolRegExp.test(url)
  );

  return hasUrlProtocol ? url : `https://${url}`;
};

export const validateDoctorPhone = ({ officePhone }) => !!officePhone;

export const getAllPatientFilterItem = t =>
  new FilterItem({
    name: t('dashboard.label.allPatients'),
    value: ALL_VALUE,
    isIconPresent: false
  });

export const isMobileDevice = () => window.matchMedia(MOBILE_MEDIA_QUERY).matches;

export const buildMessageTemplateText = ({ bodyPartOne, linkName, signature }) =>
  `${bodyPartOne}\n\n${linkName || ''}${linkName ? '\n\n' : ''}${signature}`;

export const buildNotificationTemplateText = ({
  greeting,
  bodyPartOne,
  linkName,
  signature,
  withLink = false
}) => `${greeting}\n\n${bodyPartOne}${withLink ? `\n\n${linkName}` : ''}\n\n${signature}`;

export const handleRoutingInfo = (routingInfo, $router, $modal) => {
  if (isMobileDevice()) {
    $router.push({ name: routingInfo.route, params: routingInfo.params });
  }

  $modal.show(routingInfo.modal, routingInfo.params);
};

export const getDateObjectFromString = dateString => {
  const date = new Date(dateString);

  return {
    day: date.getDate().toString(),
    month: (date.getMonth() + 1).toString(),
    year: date.getFullYear().toString()
  };
};

export const getDateFromDateObject = ({ day, month, year }) => new Date(year, month - 1, day);

export const getMessageTemplateTypeIconClass = (type, isActive) =>
  `icon-${lowerCase(type)}${isActive ? '-orange' : ''}`;

export const handleDashboardFunctionalButton = (router, modal, type) => {
  const routingInfo = DASHBOARD_FUNCTIONAL_BUTTONS[type];

  if (isMobileDevice()) {
    router.push({ name: routingInfo.route });

    return;
  }

  modal.show(routingInfo.modal);
};

export const getStringLength = string => (string || '').length;

export const getNonNegativeNumber = number => (number > 0 ? number : 0);

export const hasPatientPhoto = patientPhoto =>
  !!(patientPhoto && patientPhoto.convertedToBase64Photo);

export const updateDropdownVisibility = ({
  dropdownContainer,
  dropdown,
  isVisible,
  elementHeight,
  top,
  initialTop = '0px'
}) => {
  if (!isVisible) {
    return;
  }

  dropdown.style.top = initialTop;

  const { bottom } = dropdown.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const isDropdownVisibleOnBottom = bottom < windowHeight;

  if (!dropdownContainer) {
    if (!isDropdownVisibleOnBottom) {
      dropdown.style.top = top;
    }

    return;
  }

  const parentElement = dropdownContainer.offsetParent;

  const {
    top: parentTopOffset,
    bottom: parentBottomOffset
  } = parentElement.getBoundingClientRect();

  const nonNegativeParentTopOffset = parentTopOffset > 0 ? parentTopOffset : 0;

  const {
    top: dropdownContainerTopOffset,
    bottom: dropdownContainerBottomOffset
  } = dropdownContainer.getBoundingClientRect();

  const hasAvailableContainerTopSpace =
    dropdownContainerTopOffset - nonNegativeParentTopOffset > elementHeight;

  if (!isDropdownVisibleOnBottom && hasAvailableContainerTopSpace) {
    dropdown.style.top = top;
  }

  const hasAvailableBottomSpace =
    parentBottomOffset - dropdownContainerBottomOffset > elementHeight;

  if (!hasAvailableBottomSpace) {
    dropdown.style.top = top;
  }
};

export const formatNumberWithTwoDecimalPart = price =>
  parseFloat(Math.round(price * 100) / 100).toFixed(2);

export const formatNumberWithOneDecimalPart = number =>
  parseFloat(Math.round(number * 100) / 100).toFixed(1);

export const formatPrice = ({ currency, price, useCurrencySymbol }) => {
  const currencySymbol = useCurrencySymbol ? CURRENCY_SYMBOLS[currency] : currency;

  return `${currencyFormat(currencySymbol)}${formatNumberWithTwoDecimalPart(price)}`;
};

export const formatIngredientConcentration = (
  numberOfCapsules,
  ingredientName,
  hasOneDecimalPart = true
) => {
  const ingredientConcentration = numberOfCapsules * INGREDIENT_CONCENTRATIONS[ingredientName];

  return hasOneDecimalPart
    ? formatNumberWithOneDecimalPart(ingredientConcentration)
    : formatNumberWithTwoDecimalPart(ingredientConcentration);
};

export const convertMonthsToDays = (months = 0) => months * NUMBER_OF_DAYS_IN_MONTH;

export const getSelectOptionsWithLabel = ({ $t, options = [], keyPrefix }) =>
  options.map(option => {
    const genderLabel = $t(`${keyPrefix}${option}`);

    return new InputObject(upperFirst(genderLabel), option);
  });

export const getMedicalDataSelectOptionsWithLabel = ($t, options) =>
  options.map(option => {
    const labelKey = option === '' ? NOT_APPLICABLE_KEY : option;

    return new InputObject($t(`dashboard.medicalData.${labelKey}`), option);
  });

export const getClarificationQuestionKey = number => `${CLARIFICATION_QUESTION_PREFIX}${number}`;

export const getClarificationQuestionsResponses = (questionsToAsk, questions) =>
  questionsToAsk.reduce((questionResponses, questionKey, index) => {
    const propertyName = `q${questionKey}`;
    const questionAnswer = get(questions[index], 'answer');

    return {
      ...questionResponses,
      [propertyName]: questionAnswer
    };
  }, {});

export const validatePhotoFile = file => {
  const { size: fileSize } = file;
  const [fileType] = getFileType(file);

  if (fileType !== ALLOWED_FILE_TYPE) {
    return PHOTO_VALIDATION_ERRORS.TYPE_NOT_ALLOWED;
  }

  if (fileSize > MAX_PHOTO_SIZE_IN_BYTES) {
    return PHOTO_VALIDATION_ERRORS.MAX_FILE_SIZE;
  }

  return '';
};

export const getFacePhotoValidationError = (noFace, severalFaces) => {
  if (noFace) {
    return PHOTO_VALIDATION_ERRORS.NO_FACE_ON_PHOTO;
  }

  if (severalFaces) {
    return PHOTO_VALIDATION_ERRORS.SEVERAL_FACES_ON_PHOTO;
  }

  return '';
};

export const getPatientPageNameForTreatmentRecommendationProcess = () => {
  const patientPageName = isMobileDevice()
    ? PATIENT_PAGE_NAMES.CREATE_TREATMENT
    : PATIENT_PAGE_NAMES.PATIENT_CARD;

  return patientPageName;
};

export const getDefaultProductsBySectionName = (sectionName, availableProducts = []) =>
  SECTION_NAME_TO_PRODUCTS_BINGING[sectionName]
    .filter(product => availableProducts.includes(product))
    .map(productReference => CREATE_TREATMENT_DEFAULT_PRODUCTS[productReference]);

export const checkIsPdfFile = file => {
  const fileType = get(file, ['type'], '');

  return fileType === PDF_APPLICATION_TYPE;
};

export const getExistingFieldsTextareaBindings = ({
  fields = [],
  templateData = {},
  fieldsMaxLength = {}
}) =>
  fields.map(
    fieldName =>
      new TemplateTextAreaBinding(
        fieldName,
        true,
        templateData[fieldName],
        fieldsMaxLength[fieldName]
      )
  );

export const getProductName = productReference => {
  if (CHANGABLE_SIZE_PRODUCTS_REFERENCES.includes(productReference)) {
    return CHANGABLE_SIZE_PRODUCTS_NAMES[productReference];
  }

  return productReference;
};

export const getTemplateErrorsFromVeeValidationErrors = ({ items }) =>
  items.reduce(
    (templateErrors, { field: errorField }) => ({
      ...templateErrors,
      [errorField]: TEMPLATE_ERRORS.includes(errorField)
    }),
    {}
  );

export const hasTemplateError = errors => Object.values(errors).includes(true);

export const printFile = elementId => {
  const element = window.document.getElementById(elementId);
  const url = '';
  const name = '_blank';
  const config = ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'];
  const newWindow = window.open(url, name, config);

  newWindow.document.write(`
        <html>
          <head>
            <title>Recovery codes</title>
          </head>
          <body>
            ${element.innerHTML}
          </body>
        </html>
      `);

  setTimeout(() => {
    newWindow.document.close();
    newWindow.focus();
    newWindow.print();
    newWindow.close();
  }, 1000);
};
