import Splitter from 'split-sms';

import RoutingInfo from '@/modules/dashboard/components/common/RoutingInfo';
import Product from '@/modules/dashboard/components/create-treatment-tab/treatments/Product';

import { TUTORIAL_CONFIG_TYPE } from '@/modules/dashboard/api/tutorial';
import { BOOLEAN } from '@/modules/questionnaire/api/constants';

export const DEFAULT_PERFECT_SCROLLBAR_OPTIONS = {
  suppressScrollX: true,
  wheelPropagation: false
};

export const NOTIFICATION_TYPE = {
  NEW_QUESTIONNAIRE: 'NEW_QUESTIONNAIRE',
  TREATMENT_COMPLETED: 'TREATMENT_COMPLETED',
  ONGOING_TREATMENT: 'ONGOING_TREATMENT',
  TREATMENT_STARTS_IN: 'TREATMENT_STARTS_IN',
  INACTIVE: 'INACTIVE',
  OFFER_SENT: 'OFFER_SENT',
  ORDER_UNDER_DELIVERY: 'ORDER_UNDER_DELIVERY',
  ORDER_CANCELLED: 'ORDER_CANCELLED',
  NEW_PHOTO_ANALYSIS: 'NEW_PHOTO_ANALYSIS'
};

export const SORTING_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const NOTIFICATION_TYPE_VALUES = Object.values(NOTIFICATION_TYPE);

export const STATUSES_EXCEPT_INACTIVE = NOTIFICATION_TYPE_VALUES.filter(
  value => value !== NOTIFICATION_TYPE.INACTIVE
);

export const DISABLE_DELETE_OPTION_STATUSES = [
  NOTIFICATION_TYPE.OFFER_SENT,
  NOTIFICATION_TYPE.TREATMENT_STARTS_IN,
  NOTIFICATION_TYPE.ORDER_UNDER_DELIVERY
];

export const DEFAULT_PATIENT_IMAGE = import('@/assets/images/dashboard/patients/default.png');
export const EMPTY_PHOTO = import('@/assets/images/dashboard/icons/empty-photo.svg');
export const DOCTOR_PHOTO_FILE_NAME = 'doctorPhoto';

export const ACTIVES = {
  OXIDATIVE_STRESS: 'oxidativeStress',
  SKIN_APPEARANCE: 'skinAppearance',
  SKIN_REDNESS: 'skinRedness',
  SKIN_DRYNESS: 'skinDryness',
  SKIN_OILINESS: 'skinOiliness',
  SKIN_TEXTURE: 'skinTexture',
  PIMPLES: 'pimples',
  SKIN_PIGMENTATION: 'skinPigmentation'
};

export const SKIN_DYSFUNCTIONS_COLOR = {
  [ACTIVES.OXIDATIVE_STRESS]: 'pale-brown-color',
  [ACTIVES.SKIN_APPEARANCE]: 'pink-color',
  [ACTIVES.SKIN_REDNESS]: 'purple-color',
  [ACTIVES.SKIN_DRYNESS]: 'blue-steel-color',
  [ACTIVES.SKIN_OILINESS]: 'chocolate-color',
  [ACTIVES.SKIN_TEXTURE]: 'mouse-gray',
  [ACTIVES.PIMPLES]: 'camouflage-green-color',
  [ACTIVES.SKIN_PIGMENTATION]: 'anthracite-color'
};

export const DYSFUNCTIONS_COLORS = {
  [ACTIVES.OXIDATIVE_STRESS]: '#84764e',
  [ACTIVES.SKIN_APPEARANCE]: '#9e797c',
  [ACTIVES.SKIN_REDNESS]: '#962f4c',
  [ACTIVES.SKIN_DRYNESS]: '#526d9f',
  [ACTIVES.SKIN_OILINESS]: '#be5f20',
  [ACTIVES.SKIN_TEXTURE]: '#8a7d71',
  [ACTIVES.PIMPLES]: '#60885d',
  [ACTIVES.SKIN_PIGMENTATION]: '#4e433c'
};

export const EYE_CONTOUR_SERUM_ELEMENTS = ['glycolic', 'salicylic', 'phytic', 'zinc', 'lacto'];

export const DEFAULT_INGREDIENT_NUMBER_OF_CAPSULES = 1;

export const INGREDIENTS = {
  SERUM: 'serum',
  FERULIC: 'ferulic',
  DMAE: 'dmae',
  DPANTHENOL: 'dpanthenol',
  GLYCOLIC: 'glycolic',
  ARBUTIN: 'arbutin',
  KOJIC: 'kojic',
  SOD: 'sod',
  MADECA: 'madeca',
  AZELAIC: 'azelaic',
  RUTIN: 'rutin',
  SALICYLIC: 'salicylic',
  PHYTIC: 'phytic',
  ZINC: 'zinc',
  LACTO: 'lacto',
  NIACINAMIDE: 'niacinamide',
  ALOE: 'aloe',
  ISO: 'iso',
  RETINOL: 'retinol',
  ASCORBIC: 'ascorbic'
};

export const INGREDIENT_CONCENTRATIONS = {
  [INGREDIENTS.FERULIC]: 1.0,
  [INGREDIENTS.DMAE]: 2.5,
  [INGREDIENTS.DPANTHENOL]: 5.0,
  [INGREDIENTS.GLYCOLIC]: 7.0,
  [INGREDIENTS.ARBUTIN]: 3.0,
  [INGREDIENTS.KOJIC]: 2.0,
  [INGREDIENTS.SOD]: 2.0,
  [INGREDIENTS.MADECA]: 1.0,
  [INGREDIENTS.AZELAIC]: 5.4,
  [INGREDIENTS.RUTIN]: 3.2,
  [INGREDIENTS.SALICYLIC]: 2.0,
  [INGREDIENTS.PHYTIC]: 2.0,
  [INGREDIENTS.ZINC]: 2.5,
  [INGREDIENTS.LACTO]: 3.5,
  [INGREDIENTS.ASCORBIC]: 7.0,
  [INGREDIENTS.RETINOL]: 0.2,
  [INGREDIENTS.NIACINAMIDE]: 4.0,
  [INGREDIENTS.ALOE]: 5.4,
  [INGREDIENTS.ISO]: 2.0
};

export const INGREDIENT_ACTION_BLOCKSIZES = {
  BIG: 'big',
  MEDIUM: 'medium',
  SMALL: 'small'
};

export const MEDICAL_CONCENTRATION_KEYS = {
  ONE_CAPSULE_MEDICAL_CONCENTRATION: 1,
  TWO_CAPSULES_MEDICAL_CONCENTRATION: 2,
  THREE_CAPSULES_MEDICAL_CONCENTRATION: 3
};

export const INGREDIENT_MEDICAL_CONCENTRATIONS = {
  [INGREDIENTS.RETINOL]: MEDICAL_CONCENTRATION_KEYS.THREE_CAPSULES_MEDICAL_CONCENTRATION,
  [INGREDIENTS.ISO]: MEDICAL_CONCENTRATION_KEYS.THREE_CAPSULES_MEDICAL_CONCENTRATION,
  [INGREDIENTS.DMAE]: MEDICAL_CONCENTRATION_KEYS.TWO_CAPSULES_MEDICAL_CONCENTRATION,
  [INGREDIENTS.GLYCOLIC]: MEDICAL_CONCENTRATION_KEYS.TWO_CAPSULES_MEDICAL_CONCENTRATION,
  [INGREDIENTS.ARBUTIN]: MEDICAL_CONCENTRATION_KEYS.TWO_CAPSULES_MEDICAL_CONCENTRATION,
  [INGREDIENTS.KOJIC]: MEDICAL_CONCENTRATION_KEYS.TWO_CAPSULES_MEDICAL_CONCENTRATION,
  [INGREDIENTS.AZELAIC]: MEDICAL_CONCENTRATION_KEYS.TWO_CAPSULES_MEDICAL_CONCENTRATION,
  [INGREDIENTS.RUTIN]: MEDICAL_CONCENTRATION_KEYS.TWO_CAPSULES_MEDICAL_CONCENTRATION,
  [INGREDIENTS.SALICYLIC]: MEDICAL_CONCENTRATION_KEYS.TWO_CAPSULES_MEDICAL_CONCENTRATION,
  [INGREDIENTS.ZINC]: MEDICAL_CONCENTRATION_KEYS.TWO_CAPSULES_MEDICAL_CONCENTRATION,
  [INGREDIENTS.NIACINAMIDE]: MEDICAL_CONCENTRATION_KEYS.TWO_CAPSULES_MEDICAL_CONCENTRATION
};

export const PRODUCT_REFERENCES = {
  ACTIVE_UNIT: 'activeUnit',
  ACTIVE_UNIT_PROMO: 'activeUnit7plusOnline',
  SERUM_UNIT: 'serumUnit',
  SERUM_X2_UNIT: 'serum2Unit',
  MICRONEEDLING_FACE: 'microneedlingFace',
  MICRONEEDLING_EYES: 'microneedlingEyes',
  NEXULTRA_O: 'nexUltraO',
  NEXULTRA_O1: 'nexUltraO1',
  NEXULTRA_O2: 'nexUltraO2',
  NEXULTRA_1: 'nexUltra1',
  NEXULTRA_2: 'nexUltra2',
  NEXULTRA_3: 'nexUltra3',
  NEXULTRA_B: 'nexUltraB',
  NEXULTRA_B1: 'nexUltraB1',
  NEXULTRA_B2: 'nexUltraB2',
  UNIVERSKIN_H: 'universkinH',
  NEXULTRA_L: 'nexUltraL',
  NEXULTRA_L1: 'nexUltraL1',
  NEXULTRA_L2: 'nexUltraL2',
  NEXULTRA_Z: 'nexUltraZ',
  NEXULTRA_UV: 'nexUltraUV',
  UNIVERSKIN_M: 'universkinM',
  NEXULTRA_L_BRUSH: 'nexUltraLBrush',
  NEXULTRA_P_LIGHT: 'nexUltraPLight',
  NEXULTRA_P_RICH: 'nexUltraPRich'
};

export const PRODUCT_REFERENCE_TO_DISPLAY_TITLE_MAP = {
  [PRODUCT_REFERENCES.ACTIVE_UNIT]: 'dashboard.settings.activeUnit',
  [PRODUCT_REFERENCES.ACTIVE_UNIT_PROMO]: 'dashboard.settings.activeUnit7plusOnline',
  [PRODUCT_REFERENCES.SERUM_UNIT]: 'dashboard.settings.serumUnit',
  [PRODUCT_REFERENCES.SERUM_X2_UNIT]: 'dashboard.settings.serum2Unit',
  [PRODUCT_REFERENCES.MICRONEEDLING_FACE]: 'dashboard.settings.microneedlingFace',
  [PRODUCT_REFERENCES.MICRONEEDLING_EYES]: 'dashboard.settings.microneedlingEyes',
  [PRODUCT_REFERENCES.NEXULTRA_O]: 'dashboard.settings.nexUltraO',
  [PRODUCT_REFERENCES.NEXULTRA_O1]: 'dashboard.settings.nexUltraO',
  [PRODUCT_REFERENCES.NEXULTRA_O2]: 'dashboard.settings.nexUltraO',
  [PRODUCT_REFERENCES.NEXULTRA_1]: 'dashboard.settings.nexUltra1',
  [PRODUCT_REFERENCES.NEXULTRA_2]: 'dashboard.settings.nexUltra2',
  [PRODUCT_REFERENCES.NEXULTRA_3]: 'dashboard.settings.nexUltra3',
  [PRODUCT_REFERENCES.NEXULTRA_B]: 'dashboard.settings.nexUltraB',
  [PRODUCT_REFERENCES.NEXULTRA_B1]: 'dashboard.settings.nexUltraB1',
  [PRODUCT_REFERENCES.NEXULTRA_B2]: 'dashboard.settings.nexUltraB2',
  [PRODUCT_REFERENCES.UNIVERSKIN_H]: 'dashboard.settings.universkinH',
  [PRODUCT_REFERENCES.NEXULTRA_L]: 'dashboard.settings.nexUltraL',
  [PRODUCT_REFERENCES.NEXULTRA_L1]: 'dashboard.settings.nexUltraL1',
  [PRODUCT_REFERENCES.NEXULTRA_Z]: 'dashboard.settings.nexUltraZ',
  [PRODUCT_REFERENCES.NEXULTRA_UV]: 'dashboard.settings.nexUltraUV',
  [PRODUCT_REFERENCES.UNIVERSKIN_M]: 'dashboard.settings.universkinM',
  [PRODUCT_REFERENCES.NEXULTRA_L_BRUSH]: 'dashboard.settings.nexultraLBrush',
  [PRODUCT_REFERENCES.NEXULTRA_P_LIGHT]: 'dashboard.settings.nexUltraPLight',
  [PRODUCT_REFERENCES.NEXULTRA_P_RICH]: 'dashboard.settings.nexUltraPRich'
};

export const PRICE_PRODUCT_REFERENCE_TO_DISPLAY_TITLE_MAP = {
  ...PRODUCT_REFERENCE_TO_DISPLAY_TITLE_MAP,
  [PRODUCT_REFERENCES.SERUM_UNIT]: 'dashboard.priceSettings.serumUnit',
  [PRODUCT_REFERENCES.SERUM_X2_UNIT]: 'dashboard.priceSettings.serum2Unit'
};

export const MICRONEEDLING_PRODUCT_REFERENCES = [
  PRODUCT_REFERENCES.MICRONEEDLING_FACE,
  PRODUCT_REFERENCES.MICRONEEDLING_EYES
];

export const PRODUCT_NAMES = {
  ...PRODUCT_REFERENCES,
  TREAT_FACE: 'treatFace',
  TREAT_EYES: 'treatEyes'
};

export const PRODUCT_LETTERS = {
  activeUnit: '',
  serumUnit: 'P',
  serum2Unit: 'P',
  nexUltraO: 'O',
  nexUltraO1: 'O',
  nexUltraO2: 'O',
  nexUltra1: '1',
  nexUltra2: '2',
  nexUltra3: '3',
  nexUltraB: 'B',
  nexUltraB1: 'B',
  nexUltraB2: 'B',
  universkinH: 'H',
  nexUltraL: 'L',
  nexUltraL1: 'L',
  nexUltraZ: 'Z',
  nexUltraUV: 'UV',
  nexUltraLBrush: 'UV',
  universkinM: 'M',
  nexUltraPLight: 'PL',
  nexUltraPRich: 'PR'
};

export const TREATMENT_SECTION_NAME = {
  TREAT_FACE: 'treatFace',
  TREAT_EYES: 'treatEyes'
};

export const MICRONEEDLING_PRODUCT_SECTION_TO_PRODUCT_REFERENCE_MAP = {
  [TREATMENT_SECTION_NAME.TREAT_FACE]: PRODUCT_REFERENCES.MICRONEEDLING_FACE,
  [TREATMENT_SECTION_NAME.TREAT_EYES]: PRODUCT_REFERENCES.MICRONEEDLING_EYES
};

export const SERUM_BOTTLE_NUMBER = {
  FIRST: 'first',
  SECOND: 'second'
};

export const SERUM_BOTTLE_KEY = {
  [SERUM_BOTTLE_NUMBER.FIRST]: 'compositionBottle1',
  [SERUM_BOTTLE_NUMBER.SECOND]: 'compositionBottle2'
};

export const DEFAULT_PATIENT_FILTER = {
  statuses: [],
  clinics: [],
  sources: [],
  influencers: []
};

export const PATIENT_SOURCE = {
  INVITED_BY_INFLUENCER: 'INVITED_BY_INFLUENCER',
  INVITED_BY_DOCTOR: 'INVITED_BY_DOCTOR',
  CREATED_BY_DOCTOR: 'CREATED_BY_DOCTOR'
};

export const PATIENT_SOURCE_VALUE = {
  [PATIENT_SOURCE.INVITED_BY_DOCTOR]: 'dashboard.patientFilter.invitedByMe',
  [PATIENT_SOURCE.CREATED_BY_DOCTOR]: 'dashboard.patientFilter.createdByMe'
};

export const ZOOMABLE_PHOTO_TYPES = {
  BIG: 'big',
  SMALL: 'small'
};

export const ALL_VALUE = 'ALL';

export const PDF_APPLICATION_TYPE = 'application/pdf';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DEFAULT_ISO_CODE = 'FR';

export const PATIENT_TAB_NAMES = {
  CREATE_TREATMENT: 'CreateTreatmentTab',
  TREATMENT_HISTORY: 'TreatmentHistoryTab',
  DIAGNOSTICS: 'DiagnosticTab',
  MEDICAL_DATA: 'MedicalDataTab',
  PHOTOS: 'PhotoTab',
  COMMENTS: 'CommentsTab'
};

export const INVITE_TAB_NAMES = {
  EMAIL_MESSAGE: 'SendPatientInviteWithEmail',
  SMS_MESSAGE: 'SendPatientInviteWithTextMessage'
};

export const MESSAGE_TEMPLATES_TAB_NAMES = {
  SKINXS_CAMPAIGNS: 'SkinxsCampaignsTab',
  CUSTOM_CAMPAIGNS: 'CustomCampaignsTab',
  NOTIFICATIONS: 'NotificationsTab'
};

export const DOCTOR_PROFILE_TAB_NAMES = {
  PERSONAL_INFO: 'PersonalInfo',
  SECURITY_SETTINGS: 'SecuritySettings'
};

export const MESSAGE_TEMPLATE_TYPES = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  FACEBOOK: 'FACEBOOK'
};

export const MESSAGE_TEMPLATE_TYPE_VALUES = Object.values(MESSAGE_TEMPLATE_TYPES);

export const REVIEW_TREATMENT_MESSAGE_TYPE = {
  CREATE_TREATMENT: 'create-treatment',
  SEND_OFFER: 'send-offer',
  RESEND_OFFER: 'resend-offer'
};

export const REVIEW_TREATMENT_MESSAGE_MODAL_NAMES = {
  [REVIEW_TREATMENT_MESSAGE_TYPE.CREATE_TREATMENT]: {
    parentModalName: 'review-message-modal',
    finishModalName: 'finish-treatment-validation-modal'
  },
  [REVIEW_TREATMENT_MESSAGE_TYPE.SEND_OFFER]: {
    parentModalName: 'review-send-offer-message-modal',
    finishModalName: 'finish-send-offer-modal'
  },
  [REVIEW_TREATMENT_MESSAGE_TYPE.RESEND_OFFER]: {
    parentModalName: 'review-send-offer-message-modal',
    finishModalName: 'finish-send-offer-modal'
  }
};

export const TREATMENT_TIMELINE_TAB_NAMES = {
  TREATMENT_COMPOSITION: 'TreatmentCompositionTab',
  DIRECTIONS_OF_USE: 'DirectionsOfUseTab'
};

export const TIMELINE_SECTION_DEFAULT_PROPS = {
  treatment: {
    type: Object,
    default: () => ({})
  },
  isActive: {
    type: Boolean,
    default: false
  },
  canOpenSection: {
    type: Boolean,
    default: true
  }
};

export const TREATMENT_STATUSES = {
  TREATMENT_STARTS_IN: 'TREATMENT_STARTS_IN',
  ONGOING_TREATMENT: 'ONGOING_TREATMENT',
  TREATMENT_COMPLETED: 'TREATMENT_COMPLETED'
};

export const TREATMENT_ORDERS_STATUSES = {
  TREATMENT_OFFER_CREATED: 'TREATMENT_OFFER_CREATED',
  TREATMENT_PAID_ONLINE: 'TREATMENT_PAID_ONLINE',
  TREATMENT_FOR_FREE: 'TREATMENT_FOR_FREE',
  TREATMENT_PAID_OFFLINE: 'TREATMENT_PAID_OFFLINE',
  UNDER_PREPARATION: 'UNDER_PREPARATION',
  RESEND_OPERATION: 'RESEND_OPERATION',
  CANCELLED: 'CANCELLED',
  DELIVERED: 'DELIVERED'
};

export const TREATMENT_OFFLINE_ORDER_STATUSES = [
  TREATMENT_ORDERS_STATUSES.TREATMENT_PAID_OFFLINE,
  TREATMENT_ORDERS_STATUSES.TREATMENT_FOR_FREE
];

export const TREATMENT_ICONS = {
  ICON_EXPIRED: 'icon-expired',
  ICON_EXPIRED_GRAY: 'icon-expired-gray',
  ICON_STARTED_GRAY: 'icon-started-gray',
  ICON_OFFER_SENT: 'icon-offer-sent',
  ICON_CLOCKS: 'icon-clocks',
  ICON_CANCELLED: 'icon-order-cancelled',
  ICON_UNDER_PREPARATION: 'icon-under-preparation'
};

export const HOW_TO_APPLY_SPLITTING_SYMBOLS = '##';

export const NO_TEMPLATE = 'No template';
export const SELECT_A_TEMPLATE = 'Select a template';

export const DASHBOARD_FUNCTIONAL_BUTTON_TYPES = {
  CREATE_TREATMENT: 'createTreatment',
  INVITE_PATIENT: 'invitePatient',
  PERSONALIZE_PACKAGING: 'personalizePackaging'
};

export const PATIENT_PAGE_NAMES = {
  PATIENT_CARD: 'PatientCard',
  CREATE_TREATMENT: 'CreateTreatment'
};

export const DASHBOARD_FUNCTIONAL_BUTTONS = {
  [DASHBOARD_FUNCTIONAL_BUTTON_TYPES.CREATE_TREATMENT]: new RoutingInfo(
    'new-treatment-modal',
    'NewTreatment'
  ),
  [DASHBOARD_FUNCTIONAL_BUTTON_TYPES.INVITE_PATIENT]: new RoutingInfo(
    'invite-patient-modal',
    'InvitePatient'
  ),
  [DASHBOARD_FUNCTIONAL_BUTTON_TYPES.PERSONALIZE_PACKAGING]: new RoutingInfo(
    'personalize-packaging-modal',
    'PersonalizePackaging'
  )
};

export const CUSTOM_CAMPAIGN_TITLE_ERROR =
  'This title is already used by a doctor in Custom campaign`s list ';

export const CREATE_TREATMENT_ERRORS = {
  TREAT_FACE_FIRST_BOTTLE_SERUM_VALIDATION: 'treatFaceFirstBottleSerumValidation',
  TREAT_FACE_SECOND_BOTTLE_SERUM_VALIDATION: 'treatFaceSecondBottleSerumValidation',
  TREAT_FACE_ONE_BOTTLE_VALIDATION: 'treatFaceOneBottleValidation',
  TREAT_EYES_BOTTLE_SERUM_VALIDATION: 'treatEyesBottleSerumValidation'
};

export const PATIENT_MIN_INFO_DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  phoneIsoCode: '',
  phone: '',
  smsAccepted: false
};

export const UNSAVED_CHANGES_INPUTS = {
  CANCEL: 'cancel',
  NO: 'no',
  YES: 'yes'
};

export const TOGGLE_ACTIONS = {
  ACTIVATE: 'ACTIVATE',
  DEACTIVATE: 'DEACTIVATE'
};

export const PHOTO_VALIDATION_ERRORS = {
  TYPE_NOT_ALLOWED: 'typeNotAllowed',
  MAX_FILE_SIZE: 'maxFileSize',
  NO_FACE_ON_PHOTO: 'noFaceOnPhoto',
  SEVERAL_FACES_ON_PHOTO: 'severalFacesOnPhoto'
};

export const DASHBOARD_QUESTION_COMPONENTS = {
  RADIO_BUTTON_QUESTION: 'RadioButtonQuestion',
  DATE_QUESTION: 'DateQuestion',
  SELECT_QUESTION: 'SelectQuestion'
};

export const YES_NO_QUESTION_BINDINGS = [
  {
    translationLabel: `dashboard.label.${BOOLEAN.YES}`,
    value: BOOLEAN.YES
  },
  {
    translationLabel: `dashboard.label.${BOOLEAN.NO}`,
    value: BOOLEAN.NO
  }
];

export const MIN_AGE = 12;
export const MAX_AGE = 120;

export const NOTIFICATION_TEMPLATES_TYPES = {
  QUESTIONNAIRE_COMPLETED: 'QUESTIONNAIRE_COMPLETED',
  INCOMPLETE_QUESTIONNAIRE: 'INCOMPLETE_QUESTIONNAIRE',
  TREATMENT_COMPLETED: 'TREATMENT_COMPLETED',
  TREATMENT_COMPLETED_MICRONEEDLING: 'TREATMENT_COMPLETED_MICRONEEDLING',
  NEW_TREATMENT: 'NEW_TREATMENT',
  TREATMENT_OFFER: 'TREATMENT_OFFER',
  RENEW_OFFER: 'RENEW_OFFER',
  RENEW_OFFER_MICRONEEDLING: 'RENEW_OFFER_MICRONEEDLING',
  REMINDER_OFFER: 'REMINDER_OFFER'
};

export const TEMPLATE_GROUP_TYPES = {
  ...NOTIFICATION_TEMPLATES_TYPES,
  SKINXS_CAMPAIGN: 'SKINXS_CAMPAIGN',
  CUSTOM_CAMPAIGN: 'CUSTOM_CAMPAIGN'
};

export const MARKETING_CAMPAIGN_TEMPLATE_NAMES = {
  EMAIL_TEMPLATE: 'EmailMarketingMessageTemplate',
  DEFAULT: 'DefaultMarketingMessageTemplate'
};

export const REVIEW_TREATMENT_TEMPLATE_TYPE = {
  [REVIEW_TREATMENT_MESSAGE_TYPE.CREATE_TREATMENT]: NOTIFICATION_TEMPLATES_TYPES.NEW_TREATMENT,
  [REVIEW_TREATMENT_MESSAGE_TYPE.SEND_OFFER]: NOTIFICATION_TEMPLATES_TYPES.TREATMENT_OFFER,
  [REVIEW_TREATMENT_MESSAGE_TYPE.RESEND_OFFER]: NOTIFICATION_TEMPLATES_TYPES.TREATMENT_OFFER
};

export const MESSAGE_MAX_LENGTH = 10000;

export const SMS_MAX_LENGTH = {
  [Splitter.GSM]: 765,
  [Splitter.UNICODE]: 335
};

export const CREATE_TREATMENT_TYPES = {
  DIAGNOSTIC_BASED_TREATMENT: 'diagnosticBased',
  TREATMENT_DIRECTORY: 'treatmentDirectory',
  TREATMENT_FROM_SCRATCH: 'treatmentFromScratch'
};

export const TEMPLATE_TYPE_NAME = {
  [MESSAGE_TEMPLATE_TYPES.EMAIL]: 'Email',
  [MESSAGE_TEMPLATE_TYPES.FACEBOOK]: 'Facebook',
  [MESSAGE_TEMPLATE_TYPES.SMS]: 'SMS'
};

export const NOTIFICATION_TEMPLATE_TITLE_KEYS_POSTFIX = {
  [NOTIFICATION_TEMPLATES_TYPES.QUESTIONNAIRE_COMPLETED]: 'questionnaireCompleted',
  [NOTIFICATION_TEMPLATES_TYPES.INCOMPLETE_QUESTIONNAIRE]: 'incompleteQuestionnaire',
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED]: 'treatmentCompleted',
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED_MICRONEEDLING]:
    'treatmentCompletedMicroneedling',
  [NOTIFICATION_TEMPLATES_TYPES.NEW_TREATMENT]: 'newTreatmentCreated',
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_OFFER]: 'treatmentOffer',
  [NOTIFICATION_TEMPLATES_TYPES.RENEW_OFFER]: 'renewTreatment',
  [NOTIFICATION_TEMPLATES_TYPES.RENEW_OFFER_MICRONEEDLING]: 'renewTreatmentMicroneedling',
  [NOTIFICATION_TEMPLATES_TYPES.REMINDER_OFFER]: 'reminderOffer'
};

export const NOTIFICATION_TEMPLATE_DESCRIPTION_TRANSLATION_POSTFIX = {
  [NOTIFICATION_TEMPLATES_TYPES.QUESTIONNAIRE_COMPLETED]: 'questionnaireCompletedDescription',
  [NOTIFICATION_TEMPLATES_TYPES.INCOMPLETE_QUESTIONNAIRE]: 'questionnaireIncompleteDescription',
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED]: 'treatmentCompletedDescription',
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED_MICRONEEDLING]:
    'treatmentCompletedMicroneedlingDescription',
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_OFFER]: 'treatmentOfferDescription',
  [NOTIFICATION_TEMPLATES_TYPES.RENEW_OFFER]: 'renewTreatmentDescription',
  [NOTIFICATION_TEMPLATES_TYPES.RENEW_OFFER_MICRONEEDLING]:
    'renewTreatmentMicroneedlingDescription',
  [NOTIFICATION_TEMPLATES_TYPES.REMINDER_OFFER]: 'reminderOffer'
};

export const PERSONALIZE_PACKAGING_TREATMENT_DOWNLOAD_SIZE = 6;

export const SMS_TEMPLATE_CONST_SYMBOLS = 4;
export const SMS_TEMPLATE_CONST_SYMBOLS_WITH_LINK = 6;

export const MESSAGE_SECTION_VALUES = {
  greeting: '',
  bodyPartOne: '',
  bodyPartTwo: '',
  bodyPartThree: '',
  signature: ''
};

export const EMAIL_TEMPLATE_DATA_DEFAULT_VALUES = {
  subject: '',
  ...MESSAGE_TEMPLATE_TYPES
};

export const PHOTO_VUE_TYPES = {
  type: Object,
  default: () => ({})
};

export const CREATE_TREATMENT_SECTION_NAMES = {
  TREAT: 'treat',
  TREAT_FACE: 'TREAT_FACE',
  TREAT_EYES: 'TREAT_EYES',
  CLEANSE: 'cleanse',
  MOISTURIZE: 'moisturize',
  PROTECT: 'protect'
};

export const CREATE_TREATMENT_COMPLEMENTARY_CARE_SECTIONS = [
  CREATE_TREATMENT_SECTION_NAMES.CLEANSE,
  CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE,
  CREATE_TREATMENT_SECTION_NAMES.PROTECT
];

export const SERUM_USAGE_KEYS = {
  MORNING_ONLY: 'morningOnly',
  MORNING_AND_EVENING: 'morningAndEvening',
  EVENING_ONLY: 'eveningOnly',
  ONE_MORNING_ONE_EVENING: 'oneMorningOneEvening'
};

export const TREATMENT_USAGE_ICON_CLASSES = {
  [SERUM_USAGE_KEYS.MORNING_ONLY]: 'icon-morning-usage',
  [SERUM_USAGE_KEYS.MORNING_AND_EVENING]: 'icon-morning-and-evening-usage',
  [SERUM_USAGE_KEYS.EVENING_ONLY]: 'icon-evening-usage'
};

export const SERUM_USAGE_ABBREVIATION = {
  [SERUM_USAGE_KEYS.MORNING_ONLY]: 'AM',
  [SERUM_USAGE_KEYS.MORNING_AND_EVENING]: 'AM/PM',
  [SERUM_USAGE_KEYS.EVENING_ONLY]: 'PM'
};

export const TREATMENT_PRODUCT_STATUS = {
  DOCTOR_RECOMMENDATION: 'DOCTOR_RECOMMENDATION',
  REMOVED_BY_PATIENT: 'REMOVED_BY_PATIENT',
  ADDED_BY_PATIENT: 'ADDED_BY_PATIENT',
  MODIFIED_BY_PATIENT: 'MODIFIED_BY_PATIENT'
};

export const MODIFIED_TREATMENT_PRODUCT_STATUSES = [
  TREATMENT_PRODUCT_STATUS.ADDED_BY_PATIENT,
  TREATMENT_PRODUCT_STATUS.MODIFIED_BY_PATIENT,
  TREATMENT_PRODUCT_STATUS.REMOVED_BY_PATIENT
];

export const TREATMENT_PRODUCT_HINTS_CONFIG = {
  [PRODUCT_REFERENCES.NEXULTRA_O]: 'dashboard.label.nexultraOTooltip',
  [PRODUCT_REFERENCES.NEXULTRA_1]: 'dashboard.label.nexultra1Tooltip',
  [PRODUCT_REFERENCES.NEXULTRA_2]: 'dashboard.label.nexultra2Tooltip',
  [PRODUCT_REFERENCES.NEXULTRA_3]: 'dashboard.label.nexultra3Tooltip',
  [PRODUCT_REFERENCES.NEXULTRA_B]: 'dashboard.label.nexultraBTooltip',
  [PRODUCT_REFERENCES.UNIVERSKIN_H]: 'dashboard.label.universkinHTooltip',
  [PRODUCT_REFERENCES.NEXULTRA_L]: 'dashboard.label.nexultraLTooltip',
  [PRODUCT_REFERENCES.NEXULTRA_Z]: 'dashboard.label.nexultraZTooltip',
  [PRODUCT_REFERENCES.NEXULTRA_UV]: 'dashboard.label.nexultraUVTooltip',
  [PRODUCT_REFERENCES.NEXULTRA_L_BRUSH]: 'dashboard.label.nexultraLBrushTooltip',
  [PRODUCT_REFERENCES.UNIVERSKIN_M]: 'dashboard.label.universkinMTooltip',
  [PRODUCT_REFERENCES.NEXULTRA_P_LIGHT]: 'dashboard.label.nexultraPTooltip',
  [PRODUCT_REFERENCES.NEXULTRA_P_RICH]: 'dashboard.label.nexultraPTooltip'
};

export const TREATMENT_USAGE_HEADER_LABELS_KEYS = {
  [SERUM_USAGE_KEYS.MORNING_ONLY]: 'dashboard.label.morning',
  [SERUM_USAGE_KEYS.EVENING_ONLY]: 'dashboard.label.evening',
  [SERUM_USAGE_KEYS.MORNING_AND_EVENING]: 'dashboard.label.morningEveningSerum'
};

export const DEFAULT_TREATMENT_DURATION = 2;
export const DEFAULT_TREAT_EYES_TREATMENT_DURATION = 4;
export const NEXULTRA_L_DURATION = {
  50: 2,
  75: 4
};

export const NEXULTRA_B_DURATION = {
  30: 2,
  100: 4
};

export const NEXULTRA_O_DURATION = {
  100: 2,
  200: 4
};

export const NEXULTRA_P_DURATION = {
  [SERUM_USAGE_KEYS.MORNING_ONLY]: 2,
  [SERUM_USAGE_KEYS.EVENING_ONLY]: 2,
  [SERUM_USAGE_KEYS.MORNING_AND_EVENING]: 1
};

export const TIME_USAGE_DROPDOWN_OPTIONS = [
  SERUM_USAGE_KEYS.MORNING_ONLY,
  SERUM_USAGE_KEYS.MORNING_AND_EVENING,
  SERUM_USAGE_KEYS.EVENING_ONLY
];

export const TREATMENT_TIME_USAGE_CONFIG = {
  [PRODUCT_REFERENCES.NEXULTRA_1]: TIME_USAGE_DROPDOWN_OPTIONS,
  [PRODUCT_REFERENCES.NEXULTRA_2]: TIME_USAGE_DROPDOWN_OPTIONS,
  [PRODUCT_REFERENCES.NEXULTRA_3]: TIME_USAGE_DROPDOWN_OPTIONS,
  [PRODUCT_REFERENCES.NEXULTRA_P_LIGHT]: TIME_USAGE_DROPDOWN_OPTIONS,
  [PRODUCT_REFERENCES.NEXULTRA_P_RICH]: TIME_USAGE_DROPDOWN_OPTIONS
};

export const SIZE_MEASUREMENT_UNITS = {
  GRAMM: 'gramm',
  ML: 'ml'
};

export const DEFAULT_PRODUCT_ONE_BOTTLE_SIZES = {
  [PRODUCT_NAMES.SERUM_UNIT]: 15
};

export const TREATMENT_PRODUCT_SIZES_CONFIG = {
  [PRODUCT_REFERENCES.NEXULTRA_B]: ['30', '100'],
  [PRODUCT_REFERENCES.NEXULTRA_L]: ['50', '75'],
  [PRODUCT_REFERENCES.NEXULTRA_O]: ['100', '200']
};

export const TREATMENT_BOTTLE_ICONS_CONFIG = {
  [PRODUCT_REFERENCES.NEXULTRA_O]: 'icon-universkin-o',
  [PRODUCT_REFERENCES.NEXULTRA_O1]: 'icon-universkin-o',
  [PRODUCT_REFERENCES.NEXULTRA_O2]: 'icon-universkin-o',
  [PRODUCT_REFERENCES.NEXULTRA_1]: 'icon-nexultra',
  [PRODUCT_REFERENCES.NEXULTRA_2]: 'icon-nexultra',
  [PRODUCT_REFERENCES.NEXULTRA_3]: 'icon-nexultra',
  [PRODUCT_REFERENCES.NEXULTRA_B]: 'icon-nexultra-b',
  [PRODUCT_REFERENCES.NEXULTRA_B1]: 'icon-nexultra-b',
  [PRODUCT_REFERENCES.NEXULTRA_B2]: 'icon-nexultra-b',
  [PRODUCT_REFERENCES.UNIVERSKIN_H]: 'icon-universkin-h',
  [PRODUCT_REFERENCES.NEXULTRA_L]: 'icon-nexultra-sunscreen',
  [PRODUCT_REFERENCES.NEXULTRA_L1]: 'icon-nexultra-sunscreen',
  [PRODUCT_REFERENCES.NEXULTRA_L2]: 'icon-nexultra-sunscreen',
  [PRODUCT_REFERENCES.NEXULTRA_Z]: 'icon-nexultra-sunscreen',
  [PRODUCT_REFERENCES.NEXULTRA_UV]: 'icon-nexultra-uv',
  [PRODUCT_REFERENCES.NEXULTRA_L_BRUSH]: 'icon-nexultra-l-brush',
  [PRODUCT_REFERENCES.UNIVERSKIN_M]: 'icon-universkin-m',
  [PRODUCT_REFERENCES.NEXULTRA_P_LIGHT]: 'icon-nexultra-p',
  [PRODUCT_REFERENCES.NEXULTRA_P_RICH]: 'icon-nexultra-p'
};

export const DEFAULT_COMPOSITION_BOTTLE_VALUES = {
  actif1: null,
  actif2: null,
  actif3: null,
  isDuplicated: false,
  usage: SERUM_USAGE_KEYS.MORNING_ONLY,
  microneedling: null
};

export const DEFAULT_CREATE_TREATMENT_SUMMARY = {
  summaryTreatmentId: null,
  treatFace: {
    compositionBottle1: null,
    compositionBottle2: null
  },
  treatEyes: {
    compositionBottle1: null
  },
  cleanse: [],
  moisturize: [],
  protect: []
};

export const EMPTY_TREAT_SECTION_BOTTLES = {
  compositionBottle1: null,
  compositionBottle2: null
};

export const EMPTY_COMPLEMENTARY_CARE_SECTION = { products: [] };

export const ACTIVE_INGREDIENTS_KEYS = ['actif1', 'actif2', 'actif3'];

export const CREATE_TREATMENT_DEFAULT_PRODUCTS = {
  [PRODUCT_REFERENCES.NEXULTRA_O]: new Product({
    name: PRODUCT_REFERENCES.NEXULTRA_O,
    isRecommended: true,
    size: 100,
    multiplier: 1,
    price: 10
  }),
  [PRODUCT_REFERENCES.NEXULTRA_B]: new Product({
    name: PRODUCT_REFERENCES.NEXULTRA_B,
    size: 30
  }),
  [PRODUCT_REFERENCES.NEXULTRA_L]: new Product({
    name: PRODUCT_REFERENCES.NEXULTRA_L,
    usage: SERUM_USAGE_KEYS.MORNING_ONLY,
    size: 50
  }),
  [PRODUCT_REFERENCES.UNIVERSKIN_H]: new Product({
    name: PRODUCT_REFERENCES.UNIVERSKIN_H,
    usage: SERUM_USAGE_KEYS.MORNING_AND_EVENING,
    size: 15
  }),
  [PRODUCT_REFERENCES.NEXULTRA_Z]: new Product({
    name: PRODUCT_REFERENCES.NEXULTRA_Z,
    usage: SERUM_USAGE_KEYS.MORNING_ONLY,
    size: 75
  }),
  [PRODUCT_REFERENCES.NEXULTRA_UV]: new Product({
    name: PRODUCT_REFERENCES.NEXULTRA_UV,
    size: 14
  }),
  [PRODUCT_REFERENCES.NEXULTRA_L_BRUSH]: new Product({
    name: PRODUCT_REFERENCES.NEXULTRA_L_BRUSH,
    size: 14
  }),
  [PRODUCT_REFERENCES.UNIVERSKIN_M]: new Product({
    name: PRODUCT_REFERENCES.UNIVERSKIN_M,
    usage: SERUM_USAGE_KEYS.MORNING_AND_EVENING,
    size: 150
  }),
  [PRODUCT_REFERENCES.NEXULTRA_P_LIGHT]: new Product({
    name: PRODUCT_REFERENCES.NEXULTRA_P_LIGHT,
    usage: SERUM_USAGE_KEYS.MORNING_AND_EVENING,
    size: 50
  }),
  [PRODUCT_REFERENCES.NEXULTRA_P_RICH]: new Product({
    name: PRODUCT_REFERENCES.NEXULTRA_P_RICH,
    usage: SERUM_USAGE_KEYS.MORNING_AND_EVENING,
    size: 50
  })
};

export const DEFAULT_ACTIVE_INGREDIENT = { name: '', nbOfCapsules: 0 };
export const DEFAULT_TREAT_SECTION_TREATMENT = {
  isRecommended: false,
  formulasOptions: {
    twoFormulas: { isFound: false },
    oneFormula: { isFound: false }
  }
};

export const CLEANSE_PRODUCTS = [PRODUCT_REFERENCES.NEXULTRA_O];

export const CLEANSE_TREATMENT_DURATION = 2;
export const MOISTURIZE_TREATMENT_MORNING_AND_EVENING_ONLY_DURATION = 1.5;
export const MOISTURIZE_TREATMENT_DEFAULT_DURATION = 3;
export const PROTECT_TREATMENT_DURATION = {
  [PRODUCT_REFERENCES.NEXULTRA_Z]: 3,
  [PRODUCT_REFERENCES.NEXULTRA_UV]: 3,
  [PRODUCT_REFERENCES.NEXULTRA_L_BRUSH]: 3,
  [PRODUCT_REFERENCES.UNIVERSKIN_M]: 2
};

export const MOISTURIZE_PRODUCTS = [
  PRODUCT_REFERENCES.NEXULTRA_P_LIGHT,
  PRODUCT_REFERENCES.NEXULTRA_P_RICH,
  PRODUCT_REFERENCES.NEXULTRA_B,
  PRODUCT_REFERENCES.NEXULTRA_L,
  PRODUCT_REFERENCES.UNIVERSKIN_H
];

export const RESTRICTED_MOISTURIZE_PRODUCTS = [
  PRODUCT_REFERENCES.NEXULTRA_P_LIGHT,
  PRODUCT_REFERENCES.NEXULTRA_P_RICH
];

export const INITIAL_MOISTURIZE_USAGES = {
  [SERUM_USAGE_KEYS.MORNING_ONLY]: null,
  [SERUM_USAGE_KEYS.EVENING_ONLY]: null,
  [SERUM_USAGE_KEYS.MORNING_AND_EVENING]: null
};

export const PROTECT_PRODUCTS = [
  PRODUCT_REFERENCES.NEXULTRA_Z,
  PRODUCT_REFERENCES.NEXULTRA_UV,
  PRODUCT_REFERENCES.NEXULTRA_L_BRUSH,
  PRODUCT_REFERENCES.UNIVERSKIN_M
];

export const SECTION_NAME_TO_PRODUCTS_BINGING = {
  [CREATE_TREATMENT_SECTION_NAMES.CLEANSE]: CLEANSE_PRODUCTS,
  [CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE]: MOISTURIZE_PRODUCTS,
  [CREATE_TREATMENT_SECTION_NAMES.PROTECT]: PROTECT_PRODUCTS
};

export const CHANGABLE_SIZE_PRODUCTS = [
  PRODUCT_REFERENCES.NEXULTRA_B,
  PRODUCT_REFERENCES.NEXULTRA_L,
  PRODUCT_REFERENCES.NEXULTRA_O
];

export const CHANGABLE_SIZE_PRODUCTS_REFERENCES = [
  PRODUCT_REFERENCES.NEXULTRA_B1,
  PRODUCT_REFERENCES.NEXULTRA_B2,
  PRODUCT_REFERENCES.NEXULTRA_L1,
  PRODUCT_REFERENCES.NEXULTRA_L2,
  PRODUCT_REFERENCES.NEXULTRA_O1,
  PRODUCT_REFERENCES.NEXULTRA_O2
];

export const CHANGABLE_SIZE_PRODUCTS_BINDINGS = {
  [PRODUCT_REFERENCES.NEXULTRA_B]: {
    30: PRODUCT_REFERENCES.NEXULTRA_B1,
    100: PRODUCT_REFERENCES.NEXULTRA_B2
  },
  [PRODUCT_REFERENCES.NEXULTRA_L]: {
    50: PRODUCT_REFERENCES.NEXULTRA_L1,
    75: PRODUCT_REFERENCES.NEXULTRA_L2
  },
  [PRODUCT_REFERENCES.NEXULTRA_O]: {
    100: PRODUCT_REFERENCES.NEXULTRA_O1,
    200: PRODUCT_REFERENCES.NEXULTRA_O2
  }
};

export const CHANGABLE_SIZE_PRODUCTS_NAMES = {
  [PRODUCT_REFERENCES.NEXULTRA_B1]: PRODUCT_REFERENCES.NEXULTRA_B,
  [PRODUCT_REFERENCES.NEXULTRA_B2]: PRODUCT_REFERENCES.NEXULTRA_B,
  [PRODUCT_REFERENCES.NEXULTRA_L1]: PRODUCT_REFERENCES.NEXULTRA_L,
  [PRODUCT_REFERENCES.NEXULTRA_O1]: PRODUCT_REFERENCES.NEXULTRA_O,
  [PRODUCT_REFERENCES.NEXULTRA_O2]: PRODUCT_REFERENCES.NEXULTRA_O
};

export const TREATMENT_TYPES = {
  FROM_SCRATCH: 'FROM_SCRATCH',
  DIAGNOSTIC: 'DIAGNOSTIC',
  TREATMENT_DIRECTORY: 'TREATMENT_DIRECTORY'
};

export const NEW_TREATMENT_FLOW_TYPES = {
  TREATMENT_FROM_SCRATCH: 'treatment-from-scratch',
  TREATMENT_FROM_PHOTO_ANALYSIS: 'treatment-from-photo-analysis',
  TREATMENT_FROM_TREATMENT_DIRECTORY: 'treatment-from-treatment-directory'
};

export const PHOTO_ANALYSIS_ROUTING_INFO = new RoutingInfo(
  'photo-analysis-add-photo-modal',
  'PhotoAnalysisAddPhoto'
);

export const PHOTO_ANALYSIS_WITH_PREVIOUS_ROUTING_INFO = new RoutingInfo(
  'photo-analysis-add-photo-modal',
  'PhotoAnalysisAddPhotoWithPrevious'
);

export const ANALYZING_ROUTING_INFO = new RoutingInfo('analyzing-photo-modal', 'AnalyzingPhoto', {
  canUploadAnother: false
});

export const TREATMENT_SELECTION_ROUTING_INFO = new RoutingInfo(
  'treatment-selection-modal',
  'TreatmentSelection',
  { isHeaderWithBackButton: true }
);

export const TWO_FACTOR_AUTHENTICATION_SET_UP_ROUTING_INFO = new RoutingInfo(
  'two-factor-authentication-set-up-modal',
  'TwoFactorAuthenticationSetUp',
  { isHeaderWithBackButton: false }
);

export const RECOVERY_CODES_ROUTING_INFO = new RoutingInfo(
  'authentication-recovery-codes-modal',
  'TwoFactorAuthenticationRecoveryCodes'
);

export const AUTHENTICATION_SCAN_CODE_ROUTING_INFO = new RoutingInfo(
  'authentication-scan-code-modal',
  'TwoFactorAuthenticationScanCode'
);

export const AUTHENTICATION_MANUAL_FLOW_ROUTING_INFO = new RoutingInfo(
  'authentication-manual-flow-modal',
  'TwoFactorAuthenticationManualFlow'
);

export const PROFILE_ROUTING_INFO = new RoutingInfo('/dashboard/profile', 'UserProfile', {
  isHeaderWithBackButton: false
});

export const CLARIFICATION_QUESTION_PREFIX = 'q';

export const STORE_IMAGE_OPTIONS = {
  STORE: true,
  DO_NOT_STORE: false
};

export const PHOTO_ANALYSIS_INITIAL_STATE = {
  photoId: null,
  hasPermissionToDelete: false,
  result: null,
  spiderChart: null,
  updatedResults: null,
  storeImageOption: STORE_IMAGE_OPTIONS.STORE
};

export const TREATMENT_DIRECTORY_INITIAL_STATE = {
  QUESTIONS: {
    name: null,
    gender: null,
    phototype: null,
    sensitiveSkin: null,
    skinThickness: null
  },
  RECOMMENDATIONS: null
};

export const SPF_WARNING_RECOMMENDATION = 'highlyRecommendedSPF';

export const INGREDIENT_SEND_IGNORE_FIELDS = ['color', 'selection'];

export const DIAGNOSTIC_TYPES = {
  QUESTIONNAIRE_BASED: 'QUESTIONNAIRE_BASED',
  PHOTO_BASED: 'PHOTO_BASED'
};

export const PERSON_DATA_FIELDS_DEFAULT_VALUES = {
  gender: null,
  phototype: null,
  skinThickness: null,
  sensitiveSkin: null
};

export const SECTION_DATA_FIELDS_DEFAULT_VALUES = {
  hasAcneScars: null,
  wrinklesDepth: null,
  hasRosacea1: null,
  hasCouperose: null,
  dryness: null,
  dehydration: null,
  hasAcneVulgaris: null,
  hasAcneRosacea: null,
  hasAcneNodular: null,
  comesFromPIH: null,
  comesFromAgeSpotsMelasma: null
};

export const PERSON_DATA_FIELDS_NAMES = Object.keys(PERSON_DATA_FIELDS_DEFAULT_VALUES);
export const SELECT_DATA_FIELDS_NAMES = Object.keys(SECTION_DATA_FIELDS_DEFAULT_VALUES);
export const SELECT_DATA_FIELDS = [...PERSON_DATA_FIELDS_NAMES, ...SELECT_DATA_FIELDS_NAMES];

export const ANALYSIS_RESULT_COLORS = {
  YELLOW: 'yellow',
  PINK: 'pink',
  RED: 'red',
  BLUE: 'blue',
  ORANGE: 'orange',
  GREY: 'grey',
  GREEN: 'green',
  BROWN: 'brown'
};

export const ANALYSIS_RESULT_COLORS_ARRAY = Object.values(ANALYSIS_RESULT_COLORS);

export const COLORS_TO_ACTIVES_COMPATIBILITY = {
  [ANALYSIS_RESULT_COLORS.YELLOW]: ACTIVES.OXIDATIVE_STRESS,
  [ANALYSIS_RESULT_COLORS.PINK]: ACTIVES.SKIN_APPEARANCE,
  [ANALYSIS_RESULT_COLORS.RED]: ACTIVES.SKIN_REDNESS,
  [ANALYSIS_RESULT_COLORS.BLUE]: ACTIVES.SKIN_DRYNESS,
  [ANALYSIS_RESULT_COLORS.ORANGE]: ACTIVES.SKIN_OILINESS,
  [ANALYSIS_RESULT_COLORS.GREY]: ACTIVES.SKIN_TEXTURE,
  [ANALYSIS_RESULT_COLORS.GREEN]: ACTIVES.PIMPLES,
  [ANALYSIS_RESULT_COLORS.BROWN]: ACTIVES.SKIN_PIGMENTATION
};

export const ACTIVES_TO_COLORS_COMPATIBILITY = {
  [ACTIVES.OXIDATIVE_STRESS]: ANALYSIS_RESULT_COLORS.YELLOW,
  [ACTIVES.SKIN_APPEARANCE]: ANALYSIS_RESULT_COLORS.PINK,
  [ACTIVES.SKIN_REDNESS]: ANALYSIS_RESULT_COLORS.RED,
  [ACTIVES.SKIN_DRYNESS]: ANALYSIS_RESULT_COLORS.BLUE,
  [ACTIVES.SKIN_OILINESS]: ANALYSIS_RESULT_COLORS.ORANGE,
  [ACTIVES.SKIN_TEXTURE]: ANALYSIS_RESULT_COLORS.GREY,
  [ACTIVES.PIMPLES]: ANALYSIS_RESULT_COLORS.GREEN,
  [ACTIVES.SKIN_PIGMENTATION]: ANALYSIS_RESULT_COLORS.BROWN
};

export const HIGHLY_RECOMMENDED_SELECTION_SCORE = 4;
export const MOST_RECOMMENDED_SELECTION_SCORE = 3;

export const DROPDOWN_TYPES = {
  USAGE: 'usage',
  SIZE: 'size',
  CONCENTRATION: 'concentration'
};

export const SECTION_DYSFUNCTION_QUESTION_NAME_POSTFIX_BINDING = {
  hasAcneScars: 'dashboard.photoAnalysis.label.acneScars',
  wrinklesDepth: 'dashboard.photoAnalysis.label.wrinkles',
  hasRosacea1: 'dashboard.photoAnalysis.label.rosacea',
  hasCouperose: 'dashboard.photoAnalysis.label.couperose',
  dryness: 'dashboard.photoAnalysis.label.dryness',
  dehydration: 'dashboard.photoAnalysis.label.dehydration',
  hasAcneVulgaris: 'dashboard.photoAnalysis.label.acneVulgaris',
  hasAcneRosacea: 'dashboard.photoAnalysis.label.acneRosacea',
  hasAcneNodular: 'dashboard.photoAnalysis.label.acneNodular',
  comesFromPIH: 'dashboard.photoAnalysis.label.pih',
  comesFromAgeSpotsMelasma: 'dashboard.photoAnalysis.ageSpot'
};

export const REDEEM_CARD_TYPES = {
  AMAZON_50: 'amazon50',
  FREE_PRODUCTS: 'freeProducts'
};

export const REDEEM_TYPES = {
  GIFT_CARD_REDEEM: 'gift-card',
  FREE_PRODUCT_REDEEM: 'free-products',
  TRANSFER: 'transfer'
};

export const REDEEM_CARD_TYPE_TO_REDEEM_TYPE_BINDING = {
  [REDEEM_CARD_TYPES.AMAZON_50]: REDEEM_TYPES.GIFT_CARD_REDEEM,
  [REDEEM_CARD_TYPES.FREE_PRODUCTS]: REDEEM_TYPES.FREE_PRODUCT_REDEEM
};

export const REDEEM_OPERATIONS = {
  [REDEEM_TYPES.GIFT_CARD_REDEEM]: 'REDEEM_FOR_GIFT_CARD',
  [REDEEM_TYPES.FREE_PRODUCT_REDEEM]: 'REDEEM_FOR_FREE_PRODUCT',
  [REDEEM_TYPES.TRANSFER]: 'TRANSFER'
};

export const TUTORIAL_ROUTING_INFO = new RoutingInfo('tutorial-modal', 'Tutorial', {
  tutorialScript: TUTORIAL_CONFIG_TYPE.CREATE_TREATMENT
});

export const TREAT_SECTIONS = {
  FACE_SECTION: 'FACE_SECTION',
  EYE_CONTOUR: 'EYE_CONTOUR'
};

export const UPDATE_PATIENT_FIELDS = [
  'id',
  'firstName',
  'lastName',
  'gender',
  'email',
  'smsAccepted',
  'marketingEmailAccepted',
  'dateOfBirth',
  'phone',
  'phoneIsoCode',
  'patientAddress',
  'clinicDto',
  'locale'
];

export const ADDITIONAL_INFO_TYPE = {
  NOTE: 'note',
  TIP: 'tip',
  REMEMBER: 'remember'
};

export const TREATMENT_COMPOSITION_PRODUCT_TYPE = {
  SERUM: 'SERUM',
  COMPLEMENTARY_CARE: 'COMPLEMENTARY_CARE',
  MICRONEEDLING: 'MICRONEEDLING'
};

export const TREATMENT_COMPOSITION_SECTION_KEY = {
  [CREATE_TREATMENT_SECTION_NAMES.TREAT_FACE]: 'dashboard.treatmentComposition.face',
  [CREATE_TREATMENT_SECTION_NAMES.TREAT_EYES]: 'dashboard.treatmentComposition.eyes',
  [CREATE_TREATMENT_SECTION_NAMES.CLEANSE]: 'dashboard.treatmentComposition.cleanser',
  [CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE]: 'dashboard.treatmentComposition.moisturizer',
  [CREATE_TREATMENT_SECTION_NAMES.PROTECT]: {
    [PRODUCT_REFERENCES.NEXULTRA_Z]: 'dashboard.treatmentComposition.sunProtection',
    [PRODUCT_REFERENCES.NEXULTRA_UV]: 'dashboard.treatmentComposition.sunProtection',
    [PRODUCT_REFERENCES.NEXULTRA_L_BRUSH]: 'dashboard.treatmentComposition.sunProtection',
    [PRODUCT_REFERENCES.UNIVERSKIN_M]: 'dashboard.treatmentComposition.universkinM'
  }
};

export const ACTIVES_PROMO_NUMBER = 6;

export const TEMPLATE_ERRORS = ['subject', 'greeting', 'bodyPartOne', 'patientEmailAgreement'];

export const COMPLEMENTARY_PRODUCT_SIZE_UNITS = {
  [PRODUCT_REFERENCES.NEXULTRA_O]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_O1]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_O2]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_1]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_2]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_3]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_B]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_B1]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_B2]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.UNIVERSKIN_H]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_L]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_L1]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_L2]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_Z]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_UV]: SIZE_MEASUREMENT_UNITS.GRAMM,
  [PRODUCT_REFERENCES.NEXULTRA_L_BRUSH]: SIZE_MEASUREMENT_UNITS.GRAMM,
  [PRODUCT_REFERENCES.UNIVERSKIN_M]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_P_LIGHT]: SIZE_MEASUREMENT_UNITS.ML,
  [PRODUCT_REFERENCES.NEXULTRA_P_RICH]: SIZE_MEASUREMENT_UNITS.ML
};

export const PRODUCT_SIZE_LABEL = {
  [SIZE_MEASUREMENT_UNITS.ML]: 'dashboard.label.ml',
  [SIZE_MEASUREMENT_UNITS.GRAMM]: 'dashboard.label.gramm'
};

export const MARKETING_CAMPAIGN_TITLES_KEY = {
  questionnaire: 'dashboard.marketingCampaignTitle.questionnaire',
  summerGlow: 'dashboard.marketingCampaignTitle.summerGlow',
  perfectSkin: 'dashboard.marketingCampaignTitle.perfectSkin',
  winterBlues: 'dashboard.marketingCampaignTitle.winterBlues',
  springSprung: 'dashboard.marketingCampaignTitle.springSprung',
  searchOver: 'dashboard.marketingCampaignTitle.searchOver',
  noTime: 'dashboard.marketingCampaignTitle.noTime',
  selfCareDeserve: 'dashboard.marketingCampaignTitle.selfCareDeserve',
  selfCareSelfLove: 'dashboard.marketingCampaignTitle.selfCareSelfLove',
  treatYourself: 'dashboard.marketingCampaignTitle.treatYourself'
};

export const MEDICAL_DATA_TRANSFER_SOURCE = {
  DASHBOARD: 'DASHBOARD',
  QUESTIONNAIRE: 'QUESTIONNAIRE'
};

export const CREATOR_TYPES = {
  ADMIN: 'ADMIN',
  DOCTOR: 'DOCTOR',
  AUTOPILOT: 'AUTOPILOT'
};
