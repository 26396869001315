import { omit } from 'lodash';

import { i18n } from '@/i18n';

import { getInitialState } from '@/store/state';

import { types } from '@/store/types';

export default {
  [types.mutations.RESET_MODULE](state) {
    Object.assign(state, omit(getInitialState(), types.state.LOCALE));

    i18n.locale = state[types.state.LOCALE];
  },

  [types.mutations.SET_LOADING](state, loading) {
    state[types.state.STATUS].loading = loading;
  },

  [types.mutations.SET_USERNAME](state, username) {
    state[types.state.USER_NAME] = username;
  },

  [types.mutations.SET_USER](state, user) {
    state[types.state.USER] = user;
  },

  [types.mutations.SET_LOCALE](state, locale) {
    state[types.state.LOCALE] = locale;
  },

  [types.mutations.SET_USER_PHOTO](state, photo) {
    state[types.state.USER].photo = photo;
  },

  [types.mutations.SET_USER_RECOVERY_CODES](state, recoveryCodes) {
    state[types.state.USER_AUTHENTICATION_DATA].recoveryCodes = recoveryCodes;
  },

  [types.mutations.SET_USER_AUTHENTICATION_CODE](state, code) {
    state[types.state.USER_AUTHENTICATION_DATA].authenticatationCode = code;
  }
};
