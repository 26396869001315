/**
 * Provides async variant of `vue-js-modal`'s `show()` function.
 *
 * - it wraps `show()` call into Promise
 * - it provides `onClose` callback to the modal being opened
 * - it exposes `Vue.prototype.$asyncModal.show()` method
 */
export default {
  install(Vue) {
    Vue.prototype.$asyncModal = {};

    Vue.prototype.$asyncModal.show = (modal, params) => {
      if (!Vue.prototype.$modal) {
        throw Error('Missing required plugin `vue-js-modal`');
      }

      return new Promise(resolve => {
        const onClose = (...outputArgs) => {
          Vue.prototype.$modal.hide(modal);
          resolve(...outputArgs);
        };

        Vue.prototype.$modal.show(modal, { ...params, onClose });
      });
    };
  }
};
